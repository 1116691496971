import CustomRenderHTML from "components/CustomRenderHTML"
import { modal } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { useTab } from "providers/TabProvider"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import type { ListRenderItem, StyleProp, ViewStyle } from "react-native"
import { FlatList, View } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"

interface IProps {
  isBottom?: boolean
  indexing: Array<{ index: string; rules: Array<string | undefined> | undefined }>
  scrollViewStyle?: StyleProp<ViewStyle>
}

const keyExtractor = (item: string): string => item

const RuleTabContent: FC<IProps> = ({ indexing, isBottom }) => {
  const t = useTypedTranslation()

  const {
    colors: { onSurface },
    dimensions: { margin: themeMargin },
  } = useTheme()

  const { onLayout, width } = useLayout()

  const { currentTab } = useTab()
  const cs = useCommonStyles()

  const data = currentTab?.index
    ? _.find(indexing, value => value.index === currentTab.index)?.rules
    : undefined
  const { style, scrollView, contentView } = useStyles(
    ({ dimensions: { margin } }) => ({
      scrollView: {
        height: modal.ACQUIRED_SCROLL_VIEW,
      },
      contentView: {
        paddingBottom: isBottom ? modal.SCROLL_VIEW : undefined,
        minHeight: modal.ACQUIRED_SCROLL_VIEW,
      },
      style: {
        marginHorizontal: margin,
        marginTop: margin,
      },
    }),
    [isBottom],
  )

  const customStyle = useMemo<MixedStyleDeclaration>(
    () => ({
      marginHorizontal: themeMargin,
      marginTop: themeMargin / 2,
    }),
    [themeMargin],
  )

  const renderItem: ListRenderItem<string> = useCallback(
    ({ item }) => (
      <View style={[cs.fullWidth, style]} {...{ onLayout }}>
        <CustomRenderHTML
          content={t(`${ns.MODAL}.ruleAcquired.subtitle`, { rule: item })}
          typographyVariant="subtitle1"
          width={width}
          // do NOT use a style from a StyleSheet here
          {...{ customStyle }}
          overrideColor={onSurface.highEmphasis}
        />
      </View>
    ),
    [cs.fullWidth, customStyle, onLayout, onSurface.highEmphasis, style, t, width],
  )
  return data && currentTab ? (
    <FlatList
      style={scrollView}
      contentContainerStyle={contentView}
      {...{ data, keyExtractor, renderItem }}
    />
  ) : null
}

export default RuleTabContent
