import CustomRenderHTML from "components/CustomRenderHTML"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import _ from "lodash"
import type { ModalType } from "models/ModalInterfaces"
import type { FC, PropsWithChildren } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { StyleProp, TextStyle } from "react-native"
import { View } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import type { TypographyVariant } from "theme/types"

import { H6 } from "../Texts"

interface IProps {
  bodySubtitle?: string
  bodySubtitleStyle?: StyleProp<TextStyle>
  bodyTitle?: string
  bodyTitleStyle?: StyleProp<TextStyle>
  hasImage: boolean
  isEvaluation?: boolean
  numberOfMasteredRules?: number
  type: ModalType
  typographyVariant?: TypographyVariant
}

const ModalBody: FC<PropsWithChildren<IProps>> = ({
  bodySubtitle,
  bodySubtitleStyle,
  bodyTitle,
  bodyTitleStyle,
  children,
  hasImage,
  isEvaluation = false,
  numberOfMasteredRules,
  type,
  typographyVariant,
}) => {
  const { t } = useTranslation()

  const title =
    bodyTitle ??
    t([`${ns.MODAL}.${type}.title`, "common.empty"], {
      count: numberOfMasteredRules,
    })

  const subtitle = bodySubtitle ?? t([`${ns.MODAL}.${type}.subtitle`, "common.empty"])

  const cs = useCommonStyles()

  const { onLayout, width } = useLayout()

  const { style, ...s } = useStyles(
    ({
      dimensions: { spacing, margin },
      colors: {
        surface: { backgroundModal },
        onSurface: { mediumEmphasis },
      },
    }) => ({
      style: {
        alignItems: isEvaluation ? undefined : ("center" as const),
        backgroundColor: backgroundModal,
        marginTop: !hasImage || isEvaluation ? undefined : margin * 3,
        paddingHorizontal: spacing,
      },
      title: {
        paddingTop: spacing,
        textAlign: "left" as const,
      },
      subtitle: {
        color: mediumEmphasis,
        paddingTop: spacing,
      },
    }),
    [hasImage, isEvaluation],
  )

  const subtitleStyle = useMemo(
    () =>
      ({
        textAlign: !hasImage || isEvaluation ? ("left" as const) : ("center" as const),
      } as MixedStyleDeclaration),
    [hasImage, isEvaluation],
  )

  return (
    <>
      <View {...{ style }}>
        <>
          {_.isEmpty(title) ? null : (
            <H6
              style={[
                cs.textCenter,
                !hasImage || isEvaluation ? s.title : undefined,
                bodyTitleStyle,
              ]}
            >
              {title}
            </H6>
          )}
          {!subtitle || subtitle === "" ? null : (
            <View {...{ onLayout }} style={[s.subtitle, bodySubtitleStyle]}>
              <CustomRenderHTML
                isAlignCenter={!isEvaluation}
                content={subtitle}
                typographyVariant={typographyVariant || "subtitle1"}
                width={width}
                customStyle={subtitleStyle}
              />
            </View>
          )}
        </>
      </View>
      {children}
    </>
  )
}

export default ModalBody
