import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import type { TFunction } from "hooks/useTypedTranslation"

dayjs.extend(duration)

export const formatTimeByDuration = (value: number, t: TFunction): string =>
  dayjs
    .duration(value, "seconds")
    .format(t(value >= 3600 ? "common.durationFormat" : "common.durationMin"))

export const getCurrentISODate = (): string => new Date().toISOString()
