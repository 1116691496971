import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const CheckLevelSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { primary_400 },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 34} height={props?.height ?? 35} viewBox="0 0 34 35" fill="none">
      <Path
        fill={props?.color ?? primary_400}
        stroke={props?.color ?? primary_400}
        strokeWidth={2}
        d="M13.25 23.213 8.038 18l-1.775 1.763 6.987 6.987 15-15-1.762-1.762L13.25 23.213Z"
      />
    </Svg>
  )
}

export default CheckLevelSVG
