import type { LevelId } from "@newpv/js-common"
import { useNavigation } from "@react-navigation/native"
import { ModalListContent } from "components/Modal"
import { iconSize } from "constants/constants"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import type { ModalResponse } from "models/ModalInterfaces"
import { ModalType } from "models/ModalInterfaces"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useModal } from "providers/ModalProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useCallback, useEffect, useRef } from "react"
import LockSVG from "svgs/Lock"
import ReplaySVG from "svgs/Replay"

export function useModuleAndLevelModals(): {
  showWelcomeModal: () => void
  showRetryModal: (levelId?: LevelId) => Promise<ModalResponse>
  showDisabledModal: (type?: ModalType, final?: boolean) => void
} {
  const navigation = useNavigation()
  const t = useTypedTranslation()
  const { scenario, progressionData } = useScenarioAndModule()
  const { setIsRetryLevel, setLevelId, setRevisionLevelWithRules, setLevelType } =
    useLevelAndEvaluation()
  const { showModal } = useModal()
  const showWelcomeModalOnce = useRef(false)
  const {
    dispatchPreferences,
    preferenceState: { hasWelcomeModalBeenDisplayed },
  } = usePreferences()
  const showRetryModal = useCallback(
    (levelId?: LevelId) =>
      showModal({
        type: ModalType.RETRY,
        dismissable: true,
        headerImage: props => (
          <ReplaySVG {...props} width={iconSize.LARGE} height={iconSize.LARGE} />
        ),
        positive: {
          label: t("common.button.replay"),
          onPress: () => {
            setLevelId(levelId)
            setLevelType("static")
            setIsRetryLevel(true)
            setRevisionLevelWithRules(undefined)
            setTimeout(() => navigation.navigate(ns.EXERCISE), 100)
          },
        },
      }),
    [
      showModal,
      t,
      setLevelId,
      setLevelType,
      setIsRetryLevel,
      setRevisionLevelWithRules,
      navigation,
    ],
  )

  const showDisabledModal = useCallback(
    (type: ModalType) => {
      // noinspection JSIgnoredPromiseFromCall
      showModal({
        type,
        dismissable: true,
        headerImage: props => <LockSVG {...props} width={iconSize.LARGE} height={iconSize.LARGE} />,
        positive: {
          label: t("common.button.ok"),
        },
      })
    },
    [showModal, t],
  )

  const showWelcomeModal = useCallback(
    () =>
      showModal({
        type: ModalType.WELCOME,
        dismissable: false,
        positive: {
          label: t("common.button.start"),
          onPress: () => {
            dispatchPreferences({ type: "set", name: "hasWelcomeModalBeenDisplayed", value: true })
          },
        },
        children: <ModalListContent type={ModalType.WELCOME} isWelcome={true} />,
      }),
    [dispatchPreferences, showModal, t],
  )

  useEffect(() => {
    if (!scenario || showWelcomeModalOnce.current || hasWelcomeModalBeenDisplayed) {
      return
    }
    if (
      progressionData?.progressionDetail.trainingDuration === 0 &&
      scenario.initialEvaluation == null
    ) {
      showWelcomeModalOnce.current = true
      // noinspection JSIgnoredPromiseFromCall
      showWelcomeModal()
      return
    }
  }, [hasWelcomeModalBeenDisplayed, progressionData, scenario, showWelcomeModal])

  return { showRetryModal, showDisabledModal, showWelcomeModal }
}
