import type { ClickOnMistakeExercise, Result } from "@newpv/js-common"
import CorrectionSentence from "components/Correction/CorrectionSentence"
import { ExerciseSentence } from "components/Texts"
import { buttonMaxWidth, isAndroid } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { useCallback, useState } from "react"
import { KeyboardAvoidingView, View } from "react-native"
import { generateSentence } from "utils/generateSentence"

import { Button } from "../Button"

interface Props {
  exercise?: ClickOnMistakeExercise
  headerHeight?: number
  result?: Result
  onSelectPress?: (result: Result) => Promise<void>
}

const Dictation = ({ exercise, result, onSelectPress }: Props): JSX.Element => {
  const t = useTypedTranslation()
  const [dictationAnswers, setDictationAnswers] = useState<Record<string, string>>()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ typography: { exerciseSentence }, roundness, dimensions: { spacing, margin } }) => ({
      buttonStyle: {
        alignSelf: "center",
        borderRadius: roundness * 4,
        marginVertical: margin / 2,
        maxWidth: buttonMaxWidth,
        width: "100%" as const,
      },
      container: {
        marginHorizontal: margin,
        paddingBottom: spacing,
      },
      textInput: {
        borderRadius: 0,
        fontSize: exerciseSentence.fontSize,
        height: (exerciseSentence?.fontSize ?? 0) + spacing * 0.75,
        marginTop: margin,
        paddingHorizontal: spacing * 0.375,
        width: 100,
      },
      word: {
        marginTop: margin * 1.5,
      },
    }),
    [result],
  )

  const submitAnswer = useCallback(async () => {
    const answerSentence = generateSentence(
      exercise?.sentence.map((wordBlock, index) =>
        (exercise.hasMistake && wordBlock.mistake) || (!exercise.hasMistake && wordBlock.clue)
          ? { ...wordBlock, text: dictationAnswers?.[index].trim() ?? "" }
          : wordBlock,
      ),
    ).trim()

    const answerSentenceVariations = [
      answerSentence,
      // this takes into account exercises with multiple corrections starting by a) or b) - although they are probably not convertibleToDictation
      `a) ${answerSentence}`,
      `b) ${answerSentence}`,
    ]

    const correctionSentences =
      exercise?.corrections && exercise.corrections.length > 0
        ? exercise.corrections?.map(correction => generateSentence(correction))
        : !exercise?.hasMistake && _.some(exercise?.sentence, w => w.clue)
        ? [generateSentence(exercise?.sentence)]
        : []

    const answerAndCorrectionIntersection = _.intersection(
      answerSentenceVariations,
      correctionSentences,
    )

    await onSelectPress?.((answerAndCorrectionIntersection?.length ?? 0) > 0 ? "correct" : "wrong")
  }, [dictationAnswers, exercise, onSelectPress])

  return (
    <KeyboardAvoidingView enabled={isAndroid}>
      <View style={s.container}>
        {exercise ? (
          <CorrectionSentence
            wordBlocks={exercise.sentence}
            baseTypography="exerciseSentence"
            Comp={ExerciseSentence}
            isDictation={true}
            {...{ dictationAnswers, setDictationAnswers, exercise }}
          />
        ) : null}

        <Button style={s.buttonStyle} labelStyle={[cs.labelClickOnMistake]} onPress={submitAnswer}>
          {t("common.button.submitAnswer")}
        </Button>
      </View>
    </KeyboardAvoidingView>
  )
}

export default Dictation
