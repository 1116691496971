import type { Result } from "@newpv/js-common"
import { isWeb } from "constants/constants"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import type { Dispatch, SetStateAction } from "react"
import { useCallback } from "react"
import type { GestureResponderEvent, LayoutChangeEvent, StyleProp, TextStyle } from "react-native"
import { Pressable, View } from "react-native"
import wait from "utils/wait"

import { ExerciseSentence } from "../Texts"

interface PressableWordProps {
  word: string
  index: number | string
  isCorrectAnswer: boolean
  sentenceHasAMistake: boolean
  setLocalCoords?: ({ x, y }: { x: number; y: number }) => void
  setHover: Dispatch<SetStateAction<boolean>>
  onSelectPress?: (value: Result) => Promise<void>
  result?: Result
  isEvaluation?: boolean
  innerTextStyle?: StyleProp<TextStyle>
  onLayout?: (event: LayoutChangeEvent) => void
}

const PressableWord = ({
  word,
  index,
  isCorrectAnswer,
  setHover,
  setLocalCoords,
  sentenceHasAMistake,
  onSelectPress,
  innerTextStyle,
  onLayout: propsOnLayout,
}: PressableWordProps): JSX.Element => {
  const { onLayout, x, y } = useLayout()

  const s = useStyles(
    ({ dimensions: { margin } }) => ({
      pressable: {
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: margin * 1.5,
        zIndex: 1,
      },
      // the zIndex is necessary for web
      text: {
        zIndex: 1,
      },
      wavyLine: {
        bottom: -1,
        left: 0,
        position: "absolute",
        zIndex: 1,
      },
    }),
    [],
  )

  const onPress = useCallback(
    async (event: GestureResponderEvent) => {
      setLocalCoords?.(
        isWeb
          ? // @ts-expect-error layerX and layerY exist on the web
            { x: event.nativeEvent.layerX + x, y: event.nativeEvent.layerY + y }
          : { x: event.nativeEvent.locationX + x, y: event.nativeEvent.locationY + y },
      )
      setHover(false)
      await wait(50)
      setHover(true)
      const res = isCorrectAnswer
        ? "correct"
        : !sentenceHasAMistake
        ? "error/no-mistake"
        : "error/wrong-word"
      await onSelectPress?.(res)
    },
    [isCorrectAnswer, onSelectPress, sentenceHasAMistake, setHover, setLocalCoords, x, y],
  )

  return (
    <Pressable
      {...{ onPress, onLayout }}
      onLayout={e => {
        onLayout(e)
        propsOnLayout?.(e)
      }}
      style={s.pressable}
      hitSlop={{ top: 0, bottom: 0, left: 4, right: 4 }}
    >
      <View style={innerTextStyle}>
        <ExerciseSentence key={`${word}-${index}`} style={[s.text, innerTextStyle]}>
          {word}
        </ExerciseSentence>
      </View>
    </Pressable>
  )
}

export default PressableWord
