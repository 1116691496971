/* eslint-disable unused-imports/no-unused-imports-ts */
// import "wdyr.js"
import "react-native-reanimated"

import React from "react"

import RootApp from "./src/RootApp"

// @ts-ignore
window.React = React

export default RootApp
