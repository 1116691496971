import { defaultMaxTabHeight, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import type { TabType } from "models/TabInterfaces"
import { useTab } from "providers/TabProvider"
import type { FC } from "react"
import { useCallback, useEffect, useState } from "react"
import type { ListRenderItem, StyleProp, TextStyle, ViewStyle } from "react-native"
import { Dimensions, FlatList, StyleSheet } from "react-native"
import useTheme from "theme/ThemeProvider"

import Tab from "./Tab"

interface IProps {
  tabs: TabType[]
  maxHeight?: number
  disabled?: boolean
  listStyle?: StyleProp<ViewStyle>
  tabLabelStyle?: StyleProp<TextStyle>
}

const keyExtractor = (item: TabType): string => `${item.index} - ${item.title}`

const TabBar: FC<IProps> = ({ tabs, disabled, listStyle, tabLabelStyle }): JSX.Element => {
  const cs = useCommonStyles()
  const [width, setWidth] = useState<number>()
  const { tabRef } = useTab()
  const { width: windowWidth } = Dimensions.get("window")
  const {
    colors: { surface },
  } = useTheme()

  const renderItem: ListRenderItem<TabType> = useCallback(
    ({ item: tab, index }) => (
      <Tab
        labelStyle={tabLabelStyle}
        key={tab.index}
        {...{ tab, index, width, disabled }}
        numberOfTabs={tabs.length}
      />
    ),
    [disabled, tabLabelStyle, tabs.length, width],
  )

  useEffect(() => {
    if (isWeb && width && windowWidth < width) {
      setWidth(windowWidth)
    }
  }, [width, windowWidth])

  return (
    <FlatList<TabType>
      style={[styles.style, listStyle]}
      contentContainerStyle={[cs.fullWidth, { backgroundColor: surface.backgroundModal }]}
      onContentSizeChange={w => setWidth(w)}
      alwaysBounceHorizontal={false}
      alwaysBounceVertical={false}
      showsHorizontalScrollIndicator={false}
      bounces={false}
      data={tabs}
      horizontal={true}
      ref={tabRef}
      {...{ renderItem, keyExtractor }}
    />
  )
}

const styles = StyleSheet.create({
  style: {
    maxHeight: defaultMaxTabHeight,
  },
})

export default TabBar
