import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const LockSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M11.683 0a7.018 7.018 0 0 0-7.018 7.018v4.002a.168.168 0 0 1-.104.136c-.377.128-.689.24-.969.352a.96.96 0 0 0-.592.864v9.211a.985.985 0 0 0 .592.872c5.2 2.06 10.99 2.06 16.19 0a.976.976 0 0 0 .584-.872V12.38a.961.961 0 0 0-.593-.864c-.28-.112-.592-.224-.968-.352a.185.185 0 0 1-.104-.136V7.026A7.02 7.02 0 0 0 11.683 0ZM9.77 15.205a1.913 1.913 0 0 1 3.825 0 1.888 1.888 0 0 1-.952 1.6v3.202a.96.96 0 0 1-1.912 0v-3.201a1.897 1.897 0 0 1-.937-1.617l-.024.016Zm6.522-8.17v3.464a21.8 21.8 0 0 0-4.61-.496 22.4 22.4 0 0 0-4.609.489V7.034a4.61 4.61 0 0 1 9.22 0Z"
        fill={props?.color ?? onSurface?.highEmphasis}
        fillOpacity={0.32}
      />
    </Svg>
  )
}

export default LockSVG
