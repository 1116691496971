import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ModuleImageChatSVG = (props?: SvgProps): JSX.Element => {
  const {
    darkMode,
    colors: {
      primary_600,
      primary_200,
      modules: { highEmphasis },
    },
  } = useTheme()

  const mainColor = darkMode ? primary_600 : primary_200
  return (
    <Svg {...props} width={props?.width ?? 100} height={props?.height ?? 82} fill="none">
      <Path
        d="M49.642 82c22.644 0 41-18.356 41-41s-18.356-41-41-41-41 18.356-41 41 18.357 41 41 41Z"
        fill={mainColor}
      />
      <Path
        d="M52.66 12.145c-5.493 0-10.908 0-16.4.078-5.416.077-10.908.154-16.323.232-2.63.232-4.951 2.011-5.802 4.41-.232.618-.31 1.237-.387 1.856v2.011l.077 4.1.232 16.4c0 .464-.387.851-.85.928a.878.878 0 0 1-.62-.232h-.077l1.393-.232-3.095 6.421-3.171 6.42-1.393-1.314 5.802-3.25 5.802-3.171c.155-.078.31-.155.464-.155 3.249 0 6.498 0 9.747.078 3.25 0 6.498.077 9.747.154l9.748.155 4.873.077c1.547 0 3.017-.386 4.255-1.315 1.238-.928 2.166-2.243 2.553-3.713.232-.774.232-1.47.232-2.32l.077-2.476.155-9.747.077-4.874v-2.475c0-.851 0-1.625-.155-2.399-.31-1.547-1.237-3.017-2.552-4.022-1.16-1.006-2.785-1.547-4.41-1.625Zm0 0c1.624 0 3.248.542 4.486 1.547 1.238 1.006 2.243 2.476 2.553 4.023.154.774.232 1.625.232 2.398v2.476l.077 4.873.155 9.747.077 2.476c0 .773 0 1.702-.154 2.475a7.402 7.402 0 0 1-2.708 4.255c-1.315 1.083-3.094 1.702-4.796 1.702l-4.874.077-9.747.155c-3.25.077-6.498.077-9.747.155-3.25.077-6.498.077-9.747.077l.464-.155-5.802 3.25-5.802 3.171c-.464.232-1.083.078-1.315-.387a.986.986 0 0 1 0-.928l3.249-6.42 3.249-6.344c.232-.464.774-.619 1.16-.387.078 0 .155.078.155.155l.078.077-1.47.696.232-16.4.077-4.1c0-.696 0-1.315.077-2.088.078-.697.233-1.47.542-2.089 1.083-2.708 3.79-4.641 6.653-4.719l16.4.232h16.245Z"
        fill={highEmphasis}
      />
      <Path
        d="m39.276 47.653.232 6.498c0 1.083 0 2.166.387 3.094.31 1.006.85 1.857 1.547 2.63.696.697 1.547 1.316 2.553 1.702.928.387 1.934.465 3.017.465l6.498-.078 12.996-.232 12.997-.077c.541 0 1.005.464 1.005.928a.878.878 0 0 1-.232.619v.077l-.31-1.547 12.842 6.344-1.315 1.392-6.343-11.604c-.078-.154-.155-.31-.155-.464l.077-13.15.078-6.576.077-3.25c0-1.005-.155-2.01-.541-2.862-.774-1.856-2.476-3.249-4.487-3.713-.232-.077-.464-.077-.774-.155-.232 0-.541 0-.773-.077h-1.625l-3.249-.077c-4.41-.155-8.742-.232-13.15-.387 4.409-.155 8.74-.31 13.15-.387l3.25-.077h2.474c.31.077.62.077.852.154 2.32.465 4.409 2.09 5.415 4.255.541 1.083.85 2.244.85 3.481l.078 3.25.077 6.575.078 13.15-.155-.463 6.42 11.603c.31.465.078 1.083-.386 1.393-.31.154-.696.154-.928 0l-12.842-6.42c-.464-.233-.696-.852-.464-1.316.077-.077.077-.155.155-.232v-.078l.696 1.625-12.996-.077-12.997-.232-6.498-.078c-1.083 0-2.32-.154-3.326-.619a7.108 7.108 0 0 1-2.708-2.01c-.773-.852-1.315-1.858-1.547-3.018-.31-1.083-.232-2.243-.232-3.326.077-2.321.155-4.487.232-6.653Z"
        fill={highEmphasis}
      />
      <Path
        d="M1.138 25.606c5.26 3.558 11.604 5.26 17.87 5.647 6.266.464 12.687-.232 18.798-1.857 3.017-.85 6.034-1.856 8.742-3.404 1.315-.773 2.63-1.624 3.558-2.785.465-.541.697-1.16.62-1.779-.078-.619-.387-1.16-1.006-1.547-1.16-.696-2.708-.773-4.178-.541-1.47.232-2.862.85-4.1 1.701-1.237.851-2.32 1.934-3.094 3.172-.774 1.238-1.238 2.708-1.392 4.177a14.604 14.604 0 0 0 .231 4.487c.31 1.47.852 2.863 1.702 4.023.851 1.16 2.012 2.011 3.404 2.553 1.315.541 2.863.773 4.332.85 3.017.155 6.111-.386 9.129-1.082a92.47 92.47 0 0 0 8.973-2.785c5.88-2.166 11.681-4.72 17.406-7.504 2.862-1.392 5.647-2.785 8.432-4.332 2.785-1.47 5.57-3.017 8.355-4.564h.154v.154c-2.63 1.857-5.26 3.559-7.968 5.26a288.663 288.663 0 0 1-8.277 4.797c-2.785 1.547-5.647 2.94-8.51 4.332-2.862 1.393-5.801 2.708-8.818 3.79-3.017 1.084-6.034 2.167-9.206 2.863-3.172.696-6.42 1.238-9.747 1.006-1.625-.078-3.327-.387-4.951-1.083a10.322 10.322 0 0 1-4.1-3.172c-1.006-1.392-1.625-3.094-1.934-4.719a15.616 15.616 0 0 1-.155-5.028c.232-1.702.774-3.327 1.702-4.72.928-1.469 2.166-2.63 3.559-3.558a11.372 11.372 0 0 1 4.641-1.779c.851-.154 1.702-.154 2.553-.077.85.077 1.702.31 2.475.85.387.233.697.62 1.006 1.006.232.387.387.929.464 1.393.078 1.006-.386 1.934-.85 2.63-1.084 1.392-2.476 2.243-3.946 3.017-2.862 1.547-5.957 2.553-9.05 3.326-6.19 1.548-12.688 2.09-19.031 1.548C12.587 31.33 6.244 29.55.984 25.838c-.078 0-.078-.078 0-.155.077-.077.154-.077.154-.077Z"
        fill={highEmphasis}
      />
    </Svg>
  )
}

export default ModuleImageChatSVG
