import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const LightBulbFullSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg
      width={props?.width ?? 10}
      height={props?.height ?? 10}
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <G clipPath="url(#clip0_5493_216022)">
        <Path
          d="M3.75 8.75c0 .208.167.417.417.417h1.667c.25 0 .417-.209.417-.417v-.417h-2.5v.417zM5 .833A2.899 2.899 0 002.085 3.75c0 1 .5 1.875 1.25 2.375v.958c0 .209.167.417.417.417h2.5c.25 0 .416-.208.416-.417v-.958c.75-.542 1.25-1.417 1.25-2.375A2.899 2.899 0 005.001.833z"
          fill={props?.color ?? onSurface.highEmphasis}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5493_216022">
          <Path fill="#fff" d="M0 0H10V10H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default LightBulbFullSVG
