import { DATA_PICKER_MAX_WIDTH, isAndroid, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import React from "react"
import { View } from "react-native"
import type { ItemType, ValueType } from "react-native-dropdown-picker"
import DropDownPicker from "react-native-dropdown-picker"
import ArrowDownSVG from "svgs/ArrowDown"
import ArrowUpSVG from "svgs/ArrowUp"
import useTheme from "theme/ThemeProvider"

import { Caption } from "../Texts"

interface IDataPickerProps {
  items: Array<ItemType<ValueType>>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  value: string | null
  setValue: (value: any) => void
  legendName: string
}

export const DataPicker: React.FC<IDataPickerProps> = ({
  items,
  open,
  value,
  setOpen,
  setValue,
  legendName,
}) => {
  const t = useTypedTranslation()
  const { darkMode, colors: themeColors } = useTheme()
  const cs = useCommonStyles()
  const s = useStyles(
    ({ colors: { surface, onSurface, onPrimary, primary }, dimensions: { spacing } }) => ({
      container: {
        borderColor: open ? primary : onSurface.mediumEmphasis,
        borderRadius: 4,
        borderWidth: open ? 2 : 1,
        flexDirection: "row",
        minWidth: isWeb ? DATA_PICKER_MAX_WIDTH : undefined,
        paddingHorizontal: spacing,
        paddingVertical: open ? spacing : spacing + 1,
        backgroundColor: surface.surfaceSecondary,
      },
      dropdownContainer: {
        borderWidth: 0,
        marginTop: 1,
        minWidth: isWeb ? DATA_PICKER_MAX_WIDTH : undefined,
        overflow: "visible",
        backgroundColor: darkMode ? surface.surface : undefined,
      },
      itemSelectedContainer: {
        backgroundColor: darkMode ? surface.surfaceSecondary : surface.background,
      },
      legendContainer: {
        backgroundColor: onPrimary.highEmphasis,
        left: spacing,
        paddingLeft: spacing / 4,
        position: "absolute",
        top: -spacing / 2,
        zIndex: 9999,
      },
      resetPaddingHorizontal: {
        paddingHorizontal: spacing,
        paddingVertical: isWeb ? spacing : undefined,
      },
    }),
    [open, themeColors.primary, themeColors.surface.outline],
  )

  return (
    <>
      {open && (
        <View style={s.legendContainer}>
          <Caption color={themeColors.primary_800}>{legendName}</Caption>
        </View>
      )}
      <DropDownPicker
        /* zIndex is necessary for Android otherwise we are unable to select an option */
        {...(isAndroid ? { zIndex: 3000 } : {})}
        {...{ items, open, value, setValue, setOpen }}
        disableBorderRadius={false}
        ArrowDownIconComponent={() => <ArrowDownSVG />}
        ArrowUpIconComponent={() => <ArrowUpSVG color={open ? themeColors.primary : undefined} />}
        style={s.container}
        listItemLabelStyle={cs.subtitleHighEmphasis}
        placeholder={t("Profile.dataPickerPlaceholder")}
        selectedItemContainerStyle={s.itemSelectedContainer}
        dropDownContainerStyle={s.dropdownContainer}
        showTickIcon={false}
        labelStyle={cs.subtitleHighEmphasis}
        translation={{
          NOTHING_TO_SHOW: t("Profile.dataPickerEmpty"),
        }}
        listParentContainerStyle={s.resetPaddingHorizontal}
        placeholderStyle={cs.subtitleHighEmphasis}
        listMode="SCROLLVIEW"
      />
    </>
  )
}
