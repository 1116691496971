import type { IColorTheme } from "./types"

export const colors: IColorTheme = {
  primary_900: "#212602",
  primary_800: "#495309",
  primary_700: "#71AD00",
  primary_600: "#8FBA13",
  primary: "#A2D416",
  primary_400: "#BEED29",
  primary_300: "#DFFB6B",
  primary_200: "#D3E387",
  primary_100: "#F0FEB4",
  primary_50: "#F9FFDF", // ripple color
  secondary: "#FEAE06",
  secondary_700: "#F08C03",
  secondary_600: "#F99E22",
  secondary_400: "#FEBD34",
  secondary_350: "#F7C581",
  secondary_300: "#FFCD8A",
  secondary_200: "#FFDB90",
  secondary_100: "#FFE2A7",
  secondary_50: "#FFE7B4",
  neutral_700: "#5C7360",
  neutral: "#93B298",
  neutral_200: "#C8D9CB",
  neutral_100: "#E4E9E5",
  neutral_50: "#F0F4F1",
  icon: "#F4C974",
  black: "#000000",
  error: "#B00020",
  ripple: "#F9FFDF",
  onPrimary: {
    highEmphasis: "#FFFFFF",
    mediumEmphasis: "rgba(255, 255, 255, 0.74)",
    disabled: "rgba(255, 255, 255, 0.38)",
  },
  onSurface: {
    button: "#161B27", // used to counter the default color in darkMode
    transparent: "rgba(0, 0, 0, 0)",
    highEmphasis: "#161B27",
    mediumEmphasis: "rgba(22, 27, 39, 0.73)",
    lowEmphasis: "rgba(22, 27, 39, 0.73)",
    disabled: "rgba(22, 27, 39, 0.32)",
    disabledIcon: "rgba(22, 27, 39, 0.32)",
    disabledModal: "rgba(22, 27, 39, 0.32)",
    light: "#E6E6E6",
  },
  surface: {
    surface: "#FFFFFF",
    surfaceSecondary: "#FFFFFF",
    overlayProgression: "#ecf0f1",
    overlay: "rgba(5, 6, 40, 0.08)",
    background: "#F5F5F7",
    backgroundModal: "#FFFFFF",
    backgroundWide: "#F5F5F7",
    backdropWeb: "rgba(22, 27, 39, 0.32)",
    outline: "rgba(5, 6, 40, 0.16)",
    highlight: "rgba(5, 6, 40, 0.04)",
    translucent: "rgba(255, 255, 255, 0.65)",
    translucentIntensiveTraining: "rgba(245, 245, 247, 0.65)",
  },
  accents: {
    error: "#FF8F1F",
    danger: "#FF8F1F",
    success: "#8EB912",
    info: "#276FBF",
    warning: "#FCD305",
  },
  placeholder: {
    nav: "rgba(23, 158, 255, 0.3199999928474426)",
    content: "rgba(23, 158, 255, 0.1599999964237213)",
  },
  modules: {
    highEmphasis: "black",
  },
} as const

export const darkOverride: Partial<IColorTheme> = {
  icon: "#FEBD34",
  // override secondary values with a static shade (secondary_400)
  secondary_50: "#FEBD34",
  secondary_200: "#FEBD34",
  secondary_300: "#FEBD34",
  secondary_350: "#FEBD34",
  ripple: "#121212",
  surface: {
    surface: "#121212",
    surfaceSecondary: "#2F2F2E", // headers and some exceptions
    background: "#232323",
    backgroundModal: "#232323", // dark.background / light.surface
    backgroundWide: "#121212", // dark.surface / light.background
    backdropWeb: "rgba(20, 20, 20, 0.64)",
    overlayProgression: "#5B5858",
    overlay: "rgba(5, 6, 40, 0.08)",
    outline: "#818181",
    highlight: "rgba(5, 6, 40, 0.04)",
    translucent: "rgba(35,35,35, 0.65)",
    translucentIntensiveTraining: "rgba(35,35,35, 0.65)",
  },
  onSurface: {
    button: "#121212", // mainly used for button to override default highEmphasis and some exceptions
    transparent: "rgba(0, 0, 0, 0)",
    highEmphasis: "#FFFFFF",
    mediumEmphasis: "#C8C8C8",
    lowEmphasis: "#818181",
    // better shades to read on dark surfaces
    disabled: "rgba(129,129,129, 0.32)",
    disabledIcon: "rgba(225, 255, 255, 0.64)",
    disabledModal: "rgba(20, 20, 20, 0.64)",
    light: "#818181",
  },
  accents: {
    error: "#FFBC79",
    danger: "#FFBC79",
    success: "#BBD571",
    info: "#7DA9D9",
    warning: "#FDE569",
  },
  modules: {
    highEmphasis: "#FFFFFF",
  },
} as const
