import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const PlayIconSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path d="M8.5 5.5v14l11-7-11-7Z" fill={props?.color ?? onSurface.button} />
    </Svg>
  )
}

export default PlayIconSVG
