import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const SpeechBubblesSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onPrimary },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 34} height={props?.height ?? 34} fill="none" viewBox="0 0 34 34">
      <Path
        d="M22.561 14.638c0-3.942-4.156-7.138-9.289-7.138S4 10.696 4 14.638a5.999 5.999 0 0 0 1.714 4.105 9.7 9.7 0 0 1-1.603 2.434.352.352 0 0 0-.06.386.335.335 0 0 0 .326.214 7.901 7.901 0 0 0 3.96-1.114 11.295 11.295 0 0 0 4.96 1.114c5.108 0 9.264-3.188 9.264-7.139Zm5.434 9.821a6.068 6.068 0 0 0 1.713-4.105c0-2.99-2.39-5.544-5.775-6.615.04.298.061.598.06.9 0 4.73-4.808 8.569-10.712 8.569a12.776 12.776 0 0 1-1.414-.086c1.405 2.571 4.713 4.37 8.57 4.37 1.716.011 3.413-.37 4.961-1.113a7.901 7.901 0 0 0 3.96 1.114.35.35 0 0 0 .325-.215.342.342 0 0 0-.069-.385 10.283 10.283 0 0 1-1.62-2.434Z"
        fill={onPrimary.highEmphasis}
      />
    </Svg>
  )
}

export default SpeechBubblesSVG
