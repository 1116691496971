import { bottomSheet } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import { useBottomSheet } from "providers/BottomSheetProvider"
import type { FC, ReactNode } from "react"
import { useMemo } from "react"
import { Animated } from "react-native"
import useTheme from "theme/ThemeProvider"

interface Props {
  children?: ReactNode
  headerHeight: number
}

const CoreAnimatedView: FC<Props> = ({ children, headerHeight }) => {
  const { dimensions } = useTheme()

  const { onLayout, hiddenPartHeight, getSliderMaxHeight } = useBottomSheet()

  const s = useStyles(
    ({ dimensions: { spacing }, colors: { surface } }) => ({
      padding: {
        padding: spacing / 2,
      },
      container: {
        backgroundColor: surface.backgroundModal,
        flex: 1,
        alignSelf: "center",
        position: "absolute",
        bottom: -bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT * 2,
        width: "100%" as const,
        maxWidth: bottomSheet.MAX_WIDTH,
      },
    }),
    [],
  )
  const sliderMaxHeight = getSliderMaxHeight(headerHeight)

  // Animated View style
  const style = useMemo(() => {
    const maxRange = Math.max(sliderMaxHeight - bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT, 1)

    return [
      s.container,
      {
        borderRadius: dimensions.margin / 2,
        height: sliderMaxHeight + bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT * 2,
        transform: [
          {
            translateY: hiddenPartHeight.interpolate({
              inputRange: [-bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT, sliderMaxHeight],
              outputRange: [0, maxRange - bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT * 2],
              extrapolate: "clamp",
            }),
          },
        ],
      },
    ]
  }, [dimensions.margin, hiddenPartHeight, s.container, sliderMaxHeight])

  return <Animated.View {...{ onLayout, style }}>{children}</Animated.View>
}

export default CoreAnimatedView
