import type {
  ApiError,
  FrontExamination,
  Level,
  LevelDetails,
  LevelId,
  LevelPracticeTest,
  LevelRevision,
  LevelType,
  Rule,
  StepId,
} from "@newpv/js-common"
import { useFetchLevel } from "hooks/useFetchLevelInfo"
import _ from "lodash"
import type { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { createContext, useCallback, useContext, useState } from "react"

interface ContextData {
  // Global state use for various situations
  levelId?: LevelId
  setLevelId: Dispatch<SetStateAction<LevelId | undefined>>
  levelType?: LevelType
  setLevelType: Dispatch<SetStateAction<LevelType | undefined>>
  currentStaticLevel: {
    currentLevel?: LevelDetails
    isCurrentLevelLoading: boolean
    currentLevelError: ApiError | null
  }
  revisionLevelWithRules?: { levelId: LevelId; rules: Rule[]; stepId: StepId }
  setRevisionLevelWithRules: Dispatch<
    SetStateAction<{ levelId: LevelId; rules: Rule[]; stepId: StepId } | undefined>
  >
  isRetryLevel: boolean
  setIsRetryLevel: Dispatch<SetStateAction<boolean>>
  // State for Evaluation
  isInitialEvaluationCompleted?: boolean
  setIsInitialEvaluationCompleted: Dispatch<SetStateAction<boolean | undefined>>
  examination?: FrontExamination
  setExamination: Dispatch<SetStateAction<FrontExamination | undefined>>
  // State for list of levels
  groupedLevels: Level[][] // static levels with corresponding revision level
  setGroupedLevels: Dispatch<SetStateAction<Level[][]>>
  finalLevels: Array<LevelRevision | LevelPracticeTest> // last level (practice test and final revision)
  setFinalLevels: Dispatch<SetStateAction<Level[]>>
  // State for selected level inside a module
  seeMasteredRules: boolean
  setSeeMasteredRules: Dispatch<SetStateAction<boolean>>
  isRuleListEmpty: boolean
  setIsRuleListEmpty: Dispatch<SetStateAction<boolean>>
  // reset function for all states in the provider
  resetLevelAndEvalState: () => void
  // Audio issue
  isResumedEval: boolean
  setIsResumedEval: Dispatch<boolean>
}

const levelContext = createContext<ContextData>({} as ContextData)

const LevelAndEvaluationProvider = ({ children }: PropsWithChildren<any>): JSX.Element => {
  /** For evaluation, will be contentId */
  const [levelId, setLevelId] = useState<LevelId>()
  const [levelType, setLevelType] = useState<LevelType>()

  const [examination, setExamination] = useState<FrontExamination>()
  const [isInitialEvaluationCompleted, setIsInitialEvaluationCompleted] = useState<boolean>()

  const [revisionLevelWithRules, setRevisionLevelWithRules] = useState<
    { levelId: LevelId; rules: Rule[]; stepId: StepId } | undefined
  >()
  const [isRetryLevel, setIsRetryLevel] = useState(false)

  // array of level blocks: static levels with the corresponding revision levels
  const [groupedLevels, setGroupedLevels] = useState<Level[][]>([])
  const [finalLevels, setFinalLevels] = useState<Array<LevelRevision | LevelPracticeTest>>([])

  const [seeMasteredRules, setSeeMasteredRules] = useState(false)
  const [isRuleListEmpty, setIsRuleListEmpty] = useState(true)

  const [isResumedEval, setIsResumedEval] = useState(false)

  // for evaluation (contentId) or static levels (including practice test)
  const {
    data: currentLevel,
    isLoading: isCurrentLevelLoading,
    error: currentLevelError,
  } = useFetchLevel(
    levelId,
    !levelType || levelType === "revision" || levelType === "finalRevision",
  )

  const resetLevelAndEvalState = useCallback(() => {
    setLevelId(undefined)
    setLevelType(undefined)
    setExamination(undefined)
    setIsInitialEvaluationCompleted(undefined)
    setRevisionLevelWithRules(undefined)
    setGroupedLevels([])
    setFinalLevels([])
    setSeeMasteredRules(false)
    setIsRuleListEmpty(true)
  }, [])

  const contextValue: ContextData = {
    levelId,
    levelType,
    setLevelId,
    setLevelType,
    currentStaticLevel: { currentLevel, isCurrentLevelLoading, currentLevelError },
    revisionLevelWithRules,
    setRevisionLevelWithRules,
    isRetryLevel,
    setIsRetryLevel,
    isInitialEvaluationCompleted,
    setIsInitialEvaluationCompleted,
    examination,
    setExamination,
    groupedLevels,
    setGroupedLevels,
    finalLevels,
    setFinalLevels,
    seeMasteredRules,
    setSeeMasteredRules,
    isRuleListEmpty,
    setIsRuleListEmpty,
    resetLevelAndEvalState,
    isResumedEval,
    setIsResumedEval,
  }

  return <levelContext.Provider value={contextValue}>{children}</levelContext.Provider>
}

export const useLevelAndEvaluation = (): ContextData => {
  const context = useContext(levelContext)
  if (_.isEmpty(context)) {
    throw new Error("useLevel must be used within a ScenarioAndModuleProvider")
  }

  return context
}

export default LevelAndEvaluationProvider
