import type { Result, WordBlock } from "@newpv/js-common"
import { HIGHLIGHT_HEIGHT, HIGHLIGHT_OVERFLOW, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { useWordBlockLayout } from "hooks/useWordBlockLayout"
import _ from "lodash"
import { useExercise } from "providers/ExerciseProvider"
import type { Dispatch, SetStateAction } from "react"
import { Fragment, useCallback } from "react"
import type { LayoutChangeEvent, TextStyle } from "react-native"
import { View } from "react-native"
import LineSVG from "svgs/Line"
import WavyLineSVG from "svgs/WavyLine"
import useTheme from "theme/ThemeProvider"
import parseSentence from "utils/parseSentence"

import PressableWord from "../PressableWord"
import { ExerciseSentence } from "../Texts"

interface Props {
  block: WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }
  parsedSentence: Array<WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }>
  index: number
  setHover: Dispatch<SetStateAction<boolean>>
  result?: Result
  exerciseHasMistake: boolean
  onSelectPress?: (value: Result) => Promise<void>
  isEvaluation?: boolean
  exerciseHasAnExplicitClue?: boolean
  setLocalCoordsOfClickedWord: Dispatch<
    SetStateAction<
      | {
          x: number
          y: number
        }
      | undefined
    >
  >
  setExtraViewTargetIndex: Dispatch<SetStateAction<number>>
  // TODO: refacto?
  isInExtraView?: boolean
  leadingBlockIndex?: number
}

const ClickOnMistakeWordBlock = ({
  block,
  index,
  result,
  setHover,
  exerciseHasMistake,
  isEvaluation,
  onSelectPress,
  setLocalCoordsOfClickedWord,
  setExtraViewTargetIndex,
  isInExtraView,
  leadingBlockIndex,
  parsedSentence,
}: Props): JSX.Element => {
  // Theme
  const {
    typography,
    colors: { primary_400: themePrimary },
    dimensions: { spacing: themeSpacing },
  } = useTheme()
  // Context
  const {
    currentExercise: { isClueVisible },
  } = useExercise()
  const { fontStyle, splitBlockArray, onLayout, itemsDataArray, underlineLengths } =
    useWordBlockLayout(block)

  const { width: afterBlockWidth, onLayout: afterBlockOnLayout } = useLayout()
  const { width: beforeBlockWidth, onLayout: beforeBlockOnLayout } = useLayout()

  const cs = useCommonStyles()
  const s = useStyles(
    () => ({
      wavyLine: {
        bottom: -1,
        left: 0,
        position: "absolute",
        zIndex: 1,
      },
      highlightPosition: {
        bottom: -1,
        left: 0,
        position: "absolute",
        zIndex: 0,
      },
      beforeBlockTranslate: {
        left: beforeBlockWidth ?? 0,
      },
    }),
    [beforeBlockWidth],
  )

  const setLocalCoords = useCallback(
    (subBlockIndex: number) =>
      ({ x, y }: { x: number; y: number }) => {
        if (
          x != null &&
          y != null &&
          itemsDataArray?.[subBlockIndex]?.x != null &&
          itemsDataArray?.[subBlockIndex]?.y != null
        ) {
          setLocalCoordsOfClickedWord({
            x: itemsDataArray[subBlockIndex].x + x,
            y: itemsDataArray[subBlockIndex].y + y,
          })
          if (!!isInExtraView && leadingBlockIndex != null) {
            setExtraViewTargetIndex(leadingBlockIndex)
          }
        }
      },
    [
      isInExtraView,
      itemsDataArray,
      leadingBlockIndex,
      setExtraViewTargetIndex,
      setLocalCoordsOfClickedWord,
    ],
  )

  return (
    <Fragment>
      {/** we don't need a leading space if: 
      - it's the beginning of the sentence
      - the previous block requires a carriage return
      - the previous block is a 'noSpaceAfter' block
      - the block itself is a 'noSpaceAfter' block (the space is added in the ClickOnMistake component)
      */}
      {index === 0 ||
      parsedSentence?.[index - 1].breakAfter ||
      parsedSentence?.[index - 1].endsWithSpace ||
      // for noSpaceAfter, we do display a space, but outside of the component
      block.noSpaceAfter ||
      block.afterNoSpace ? null : (
        <ExerciseSentence
          style={[
            // @ts-ignore
            isWeb ? { cursor: "pointer" } : {},
            fontStyle,
          ]}
        >
          {" "}
        </ExerciseSentence>
      )}
      {/* the block was split in several subBlocks with extra data */}
      {splitBlockArray.map((subBlock, subBlockIndex) => {
        const parsedTextWithoutMarkup = parseSentence(
          subBlock,
          typography.exerciseSentence.fontSize ?? 24,
        )

        const subBlockUnderlineLength = underlineLengths?.[subBlockIndex]

        const finalUnderlineLength = subBlockUnderlineLength
          ? // if it has to take into account the length of the after block
            _.findLastIndex(underlineLengths, length => !!length) === subBlockIndex &&
            afterBlockWidth
            ? subBlockUnderlineLength - afterBlockWidth
            : // if it has to take into account the length of the before block
            _.findIndex(underlineLengths, length => !!length) === subBlockIndex && beforeBlockWidth
            ? subBlockUnderlineLength - beforeBlockWidth
            : subBlockUnderlineLength
          : 0

        return (
          <View
            onLayout={(event: LayoutChangeEvent) => {
              onLayout(event, subBlockIndex)
            }}
            key={`${subBlock}-${subBlockIndex}`}
            style={{ flexDirection: "row" }}
          >
            {/* display the "before" content before the first element in splitBlockArray */}
            {block.before && subBlockIndex === 0 ? (
              <PressableWord
                index={`${index}-before`}
                word={block.before}
                isCorrectAnswer={(block.mistake || block.correction) ?? false}
                sentenceHasAMistake={exerciseHasMistake}
                {...{ result, setHover, onSelectPress }}
                innerTextStyle={fontStyle}
                onLayout={beforeBlockOnLayout}
              />
            ) : null}
            {itemsDataArray?.[subBlockIndex].x === themeSpacing &&
            // we only remove the leading space if there is no before of after block to take into account
            !block.before &&
            !block.after &&
            subBlock === " " ? null : (
              <>
                <View
                  style={[
                    s.highlightPosition,
                    _.findIndex(underlineLengths, length => !!length) === subBlockIndex &&
                    beforeBlockWidth
                      ? s.beforeBlockTranslate
                      : null,
                    {
                      width: finalUnderlineLength ? finalUnderlineLength + HIGHLIGHT_OVERFLOW : 0,
                      height: HIGHLIGHT_HEIGHT,
                      backgroundColor:
                        block.correction && result && !isEvaluation ? themePrimary : "transparent",
                    },
                  ]}
                />
                {/* to display red underline */}
                {!isEvaluation && block.mistake && result && finalUnderlineLength ? (
                  <WavyLineSVG
                    width={finalUnderlineLength}
                    style={[
                      s.wavyLine,
                      _.findIndex(underlineLengths, length => !!length) === subBlockIndex &&
                      beforeBlockWidth
                        ? { left: beforeBlockWidth }
                        : null,
                    ]}
                  />
                ) : null}
                {/* to display clue highlight */}
                {isClueVisible && block.clue && !result ? (
                  <LineSVG width="100%" style={[s.wavyLine, { bottom: 0 }]} />
                ) : null}
                {/* actual content (besides before and after) is displayed here, after parsing to handle HTML markup */}
                {parsedTextWithoutMarkup.map((item, itemIndex) => (
                  <PressableWord
                    key={`${item.content}-${itemIndex}`}
                    index={itemIndex}
                    word={item.content}
                    isCorrectAnswer={(block.mistake || block.correction) ?? false}
                    sentenceHasAMistake={exerciseHasMistake}
                    setLocalCoords={setLocalCoords(subBlockIndex)}
                    {...{
                      result,
                      setHover,
                      onSelectPress,
                      isEvaluation,
                    }}
                    // type assertion because of "fontSize: small" on web
                    innerTextStyle={[fontStyle, item.style as TextStyle]}
                  />
                ))}
              </>
            )}
            {/* display the "after" content after the last element in splitBlockArray */}
            {block.after && subBlockIndex === splitBlockArray.length - 1 ? (
              <PressableWord
                index={`${index}-after`}
                word={block.after}
                isCorrectAnswer={(block.mistake || block.correction) ?? false}
                sentenceHasAMistake={exerciseHasMistake}
                {...{ result, setHover, onSelectPress }}
                innerTextStyle={fontStyle}
                onLayout={afterBlockOnLayout}
              />
            ) : null}
          </View>
        )
      })}

      {block.breakAfter ? <View style={cs.fullWidth} /> : null}
    </Fragment>
  )
}

export default ClickOnMistakeWordBlock
