import type { SvgProps } from "react-native-svg"
import Svg, { G, Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const CorrectSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { primary_400 },
  } = useTheme()
  return (
    <Svg
      {...props}
      width={props?.width ?? 24}
      height={props?.height ?? 24}
      fill="none"
      viewBox="0 0 184 184"
    >
      <G>
        <Path
          d="M69 123.97 37.03 92l-10.886 10.81L69 145.666l92-92-10.81-10.81L69 123.97Z"
          fill="#000"
          opacity="0.3"
          transform="translate(2, 1)"
          stroke="#000"
          strokeWidth={2}
        />
        <Path
          d="m40.566 88.464-3.523-3.523-3.536 3.51-10.886 10.81-3.561 3.536 3.548 3.548 42.857 42.857L69 152.738l3.536-3.536 92-92 3.535-3.536-3.535-3.535-10.81-10.81-3.534-3.534-3.536 3.532L69.002 116.9 40.566 88.464Z"
          fill="#000"
          opacity="0.3"
          transform="translate(2, 1)"
          stroke="#000"
          strokeWidth={2}
        />
      </G>
      <G>
        <Path
          d="M69 123.97 37.03 92l-10.886 10.81L69 145.666l92-92-10.81-10.81L69 123.97Z"
          fill={primary_400 ?? "#C4DF1D"}
        />
        <Path
          d="m40.566 88.464-3.523-3.523-3.536 3.51-10.886 10.81-3.561 3.536 3.548 3.548 42.857 42.857L69 152.738l3.536-3.536 92-92 3.535-3.536-3.535-3.535-10.81-10.81-3.534-3.534-3.536 3.532L69.002 116.9 40.566 88.464Z"
          fill={primary_400 ?? "#C4DF1D"}
          strokeWidth={10}
        />
      </G>
    </Svg>
  )
}

export default CorrectSVG
