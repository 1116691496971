import { useSafeAreaInsets } from "react-native-safe-area-context"

// Check if it's a device with specific button at the bottom (IphoneX, Ipad and others similar models)
export const useIsIphoneX = (): boolean => {
  const insets = useSafeAreaInsets()

  return insets.bottom > 0
}

export default useIsIphoneX
