import type { WordBlock } from "@newpv/js-common"
import _ from "lodash"
import { useCallback, useLayoutEffect, useMemo, useState } from "react"
import type { LayoutChangeEvent } from "react-native"
import { waveLength } from "svgs/WavyLine"
import useTheme from "theme/ThemeProvider"
import {
  cleanHtmlString,
  getFontStyle,
  useGetBaseFontConfigFromFontFamily,
} from "utils/parseSentence"

export interface ItemData {
  width: number
  overflow: number
  x: number
  y: number
  totalUnderlineOrHiglightLengthForLine?: number
}

interface WordBlockLayout {
  fontStyle: { fontFamily: string }
  splitBlockArray: string[]
  onLayout: (e: LayoutChangeEvent, pressableWordIndex: any) => void
  getUnderlineOrHighlightLength: (subBlockIndex: number) => number
  itemsDataArray?: ItemData[]
  underlineLengths?: Array<number | undefined>
}

export function useWordBlockLayout(block: WordBlock): WordBlockLayout {
  const { fontMaker } = useTheme()
  const cleanWordBlock1 = cleanHtmlString(block.text)
  const cleanWordBlock2 = cleanHtmlString(cleanWordBlock1)
  const splitBlockArray = useMemo(() => _.compact(cleanWordBlock2.split(/( )/g)), [cleanWordBlock2])
  const [itemsDataArray, setItemsDataArray] = useState<ItemData[]>()

  const baseFontConfig = useGetBaseFontConfigFromFontFamily("exerciseSentence")
  const fontStyle = getFontStyle(baseFontConfig, block, fontMaker)

  useLayoutEffect(() => {
    const length = splitBlockArray.length
    setItemsDataArray(new Array(length).fill({}))
  }, [splitBlockArray.length])

  const onLayout = (event: LayoutChangeEvent, pressableWordIndex): void => {
    const { width, x, y } = event?.nativeEvent?.layout

    const itemData = {
      width,
      overflow: Math.round(width / waveLength) * waveLength - width,
      x,
      y,
    }
    if (width) {
      setItemsDataArray(prevState =>
        prevState?.map((item, itemIndex) => (itemIndex === pressableWordIndex ? itemData : item)),
      )
    }
  }

  const itemsWithExtraData: ItemData[] | undefined = useMemo(() => {
    const lineBreaksIndexes = itemsDataArray
      ?.map((item, itemIndex, itemArray) => {
        if (
          itemIndex === 0 ||
          item.x < (itemArray[itemIndex - 1]?.x ?? 0) ||
          // for leading spaces that would have been removed
          (itemArray[itemIndex - 1]?.x !== undefined && item.x === itemArray[itemIndex - 1].x)
        ) {
          return itemIndex
        } else {
          return undefined
        }
      })
      .filter(e => e !== undefined)

    return itemsDataArray?.map((value, valueIndex) => {
      if (lineBreaksIndexes?.includes(valueIndex)) {
        return {
          ...value,
          totalUnderlineOrHiglightLengthForLine: itemsDataArray
            ?.slice(
              valueIndex,
              lineBreaksIndexes[lineBreaksIndexes.indexOf(valueIndex) + 1] ?? undefined,
            )
            .reduce((accumulator, currentValue) => accumulator + currentValue.width, 0),
        }
      } else {
        return value
      }
    })
  }, [itemsDataArray])

  const getUnderlineOrHighlightLength = useCallback(
    (subBlockIndex: number) =>
      itemsWithExtraData?.[subBlockIndex]?.totalUnderlineOrHiglightLengthForLine ?? 0,
    [itemsWithExtraData],
  )

  const underlineLengths = useMemo(
    () => itemsWithExtraData?.map(item => item.totalUnderlineOrHiglightLengthForLine),
    [itemsWithExtraData],
  )

  return {
    fontStyle,
    splitBlockArray,
    onLayout,
    getUnderlineOrHighlightLength,
    itemsDataArray,
    underlineLengths,
  }
}
