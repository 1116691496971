import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const RuleIcon = (props?: SvgProps): JSX.Element => {
  const {
    darkMode,
    colors: { onPrimary, surface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 8} height={props?.height ?? 10} fill="none">
      <Path
        d="M6.35 1.75H4.998a1.083 1.083 0 0 0-2.166 0H1.477a.812.812 0 0 0-.812.811v5.96a.812.812 0 0 0 .812.812H6.35a.812.812 0 0 0 .815-.812V2.558a.812.812 0 0 0-.815-.808Zm-2.435-.408a.407.407 0 1 1-.407.408.405.405 0 0 1 .407-.41v.002Zm2.051 3.926-2.42 2.4a.208.208 0 0 1-.29 0l-1.4-1.415a.205.205 0 0 1 0-.289l.479-.476a.202.202 0 0 1 .289 0l.777.783 1.797-1.78a.202.202 0 0 1 .289 0l.476.48a.205.205 0 0 1 .003.294v.003Z"
        fill={darkMode ? surface.backgroundModal : onPrimary.highEmphasis}
      />
    </Svg>
  )
}

export default RuleIcon
