import type { SvgProps } from "react-native-svg"
import Svg, { Defs, Pattern, Rect } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const LineSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 2} height={1} style={props?.style}>
      <Defs>
        <Pattern
          id="Line"
          patternUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="2"
          height="1"
          viewBox="0 0 2 1"
        >
          <Rect width="2" height="1" fill={props?.color ?? onSurface.highEmphasis} />
        </Pattern>
      </Defs>
      <Rect fill="url(#Line)" width="100%" height="1" />
    </Svg>
  )
}

export default LineSVG
