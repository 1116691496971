import { useNavigation } from "@react-navigation/native"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { DataPicker } from "components/DataPicker/DataPicker"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import { Graph } from "components/Graph"
import { H5, H6, Subtitle2 } from "components/Texts"
import { isWeb, maxModalWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useHardwareButton from "hooks/useHardwareButton"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import useAuthContext from "providers/AuthProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Platform, ScrollView, View } from "react-native"
import type { ItemType } from "react-native-dropdown-picker"
import StarSVG from "svgs/Star"
import useTheme from "theme/ThemeProvider"
import { formatTimeByDuration } from "utils/formatTime"
import ScreenWrapper from "wrappers/ScreenWrapper/ScreenWrapper"

import { StatsBlock } from "./StatsBlock"

export const ProfileScreen = (): JSX.Element => {
  const { isSmallWidth } = useDeviceTools()
  const t = useTypedTranslation()
  const {
    colors: { icon },
  } = useTheme()
  const navigation = useNavigation()
  useHardwareButton({
    navigation: navigation.goBack,
  })
  const cs = useCommonStyles()

  const s = useStyles(
    ({ screenStyle, colors: { surface }, dimensions: { spacing, margin } }) => ({
      content: {
        ...(isWeb && !isSmallWidth ? screenStyle : undefined),
        backgroundColor: surface.backgroundModal,
        paddingHorizontal: isWeb && !isSmallWidth ? spacing * 6 : spacing / 2,
        paddingTop: spacing,
      },
      flex: {
        flex: 3,
      },
      pickerContainer: {
        maxWidth: isWeb && !isSmallWidth ? 300 : undefined,
        ...(Platform.OS !== "android"
          ? {
              zIndex: 1,
            }
          : {}),
      },
      usernameText: {
        maxWidth: "100%",
        paddingHorizontal: spacing * 2.5,
        textAlign: "center",
      },
      usernameView: {
        alignItems: "center",
        marginBottom: spacing * 1.5,
        marginTop: spacing * 2,
      },
      link: {
        margin: 0.5 * margin,
        textDecorationLine: "underline",
      },
    }),
    [isSmallWidth],
  )
  const {
    scenario,
    routeIndex,
    moduleId,
    progressionData,
    progressionError,
    isProgressionLoading,
  } = useScenarioAndModule()
  const { authScenarioId: scenarioId, currentUser } = useAuthContext()

  const moduleList = useMemo(
    () => (scenario ? scenario?.routes?.[routeIndex ?? 0]?.modules : []),
    [routeIndex, scenario],
  )

  const [open, setOpen] = useState<boolean>(false)
  const [modules, setModules] = useState<Array<ItemType<string>>>([])
  const [locallySelectedModuleId, _setSelectedModuleId] = useState<number | undefined>(moduleId)

  const setSelectedModuleId = useCallback(
    (onPressItem?: () => number | string) => {
      const sModuleId = onPressItem?.()
      return _setSelectedModuleId(_.isNumber(sModuleId) ? sModuleId : Number(sModuleId))
    },
    [_setSelectedModuleId],
  )

  const locallySelectedModule = useMemo(() => {
    const selectedId = _.isNumber(locallySelectedModuleId)
      ? locallySelectedModuleId
      : Number(locallySelectedModuleId)
    return moduleList?.find(m => m.id === selectedId)
  }, [locallySelectedModuleId, moduleList])

  useEffect(() => {
    if (locallySelectedModuleId === undefined && moduleList) {
      setSelectedModuleId(() => moduleList[0]?.id ?? 0)
    }
  }, [locallySelectedModuleId, moduleList, setSelectedModuleId])

  const totalTrainingTime = useMemo(() => {
    if (!scenarioId || locallySelectedModuleId == null) {
      return 0
    }
    const index = _.isNumber(locallySelectedModuleId)
      ? locallySelectedModuleId
      : Number(locallySelectedModuleId)
    return formatTimeByDuration(
      (progressionData?.progressionDetail?.modules?.[index]?.trainingDuration || 0) / 1000 || 0,
      t,
    )
  }, [locallySelectedModuleId, progressionData?.progressionDetail?.modules, scenarioId, t])

  useEffect(() => {
    if (moduleList) {
      const extractModules = moduleList.map(m => ({
        label: m.title,
        value: String(m.id),
      }))
      setModules(extractModules)
    }
  }, [moduleList])

  const numberOfRulesAchieved = useMemo(() => {
    if (locallySelectedModuleId == null) {
      return 0
    }
    const selectedId = _.isNumber(locallySelectedModuleId)
      ? locallySelectedModuleId
      : Number(locallySelectedModuleId)
    return progressionData?.progressionDetail?.modules?.[selectedId]?.knownRulesNbr ?? 0
  }, [locallySelectedModuleId, progressionData?.progressionDetail?.modules])

  const data = useMemo(
    () =>
      (locallySelectedModuleId &&
        scenarioId &&
        progressionData?.progressionDetail?.modules?.[locallySelectedModuleId]?.completionGraph) ||
      [],
    [locallySelectedModuleId, progressionData, scenarioId],
  )
  return isProgressionLoading ? (
    <ActivityIndicator />
  ) : progressionError ? (
    <ErrorPanel title={progressionError.code} description={progressionError.message} />
  ) : (
    <ScreenWrapper>
      <ScrollView contentContainerStyle={s.content}>
        <View style={s.usernameView}>
          <H6 style={s.usernameText}>{`${currentUser?.firstName ?? ""}\n${
            currentUser?.lastName ?? ""
          }`}</H6>
        </View>
        <View style={cs.alignItemsCenter}>
          <View style={s.pickerContainer}>
            <DataPicker
              {...{ open, setOpen }}
              setValue={setSelectedModuleId}
              value={String(locallySelectedModuleId) ?? null}
              items={modules}
              legendName={t("Profile.dataPickerLegend")}
            />
          </View>
        </View>
        <View style={{ zIndex: -1 }}>
          <Graph
            {...{ data }}
            withGradient={true}
            width={isWeb && !isSmallWidth ? maxModalWidth : undefined}
            clipOptions={{ clipHeight: 4, clipStrokeLinecap: "round", clipStrokeColor: "red" }}
          />
        </View>
        <View style={cs.statsContainer}>
          <View style={cs.block}>
            <StatsBlock title={t("Profile.statistics.totalTrainingTime")}>
              <H5>{totalTrainingTime}</H5>
            </StatsBlock>
          </View>
          <View style={cs.block}>
            <StatsBlock title={t("Profile.statistics.masteredRules")}>
              <View style={cs.statsContainer}>
                <StarSVG color={icon} />
                <H5 style={cs.paddingLeft}>{numberOfRulesAchieved}</H5>
                <Subtitle2 style={cs.subtitleScore}>/{locallySelectedModule?.rulesNbr}</Subtitle2>
              </View>
            </StatsBlock>
          </View>
        </View>
      </ScrollView>
    </ScreenWrapper>
  )
}
