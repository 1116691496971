import CustomRenderHTML from "components/CustomRenderHTML"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { View } from "react-native"

const LabelText = ({ text }: { text: string }): JSX.Element => {
  const { style } = useStyles(
    ({ dimensions: { margin } }) => ({
      style: {
        flex: 1,
        margin,
      },
    }),
    [],
  )

  const { onLayout, width } = useLayout()

  return (
    <View {...{ style, onLayout }}>
      <CustomRenderHTML content={text} width={width} typographyVariant="body1" />
    </View>
  )
}

export default LabelText
