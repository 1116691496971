import { isiOS } from "constants/constants"
import { useExercise } from "providers/ExerciseProvider"
import type { Dispatch, FC, SetStateAction } from "react"
import { useCallback, useMemo } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import type { CustomRendererProps, TPhrasing, TText } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"

interface Props extends CustomRendererProps<TText | TPhrasing> {
  setIsAnswerCorrect: Dispatch<SetStateAction<boolean>>
  isAnswerCorrect: boolean
  isClickable: boolean
  isEvaluation?: boolean
}

const CustomSpanRenderer: FC<Props> = ({
  TDefaultRenderer,
  setIsAnswerCorrect,
  isAnswerCorrect,
  isClickable,
  isEvaluation,
  ...props
}) => {
  const {
    currentExercise: { isClueVisible },
  } = useExercise()
  const {
    typography,
    fontMaker,
    darkMode,
    colors: { secondary_200, onSurface },
  } = useTheme()

  const classValue = props.tnode.attributes?.class
  const answer = classValue?.includes("mistake") ? true : false
  const clue = classValue?.includes("clue") ? true : false
  const emailHeaderField = classValue?.includes("emailHeaderField") ? true : false
  const empty = classValue?.includes("empty") ? true : false
  const section = classValue?.includes("section") ? true : false

  const handleOnPress = useCallback(() => {
    if (isClickable && !emailHeaderField) {
      setIsAnswerCorrect(answer)
    }
    return
  }, [answer, emailHeaderField, isClickable, setIsAnswerCorrect])

  const extraProps = useMemo(() => (isiOS ? { suppressHighlighting: true } : {}), [])

  const style = useMemo(
    () =>
      [
        {
          cursor: isClickable && !emailHeaderField ? "pointer" : "default",
          ...fontMaker(),
          fontSize: typography.body1.fontSize,
        },
        emailHeaderField
          ? {
              ...typography.body1,
              color: darkMode ? onSurface.lowEmphasis : undefined,
            }
          : {},
        section
          ? {
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              width: "100%" as const,
            }
          : {},
        empty
          ? {
              display: "flex",
              flex: 1,
              height: (typography.body1.fontSize ?? 16) + 2,
              color: "transparent",
            }
          : {},
        !emailHeaderField && !section && isAnswerCorrect != null && answer && !isEvaluation
          ? {
              backgroundColor: isEvaluation ? secondary_200 : undefined,
              /* textDecoration: "underline",
              // only web and iOS, not android
              textDecorationColor: "red",
              // wavy seems to work on most web platforms
              textDecorationStyle: isWeb ? "wavy" : "solid",
              // for mobile
              textDecorationLine: "underline",
              // animation: "blinking .2s infinite",
              // WebkitAnimation: "blinking 0.3s infinite backwards alternate", */
            }
          : {},
        isClueVisible && clue
          ? {
              textDecorationLine: "underline",
              textDecoration: "underline",
              textDecorationColor: onSurface.highEmphasis,
            }
          : {},
      ] as StyleProp<ViewStyle>,
    [
      answer,
      clue,
      darkMode,
      emailHeaderField,
      empty,
      fontMaker,
      isAnswerCorrect,
      isClickable,
      isClueVisible,
      isEvaluation,
      onSurface.highEmphasis,
      onSurface.lowEmphasis,
      secondary_200,
      section,
      typography.body1,
    ],
  )

  return (
    <TDefaultRenderer
      {...props}
      style={style}
      onPress={handleOnPress}
      nativeProps={{ ...props.nativeProps, ...extraProps }}
    />
  )
}

export default CustomSpanRenderer
