import { Button } from "components/Button"
import { H6, Subtitle1 } from "components/Texts"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { ViewProps } from "react-native"
import { View } from "react-native"
import ErrorLogoSVG from "svgs/ErrorLogo"
import RefreshSVG from "svgs/Refresh"

interface Props extends ViewProps {
  title?: string
  description?: string
  code?: string
  onPress?: () => void
}

const ErrorPanel = ({ title, description, onPress, code, ...rest }: Props): JSX.Element => {
  const t = useTypedTranslation()
  const cs = useCommonStyles()
  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      errorTitle: {
        marginBottom: spacing,
        textAlign: "center",
        textAlignVertical: "center",
      },
      containerError: {
        marginTop: spacing * 2,
        textAlign: "center",
        textAlignVertical: "center",
      },
      marginBottom: {
        marginBottom: spacing,
      },
      button: {
        maxWidth: 150,
      },
    }),
    [],
  )

  // TODO need to redirect on mobile (now Home is not reachable)
  const refreshPage = (): void => {
    if (isWeb) {
      // @ts-ignore
      if (window) {
        // @ts-ignore
        window.location.href = "/"
      }
    }
  }

  return (
    <View style={s.containerError} {...rest}>
      <View style={cs.flexRowCenter}>
        <ErrorLogoSVG height={125} width={125} />
      </View>
      {title ? <H6 style={s.errorTitle}>{title}</H6> : undefined}
      <Subtitle1 style={s.marginBottom}>
        {description ? description : t("common.errors.contact_support")}
      </Subtitle1>
      <Subtitle1 style={s.marginBottom}>
        {t("common.errors.code_error", { error: code?.toUpperCase() ?? "UNKNOWN" })}
      </Subtitle1>
      <View style={cs.alignItemsCenter}>
        <Button onPress={onPress ?? refreshPage} icon={() => <RefreshSVG />} style={s.button}>
          {t("common.button.retry")}
        </Button>
      </View>
    </View>
  )
}
export default ErrorPanel
