import type { IntensiveTrainingExercise, Rule } from "@newpv/js-common"
import BottomSheetTabsContent from "components/BottomSheet/customComponents/BottomSheetTabsContent"
import { TabBar } from "components/TabBar"
import { MAX_TAB_HEIGHT } from "constants/constants"
import { LinearGradient } from "expo-linear-gradient"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { getBottomSheetTabs } from "models/TabInterfaces"
import { useTab } from "providers/TabProvider"
import type { FC, RefObject } from "react"
import { ScrollView, View } from "react-native"
import type { ICarouselInstance } from "react-native-reanimated-carousel"
import useTheme from "theme/ThemeProvider"

interface Props {
  rule: Omit<Rule, "exercises">
  exercises?: IntensiveTrainingExercise[]
  swiper: RefObject<ICarouselInstance>
  intensiveCount?: number
  isSurgingVideoMode: boolean
}

export const Tabs: FC<Props> = ({ rule, intensiveCount, isSurgingVideoMode }) => {
  const t = useTypedTranslation()
  const { currentTab } = useTab()
  const {
    colors: { surface: themeSurface },
  } = useTheme()

  const s = useStyles(
    ({ dimensions: { spacing, margin }, roundness }) => ({
      ruleViewContainer: {
        borderRadius: roundness,
        marginTop: margin / 2,
        maxHeight:
          isSurgingVideoMode || (currentTab && currentTab.index === "video") ? undefined : 400,
        minHeight: isSurgingVideoMode ? "100%" : 250,
        paddingBottom: spacing * 2,
      },
      gradient: {
        position: "absolute",
        bottom: spacing * 2,
        height: 40,
        width: "100%",
      },
    }),
    [currentTab, isSurgingVideoMode],
  )

  return (
    <View style={s.ruleViewContainer}>
      <TabBar
        listStyle={{ minHeight: MAX_TAB_HEIGHT }}
        tabs={getBottomSheetTabs(t, !!rule.resources?.videos, !!rule.resources?.lesson)}
      />
      <ScrollView contentContainerStyle={{ paddingBottom: 40 }} nestedScrollEnabled={true}>
        <BottomSheetTabsContent {...{ rule, intensiveCount }} isIntensiveTraining={true} />
      </ScrollView>
      <LinearGradient
        style={s.gradient}
        colors={[themeSurface.translucent, themeSurface.backgroundModal]}
      />
    </View>
  )
}
