import type { ModuleId } from "@newpv/js-common"
import { iconSize, isWeb } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import type { FC, PropsWithChildren } from "react"
import { useCallback } from "react"
import { List } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"
import { AccessibilityListItemWrapper } from "wrappers/AccessibilityListWrapper"

import ProgressionItem from "../Progression/ProgressionItem"

interface IProps {
  title: string
  description?: string
  onPress: () => void
  moduleId: ModuleId
  percent?: number
  disabled?: boolean
}

const ModuleItem: FC<PropsWithChildren<IProps>> = ({
  onPress,
  description,
  title,
  percent,
  moduleId,
  disabled,
}) => {
  const {
    colors: { ripple },
  } = useTheme()
  const s = useStyles(
    ({ typography, dimensions: { spacing }, colors: { onSurface, surface }, roundness }) => ({
      button: {
        borderColor: surface.outline,
        borderRadius: roundness * 2,
        borderWidth: 1,
        justifyContent: "center",
        margin: spacing / 2,
        minHeight: 115,
        padding: spacing,
      },
      description: {
        color: onSurface.mediumEmphasis,
        textAlign: "left",
      },
      image: {
        flex: 1,
        height: iconSize.MEDIUM,
        width: iconSize.MEDIUM,
      },
      right: {
        alignSelf: "center",
        paddingVertical: 0,
        paddingBottom: _.isEmpty(description) ? undefined : spacing / 2,
      },
      title: {
        ...typography.h6,
        paddingBottom: _.isEmpty(description) ? undefined : spacing / 2,
        textAlign: "left",
      },
    }),
    [disabled, description],
  )

  const right = useCallback(
    () => (
      <ProgressionItem
        borderWidth={4}
        itemStyle={s.right}
        item={{ locked: disabled, percent: percent ?? 0, key: String(moduleId) }}
      />
    ),
    [moduleId, percent, s.right, disabled],
  )

  return (
    <AccessibilityListItemWrapper
      accessibilityLabel={title}
      dataSet={{ moduleId: String(moduleId) }}
    >
      <List.Item
        // @ts-ignore
        style={[isWeb ? { cursor: disabled ? "default" : "pointer" } : {}, s.button]}
        titleStyle={s.title}
        titleNumberOfLines={2}
        descriptionNumberOfLines={4}
        descriptionStyle={s.description}
        rippleColor={rippleColor(ripple)}
        {...{ onPress, title, description, right, disabled }}
      />
    </AccessibilityListItemWrapper>
  )
}

export default ModuleItem
