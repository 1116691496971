import type { ExerciseId } from "@newpv/js-common"
import { Subtitle1 } from "components/Texts"
import { isWeb } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import type { FC } from "react"
import { ScrollView } from "react-native"
import generateShadow from "utils/generateShadow"

const ConsultationList: FC<{ exerciseIds?: ExerciseId[] }> = ({ exerciseIds }) => {
  const t = useTypedTranslation()
  const s = useStyles(
    ({ dimensions: { margin, spacing }, colors: { surface } }) => ({
      contentContainer: {
        backgroundColor: surface.surfaceSecondary,
        borderRadius: spacing,
        left: spacing * 4,
        maxHeight: 600,
        maxWidth: 600,
        padding: spacing,
        top: 80,
        zIndex: 1,
        ...generateShadow(2),
      },
      mobileContentContainer: {
        backgroundColor: surface.surfaceSecondary,
        borderRadius: spacing,
        marginTop: spacing / 2,
        maxWidth: 250,
        padding: spacing / 2,
        zIndex: 1,
        ...generateShadow(2),
      },
      subtitle: {
        marginVertical: margin / 2,
      },
    }),
    [],
  )
  return (
    <ScrollView
      contentContainerStyle={{ alignItems: "center" }}
      horizontal={isWeb}
      style={isWeb ? s.contentContainer : s.mobileContentContainer}
    >
      <Subtitle1>
        {t(`${ns.CONSULTATION}.${isWeb ? "contentTitle" : "mobile.contentTitle"}`)}
      </Subtitle1>
      {_.map(exerciseIds, id => (
        <Subtitle1 style={s.subtitle}>{t(`${ns.CONSULTATION}.mobile.contentId`, { id })}</Subtitle1>
      ))}
    </ScrollView>
  )
}

export default ConsultationList
