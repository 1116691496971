import { iconSize } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { StyleProp, ViewStyle } from "react-native"
import { View } from "react-native"
import { ActivityIndicator, IconButton } from "react-native-paper"
import type { IconSource } from "react-native-paper/lib/typescript/components/Icon"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

interface Props {
  icon: IconSource
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  size?: number
  color?: string
  rippleColorString?: string
  disabled?: boolean
  loading?: boolean
}

const LoadingIconButton = ({
  onPress,
  icon,
  style,
  size = iconSize.SMALL,
  rippleColorString,
  disabled,
  loading,
  color,
}: Props): JSX.Element => {
  const {
    colors: { ripple, onSurface },
  } = useTheme()

  const s = useStyles(
    () => ({
      container: {
        alignItems: "center",
        justifyContent: "center",
      },
      indicator: {
        justifyContent: "center",
        alignSelf: "center",
        position: "absolute",
      },
    }),
    [],
  )

  return (
    <View style={s.container}>
      <IconButton
        {...{ onPress, icon, size, color }}
        disabled={disabled || loading}
        rippleColor={rippleColorString ?? rippleColor(ripple)}
        style={style}
      />
      {loading ? <ActivityIndicator color={onSurface.highEmphasis} style={s.indicator} /> : null}
    </View>
  )
}

export default LoadingIconButton
