import type { BottomSheetState } from "constants/bottomSheet"
import { bottomSheetStateReducer, defaultBSValues } from "constants/bottomSheet"
import { isWeb } from "constants/constants"
import _ from "lodash"
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import type { LayoutChangeEvent } from "react-native"
import { Animated, Easing, Keyboard, StatusBar, useWindowDimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

interface IProps extends PropsWithChildren {
  sliderMinHeight?: number
  sliderMaxHeight?: number
  panAndTranslateDisabled?: boolean
  animationDuration?: number
  animation?: (value: number) => number
}

export interface BottomSheetContextData {
  showBottomSheet: () => void
  hideBottomSheet: () => void
  bsState: BottomSheetState
  closeBottomSheet: () => void
  hiddenPartHeight: Animated.Value
  getSliderMaxHeight: (height: number) => number
  onLayout: ((event: LayoutChangeEvent) => void) | undefined
  togglePanel: (onClose?: () => void, onOpen?: () => void) => void
  panAndTranslateDisabled: boolean
  childrenHeight: number
  setChildrenHeight: Dispatch<SetStateAction<number>>
}

const bottomSheetContext = createContext<BottomSheetContextData>({} as BottomSheetContextData)

export const BottomSheetProvider: FC<IProps> = ({
  children,
  animation = Easing.quad,
  sliderMinHeight = 50,
  sliderMaxHeight: maxHeight,
  animationDuration = 200,
  panAndTranslateDisabled = false,
}) => {
  /** STATES AND REDUCERS */
  const [bsState, dispatch] = useReducer(bottomSheetStateReducer, defaultBSValues)
  // the actual height of the full, deployed bottom sheet
  const [contentHeight, setContentHeight] = useState(0)
  const [childrenHeight, setChildrenHeight] = useState<number>(0)
  const { height: windowHeight } = useWindowDimensions()
  const { top: topInset, bottom: bottomInset } = useSafeAreaInsets()
  const initialTopInsetValue = useRef<number>(topInset)
  /** Animated.Value for the height of the hidden part of the bottom sheet */
  const hiddenPartHeight = useRef(new Animated.Value(0)).current
  const initialHiddenPartHeightRef = useRef<number>()
  // TODO: fix max height on some android devices - with a top notch?
  const correctiveValue =
    StatusBar.currentHeight === initialTopInsetValue.current ? StatusBar.currentHeight ?? 0 : 0

  useEffect(() => {
    hiddenPartHeight.addListener(state => {
      if (initialHiddenPartHeightRef.current == null) {
        initialHiddenPartHeightRef.current = state.value
      } else {
        if (state.value >= initialHiddenPartHeightRef.current) {
          dispatch({ type: "closePanel" })
        } else {
          dispatch({ type: "openPanel" })
        }
      }
    })

    return () => {
      hiddenPartHeight.removeAllListeners()
    }
  }, [hiddenPartHeight])

  const getSliderMaxHeight = useCallback(
    (headerHeight: number) =>
      maxHeight !== undefined
        ? maxHeight
        : windowHeight - bottomInset - topInset - headerHeight - correctiveValue,
    [bottomInset, correctiveValue, maxHeight, topInset, windowHeight],
  )

  const togglePanel = useCallback(() => {
    // if the panel is open, and the height of the content is bigger than the height of the bottom sheet when it is at its smallest, than we close it by hiding the difference between those heights => that way, only the height of the bottom sheet when it is at its smallest is visible
    Animated.timing(hiddenPartHeight, {
      duration: animationDuration,
      easing: animation,
      toValue:
        bsState.panelOpen && contentHeight >= sliderMinHeight ? contentHeight - sliderMinHeight : 0,
      useNativeDriver: false,
    }).start(() => {
      if (bsState.panelOpen) {
        dispatch({ type: "closePanel" })
        Keyboard.dismiss()
      } else {
        dispatch({ type: "openPanel" })
      }
    })
  }, [
    hiddenPartHeight,
    animationDuration,
    animation,
    bsState.panelOpen,
    contentHeight,
    sliderMinHeight,
  ])

  const onLayout = useCallback(
    ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
      setContentHeight(layout.height)
      if (layout.height) {
        hiddenPartHeight.setValue(layout.height - sliderMinHeight)
      }
    },
    [hiddenPartHeight, sliderMinHeight],
  )

  const closeBottomSheet = useCallback(() => {
    dispatch({ type: "close" })
  }, [])

  const showBottomSheet = useCallback(() => dispatch({ type: "showModal" }), [])
  const hideBottomSheet = useCallback(() => dispatch({ type: "hideModal" }), [])

  useEffect(() => {
    if (!isWeb) {
      return
    }
    global.window.addEventListener("popstate", hideBottomSheet)
    return () => global.window.removeEventListener("popstate", hideBottomSheet)
  }, [hideBottomSheet])

  return (
    <bottomSheetContext.Provider
      value={{
        showBottomSheet,
        hideBottomSheet,
        bsState,
        onLayout,
        togglePanel,
        closeBottomSheet,
        hiddenPartHeight,
        getSliderMaxHeight,
        panAndTranslateDisabled,
        childrenHeight,
        setChildrenHeight,
      }}
    >
      {children}
    </bottomSheetContext.Provider>
  )
}

export const useBottomSheet = (): BottomSheetContextData => {
  const context = useContext(bottomSheetContext)
  if (_.isEmpty(context)) {
    throw new Error("useBottomSheet must be used within a BottomSheetProvider")
  }
  return context
}

export default BottomSheetProvider
