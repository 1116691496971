import type { FC, ReactNode } from "react"
import type { StyleProp, TextProps, TextStyle } from "react-native"
import { Text as RNText } from "react-native"
import type { Theme } from "theme/ThemeProvider"
import useTheme from "theme/ThemeProvider"

export interface IBodyProps extends TextProps {
  children: ReactNode
  color?: "primary" | "secondary" | string
}

export interface ITextProps {
  type: StyleProp<TextStyle>
  theme: Theme
}

export const Text: FC<ITextProps & IBodyProps> = ({ color, ...props }) => {
  const { colors } = useTheme()
  const colorStyle = colors?.[color as "primary" | "secondary"] ?? color

  return (
    <RNText
      {...props}
      selectable={props.selectable ?? true}
      style={[props.type, props.style, colorStyle ? { color: colorStyle } : {}]}
    />
  )
}
