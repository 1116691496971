import type { Result } from "@newpv/js-common"
import { CLICK_INDICATOR_SIZE } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { Pressable, View } from "react-native"
import RenderHTML from "react-native-render-html"
import useTheme from "theme/ThemeProvider"

import CustomSpanRenderer from "./CustomSpanRenderer"

interface IProps {
  key: string
  htmlContent: string
  width: number
  onSelectPress?: (value: Result) => Promise<void>
  isClickable?: boolean
  isEvaluation?: boolean
}

const EmailClickOnMistakeRenderer: FC<IProps> = ({
  key,
  htmlContent,
  width,
  onSelectPress,
  isClickable = false,
  isEvaluation,
}) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>()

  useEffect(() => {
    if (isAnswerCorrect != null) {
      onSelectPress?.(isAnswerCorrect ? "correct" : "error/missed-mistake")
    }
  }, [isAnswerCorrect, onSelectPress])

  const {
    colors: { neutral_200, secondary_300, primary_400, onSurface },
    typography,
  } = useTheme()

  const s = useStyles(
    () => ({
      wordClickDisk: {
        backgroundColor:
          isAnswerCorrect == null
            ? "transparent"
            : isEvaluation
            ? neutral_200
            : isAnswerCorrect
            ? primary_400
            : secondary_300,
        borderRadius: CLICK_INDICATOR_SIZE,
        height: CLICK_INDICATOR_SIZE,
        left: position.x - CLICK_INDICATOR_SIZE / 2,
        opacity: 0.5,
        zIndex: isAnswerCorrect == null ? -1 : 1,
        position: "absolute",
        top: position.y - CLICK_INDICATOR_SIZE / 2,
        width: CLICK_INDICATOR_SIZE,
      },
    }),
    [isAnswerCorrect, position],
  )

  const renderers = useMemo(
    () => ({
      span: props => (
        <CustomSpanRenderer
          {...props}
          {...{ setPosition, setIsAnswerCorrect, isAnswerCorrect, isClickable, isEvaluation }}
        />
      ),
    }),
    [isAnswerCorrect, isClickable, isEvaluation],
  )

  const tagsStyle = useMemo(
    () => ({
      span: { fontSize: typography.body1.fontSize },
      body: { color: onSurface.highEmphasis },
    }),
    [onSurface.highEmphasis, typography.body1.fontSize],
  )

  return (
    <Pressable
      {...{ key }}
      onPressIn={event => {
        setPosition({ x: event.nativeEvent.locationX, y: event.nativeEvent.locationY })
      }}
      onTouchEndCapture={event => {
        setPosition({ x: event.nativeEvent.locationX, y: event.nativeEvent.locationY })
      }}
    >
      <>
        {isClickable ? <View style={s.wordClickDisk} /> : null}
        <RenderHTML
          contentWidth={width}
          tagsStyles={tagsStyle}
          renderers={renderers}
          source={{
            html: htmlContent,
          }}
          GenericPressable={props => <Pressable {...props} />}
          // necessary to avoid removing spaces at the end of html fragments
          dangerouslyDisableWhitespaceCollapsing={true}
        />
      </>
    </Pressable>
  )
}

export default EmailClickOnMistakeRenderer
