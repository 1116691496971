import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"

const ModuleImageSpellingSVG = (props?: SvgProps): JSX.Element => (
  <Svg {...props} width={props?.width ?? 90} height={props?.height ?? 82} fill="none">
    <Path
      d="M42.177 82c22.644 0 41-18.356 41-41s-18.356-41-41-41-41 18.356-41 41 18.356 41 41 41Z"
      fill="#C8D9CB"
    />
    <Path
      d="m47.283 21.428-16.4 18.257 3.249 29.241L58.268 52.14l3.094-24.368-14.08-6.344Z"
      stroke="#000"
      strokeWidth={2.628}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M65.075 29.474c-3.481-1.316-7.117-2.708-10.753-4.255l-5.57-2.32-5.57-2.399c-.464-.232-.696-.774-.464-1.238l3.095-6.885c.232-.541.85-.773 1.315-.541l5.338 2.475 5.337 2.553 5.338 2.553c1.78.85 3.481 1.78 5.26 2.63h.078c.232.078.31.387.232.619l-1.857 3.481-1.779 3.327Zm0 0 2.708-7.117.232.618c-1.857-.773-3.636-1.47-5.493-2.243-1.779-.773-3.636-1.47-5.415-2.32-1.779-.774-3.636-1.548-5.415-2.399l-5.415-2.398 1.315-.541-3.172 6.885-.464-1.316 5.183 2.476 5.183 2.63c3.481 1.934 7.04 3.79 10.753 5.725ZM58.268 52.45c2.63.54 5.183 1.237 7.736 1.778 2.552.62 5.105 1.16 7.735 1.547 2.553.387 5.183.31 7.659.31 2.553-.077 5.105-.387 7.736-.774.077 0 .077 0 .154.078 0 .077 0 .077-.077.077-2.32 1.392-4.95 2.243-7.659 2.63-2.707.31-5.492-.154-8.122-.696-2.63-.541-5.183-1.315-7.736-2.166l-7.504-2.63c-.077 0-.077-.078-.077-.155 0 .078.077 0 .155 0ZM33.745 68.308c-5.88.154-11.758-.233-17.56-1.316-2.863-.618-5.802-1.392-8.51-2.552-1.392-.62-2.707-1.316-3.945-2.244-1.238-.928-2.398-2.166-2.862-3.868-.465-1.702.154-3.558 1.237-4.873 1.083-1.315 2.398-2.166 3.714-2.94 2.707-1.47 5.647-2.243 8.586-2.785 2.94-.464 5.957-.696 8.897-.619 2.94 0 5.879.31 8.818.774.078 0 .078.077.078.155 0 .077-.078.077-.078.077-5.879.155-11.68.464-17.328 1.625-1.392.309-2.785.618-4.177 1.005-1.393.387-2.708.851-3.946 1.47-1.237.619-2.398 1.315-3.249 2.32-.85.93-1.315 2.167-1.005 3.327.232 1.16 1.16 2.244 2.243 3.095 1.083.85 2.32 1.547 3.636 2.165 2.553 1.238 5.415 2.09 8.2 2.785 5.647 1.393 11.526 2.012 17.328 2.166.078 0 .078.078.078.078l-.155.155Z"
      fill="#000"
    />
    <Path
      d="M43.724 47.808a211.316 211.316 0 0 1-4.177 9.592c-1.393 3.172-2.862 6.343-4.332 9.515-.155.387-.62.542-1.006.387-.232-.077-.387-.31-.464-.542v-.077c-.387-2.243-.696-4.487-1.006-6.73-.31-2.244-.696-4.487-1.005-6.73l-1.857-13.46c0-.31.077-.62.232-.774 2.708-3.017 5.492-6.034 8.277-9.051a458.004 458.004 0 0 1 8.432-8.896l.155.85-3.636-1.856a.372.372 0 0 1-.154-.464l3.48-6.73a669.589 669.589 0 0 1-2.784 7.04l-.155-.542 3.79 1.547c.233.077.388.387.31.696 0 .077-.077.077-.077.155a461.929 461.929 0 0 1-7.968 9.36c-2.708 3.095-5.338 6.189-8.123 9.206l.232-.774 1.857 13.46c.31 2.244.541 4.487.85 6.73.31 2.244.542 4.488.774 6.731l-1.47-.232 4.874-9.283c1.547-3.017 3.25-6.111 4.951-9.128Z"
      fill="#000"
    />
    <Path
      d="M45.349 47.189a2.862 2.862 0 1 0 0-5.725 2.862 2.862 0 0 0 0 5.725Z"
      stroke="#000"
      strokeWidth={2.628}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default ModuleImageSpellingSVG
