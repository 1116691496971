import type { ComponentProps, ReactElement } from "react"
import type { ImageSourcePropType, StyleProp, TextStyle, ViewStyle } from "react-native"
import type { Modal } from "react-native-paper"
import type { TypographyVariant } from "theme/types"

import type { SVGComponent } from "./SVGInterface"

// eslint-disable-next-line no-shadow
export enum ButtonType {
  NEGATIVE = "negative",
  NEUTRAL = "neutral",
  POSITIVE = "positive",
}

// eslint-disable-next-line no-shadow
export enum ModalType {
  CONGRATS = "congrats",
  CRUCIAL = "crucial",
  EVALUATION = "evaluation",
  INITIAL_EVALUATION = "initial_evaluation",
  NEXT_EVALUATION = "next_evaluation",
  PRACTICE_TEST = "practice_test",
  IA = "ia",
  RESULTS = "results",
  RULE_ACQUIRED = "ruleAcquired",
  WELCOME = "welcome",
  TEXT_TO_SPEECH = "textToSpeech",
  CONSULTATION_MODE = "consultationMode",
  ERROR = "error",
  INTENSIVE = "intensive",
  LOCK = "lock",
  COOLDOWN = "coolDown",
  FINAL_COOLDOWN = "finalCoolDown",
  RETRY = "retry",
}

export interface ModalButton {
  label?: string
  color?: string
  loading?: boolean
  onPress?: () => Promise<void> | void
  disabled?: boolean
}

export type ModalProps = Omit<ComponentProps<typeof Modal>, "children">

/** use this interface when the modal `children` element allows the user to enter a value
 * (TextEdit, select list, etc.) to make it possible to return the value when
 * the positive button is clicked */
export interface ChildrenRefAttributes<T = any> {
  getValue?: () => T
}

export interface IModal<T> {
  // Keep to know which modal it is. No impact in Modal logic
  type: ModalType
  dismissPositiveAction?: boolean
  headerTitle?: string
  headerImage?: ImageSourcePropType | SVGComponent
  numberOfMasteredRules?: number
  isEvaluation?: boolean
  bodyTitle?: string
  bodyTitleStyle?: StyleProp<TextStyle>
  bodySubtitle?: string
  bodySubtitleStyle?: StyleProp<ViewStyle>
  bodySubtitleTypographyVariant?: TypographyVariant
  positive?: ModalButton
  negative?: ModalButton
  neutral?: ModalButton
  children?: ReactElement & ChildrenRefAttributes<T>
}

export type IModalInternal<T = any> = IModal<T> &
  ModalProps & {
    visible: boolean
    dismissable: boolean
    onDismiss?: () => void
    resolve?: (value: any) => void
  }

export interface ModalResponse<T = any> {
  button?: ButtonType
  value?: T
}
