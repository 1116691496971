import { A } from "@expo/html-elements"
import { useNavigation } from "@react-navigation/native"
import { Caption } from "components/Texts"
import { isWeb } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import type { TranslationKeysFR } from "i18n/i18next.fr.keys"
import type { FC } from "react"
import { useCallback } from "react"
import { TouchableOpacity, View } from "react-native"

const LegalMention: FC = () => {
  const t = useTypedTranslation()
  const navigation = useNavigation()
  const s = useStyles(
    ({ dimensions: { margin }, typography: { caption } }) => ({
      container: {
        flexDirection: "row",
        justifyContent: "center",
      },
      link: {
        ...caption,
        margin: 0.5 * margin,
        textDecorationLine: "underline",
      },
      subtitle: {
        textAlign: "center",
      },
    }),
    [],
  )

  const onPress = useCallback(
    (uri: string) => navigation.navigate(ns.WEB_PAGE, { uri }),
    [navigation],
  )

  return (
    <View style={s.container}>
      {(
        t("links" as TranslationKeysFR, { returnObjects: true }) as Array<{
          label: string
          url: string
        }>
      ).map(link =>
        isWeb || link.url.includes("pdf") ? (
          <A key={link.label} href={link.url} target="blank" style={s.link}>
            {t(`Level.banner.${link.label}` as TranslationKeysFR)}
          </A>
        ) : (
          <TouchableOpacity key={link.label} onPress={() => onPress(link.url)}>
            <Caption style={s.link}>{t(`Level.banner.${link.label}` as TranslationKeysFR)}</Caption>
          </TouchableOpacity>
        ),
      )}
    </View>
  )
}

export default LegalMention
