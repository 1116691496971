import { lett } from "@newpv/js-common"
import type { NativeStackScreenProps } from "@react-navigation/native-stack"
import { Button } from "components/Button"
import { H4, Subtitle1 } from "components/Texts"
import { isWeb, maxScreenWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { rootRoutes } from "navigation/Constants"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import { useModal } from "providers/ModalProvider"
import type { ComponentProps, FC } from "react"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import type { TextInput as RNTextInput } from "react-native"
import { View } from "react-native"
import { TextInput } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import { useMutation } from "react-query"
import useTheme from "theme/ThemeProvider"
import { logger } from "utils/logger"

import { postLogin } from "./login.request"

type IPaperTextInputProps = ComponentProps<typeof TextInput>

export const LoginScreen: FC<NativeStackScreenProps<IRootParamList, "Login">> = ({
  route,
  navigation,
}) => {
  const t = useTypedTranslation()
  const {
    darkMode,
    colors: { accents, onSurface, onPrimary, surface },
  } = useTheme()
  const cs = useCommonStyles()

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        surface: { background },
      },
    }) => ({
      container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
      },
      loginContainer: {
        justifyContent: "center",
        maxWidth: maxScreenWidth,
        padding: spacing,
        width: "100%" as const,
      },
      inputContent: {
        backgroundColor: background,
      },
    }),
  )

  const [username, setUsername] = useState<string>(route?.params?.login ?? "")
  const [password, setPassword] = useState<string>(route?.params?.password ?? "")
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true)
  const passwordInputRef = useRef<RNTextInput>(null)
  const { mutate: login, data: loginResult, isError, isLoading, reset } = useMutation(postLogin)
  const { setToken } = useAuthContext()
  const disabled = _.isEmpty(_.trim(username)) || _.isEmpty(_.trim(password))
  const { hideAllModals } = useModal()

  useLayoutEffect(() => hideAllModals(), [hideAllModals])

  useEffect(() => {
    lett(loginResult?.data.jwt, setToken)
  }, [loginResult?.data.jwt, setToken])

  const textInputProps = useMemo<Partial<IPaperTextInputProps>>(
    () => ({
      autoCapitalize: "none",
      // @ts-ignore
      autoComplete: isWeb ? "none" : "off",
      style: [cs.marginBottom, s.inputContent],
      error: isError,
      theme: {
        colors: {
          placeholder: darkMode ? onPrimary.disabled : undefined,
        },
      },
    }),
    [cs.marginBottom, darkMode, isError, onPrimary.disabled, s.inputContent],
  )

  useEffect(() => {
    reset()
  }, [username, password, reset])

  const onSubmit = useCallback(async () => {
    // this seems to be the key to fixing iOS crashes for incorrect password
    logger("Start login")
    try {
      await login({ password: password.trim(), username: username.trim() })
    } catch (e) {
      logger("Login error")
    }
    logger("Done login")
  }, [login, password, username])

  return (
    <SafeAreaView style={s.container}>
      <View style={s.loginContainer}>
        <H4 style={[cs.textCenter, cs.marginBottom]} color={onSurface.highEmphasis}>
          {t("Login.title")}
        </H4>
        <TextInput
          {...textInputProps}
          keyboardType="email-address"
          label={t("Login.labels.0")}
          value={username}
          onChangeText={setUsername}
          onSubmitEditing={() => passwordInputRef.current?.focus()}
        />
        <TextInput
          ref={passwordInputRef}
          {...textInputProps}
          label={t("Login.labels.1")}
          onSubmitEditing={disabled ? undefined : onSubmit}
          secureTextEntry={secureTextEntry}
          value={password}
          onChangeText={setPassword}
          right={
            <TextInput.Icon
              containerColor={isWeb ? surface.surfaceSecondary : undefined}
              color={isWeb && darkMode ? onSurface.mediumEmphasis : undefined}
              icon={secureTextEntry ? "eye" : "eye-off"}
              onPress={() => setSecureTextEntry(!secureTextEntry)}
            />
          }
        />
        {isError ? (
          <Subtitle1 color={accents.error} style={cs.marginBottom}>
            {t("Login.error")}
          </Subtitle1>
        ) : null}
        <Button
          disabled={disabled || isLoading}
          loading={isLoading}
          onPress={onSubmit}
          style={cs.alignCenter}
          labelStyle={{ color: disabled ? onSurface.highEmphasis : onSurface.button }}
        >
          {t("Login.toLog")}
        </Button>
        <Button
          disabled={isLoading}
          onPress={() => navigation.navigate(rootRoutes.OAUTH_LOGIN)}
          style={[cs.alignCenter, cs.marginTop]}
          labelStyle={{ color: onSurface.button }}
        >
          {t("OAuthLogin.title")}
        </Button>
      </View>
    </SafeAreaView>
  )
}
