import { useStyles } from "./useStyles"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useCommonStyles = () =>
  useStyles(
    ({ typography, dimensions: { margin, spacing }, colors: { onSurface } }) => ({
      alignCenter: {
        alignSelf: "center",
      },
      alignEnd: {
        alignSelf: "flex-end",
      },
      alignItemsCenter: {
        alignItems: "center",
      },
      block: {
        flex: 1,
        padding: spacing / 2,
      },
      backButton: {
        alignSelf: "flex-start",
        marginLeft: 0,
      },
      bottomSheetTitle: {
        color: onSurface.highEmphasis,
        marginLeft: margin / 4,
      },
      bottomSheetTitleContainer: {
        alignItems: "center",
        flexDirection: "row",
        marginBottom: margin / 2,
      },
      buttonBorderRadius: {
        borderWidth: 0,
        borderRadius: 50,
      },
      center: {
        justifyContent: "center",
      },
      centerIndicator: {
        alignSelf: "center",
        flex: 1,
        justifyContent: "center",
      },
      dragging: {
        opacity: 0.2,
      },
      flexRow: {
        flexDirection: "row",
      },
      flexRowCenter: {
        flexDirection: "row",
        justifyContent: "center",
      },
      fullFlex: {
        flex: 1,
      },
      fullWidth: {
        width: "100%" as const,
      },
      inlineRow: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      labelClickOnMistake: {
        color: onSurface.button,
        marginHorizontal: margin * 1.5,
        marginVertical: margin,
      },
      labelMediumOnSurface: {
        color: onSurface.mediumEmphasis,
      },
      margin: {
        margin,
      },
      marginBottom: {
        marginBottom: margin,
      },
      marginRight: {
        marginRight: margin,
      },
      marginTop: {
        marginTop: margin,
      },
      padding: {
        padding: spacing,
      },
      paddingBottom: {
        paddingBottom: spacing,
      },
      paddingLeft: {
        paddingLeft: spacing / 4,
      },
      paddingTop: {
        paddingTop: spacing * 5,
      },
      ripple: {
        backgroundColor: "transparent",
      },
      row: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      sentenceContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        marginBottom: margin * 1.5,
        marginTop: margin,
        paddingHorizontal: spacing,
        width: "100%" as const,
      },
      statsContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
      },
      subtitleHighEmphasis: {
        ...typography.subtitle1,
        color: onSurface.highEmphasis,
      },
      subtitleScore: {
        alignSelf: "flex-end",
        color: onSurface.mediumEmphasis,
        paddingBottom: 2,
      },
      textCenter: {
        textAlign: "center",
      },
      fullHeight: {
        height: "100%",
      },
      viewContainerWithMarginTop: {
        marginTop: margin / 2,
        width: "100%" as const,
      },
    }),
    [],
  )
export default useCommonStyles
