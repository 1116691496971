import type { DragAndDropWord, Result } from "@newpv/js-common"
import type { DragAndDropAnimation } from "components/ExerciseContents/DragAndDrop"
import { MAX_TAP_DISTANCE } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import { useDragAndDrop, WordColumn } from "providers/DadProvider"
import type { FC } from "react"
import React, { useMemo } from "react"
import { PanResponder, View } from "react-native"
import { DraxView } from "react-native-drax"

import WordInColumn from "./WordInColumn"

interface Props {
  index: number
  words: DragAndDropWord[]
  result?: Result
  isEvaluation?: boolean
  clueWord?: DragAndDropWord
  setColumnInfo: React.Dispatch<React.SetStateAction<{ width: number }>>
  setItemsHeight: React.Dispatch<React.SetStateAction<Array<{ height: number; id: string }>>>
  isAnswered: boolean
  animatedItems?: DragAndDropAnimation[]
  minHeight?: number
}

const WordsColumn: FC<Props> = ({
  index,
  words,
  result,
  isEvaluation,
  clueWord,
  setColumnInfo,
  setItemsHeight,
  isAnswered,
  animatedItems,
  minHeight,
}) => {
  const localMinHeight = result != null ? minHeight : 250

  const { isDragging, manageWord, tapSelectedWord, setTapSelectedWord } = useDragAndDrop()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ dimensions: { spacing, margin }, colors: { surface } }) => ({
      receivingZone: {
        backgroundColor: isDragging ? surface.overlay : "transparent",
        borderColor: surface.outline,
        borderRadius: margin / 2,
        borderStyle: "dashed",
        borderWidth: 1,
        flex: 1,
        marginHorizontal: margin / 2,
        minHeight: localMinHeight,
        paddingVertical: spacing / 4,
      },
      bottomPaddingColumn: { paddingBottom: spacing * 4 },
    }),
    [isDragging, localMinHeight],
  )

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderRelease: (evt, gestureState) => {
          if (
            Math.abs(gestureState.dx) < MAX_TAP_DISTANCE &&
            Math.abs(gestureState.dy) < MAX_TAP_DISTANCE
          ) {
            if (tapSelectedWord) {
              manageWord(
                tapSelectedWord,
                index === 0 ? WordColumn.FIRST_COLUMN : WordColumn.SECOND_COLUMN,
              )
              setTapSelectedWord(undefined)
            }
          }
        },
        onPanResponderTerminationRequest: () => true,
      }),
    [index, manageWord, setTapSelectedWord, tapSelectedWord],
  )

  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width: columnWidth },
        },
      }) => {
        setColumnInfo({ width: columnWidth })
      }}
      style={s.receivingZone}
    >
      <DraxView
        isParent
        id={`Column${index}`}
        style={cs.fullFlex}
        renderContent={() => (
          <View style={[cs.fullFlex, s.bottomPaddingColumn]} {...panResponder.panHandlers}>
            {words?.map((word, i) => {
              const animationItem = animatedItems?.find(item => item.id === word.id)

              const animationX = animationItem?.animationX
              const animationY = animationItem?.animationY
              return (
                <WordInColumn
                  key={`${word.id}-${i}`}
                  {...{
                    word,
                    isAnswered,
                    isEvaluation,
                    index,
                    animationX,
                    animationY,
                    setItemsHeight,
                    result,
                    clueWord,
                  }}
                />
              )
            })}
          </View>
        )}
      />
    </View>
  )
}

export default WordsColumn
