import type { ApiError, CurrentUser, JwtIdentity, ScenarioId } from "@newpv/js-common"
import { apiUrl, axios, decodeJwt, getCurrentUser, logger } from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { isAxiosError, qaMode } from "constants/constants"
import { useAppState } from "hooks/useAppState"
import useAuthStorage from "hooks/useAuthStorage"
import useCommonStyles from "hooks/useCommonStyles"
import _ from "lodash"
import type { Dispatch, SetStateAction } from "react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import type { AppStateStatus } from "react-native"
import { StyleSheet } from "react-native"

interface ContextData {
  token: string | null
  authScenarioId: ScenarioId | null
  setScenarioId: (sid?: ScenarioId | null) => void
  setToken: (token: string | null) => void
  hasAuth: boolean
  authenticationType?: JwtIdentity["authenticationType"]
  triggerStatesReset: boolean
  setTriggerStatesReset: Dispatch<SetStateAction<boolean>>
  currentUser?: CurrentUser
  extraTimeFactor: number
  authError?: ApiError
  setAuthError: Dispatch<SetStateAction<ApiError | undefined>>
  isQA: boolean
}

const AuthContext = createContext({} as ContextData)

export const AuthProvider = ({ children }): JSX.Element => {
  const [authError, setAuthError] = useState<ApiError>()
  const [token, _setToken] = useState<string | null>()
  const [authScenarioId, setAuthScenarioId] = useState<ScenarioId | null>()
  const [authenticationType, setAuthenticationType] = useState<JwtIdentity["authenticationType"]>()
  const [appStateStatus, setAppStateStatus] = useState<AppStateStatus>()
  const [triggerStatesReset, setTriggerStatesReset] = useState(false)
  const { getAuthStorageItems, setTokenStorageItem, setAuthScenarioIdStorageItem } =
    useAuthStorage()
  const [currentUser, setCurrentUser] = useState<CurrentUser>()

  useEffect(() => {
    if (token) {
      getCurrentUser(`Bearer ${token}`).then(setCurrentUser)
    }
  }, [token])

  useAppState(setAppStateStatus)

  const cs = useCommonStyles()

  const setToken = useCallback(
    (t): void => {
      _setToken(t)
      setTokenStorageItem(t)
    },
    [setTokenStorageItem],
  )

  useEffect(() => {
    getAuthStorageItems().then(([t, sid]) => {
      if (t != null && !isNaN(sid)) {
        logger("Setting token and scenario from session or local storage - AuthProvider")
        setAuthScenarioId(sid)
        _setToken(t)
        setAuthenticationType(decodeJwt(t)?.identities[0].authenticationType)
      } else {
        logger("Clearing token and scenario after checking session or local storage - AuthProvider")
        setAuthScenarioId(null)
        _setToken(null)
        setAuthenticationType(undefined)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToken])

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  }, [token])

  useEffect(() => {
    if (
      token == null ||
      authScenarioId == null ||
      // does not work if we place the condition on "active"
      appStateStatus === "background" ||
      appStateStatus === "inactive"
    ) {
      return
    }
    const interval = setInterval(async () => {
      try {
        await axios.get(`${apiUrl}/authentication/status`, {
          // throw errors for all statuses >= 300
          validateStatus: status => status < 300,
        })
      } catch (error) {
        logger("error checking the token validity in AuthProvider", error)
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status >= 300 &&
          error.response.status <= 499
        ) {
          setToken(null)
          setAuthScenarioId(null)
          setTriggerStatesReset(true)
        }
      }
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [appStateStatus, authScenarioId, setToken, token])

  useEffect(() => {
    if (token) {
      setAuthenticationType(decodeJwt(token)?.identities[0].authenticationType)
    }
  }, [token])

  const setScenarioId = useCallback(
    (sid?: ScenarioId | null) => {
      setAuthScenarioId(sid)
      setAuthScenarioIdStorageItem(sid)
    },
    [setAuthScenarioIdStorageItem],
  )

  // using `=== undefined` because token and scenarioId can also be set to null
  if (token === undefined || authScenarioId === undefined) {
    return (
      <ActivityIndicator
        size="large"
        containerStyle={[StyleSheet.absoluteFill, cs.centerIndicator]}
      />
    )
  }

  // for extraTime users
  const extraTimeFactor = currentUser?.extraTime ? 1 + 1 / 3 : 1

  const isQA = qaMode || (currentUser?.cheatMode ?? false)

  const contextValue: ContextData = {
    token,
    authScenarioId,
    setScenarioId,
    setToken,
    hasAuth: token != null,
    authenticationType,
    triggerStatesReset,
    setTriggerStatesReset,
    currentUser,
    extraTimeFactor,
    authError,
    setAuthError,
    isQA,
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

const useAuthContext = (): ContextData => {
  const context = useContext(AuthContext)
  if (_.isEmpty(context)) {
    throw new Error("useAuthContext must be used within an AuthProvider")
  }
  return context
}

export default useAuthContext
