import type {
  Interlock,
  Level,
  LevelId,
  LevelProgression,
  LevelRevision,
  ModuleProgression,
  RuleId,
  StepId,
} from "@newpv/js-common"
import { fetchLevel, getLevelsForRevision } from "@newpv/js-common"
import dayjs from "dayjs"
import _ from "lodash"

interface RevisionDetailsParams {
  revisionLevel: LevelRevision
  stepId: StepId
  levels: Level[]
  revisionRulesIds?: RuleId[][]
  progression?: LevelProgression
}

export const getRevisionStepRules = async ({
  revisionLevel,
  stepId,
  levels,
  revisionRulesIds,
  progression,
}: RevisionDetailsParams): Promise<{ levelId: LevelId; rules: any[]; stepId: StepId }> => {
  const isFinalRevision = revisionLevel.type === "finalRevision"
  // we get the relevant levels => if it's a revision level, all the levels since the last,
  // if it's a final revision level, all the levels of the module
  const staticLevels = isFinalRevision
    ? _.filter(levels, ml => ml.type === "static")
    : getLevelsForRevision(levels, revisionLevel)

  const levelRules = _.reduce(
    await Promise.all(
      staticLevels.map(async l => {
        const levelDetail = await fetchLevel(undefined, l.id)
        return _(levelDetail.rules)
          .keyBy(rr => rr.id)
          .value()
      }),
    ),
    (res, lr) => ({ ...res, ...lr }),
    {},
  )
  return {
    levelId: revisionLevel.id,
    rules: isFinalRevision
      ? _.map(progression?.revisionRuleIds?.[stepId], rr => levelRules?.[rr])
      : _.map(revisionRulesIds?.[stepId], rr => levelRules?.[rr]),
    stepId,
  }
}

export const isFinalLevelAvailable = (
  levels: Level[][],
  progression?: Record<number, LevelProgression>,
): boolean => {
  const revisionLevels = _.filter(_.flatten(levels), lvl => lvl.type === "revision")
  const result = _.every(revisionLevels, revisionLevel =>
    progression?.[revisionLevel.id]?.completionPercentage != null
      ? // the revision level is completed
        progression?.[revisionLevel.id]?.completionPercentage >= 100 ||
        // or the revision level is not started but had no revision rule ids = was auto validated
        (progression?.[revisionLevel.id].completionPercentage <= 0 &&
          _.isEmpty(progression?.[revisionLevel.id]?.revisionRuleIds))
      : false,
  )
  return result
}

export const getRevisionRulesTitles = async (
  revisionLevel: LevelRevision,
  levels?: Level[],
  revisionRulesId?: RuleId[],
): Promise<string[]> => {
  const isRevision = revisionLevel.type === "revision"
  const staticLevels =
    isRevision && levels
      ? getLevelsForRevision(levels, revisionLevel)
      : _.filter(levels, ml => ml.type === "static")

  const levelRules = _.flatten(
    await Promise.all(
      staticLevels.map(async l => {
        const levelDetail = await fetchLevel(undefined, l.id)
        return levelDetail.rules
      }),
    ),
  )
  return levelRules.filter(lr => revisionRulesId?.includes(lr.id)).map(lr => lr.title)
}

export const isStaticLevelLocked = (
  /** all levels in the module */
  allLevels: Level[],
  currentLevelId: LevelId,
  moduleProgression?: ModuleProgression,
  interlock?: Interlock,
): boolean => {
  const [staticLevels, revisionLevels] = _.partition(allLevels, lvl => lvl.type === "static")
  const currentLevelIndex = _.findIndex(staticLevels, slvl => slvl.id === currentLevelId)
  if (interlock === "free" || currentLevelIndex <= 0) {
    return false
  }

  // a revision level (not final revision) is currently open and should be the only one accessible
  const hasOpenRevisionLevel = _.some(revisionLevels, revisionLevel => {
    const levelProgression = moduleProgression?.levels?.[revisionLevel.id]

    if (levelProgression == null || _.isEmpty(levelProgression.revisionRuleIds)) {
      return false
    }

    const openDate = levelProgression.revisionOpenDate
    return levelProgression.completionPercentage < 100 && dayjs().isAfter(dayjs(openDate))
  })

  const previousStaticLevelId = staticLevels?.[currentLevelIndex - 1]?.id
  const previousStaticLevelCompletion =
    moduleProgression?.levels?.[previousStaticLevelId]?.completionPercentage ?? 0
  // if the current static level was started, the user can always continue
  const currentStaticLevelCompletion =
    moduleProgression?.levels?.[currentLevelId]?.completionPercentage ?? 0

  return (
    previousStaticLevelCompletion < 100 ||
    (hasOpenRevisionLevel && currentStaticLevelCompletion <= 0)
  )
}
