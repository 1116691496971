import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const SettingsSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { icon },
  } = useTheme()

  return (
    <Svg
      {...props}
      width={props?.width ?? iconSize.SMALL}
      height={props?.width ?? iconSize.SMALL}
      fill="none"
      viewBox={`0 0 ${iconSize.SMALL} ${iconSize.SMALL}`}
    >
      <Path
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        stroke={props?.color ?? icon}
        strokeOpacity={0.6}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20 12a8.049 8.049 0 0 0-.188-1.713l2.714-2.055-2-3.464-3.143 1.326a7.986 7.986 0 0 0-2.96-1.719L14 1h-4l-.422 3.375c-1.1.352-2.11.939-2.96 1.719L3.473 4.768l-2 3.464 2.714 2.055a7.9 7.9 0 0 0 0 3.426l-2.714 2.055 2 3.464 3.143-1.326a7.987 7.987 0 0 0 2.961 1.719L10 23h4l.422-3.375a7.986 7.986 0 0 0 2.961-1.719l3.143 1.326 2-3.464-2.714-2.055A8.049 8.049 0 0 0 20 12v0Z"
        stroke={props?.color ?? icon}
        strokeOpacity={0.6}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SettingsSVG
