import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
const StarFillSVG = (props?: SvgProps): JSX.Element => (
  <Svg {...props} width={props?.width ?? 22} height={props?.height ?? 22} viewBox="0 0 22 22">
    <Path
      fill={props?.color}
      d="M10 15.27 16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27Z"
    />
  </Svg>
)
export default StarFillSVG
