import type { ApiError, Memo, Module, ModuleId, ProgressionData, Scenario } from "@newpv/js-common"
import { getRouteIndex, lett } from "@newpv/js-common"
import { useFetchOneScenario } from "hooks/useFetchScenarioInfo"
import { useFetchUserProgressionDetail } from "hooks/useFetchUserProgressionDetail"
import _ from "lodash"
import useAuthContext from "providers/AuthProvider"
import type { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import type { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query"
import { logger } from "utils/logger"

interface ContextData {
  routeIndex?: number
  setRouteIndex: Dispatch<SetStateAction<number | undefined>>
  moduleId?: ModuleId
  setModuleId: Dispatch<SetStateAction<ModuleId | undefined>>
  module?: Module
  scenario?: Scenario
  isScenarioLoading: boolean
  scenarioError: ApiError | null
  progressionData?: ProgressionData
  memos?: ProgressionData["memos"]
  setMemo: (memo: Memo) => void
  progressionError: ApiError | null
  isProgressionLoading: boolean
  /** reset all states, used on logout */
  resetScenarioAndModuleState: () => void
  refetchUserProgression: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult>
}

const scenarioAndModuleContext = createContext<ContextData>({} as ContextData)

const ScenarioAndModuleProvider = ({ children }: PropsWithChildren<any>): JSX.Element => {
  // states for LevelsScreen
  const [routeIndex, setRouteIndex] = useState<number>()
  const [memos, setMemos] = useState<ProgressionData["memos"]>()

  const { authScenarioId: scenarioId, setScenarioId } = useAuthContext()

  // states for ExerciseScreen (ExerciseWrapper)
  const [moduleId, setModuleId] = useState<ModuleId>()

  const {
    data: scenario,
    isLoading: isScenarioLoading,
    error: scenarioError,
  } = useFetchOneScenario(scenarioId)

  const {
    data: progressionData,
    isLoading: isProgressionLoading,
    error: progressionError,
    refetch,
  } = useFetchUserProgressionDetail(scenarioId)

  useEffect(() => {
    if (!scenario || !progressionData || routeIndex != null) {
      return
    }

    const ri = getRouteIndex(progressionData?.progressionDetail, scenario)

    if (ri == null) {
      logger("Can't determine route index, probably trigger an evaluation")
      return
    }

    setRouteIndex(ri)
    setModuleId(
      _.find(
        scenario.routes[ri]?.modules,
        m => (progressionData.progressionDetail.modules[m.id]?.completionPercentage ?? 0) < 100,
      )?.id ?? scenario?.routes?.[ri]?.modules[0].id,
    )
  }, [progressionData, routeIndex, scenario])

  useEffect(() => {
    if (progressionData == null) {
      return
    }
    lett(progressionData.memos, setMemos)
  }, [progressionData])

  const module = useMemo(() => {
    if (!moduleId) {
      return undefined
    }
    return scenario?.routes?.[routeIndex ?? 0]?.modules.find(m => m.id === moduleId)
  }, [scenario?.routes, routeIndex, moduleId])

  const resetScenarioAndModuleState = useCallback(() => {
    logger("calling resetScenarioAndModuleState")
    setScenarioId(null)
    setRouteIndex(undefined)
    setModuleId(undefined)
  }, [setScenarioId])

  const setMemo = useCallback((memo: Memo) => {
    setMemos(oldMemos => ({
      ...oldMemos,
      [memo.levelId]: [
        ...(oldMemos?.[memo.levelId]?.filter(m => m.ruleId !== memo.ruleId) ?? []),
        memo,
      ],
    }))
  }, [])

  const contextValue: ContextData = {
    routeIndex,
    setRouteIndex,
    moduleId,
    setModuleId,
    module,
    scenario,
    isScenarioLoading,
    scenarioError,
    progressionData,
    memos,
    setMemo,
    progressionError,
    isProgressionLoading,
    resetScenarioAndModuleState,
    refetchUserProgression: refetch,
  }

  return (
    <scenarioAndModuleContext.Provider value={contextValue}>
      {children}
    </scenarioAndModuleContext.Provider>
  )
}

export const useScenarioAndModule = (): ContextData => {
  const context = useContext(scenarioAndModuleContext)
  if (_.isEmpty(context)) {
    throw new Error("useScenarioAndModule must be used within a ScenarioAndModuleProvider")
  }

  return context
}

export default ScenarioAndModuleProvider
