import { maxModalWidth } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { ISnackInternal } from "providers/DebugProvider"
import type { FC } from "react"
import { Snackbar as PaperSnackbar } from "react-native-paper"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"

import { Body2 } from "../Texts"

interface IProps {
  hasInset?: boolean
  snack: ISnackInternal
}

const Snackbar: FC<IProps> = ({ snack: { message, ...rest }, hasInset }) => {
  const insets = useSafeAreaInsets()

  const s = useStyles(
    ({
      roundness,
      darkMode,
      colors: {
        black,
        onPrimary: { highEmphasis: white },
        onSurface: { highEmphasis: onSurfaceBlack },
      },
    }) => ({
      safeView: {
        marginBottom: insets.bottom,
      },
      snackbar: {
        alignSelf: "center",
        backgroundColor: onSurfaceBlack,
        borderRadius: !roundness || roundness === 0 ? 4 : roundness,
        bottom: 0,
        maxWidth: maxModalWidth,
        position: "absolute",
      },
      text: {
        color: darkMode ? black : white,
      },
    }),
    [insets.bottom],
  )

  return (
    <SafeAreaView style={hasInset ? s.safeView : undefined}>
      <PaperSnackbar {...rest} style={s.snackbar}>
        <Body2 style={s.text}>{message}</Body2>
      </PaperSnackbar>
    </SafeAreaView>
  )
}

export default Snackbar
