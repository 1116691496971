import RuleTabContent from "components/RuleTabContent/RuleTabContent"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import type { TabType } from "models/TabInterfaces"
import TabProvider from "providers/TabProvider"
import type { FC } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import BookSVG from "svgs/Book"
import EmptyStarSVG from "svgs/EmptyStar"

import TabBar from "../TabBar/TabBar"

interface IProps {
  isBottom?: boolean
  masteredRules?: string[]
  unmasteredRules?: string[]
}

const ModalRulesStatusContent: FC<IProps> = ({ isBottom, masteredRules, unmasteredRules }) => {
  const { t } = useTranslation()
  // Indexing rules lists with tabs
  const indexing = _.map({
    0: {
      index: "notCompleted",
      rules: unmasteredRules,
    },
    1: {
      index: "completed",
      rules: masteredRules,
    },
  }).filter(value => !_.isEmpty(value.rules))

  const tabs = useMemo<TabType[]>(
    () =>
      indexing.map(n => ({
        index: n.index as string,
        title: t(`${ns.MODAL}.${ModalType.RESULTS}.${n.index}`),
        image: props =>
          n.index === "completed" ? <EmptyStarSVG {...props} /> : <BookSVG {...props} />,
      })),
    [indexing, t],
  )

  const { tabLabel } = useStyles(
    ({ dimensions: { spacing } }) => ({
      tabLabel: {
        alignSelf: "center" as const,
        maxWidth: "80%" as const,
        paddingBottom: spacing / 2,
      },
    }),
    [],
  )

  return (
    <TabProvider initialTab={{ index: _.first(tabs)?.index ?? "notCompleted", title: "" }}>
      <TabBar tabLabelStyle={tabLabel} {...{ tabs }} />
      <RuleTabContent {...{ indexing, isBottom }} />
    </TabProvider>
  )
}

export default ModalRulesStatusContent
