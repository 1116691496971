import { maxModalWidth } from "constants/constants"
import FocusTrap from "focus-trap-react"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import type { IModalInternal } from "models/ModalInterfaces"
import DebugProvider from "providers/DebugProvider"
import type { FC } from "react"
import { Modal as PaperModal, Portal } from "react-native-paper"

import ModalBody from "./ModalBody"
import ModalButtons from "./ModalButtons"
import ModalHeader from "./ModalHeader"

interface IProps {
  modal: IModalInternal
}

const Modal: FC<IProps> = ({
  modal: {
    type,
    headerTitle,
    headerImage,
    numberOfMasteredRules,
    isEvaluation,
    bodyTitle,
    bodyTitleStyle,
    bodySubtitle,
    bodySubtitleStyle,
    bodySubtitleTypographyVariant,
    positive,
    negative,
    neutral,
    children,
    ...rest
  },
}) => {
  const { isSmallWidth } = useDeviceTools()
  const s = useStyles(
    ({
      dimensions: { margin },
      colors: {
        surface: { backgroundModal },
      },
      roundness,
    }) => ({
      contentContainerStyle: {
        maxWidth: maxModalWidth,
        alignSelf: "center",
        width: "100%" as const,
        backgroundColor: backgroundModal,
        borderRadius: roundness * 2,
        marginHorizontal: margin,
        overflow: "hidden",
      },
      style: {
        marginHorizontal: margin / 2,
      },
    }),
    [],
  )

  return (
    <Portal>
      <DebugProvider hasInset={true}>
        <FocusTrap>
          <PaperModal
            {...rest}
            style={isSmallWidth ? s.style : undefined}
            contentContainerStyle={s.contentContainerStyle}
          >
            <ModalHeader {...{ headerTitle, headerImage, type, numberOfMasteredRules }} />
            <ModalBody
              {...{
                type,
                bodyTitle,
                bodyTitleStyle,
                bodySubtitle,
                bodySubtitleStyle,
                numberOfMasteredRules,
                isEvaluation,
                hasImage: !!headerImage,
                typographyVariant: bodySubtitleTypographyVariant,
              }}
            >
              {children}
            </ModalBody>
            <ModalButtons {...{ positive, negative, neutral, type }} />
          </PaperModal>
        </FocusTrap>
      </DebugProvider>
    </Portal>
  )
}

export default Modal
