import { Button } from "components/Button"
import { bottomSheet, buttonMaxWidth, isWeb, transition } from "constants/constants"
import { LinearGradient } from "expo-linear-gradient"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { MotiView } from "moti"
import { BottomState, useModal } from "providers/ModalProvider"
import type { FC, PropsWithChildren } from "react"
import { useCallback, useMemo } from "react"
import type { StyleProp, TextStyle, ViewStyle } from "react-native"
import { StyleSheet, View } from "react-native"
import { Portal } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

// TODO: review value here
// 120? duplicate?
export const BUTTON_CONTAINER_HEIGHT = 120

export interface ButtonConfig {
  label: string
  onPress: () => void
  buttonStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  contentStyle?: StyleProp<ViewStyle>
  mode?: "contained" | "outlined" | "text"
  color?: string
}

interface Props extends PropsWithChildren {
  onDismiss?: () => void
  buttonConfigs?: ButtonConfig[]
  customHeight?: number
  disablePaddingBottom?: boolean
}

// Issue with Moti/AnimatedPresence/Frame-Motion/Expo SDK/RN-Reanimated
// https://github.com/nandorojo/moti/issues/238
const BottomModal: FC<Props> = ({
  children,
  onDismiss,
  buttonConfigs,
  customHeight,
  disablePaddingBottom,
}) => {
  const {
    colors: { onSurface, surface, ripple },
    roundness: themeRoundness,
  } = useTheme()

  const { bottom: bottomInset } = useSafeAreaInsets()
  const { onLayout, height } = useLayout()
  const { setBottomModalState, bottomModalState } = useModal()

  const contentContainerStyle = useMemo<ViewStyle>(
    () => ({
      alignItems: "center",
      alignSelf: "center",
      backgroundColor: surface.backgroundModal,
      borderTopLeftRadius: isWeb ? themeRoundness * 2 : 0,
      borderTopRightRadius: isWeb ? themeRoundness * 2 : 0,
      bottom: -bottomInset,
      height: customHeight ?? bottomSheet.MAX_HEIGHT_3,
      maxWidth: bottomSheet.MAX_WIDTH,
      // @ts-ignore
      overflow: isWeb ? "clip" : undefined,
      paddingBottom: disablePaddingBottom
        ? undefined
        : buttonConfigs
        ? bottomInset + height
        : undefined,
      position: "absolute",
      width: "100%" as const,
    }),
    [
      surface.backgroundModal,
      themeRoundness,
      bottomInset,
      customHeight,
      disablePaddingBottom,
      buttonConfigs,
      height,
    ],
  )

  const s = useStyles(
    ({ roundness, dimensions: { spacing, margin } }) => ({
      button: {
        borderRadius: roundness * 4,
        marginBottom: margin / 2,
        maxWidth: buttonMaxWidth,
        width: "100%" as const,
        borderColor: surface.outline,
        borderWidth: 1,
      },
      buttonContainer: {
        alignItems: "center",
        bottom: bottomInset,
        flex: 1,
        padding: spacing,
        position: "absolute",
        width: "100%" as const,
        zIndex: 1,
      },
      view: {
        borderColor: "transparent",
        borderWidth: 1,
        flex: 1,
      },
    }),
    [height, isWeb, bottomInset, buttonMaxWidth],
  )

  const handleOnResponderGrant = useCallback(async () => {
    onDismiss?.()
    setBottomModalState(BottomState.HIDE)
  }, [onDismiss, setBottomModalState])

  return (
    <Portal>
      {bottomModalState !== BottomState.HIDE ? (
        <MotiView
          from={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          {...{ transition }}
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: onSurface.disabledModal,
              overflow: "hidden",
            },
          ]}
        >
          <View
            style={s.view}
            onStartShouldSetResponder={() => true}
            onResponderGrant={handleOnResponderGrant}
            onResponderRelease={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          />
          <MotiView
            {...{ transition }}
            from={{
              translateY: bottomSheet.MAX_HEIGHT_3,
            }}
            animate={{
              translateY: -bottomInset,
            }}
            style={contentContainerStyle}
          >
            {children}
            {buttonConfigs ? (
              <LinearGradient
                {...{ onLayout }}
                style={s.buttonContainer}
                colors={[surface.translucent, surface.backgroundModal]}
              >
                {buttonConfigs.map(
                  ({ onPress, labelStyle, buttonStyle, label, mode, contentStyle }, index) => (
                    <Button
                      key={index}
                      rippleColor={rippleColor(ripple)}
                      style={[s.button, buttonStyle]}
                      {...{ onPress, contentStyle, labelStyle, mode }}
                    >
                      {label}
                    </Button>
                  ),
                )}
              </LinearGradient>
            ) : null}
          </MotiView>
        </MotiView>
      ) : null}
    </Portal>
  )
}

export default BottomModal
