import type {
  Exercise,
  ExerciseId,
  ExerciseWithRuleId,
  Rule,
  RuleData,
  RuleId,
} from "@newpv/js-common"
import _ from "lodash"

export const getExercisesWithRuleId = (rules: Rule[] | undefined): ExerciseWithRuleId[] =>
  _(rules)
    .map(r => _.map(r.exercises, ex => ({ ...ex, ruleId: r.id })))
    .flatten()
    .orderBy(["id"])
    .value()

export const getCurrentRuleAndExerciseData = (
  rules: Rule[],
  ruleId?: RuleId,
  exerciseId?: ExerciseId,
): { rule?: Rule; exercise?: Exercise } => {
  const rule = _.find(rules, ["id", ruleId])
  const exercise = _.find(rule?.exercises, ["id", exerciseId])
  return {
    rule,
    exercise,
  }
}

/** For each provided rule, returns an object with `id` being the rule id, and `exercises` the rule's exercises */
export const getExercisesByRuleId = (
  rules: Rule[] | undefined,
): Array<{ id: RuleId; exercises: RuleData["exercises"] }> =>
  _.reduce(
    rules,
    (list, r) => [
      ...list,
      { id: r.id, exercises: _.map(r.exercises, e => ({ id: e.id, difficulty: 2 })) },
    ],
    [],
  )
