import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import { View } from "react-native"

interface IProps {
  height?: number
}

const Separator: FC<IProps> = ({ height = 1 }) => {
  const { style } = useStyles(
    ({
      colors: {
        surface: { overlay },
      },
    }) => ({
      style: {
        height,
        backgroundColor: overlay,
      },
    }),
    [height],
  )

  return <View {...{ style }} />
}

export default Separator
