import type { DragAndDropWord } from "@newpv/js-common"
import { MAX_TAP_DISTANCE } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import { useDragAndDrop, WordColumn } from "providers/DadProvider"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { PanResponder, View } from "react-native"
import { DraxSnapbackTargetPreset, DraxView } from "react-native-drax"

import WordBlock from "./WordBlock"

const WordBank: FC = () => {
  const { isSmallWidth } = useDeviceTools()

  const {
    wordsInBank,
    wordsInFirstColumn,
    wordsInSecondColumn,
    manageWord,
    setTapSelectedWord,
    tapSelectedWord,
    isTouchScreen,
  } = useDragAndDrop()

  const s = useStyles(
    ({ roundness, dimensions: { spacing, margin }, colors: { surface } }) => ({
      wordBank: {
        backgroundColor: isSmallWidth ? surface.surface : surface.backgroundModal,
        borderColor: surface.outline,
        borderRadius: roundness,
        borderStyle: "dashed",
        borderWidth: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: margin / 2,
        marginTop: margin,
        minHeight: 67,
        paddingLeft: spacing / 4,
        paddingRight: spacing * 0.75,
      },
    }),
    [],
  )

  const tapAndTapPanResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderRelease: (evt, gestureState) => {
          if (
            Math.abs(gestureState.dx) < MAX_TAP_DISTANCE &&
            Math.abs(gestureState.dy) < MAX_TAP_DISTANCE
          ) {
            if (tapSelectedWord) {
              manageWord(tapSelectedWord, WordColumn.BANK)
              setTapSelectedWord(undefined)
            }
          }
        },
        onPanResponderTerminationRequest: () => true,
      }),
    [manageWord, setTapSelectedWord, tapSelectedWord],
  )

  const renderContent = useMemo(
    () => () =>
      wordsInBank ? (
        <View
          style={s.wordBank}
          // responder for the tap and tap
          {...tapAndTapPanResponder.panHandlers}
        >
          <>
            {wordsInBank.map((wordInBank, index) => (
              <WordBlock key={`${wordInBank.id}-${index}`} word={wordInBank} />
            ))}
            {wordsInFirstColumn?.map((wordInBank, index) => (
              <WordBlock
                key={`${wordInBank.id}-${index}-filler`}
                word={wordInBank}
                isFiller={true}
              />
            ))}
            {wordsInSecondColumn?.map((wordInBank, index) => (
              <WordBlock
                key={`${wordInBank.id}-${index}-filler`}
                word={wordInBank}
                isFiller={true}
              />
            ))}
          </>
        </View>
      ) : null,
    [
      tapAndTapPanResponder.panHandlers,
      s.wordBank,
      wordsInBank,
      wordsInFirstColumn,
      wordsInSecondColumn,
    ],
  )

  const onReceiveDragDrop = useCallback(
    event => {
      const { word }: { word: DragAndDropWord } = event.dragged.payload
      manageWord(word, WordColumn.BANK)
      return DraxSnapbackTargetPreset.None
    },
    [manageWord],
  )

  return (
    <DraxView
      id="WordBank"
      isParent
      onReceiveDragDrop={!!isTouchScreen ? undefined : onReceiveDragDrop}
      {...{ renderContent }}
    />
  )
}

export default WordBank
