import type {
  ColumnNumber,
  DragAndDropExercise,
  DragAndDropWord,
  FormattedDragAndDropExercise,
} from "@newpv/js-common"
import { isIpadWeb, isWeb } from "constants/constants"
import * as Device from "expo-device"
import * as Haptics from "expo-haptics"
import _ from "lodash"
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"

interface ContextData {
  isDragging: boolean
  wordsInBank: DragAndDropWord[] | undefined
  wordsInFirstColumn: DragAndDropWord[]
  wordsInSecondColumn: DragAndDropWord[]
  activateDragging: () => void
  deactivateDragging: () => void
  getNumberOfErrors: () => number | undefined
  manageWord: (word: DragAndDropWord, type: WordColumn) => void
  columInstructions: string[]
  tapSelectedWord?: DragAndDropWord
  setTapSelectedWord: Dispatch<SetStateAction<DragAndDropWord | undefined>>
  isTouchScreen?: boolean
}

export const triggerHapticFeedback = (): void => {
  if (!isWeb) {
    // noinspection JSIgnoredPromiseFromCall
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
  }
}

// eslint-disable-next-line no-shadow
export enum WordColumn {
  BANK,
  FIRST_COLUMN,
  SECOND_COLUMN,
}

export const addWord = (
  word: DragAndDropWord,
  setState: Dispatch<SetStateAction<DragAndDropWord[] | undefined>>,
): void => {
  setState(prevState =>
    prevState?.findIndex(item => item.id === word.id) !== -1 ? prevState : [...prevState, word],
  )
}

export const removeWord = (
  word: DragAndDropWord,
  setState: Dispatch<SetStateAction<DragAndDropWord[] | undefined>>,
): void => {
  setState(previousWordsInBank => previousWordsInBank?.filter(item => item.id !== word.id))
}

const dadContext = createContext<ContextData>({} as ContextData)

export const DadProvider: FC<PropsWithChildren<{ exercise: DragAndDropExercise }>> = ({
  children,
  exercise,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [formattedExercise, setFormattedExercise] = useState<FormattedDragAndDropExercise>()
  const [wordsInBank, setWordsInBank] = useState<DragAndDropWord[]>()
  const [tapSelectedWord, setTapSelectedWord] = useState<DragAndDropWord>()
  const [wordsInFirstColumn, setWordsInFirstColumn] = useState<DragAndDropWord[]>([])
  const [wordsInSecondColumn, setWordsInSecondColumn] = useState<DragAndDropWord[]>([])
  const [isTouchScreen, setIsTouchScreen] = useState<boolean>()

  useEffect(() => {
    let mounted = true
    setFormattedExercise({
      ...exercise,
      columns: exercise.columns.map((columnContent, columnIndex) => ({
        ...columnContent,
        words: columnContent.words.map((word, index) => ({
          id: `${index}-${columnIndex}`,
          content: word,
          correctColumn: (columnIndex + 1) as ColumnNumber,
        })),
      })),
    })
    ;(async () => {
      Device.getDeviceTypeAsync().then(type => {
        if (mounted) {
          setIsTouchScreen(
            (isWeb && (type === Device.DeviceType.PHONE || type === Device.DeviceType.TABLET)) ||
              isIpadWeb,
          )
        }
      })
    })()
    return () => {
      mounted = false
    }
  }, [exercise])

  const reset = useCallback(() => {
    setIsDragging(false)
    setWordsInBank([])
    setWordsInFirstColumn([])
    setWordsInSecondColumn([])
  }, [])

  useEffect(() => {
    if (formattedExercise) {
      reset()
      const numberOfWordsInFirstColumn = _.random(1, formattedExercise.displayCount - 1)
      const pickedWords = _.concat(
        _.sampleSize(formattedExercise.columns[0].words, numberOfWordsInFirstColumn),
        _.sampleSize(
          formattedExercise.columns[1].words,
          formattedExercise.displayCount - numberOfWordsInFirstColumn,
        ),
      )
      setWordsInBank(_.shuffle(pickedWords))
    }
  }, [formattedExercise, reset])

  const activateDragging = useCallback(() => {
    triggerHapticFeedback()
    setIsDragging(true)
  }, [])

  const deactivateDragging = useCallback(() => {
    setIsDragging(false)
  }, [setIsDragging])

  const getNumberOfErrors = useCallback(
    () =>
      [wordsInFirstColumn, wordsInSecondColumn].reduce(
        (previousValue, currentArray, currentIndex) =>
          previousValue +
          currentArray.filter(word => word.correctColumn !== currentIndex + 1).length,
        0,
      ),
    [wordsInFirstColumn, wordsInSecondColumn],
  )

  const manageWord = useCallback((word: DragAndDropWord, type: WordColumn) => {
    if (type === WordColumn.BANK) {
      addWord(word, setWordsInBank)
      removeWord(word, setWordsInFirstColumn)
      removeWord(word, setWordsInSecondColumn)
      return
    }
    addWord(word, type === WordColumn.FIRST_COLUMN ? setWordsInFirstColumn : setWordsInSecondColumn)
    removeWord(word, setWordsInBank)
    removeWord(
      word,
      type === WordColumn.FIRST_COLUMN ? setWordsInSecondColumn : setWordsInFirstColumn,
    )
  }, [])

  return (
    <dadContext.Provider
      value={{
        isDragging,
        wordsInBank,
        wordsInFirstColumn,
        wordsInSecondColumn,
        activateDragging,
        deactivateDragging,
        getNumberOfErrors,
        manageWord,
        tapSelectedWord,
        setTapSelectedWord,
        isTouchScreen,
        columInstructions: exercise.columns.map(columnContent => columnContent.instruction),
      }}
    >
      {children}
    </dadContext.Provider>
  )
}

export const useDragAndDrop = (): ContextData => {
  const context = useContext(dadContext)
  if (_.isEmpty(context)) {
    throw new Error("useDragAndDrop must be used within a DadProvider")
  }
  return context
}

export default DadProvider
