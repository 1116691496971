const hitSlop = (
  value: number,
  horizontalValue?: number,
): { top: number; bottom: number; left: number; right: number } => ({
  top: value,
  bottom: value,
  left: horizontalValue ?? value,
  right: horizontalValue ?? value,
})

export default hitSlop
