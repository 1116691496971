import { useEffect } from "react"
import type { AppStateStatus } from "react-native"
import { AppState } from "react-native"

export function useAppState(onChange: (status: AppStateStatus) => void): void {
  useEffect(() => {
    const listener = AppState.addEventListener("change", onChange)
    return () => {
      listener.remove()
    }
  }, [onChange])
}
