import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"

const ErrorLogoSVG = (props?: SvgProps): JSX.Element => (
  <Svg
    {...props}
    width={props?.width ?? 24}
    height={props?.height ?? 24}
    fill="none"
    viewBox={`0 0 ${props?.width ?? 24} ${(props?.height as number) - 50 ?? 24}`}
  >
    <Path
      d="M66.937.3c-7.1 0-14.1 0-21.2.1l-21.1.3c-3.4.3-6.4 2.6-7.5 5.7-.3.8-.4 1.6-.5 2.4v2.6l.1 5.3.3 21.2c0 .6-.5 1.1-1.1 1.2-.3 0-.6-.1-.8-.3h-.1l1.8-.3-4 8.3-4.1 8.3-1.8-1.7 7.5-4.2 7.5-4.1c.2-.1.4-.2.6-.2 4.2 0 8.4 0 12.6.1 4.2 0 8.4.1 12.6.2l12.6.2 6.3.1c2 0 3.9-.5 5.5-1.7s2.8-2.9 3.3-4.8c.3-1 .3-1.9.3-3l.1-3.2.2-12.6.1-6.3v-3.2c0-1.1 0-2.1-.2-3.1-.4-2-1.6-3.9-3.3-5.2-1.5-1.3-3.6-2-5.7-2.1Zm0 0c2.1 0 4.2.7 5.8 2 1.6 1.3 2.9 3.2 3.3 5.2.2 1 .3 2.1.3 3.1v3.2l.1 6.3.2 12.6.1 3.2c0 1 0 2.2-.2 3.2-.5 2.2-1.7 4.1-3.5 5.5-1.7 1.4-4 2.2-6.2 2.2l-6.3.1-12.6.2c-4.2.1-8.4.1-12.6.2-4.2.1-8.4.1-12.6.1l.6-.2-7.5 4.2-7.5 4.1c-.6.3-1.4.1-1.7-.5-.2-.4-.2-.8 0-1.2l4.2-8.3 4.2-8.2c.3-.6 1-.8 1.5-.5.1 0 .2.1.2.2l.1.1-1.9.9.3-21.2.1-5.3c0-.9 0-1.7.1-2.7.1-.9.3-1.9.7-2.7 1.4-3.5 4.9-6 8.6-6.1l21.2.3h21Z"
      fill="#000"
    />
    <Path
      d="m49.637 46.2.3 8.4c0 1.4 0 2.8.5 4 .4 1.3 1.1 2.4 2 3.4.9.9 2 1.7 3.3 2.2 1.2.5 2.5.6 3.9.6l8.4-.1 16.8-.3 16.8-.1c.7 0 1.3.6 1.3 1.2 0 .3-.1.6-.3.8v.1l-.4-2 16.6 8.2-1.7 1.8-8.2-15c-.1-.2-.2-.4-.2-.6l.1-17 .1-8.5.1-4.2c0-1.3-.2-2.6-.7-3.7-1-2.4-3.2-4.2-5.8-4.8-.3-.1-.6-.1-1-.2-.3 0-.7 0-1-.1h-2.1l-4.2-.1c-5.7-.2-11.3-.3-17-.5 5.7-.2 11.3-.4 17-.5l4.2-.1h3.2c.4.1.8.1 1.1.2 3 .6 5.7 2.7 7 5.5.7 1.4 1.1 2.9 1.1 4.5l.1 4.2.1 8.5.1 17-.2-.6 8.3 15c.4.6.1 1.4-.5 1.8-.4.2-.9.2-1.2 0l-16.6-8.3c-.6-.3-.9-1.1-.6-1.7.1-.1.1-.2.2-.3v-.1l.9 2.1-16.8-.1-16.8-.3-8.4-.1c-1.4 0-3-.2-4.3-.8-1.4-.6-2.6-1.5-3.5-2.6-1-1.1-1.7-2.4-2-3.9-.4-1.4-.3-2.9-.3-4.3.1-3 .2-5.8.3-8.6Z"
      fill="#000"
    />
    <Path
      d="M.337 17.7c6.8 4.6 15 6.8 23.1 7.3 8.1.6 16.4-.3 24.3-2.4 3.9-1.1 7.8-2.4 11.3-4.4 1.7-1 3.4-2.1 4.6-3.6.6-.7.9-1.5.8-2.3-.1-.8-.5-1.5-1.3-2-1.5-.9-3.5-1-5.4-.7-1.9.3-3.7 1.1-5.3 2.2-1.6 1.1-3 2.5-4 4.1-1 1.6-1.6 3.5-1.8 5.4-.2 1.9-.1 3.9.3 5.8s1.1 3.7 2.2 5.2 2.6 2.6 4.4 3.3c1.7.7 3.7 1 5.6 1.1 3.9.2 7.9-.5 11.8-1.4 3.9-1 7.8-2.2 11.6-3.6 7.6-2.8 15.1-6.1 22.5-9.7 3.7-1.8 7.3-3.6 10.9-5.6 3.6-1.9 7.2-3.9 10.8-5.9h.2v.2c-3.4 2.4-6.8 4.6-10.3 6.8-3.5 2.1-7.1 4.2-10.7 6.2-3.6 2-7.3 3.8-11 5.6-3.7 1.8-7.5 3.5-11.4 4.9-3.9 1.4-7.8 2.8-11.9 3.7-4.1.9-8.3 1.6-12.6 1.3-2.1-.1-4.3-.5-6.4-1.4-2.1-.9-3.9-2.3-5.3-4.1-1.3-1.8-2.1-4-2.5-6.1-.4-2.1-.5-4.3-.2-6.5s1-4.3 2.2-6.1c1.2-1.9 2.8-3.4 4.6-4.6 1.8-1.2 3.9-2 6-2.3 1.1-.2 2.2-.2 3.3-.1 1.1.1 2.2.4 3.2 1.1.5.3.9.8 1.3 1.3.3.5.5 1.2.6 1.8.1 1.3-.5 2.5-1.1 3.4-1.4 1.8-3.2 2.9-5.1 3.9-3.7 2-7.7 3.3-11.7 4.3-8 2-16.4 2.7-24.6 2-8.2-.7-16.4-3-23.2-7.8-.1 0-.1-.1 0-.2s.2-.1.2-.1Z"
      fill="#000"
    />
  </Svg>
)

export default ErrorLogoSVG
