import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNetInfo } from "@react-native-community/netinfo"
import { LOCAL_STORAGE } from "constants/constants"
import _ from "lodash"
import useAuthContext from "providers/AuthProvider"
import type { Dispatch, FC, PropsWithChildren } from "react"
import { createContext, useContext, useEffect, useReducer, useState } from "react"
import { useColorScheme } from "react-native"

export const LightModeValues = ["system", "light", "dark"] as const
export type LightMode = typeof LightModeValues[number]

export interface PreferenceState {
  // general preferences in profile screen
  globalAudio: boolean
  hints: boolean
  theme: "light" | "dark" | "system"
  // QA preferences
  consultationMode: boolean
  seeCorrection: boolean
  openAllLevelsAndModules: boolean
  reduceTimeLimit: boolean
  // modals and banner
  displayLanguageBanner?: boolean
  hasWelcomeModalBeenDisplayed: boolean
  hasReadFromStorage: boolean
  isConnected: boolean | null
}

export type PreferenceActionName = "set" | "switch" | "reset" | "addFromStorage"

export interface PreferenceAction {
  type: PreferenceActionName
  name?: keyof PreferenceState
  value?: LightMode | boolean
  storage?: Partial<PreferenceState>
}

interface ContextData {
  preferenceState: PreferenceState
  resetAllPreferences: () => void
  isConnected: boolean | null
  darkTheme: boolean
  dispatchPreferences: Dispatch<PreferenceAction>
}

const PreferenceContext = createContext<ContextData>({} as ContextData)

function createInitialState(): PreferenceState {
  return {
    consultationMode: false,
    globalAudio: true,
    hints: true,
    seeCorrection: false,
    openAllLevelsAndModules: false,
    reduceTimeLimit: false,
    hasWelcomeModalBeenDisplayed: false,
    hasReadFromStorage: false,
    isConnected: null,
    theme: "system",
  }
}

export const PREFERENCE_DEV_PROPERTIES: Array<
  | keyof Pick<
      PreferenceState,
      "consultationMode" | "reduceTimeLimit" | "seeCorrection" | "openAllLevelsAndModules"
    >
> = ["consultationMode", "reduceTimeLimit", "seeCorrection", "openAllLevelsAndModules"]

function reducer(state: PreferenceState, action: PreferenceAction): PreferenceState {
  switch (action.type) {
    case "reset": {
      return createInitialState()
    }
    case "addFromStorage": {
      return action.storage != null ? { ...state, ...action.storage } : state
    }
    case "set": {
      return action.name != null ? { ...state, [action.name]: action.value } : state
    }
    case "switch":
    default:
      return action.name != null
        ? {
            ...state,
            [action.name]: !state?.[action.name],
          }
        : state
  }
}

export const PreferenceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [preferenceState, dispatch] = useReducer(reducer, {}, createInitialState)
  const { isConnected } = useNetInfo()
  const { isQA } = useAuthContext()
  const [hasReadFromStorage, setHasReadFromStorage] = useState(false)

  // Need to add hasReadFromStorage to prevent this useEffect to run before the useEffect that gets current values in localStorage
  useEffect(() => {
    ;(async () => {
      if (!hasReadFromStorage) {
        return
      }
      try {
        const preferences = isQA
          ? preferenceState
          : _.pick(preferenceState, [
              "globalAudio",
              "hints",
              "hasWelcomeModalBeenDisplayed",
              "displayLanguageBanner",
            ])
        await AsyncStorage.setItem(LOCAL_STORAGE.preferences, JSON.stringify(preferences))
      } catch (err) {
        throw new Error("Error while registering preference in localStorage")
      }
    })()
  }, [hasReadFromStorage, isQA, preferenceState])

  useEffect(() => {
    ;(async () => {
      dispatch({ type: "reset" })
      const values = await AsyncStorage.getItem(LOCAL_STORAGE.preferences)
      if (values) {
        const normalizedValues: PreferenceState = JSON.parse(values)
        const storage = isQA
          ? normalizedValues
          : _.pick(normalizedValues, [
              "globalAudio",
              "hints",
              "hasWelcomeModalBeenDisplayed",
              "displayLanguageBanner",
            ])
        dispatch({ type: "addFromStorage", storage })
      }
      setHasReadFromStorage(true)
    })()
    return () => {
      setHasReadFromStorage(false)
    }
  }, [isQA])

  const systemTheme = useColorScheme()

  const selectedTheme = preferenceState.theme === "system" ? systemTheme : preferenceState.theme
  const isDarkMode = selectedTheme === "dark"

  const contextValue: ContextData = {
    dispatchPreferences: dispatch,
    preferenceState,
    isConnected,
    resetAllPreferences: () => dispatch({ type: "reset" }),
    darkTheme: isDarkMode,
  }
  return <PreferenceContext.Provider value={contextValue}>{children}</PreferenceContext.Provider>
}

export const usePreferences = (): ContextData => {
  const context = useContext(PreferenceContext)
  if (_.isEmpty(context)) {
    throw new Error("usePreference must be used within a PreferenceProvider")
  }
  return context
}

export default PreferenceProvider
