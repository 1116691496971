import { defaultMaxTabHeight } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { TabType } from "models/TabInterfaces"
import { useTab } from "providers/TabProvider"
import type { FC } from "react"
import { useCallback } from "react"
import type { StyleProp, TextStyle } from "react-native"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import { Subtitle1 } from "../Texts"
import Touchable from "../Touchable/Touchable"

interface IProps {
  tab: TabType
  index: number
  width?: number
  height?: number
  disabled?: boolean
  numberOfTabs?: number
  labelStyle?: StyleProp<TextStyle>
}

const Tab: FC<IProps> = ({ tab, index, width, numberOfTabs = 2, disabled = false, labelStyle }) => {
  const {
    darkMode,
    colors: { onSurface, ripple, primary_300 },
  } = useTheme()
  const { currentTab, setCurrentTab, tabRef } = useTab()
  const isSelected = currentTab?.index === tab.index
  const tabWidth = width ? width / numberOfTabs : undefined
  const s = useStyles(
    ({
      typography: { button },
      dimensions: { spacing },
      colors: {
        primary_400,
        onSurface: { highEmphasis, mediumEmphasis },
      },
    }) => ({
      indicator: {
        backgroundColor: isSelected ? primary_400 : onSurface.light,
        bottom: 0,
        height: 2,
        position: "absolute",
        width: "100%" as const,
      },
      label: {
        ...button,
        color: isSelected ? (darkMode ? primary_300 : highEmphasis) : mediumEmphasis,
        textAlign: "center",
      },
      tab: {
        marginHorizontal: spacing,
        marginVertical: spacing / 2,
        maxHeight: defaultMaxTabHeight,
      },
      touchable: {
        alignItems: "center",
        justifyContent: "center",
        maxHeight: defaultMaxTabHeight,
        paddingTop: spacing / 2,
        width: tabWidth,
      },
    }),
    [isSelected, tabWidth],
  )

  const onPress = useCallback(() => {
    setCurrentTab(tab)
    tabRef?.current?.scrollToIndex({ index, viewPosition: 0.5 })
  }, [index, setCurrentTab, tab, tabRef])

  return (
    <>
      <Touchable {...{ disabled, onPress }} rippleColor={rippleColor(ripple)} style={s.touchable}>
        <>
          {tab.image?.({
            color: isSelected
              ? darkMode
                ? primary_300
                : onSurface.highEmphasis
              : onSurface.mediumEmphasis,
          })}
          <View style={s.tab}>
            <Subtitle1 selectable={false} style={[s.label, labelStyle]}>
              {tab.title.toUpperCase()}
            </Subtitle1>
          </View>
          <View style={s.indicator} />
        </>
      </Touchable>
    </>
  )
}

export default Tab
