import type { StackHeaderProps } from "@react-navigation/stack"
import { isWeb, maxScreenWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import { MotiView, useDynamicAnimation } from "moti"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import type { ContextData as ExerciseContext } from "providers/ExerciseProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { Pressable, View } from "react-native"
import { IconButton, ProgressBar } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import CloseSVG from "svgs/CloseIcon"
import StarSVG from "svgs/Star"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"
import useIsIphoneX from "utils/isIphoneX"
import { logger } from "utils/logger"

import { H6, Subtitle1 } from "../Texts"
import HeaderWrapper from "./HeaderWrapper"

export interface HeaderHandle {
  animate: () => void
}

interface IProps {
  props: StackHeaderProps
  context: ExerciseContext
  onPress?: () => void
  isEvaluation?: boolean
}

const ExerciseHeader = forwardRef<HeaderHandle, IProps>(
  ({ props, context, onPress, isEvaluation }, ref) => {
    const isIphoneX = useIsIphoneX()
    const insets = useSafeAreaInsets()
    const { isSmallWidth } = useDeviceTools()
    const { isConsultation } = useConsultationMode()
    const t = useTypedTranslation()
    const {
      colors: {
        ripple,
        onSurface: { highEmphasis },
        primary_600,
        secondary_350,
        surface,
      },
    } = useTheme()
    const animationState = useDynamicAnimation(() => ({
      rotate: "0deg",
      scale: 1,
      opacity: 1,
    }))
    const {
      levelState: { nbOfAcquiredRules, totalNumberOfRulesInLevel, progress },
      consultation,
    } = context
    const [localSucceeded, setLocalSucceeded] = useState(0)
    // QA Mode
    const {
      preferenceState: { consultationMode },
    } = usePreferences()
    const cs = useCommonStyles()
    const s = useStyles(
      ({ roundness, dimensions: { spacing, margin }, colors: { onSurface } }) => ({
        backButton: {
          marginRight: margin,
        },
        consultation: {
          paddingRight: spacing,
          color: primary_600,
        },
        large: {
          alignSelf: "center",
          maxWidth: maxScreenWidth,
          width: "100%" as const,
        },
        navigation: {
          flexDirection: "row",
          justifyContent: isSmallWidth ? undefined : "space-between",
          paddingBottom: isIphoneX ? 0 : spacing,
        },
        progress: {
          borderRadius: roundness,
          height: 15,
          width: "100%" as const,
        },
        progressBarContainer: {
          flex: 1,
          alignSelf: "center",
        },
        qaButton: {
          ...(isWeb ? { cursor: !consultationMode ? "auto" : "pointer" } : {}),
          alignSelf: "center",
          flexDirection: "row",
          marginLeft: margin,
        },
        subtitle: {
          alignSelf: "center",
          color: onSurface.mediumEmphasis,
          paddingLeft: spacing / 2,
        },
      }),
      [isWeb, consultationMode, insets, isIphoneX],
    )
    useImperativeHandle(
      ref,
      () => ({
        animate: () => {
          animationState.animateTo({
            rotate: [{ value: "0deg" }, { value: "360deg", duration: 3000 }, { value: "0deg" }],
            scale: [1.1, { value: 2, duration: 3000 }, { value: 1.1, delay: 1000 }],
          })
          setLocalSucceeded(nbOfAcquiredRules)
        },
      }),
      [animationState, nbOfAcquiredRules],
    )

    // force catch-up outside of validated rule modal when loading existing interactions
    useEffect(() => {
      if (localSucceeded === 0 && nbOfAcquiredRules >= 1) {
        logger("ExerciseHeader forcing localSucceeded update", localSucceeded, nbOfAcquiredRules)
        setLocalSucceeded(nbOfAcquiredRules)
      }
    }, [localSucceeded, nbOfAcquiredRules])

    const onLongPress = useCallback(async () => {
      if (isConsultation) {
        consultation?.closeConsultationView?.()
      } else {
        consultation?.openConsultationView?.()
      }
    }, [isConsultation, consultation])

    return (
      <HeaderWrapper backgroundColor={surface.surfaceSecondary}>
        <View style={[s.navigation, isSmallWidth ? undefined : s.large]}>
          <IconButton
            containerColor={surface.surfaceSecondary}
            style={[s.backButton, cs.backButton]}
            iconColor={highEmphasis}
            rippleColor={rippleColor(ripple)}
            onPress={onPress ?? props.navigation.goBack}
            icon={() => <CloseSVG />}
          />
          <View style={s.progressBarContainer}>
            <ProgressBar
              style={s.progress}
              {...{ progress: (progress ?? 0) / 100 }}
              color={secondary_350}
            />
          </View>
          <Pressable
            onPress={consultationMode ? consultation?.openConsultationView : undefined}
            onLongPress={s && !isEvaluation && consultationMode ? onLongPress : undefined}
            style={s.qaButton}
          >
            {isConsultation && !isSmallWidth ? (
              <Subtitle1 style={s.consultation}>{t(`${ns.CONSULTATION}.activate`)}</Subtitle1>
            ) : null}
            <MotiView state={animationState}>
              <StarSVG />
            </MotiView>
            <Subtitle1 style={s.subtitle}>
              <H6>{localSucceeded}</H6>
              {` / ${totalNumberOfRulesInLevel}`}
            </Subtitle1>
          </Pressable>
        </View>
      </HeaderWrapper>
    )
  },
)

export default ExerciseHeader
