import type { DragAndDropWord, Result } from "@newpv/js-common"
import CustomRenderHTML from "components/CustomRenderHTML"
import { MAX_TAP_DISTANCE } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import { useDragAndDrop } from "providers/DadProvider"
import type { ReactElement } from "react"
import React, { useCallback, useMemo } from "react"
import { Animated, PanResponder, View } from "react-native"
import { DraxView } from "react-native-drax"
import type { MixedStyleDeclaration } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"
interface Props {
  word: DragAndDropWord
  setItemsHeight: React.Dispatch<React.SetStateAction<Array<{ height: number; id: string }>>>
  isAnswered: boolean
  index: number
  animationX?: Animated.Value
  animationY?: Animated.Value
  isEvaluation?: boolean
  result?: Result
  clueWord?: DragAndDropWord
}

const WordInColumn = ({
  word,
  isAnswered,
  isEvaluation,
  index,
  animationX,
  animationY,
  setItemsHeight,
  result,
  clueWord,
}: Props): ReactElement => {
  const { onLayout, width } = useLayout()

  // STYLES
  const {
    colors: { primary_100, secondary_200, surface: themeSurface, onSurface },
  } = useTheme()

  const memoizedStyle: MixedStyleDeclaration = useMemo(
    () => ({
      alignSelf: "center",
      textAlign: "center",
      color:
        clueWord?.content === word.content && index + 1 === word.correctColumn && !isEvaluation
          ? onSurface.button
          : isAnswered
          ? onSurface.button
          : onSurface.highEmphasis,
    }),
    [
      clueWord?.content,
      index,
      isAnswered,
      isEvaluation,
      onSurface.button,
      onSurface.highEmphasis,
      word.content,
      word.correctColumn,
    ],
  )

  const cs = useCommonStyles()

  const { tapSelectedWord, setTapSelectedWord } = useDragAndDrop()

  const s = useStyles(
    ({ dimensions: { spacing, margin }, colors: { surface } }) => ({
      wordInColumn: {
        backgroundColor: surface.surface,
        borderColor: surface.outline,
        borderRadius: margin / 2,
        borderWidth: 1,
        padding: spacing,
      },
      wordWrapper: {
        paddingHorizontal: spacing / 2,
        paddingVertical: spacing / 4,
      },
      bottomPaddingColumn: { paddingBottom: spacing * 4 },
    }),
    [],
  )

  const getWordBackgroundColor = useCallback(
    (w: DragAndDropWord) => {
      if (isAnswered && !isEvaluation) {
        if (w.correctColumn === index + 1) {
          return primary_100
        }
        return secondary_200
      }
      return themeSurface.surface
    },
    [themeSurface, isAnswered, isEvaluation, primary_100, secondary_200, index],
  )

  // Pan responder

  const wordPanResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          setTapSelectedWord(word)
        },
        onPanResponderMove: (evt, gestureState) => {
          if (
            Math.abs(gestureState.dx) > MAX_TAP_DISTANCE ||
            Math.abs(gestureState.dy) > MAX_TAP_DISTANCE
          ) {
            setTapSelectedWord(undefined)
          }
        },
        onPanResponderTerminationRequest: () => true,
      }),
    [setTapSelectedWord, word],
  )

  return (
    <Animated.View
      style={
        animationX && animationY
          ? {
              transform: [{ translateX: animationX }, { translateY: animationY }],
            }
          : undefined
      }
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => {
        setItemsHeight(prevState =>
          prevState.find(prevItem => prevItem.id === word.id)
            ? prevState
            : [...prevState, { height, id: word.id }],
        )
      }}
    >
      <DraxView draggable={false} style={s.wordWrapper}>
        <View
          style={[
            s.wordInColumn,
            result === undefined
              ? {
                  backgroundColor:
                    clueWord?.content === word.content &&
                    index + 1 === word.correctColumn &&
                    !isEvaluation
                      ? primary_100
                      : tapSelectedWord?.id === word.id
                      ? themeSurface.background
                      : themeSurface.surface,
                  opacity: tapSelectedWord?.id === word.id ? 0.6 : 1,
                }
              : {
                  backgroundColor: getWordBackgroundColor(word),
                },
          ]}
          {...wordPanResponder.panHandlers}
        >
          <View style={[cs.alignCenter, cs.fullWidth]} onLayout={onLayout}>
            <CustomRenderHTML
              // do NOT use a style from a StyleSheet here
              customStyle={memoizedStyle}
              content={word.content}
              width={width}
              typographyVariant="body1"
              customDefaultTextProps={{
                selectable: false,
                ...(_.words(word.content, / /g).length === 0
                  ? { ellipsizeMode: "tail", numberOfLines: 1 }
                  : undefined),
              }}
            />
          </View>
        </View>
      </DraxView>
    </Animated.View>
  )
}

export default WordInColumn
