import type {
  Level,
  LevelPracticeTest,
  LevelRevision,
  ModuleId,
  ModuleProgression,
} from "@newpv/js-common"
import type { ProgressItem } from "components/Progression/ProgressionItem"
import RowItem from "components/RowItem/RowItem"
import { StepIndicator } from "components/StepIndicator/StepIndicator"
import { Subtitle1 } from "components/Texts"
import { levelImages } from "config/images"
import { isWeb } from "constants/constants"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import { isEmpty } from "lodash"
import { isFinalLevelAvailable } from "models/RevisionLevelFunctions"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { usePreferences } from "providers/PreferenceProvider"
import type { FC } from "react"
import { useCallback } from "react"
import { View } from "react-native"
import { Tooltip } from "react-native-paper"
import RevisionLevelSVG from "svgs/RevisionLevelSVG"
import useTheme from "theme/ThemeProvider"
import { hashCode } from "utils/hash"

interface IProps {
  levels: Array<LevelRevision | LevelPracticeTest>
  displayedModuleId?: ModuleId
  moduleProgression?: ModuleProgression
  onRowItemPress: (level: Omit<Level, "rules">, disabled: boolean) => void
}

const FinalLevels: FC<IProps> = ({
  levels,
  displayedModuleId,
  onRowItemPress,
  moduleProgression,
}) => {
  const {
    typography,
    colors: { onSurface, surface },
  } = useTheme()
  const t = useTypedTranslation()
  const {
    preferenceState: { openAllLevelsAndModules },
  } = usePreferences()
  const { groupedLevels } = useLevelAndEvaluation()
  const cs = useCommonStyles()
  const s = useStyles(
    ({ roundness, dimensions: { spacing } }) => ({
      rowStyle: {
        borderColor: surface.outline,
        borderRadius: roundness * 2,
        borderWidth: 1,
        flex: 1,
        justifyContent: "center",
        height: 100,
        marginVertical: spacing / 2,
      },
    }),
    [],
  )

  const isLocked = useCallback(
    (lvl: Level) => {
      const isFinalRevision = lvl.type === "finalRevision"
      return (
        (isFinalRevision
          ? !isFinalLevelAvailable(groupedLevels, moduleProgression?.levels)
          : (moduleProgression?.completionPercentage ?? 0) < 100) && !openAllLevelsAndModules
      )
    },
    [
      groupedLevels,
      moduleProgression?.completionPercentage,
      moduleProgression?.levels,
      openAllLevelsAndModules,
    ],
  )

  const rightNode = useCallback(
    (level: Level) => {
      const isFinalRevision = level.type === "finalRevision"
      const finalRevisionOpenDate = moduleProgression?.levels?.[level.id]?.revisionOpenDate
      // TODO: move to common - see https://app.shortcut.com/woonoz/story/4026
      const isFinalRevisionLevelCooldownOver =
        isFinalRevision &&
        !isLocked(level) &&
        dayjs().isAfter(dayjs(moduleProgression?.levels?.[level.id]?.revisionOpenDate))

      // final revision auto-validated
      if (
        isFinalRevision &&
        isEmpty(moduleProgression?.levels?.[level.id]?.revisionRuleIds) &&
        !isLocked(level)
      ) {
        return {
          key: `right-${level.id}`,
          percent: 100,
          locked: false,
          disabled: false,
          isRevisionLevel: true,
          autoValidateRevisionLevel: true,
        }
      }

      if (!isFinalRevision && moduleProgression?.practiceTest?.lastGrade) {
        return {
          key: `${level.id}`,
          percent: 100,
        }
      }

      return {
        key: `right-${level.id}`,
        percent: moduleProgression?.levels?.[level.id]?.completionPercentage || 0,
        locked: isFinalRevision ? !isFinalRevisionLevelCooldownOver : isLocked(level),
        disabled: isFinalRevision ? !isFinalRevisionLevelCooldownOver : isLocked(level),
        ...(isFinalRevision
          ? {
              isRevisionLevel: true,
              revisionOpenDate: finalRevisionOpenDate,
            }
          : undefined),
      } as ProgressItem
    },
    [isLocked, moduleProgression],
  )

  const practiceTestScore = useCallback(
    (color: string) => {
      const { rulesKnown, totalRules } = moduleProgression?.practiceTest?.lastGrade ?? {}
      const score =
        rulesKnown != null && totalRules ? Math.floor((rulesKnown / totalRules) * 100) : undefined
      return (
        <View>
          <Subtitle1 {...{ color }}>
            {t(
              moduleProgression?.practiceTest?.progress === "started"
                ? "Level.inProgress"
                : score != null
                ? "Level.previousGrade"
                : "common.empty",
              {
                score,
              },
            )}
          </Subtitle1>
        </View>
      )
    },
    [moduleProgression?.practiceTest, t],
  )

  // fetch data with /training/progress/level/{level-id}
  const renderDescription = useCallback(
    (lvl: Level) => {
      const color = isLocked(lvl) ? onSurface.disabled : onSurface.mediumEmphasis
      return lvl.type === "finalRevision" ? (
        <View>
          <Tooltip
            title={t(`${ns.LEVEL}.intermediateLevelProgression`)}
            theme={{ colors: { surface: onSurface.highEmphasis, tooltip: surface.background } }}
            enterTouchDelay={50}
            leaveTouchDelay={50}
          >
            <StepIndicator revisionLevelId={lvl.id} maxRulePerStep={lvl.maxRulePerStep} />
          </Tooltip>
        </View>
      ) : (
        practiceTestScore(color)
      )
    },
    [
      isLocked,
      onSurface.disabled,
      onSurface.highEmphasis,
      onSurface.mediumEmphasis,
      practiceTestScore,
      surface.background,
      t,
    ],
  )

  const titleStyle = useCallback(
    (lvl: Level) => ({
      ...typography.h6,
      color: isLocked(lvl) ? onSurface.lowEmphasis : onSurface.highEmphasis,
    }),
    [isLocked, onSurface.highEmphasis, onSurface.lowEmphasis, typography.h6],
  )

  return levels.length > 0 ? (
    <>
      {levels.map((lvl, index) => {
        const LeftIcon =
          lvl.type === "practiceTest"
            ? levelImages[Math.abs(hashCode(lvl.id || "") % levelImages.length)]
            : RevisionLevelSVG
        const color = isLocked(lvl) ? onSurface.disabled : undefined
        return (
          <View
            key={`${displayedModuleId ?? "disp"}=${lvl.id ?? index}`}
            style={cs.row}
            // @ts-ignore
            accessibilityRole={isWeb ? "listitem" : undefined}
            accessibilityLabel={(lvl as any)?.title ?? ""}
            dataSet={{ levelId: lvl.id }}
          >
            <RowItem
              key={lvl.id}
              title={lvl.title}
              titleStyle={titleStyle(lvl)}
              description={renderDescription(lvl)}
              rowStyle={s.rowStyle}
              separator={false}
              onPress={() => onRowItemPress(lvl, isLocked(lvl))}
              leftIcon={() => <LeftIcon {...{ color }} />}
              rightNode={rightNode(lvl)}
            />
          </View>
        )
      })}
    </>
  ) : null
}

export default FinalLevels
