import { useCallback, useState } from "react"
import type { LayoutChangeEvent, LayoutRectangle } from "react-native"

function useLayout(): {
  x: number
  y: number
  width: number
  height: number
  onLayout: (e: LayoutChangeEvent) => void
} {
  const [layout, setLayout] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const onLayout = useCallback(e => {
    setLayout(e.nativeEvent.layout)
  }, [])

  return {
    onLayout,
    ...layout,
  }
}

export default useLayout
