import type { Theme } from "./ThemeProvider"
import type { ITypographyTheme } from "./types"

const typoGen = ({
  colors,
  fontMaker,
}: Omit<Theme, "typography" | "typeGen">): ITypographyTheme => {
  const defaultFont = fontMaker()
  const titleFont = fontMaker({ family: "title" })
  const buttonFont = fontMaker({ family: "button" })
  return {
    h1: {
      color: colors.onSurface.highEmphasis,
      fontSize: 96,
      ...titleFont,
    },
    h2: {
      color: colors.onSurface.highEmphasis,
      fontSize: 60,
      ...titleFont,
    },
    h3: {
      color: colors.onSurface.highEmphasis,
      fontSize: 48,
      ...titleFont,
    },
    h4: {
      color: colors.onSurface.highEmphasis,
      fontSize: 34,
      ...titleFont,
    },
    h5: {
      color: colors.onSurface.highEmphasis,
      fontSize: 24,
      ...titleFont,
    },
    h6: {
      color: colors.onSurface.highEmphasis,
      fontSize: 20,
      ...titleFont,
    },
    subtitle1: {
      color: colors.onSurface.mediumEmphasis,
      fontSize: 16,
      ...defaultFont,
    },
    subtitle2: {
      color: colors.onSurface.highEmphasis,
      fontSize: 14,
      ...defaultFont,
    },
    body1: {
      color: colors.onSurface.highEmphasis,
      fontSize: 16,
      ...fontMaker({ weight: "Semi-Bold" }),
    },
    body2: {
      color: colors.onSurface.mediumEmphasis,
      fontSize: 14,
      ...defaultFont,
    },
    button: {
      color: colors.onSurface.highEmphasis,
      fontSize: 14,
      ...buttonFont,
    },
    caption: {
      color: colors.onSurface.highEmphasis,
      fontSize: 12,
      ...defaultFont,
    },
    overline: {
      color: colors.black,
      fontSize: 10,
      ...defaultFont,
    },
    exerciseSentence: {
      color: colors.onSurface.highEmphasis,
      fontSize: 24,
      ...defaultFont,
    },
  }
}

export default typoGen
