import type { ApiError, LevelDetails, LevelId } from "@newpv/js-common"
import { fetchLevel } from "@newpv/js-common"
import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

export function useFetchLevel<T extends LevelDetails = LevelDetails>(
  levelId?: LevelId,
  disabled?: boolean,
): UseQueryResult<T, ApiError> {
  return useQuery<T, ApiError>(
    ["levels", levelId],
    ({ signal }) => fetchLevel<T>(signal, levelId),
    { enabled: disabled ? false : levelId !== undefined },
  )
}
