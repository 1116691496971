import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg"

const Level4 = (props?: SvgProps): JSX.Element => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <G clipPath="url(#clip0_4222_239994)">
      <Rect width={56} height={56} rx={8} fill={props?.color ?? "#9AC643"} />
      <Path
        d="M69.092 42.803c-5.42-6.232-12.803-10.445-20.521-12.955-7.693-2.608-15.96-3.787-24.144-3.68-4.056.114-8.163.426-12.06 1.528-1.9.563-3.826 1.226-5.369 2.407-.759.541-1.252 1.256-1.358 2.07-.106.815.104 1.605.752 2.298 1.224 1.261 3.136 1.858 5.052 2.035 1.916.177 3.863-.164 5.691-.852 1.829-.687 3.54-1.72 4.914-3.05 1.375-1.33 2.438-3.055 3.113-4.88a19.588 19.588 0 001.181-5.798c.095-1.975-.127-3.925-.812-5.68-.685-1.753-1.858-3.212-3.424-4.351-1.47-1.114-3.33-1.908-5.145-2.48C13.236 8.247 9.184 7.942 5.18 7.86c-4.031.016-8.113.23-12.148.665-8.071.87-16.172 2.26-24.252 3.97-4.04.856-7.984 1.736-11.978 2.813-3.968.978-7.962 2.056-11.956 3.133l-.194-.05.05-.197c3.903-1.522 7.754-2.846 11.701-4.146a371.002 371.002 0 0111.936-3.454c3.994-1.078 8.034-1.933 12.074-2.788 4.04-.855 8.152-1.586 12.284-1.997 4.133-.41 8.265-.821 12.464-.689 4.2.133 8.445.488 12.533 1.854 2.009.622 4.038 1.564 5.843 2.975a13.377 13.377 0 014.094 5.365c.802 2.1 1.019 4.47.874 6.642-.146 2.172-.607 4.368-1.455 6.464-.849 2.096-2.06 3.994-3.678 5.472-1.644 1.576-3.574 2.658-5.622 3.394a14.536 14.536 0 01-6.395.776c-1.116-.077-2.181-.35-3.221-.723s-2.03-.943-2.82-1.882c-.409-.42-.67-1.014-.93-1.607-.163-.568-.18-1.308-.124-1.925.233-1.308 1.118-2.343 1.927-3.081 1.813-1.428 3.835-2.066 5.93-2.58 4.09-1.052 8.294-1.339 12.422-1.33 8.255.018 16.568 1.419 24.332 4.15 7.765 2.733 15.123 7.044 20.491 13.473.097.025.072.124-.05.198-.123.074-.22.049-.22.049z"
        fill={props?.color ?? "#F0FEB4"}
      />
      <Path
        d="M50.074 66.981c-3.15-2.663-5.935-5.727-8.21-9.269-1.066-1.81-2.068-3.77-2.688-5.907-.305-1.115-.517-2.251-.532-3.522-.016-1.27.217-2.733 1.123-4.21.907-1.477 2.479-2.556 3.926-3.016 1.447-.46 2.702-.467 3.905-.417 2.393.194 4.437 1.011 6.325 2 1.835 1.046 3.555 2.3 5.026 3.747 1.522 1.389 2.837 3.006 4.047 4.738.04.037-.012.094-.065.151-.052.057-.092.02-.092.02-3.15-2.663-6.364-5.176-10.071-6.987-.93-.43-1.86-.859-2.842-1.231-.982-.372-1.976-.65-3.034-.779-1.059-.127-2.129-.161-3.248.18-1.066.284-2.14.979-2.762 1.982-.662.967-.91 2.206-.923 3.346-.013 1.14.16 2.24.423 3.319.489 2.12 1.398 4.102 2.372 5.932 1.987 3.697 4.615 6.933 7.517 9.789.04.036-.012.093-.012.093l-.185.041zM39.979-3.896c1.583 2.17 3.004 4.401 4.53 6.519 1.473 2.174 2.998 4.292 4.685 6.346 1.63 2.003 3.628 3.715 5.46 5.38 1.942 1.658 4.04 3.146 6.247 4.628.057.052.057.052.062.161-.052.058-.052.058-.109.006-2.654-.53-5.173-1.669-7.437-3.203-2.212-1.592-3.962-3.808-5.544-5.977-1.583-2.17-2.953-4.459-4.27-6.805l-3.785-6.992c-.058-.052-.006-.11.046-.167-.052.057.058.052.115.104z"
        fill={props?.color ?? "#F0FEB4"}
      />
    </G>
    <Rect
      x={0.5}
      y={0.5}
      width={55}
      height={55}
      rx={7.5}
      stroke={props?.color ?? "#050628"}
      strokeOpacity={0.16}
    />
    <Defs>
      <ClipPath id="clip0_4222_239994">
        <Rect width={56} height={56} rx={8} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Level4
