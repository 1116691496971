import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const CloseSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 14} height={props?.height ?? 14} fill="none" {...props}>
      <Path
        d="M14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41Z"
        fill={props?.color ?? onSurface.highEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default CloseSVG
