import { customFonts } from "config/fonts"
import { isWeb } from "constants/constants"
import Constants from "expo-constants"
import type { ReactElement } from "react"
import { useMemo } from "react"
import type { TextProps } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import RenderHTML from "react-native-render-html"
import { createSaxParser } from "tag-soup"
import useTheme from "theme/ThemeProvider"
import type { TypographyVariant } from "theme/types"

interface Props {
  content: string
  typographyVariant: TypographyVariant
  width: number
  overrideColor?: string
  customStyle?: MixedStyleDeclaration
  isAlignCenter?: boolean
  customDefaultTextProps?: TextProps
  hideExternalLinks?: boolean
}

const CustomRenderHTML = ({
  content,
  typographyVariant,
  width,
  overrideColor,
  customStyle,
  isAlignCenter,
  customDefaultTextProps,
  hideExternalLinks = false,
}: Props): ReactElement => {
  const { typography, colors } = useTheme()

  // Do NOT move in useStyles - requirement from the library to not use StyleSheet to create the styles
  const baseStyle = useMemo(
    () =>
      ({
        // font won't be respected - cannot set font family, <b> and <i> would not work properly
        color: overrideColor
          ? overrideColor
          : typographyVariant === "h6"
          ? colors.onSurface.mediumEmphasis
          : typography[typographyVariant].color,
        fontSize: typography[typographyVariant].fontSize,
        alignSelf: isAlignCenter ? "center" : undefined,
        ...customStyle,
      } as MixedStyleDeclaration),
    [
      overrideColor,
      typographyVariant,
      colors.onSurface.mediumEmphasis,
      typography,
      isAlignCenter,
      customStyle,
    ],
  )

  // TODO: handle <sup> here too, for native apps?
  // Do NOT move in useStyles - requirement from the library to not use StyleSheet to create the styles
  const classesStyles = useMemo(
    () => ({
      smallcaps: {
        alignSelf: "flex-end",
        fontSize: isWeb ? undefined : 0.8 * (typography[typographyVariant].fontSize ?? 24),
        fontVariant: isWeb ? ["small-caps" as const] : undefined,
        paddingBottom: isWeb ? undefined : 0.1 * (typography[typographyVariant].fontSize ?? 24),
        textAlignVertical: "bottom",
        textTransform: isWeb ? undefined : "uppercase",
      } as MixedStyleDeclaration,
    }),
    [typography, typographyVariant],
  )

  const fonts = useMemo(() => [...Object.keys(customFonts), ...Constants.systemFonts], [])

  const htmlContent = useMemo(() => {
    if (!hideExternalLinks) {
      return content
    }
    const htmlParts: string[] = []

    createSaxParser(
      {
        startTag({ name, attributes }) {
          const attrs = Object.values(attributes)
            .map(value => {
              if (value?.tokenType !== 2) {
                return
              }
              return `${value.rawName}=${value.rawValue} `
            })
            .join(" ")
          htmlParts.push(`<${name} ${attrs}>`)
        },
        endTag({ name }) {
          htmlParts.push(`</${name}>`)
        },
        text(text) {
          htmlParts.push(text.rawData)
        },
      },
      {
        renameTag(name) {
          if (name === "a") {
            return "span"
          }
          return name
        },
      },
    ).parse(content)

    return htmlParts.join("")
  }, [content, hideExternalLinks])

  return (
    <RenderHTML
      defaultTextProps={customDefaultTextProps ?? { selectable: true }}
      baseStyle={baseStyle}
      contentWidth={width}
      classesStyles={classesStyles}
      systemFonts={fonts}
      source={{
        html: htmlContent,
      }}
    />
  )
}

export default CustomRenderHTML
