import React from "react"
import useTheme from "theme/ThemeProvider"
import { VictoryLabel } from "victory-native"

interface ITickLabelProps {
  dy?: number
}
export const TickLabel: React.FC<ITickLabelProps> = (props: any) => {
  const {
    colors: {
      onSurface: { mediumEmphasis },
    },
    typography,
  } = useTheme()
  const { dy, ...rest } = props
  return (
    <VictoryLabel
      {...rest}
      dy={dy}
      style={{
        ...typography.caption,
        fill: mediumEmphasis,
      }}
    />
  )
}
