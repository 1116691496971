import type { ScenarioId } from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import ModuleItem from "components/ModuleItem/ModuleItem"
import { H5, Subtitle1 } from "components/Texts"
import { HEADER_HEIGHT, isiOS } from "constants/constants"
import { useFetchAllScenarios } from "hooks/useFetchScenarioInfo"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import useAuthContext from "providers/AuthProvider"
import { useCallback, useMemo } from "react"
import { ScrollView } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"
import { AccessibilityListWrapper } from "wrappers/AccessibilityListWrapper"
import ScreenWrapper from "wrappers/ScreenWrapper/ScreenWrapper"

const HomeScreen = (): JSX.Element => {
  const t = useTypedTranslation()
  const {
    colors: { surface, onSurface },
  } = useTheme()
  const { top } = useSafeAreaInsets()
  const { setScenarioId } = useAuthContext()

  const { data: scenarioList, error, isLoading } = useFetchAllScenarios()
  const s = useStyles(
    ({ screenStyle, dimensions: { spacing, margin } }) => ({
      content: {
        backgroundColor: surface.surface,
        ...screenStyle,
        flex: 1,
        paddingHorizontal: spacing / 2,
        paddingTop: HEADER_HEIGHT / 2 + (isiOS ? top : 0),
      },
      header: {
        alignSelf: "center",
        margin: margin / 2,
      },
      indicator: {
        paddingTop: spacing,
      },
      subtitle: {
        marginBottom: margin / 2,
        marginTop: margin / 2,
        paddingHorizontal: spacing / 2,
      },
    }),
    [],
  )

  const onPress = useCallback(
    (scenarioId: ScenarioId) => {
      setScenarioId(scenarioId)
    },
    [setScenarioId],
  )

  const renderScenario = useMemo(
    () =>
      scenarioList?.map((scenario, index) => (
        <ModuleItem
          moduleId={scenario.id}
          key={`${scenario.id}-${index}`}
          title={scenario.name}
          description={`${scenario.nbrRoutes} route(s) ${scenario.nbrModules} module(s)`}
          onPress={() => onPress(scenario.id)}
        />
      )),
    [onPress, scenarioList],
  )

  return (
    <ScreenWrapper>
      <ScrollView style={s.content}>
        {isLoading ? (
          <ActivityIndicator />
        ) : error ? (
          <ErrorPanel title={error.message} />
        ) : (
          <>
            <H5 style={s.header} color={onSurface.highEmphasis}>
              Ecran QA - Sélection des scénarios
            </H5>
            <Subtitle1 color={onSurface.highEmphasis} style={s.subtitle}>
              {t("Home.pickScenario")}
            </Subtitle1>
            <AccessibilityListWrapper>{renderScenario}</AccessibilityListWrapper>
          </>
        )}
      </ScrollView>
    </ScreenWrapper>
  )
}

export default HomeScreen
