import { Subtitle1 } from "components/Texts"
import Touchable from "components/Touchable/Touchable"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import React from "react"
import { View } from "react-native"
import { ChevronDownSVG } from "svgs/ChevronDown"
import { ChevronUpSVG } from "svgs/ChevronUp"
import useTheme from "theme/ThemeProvider"

interface IProps {
  isPanelOpen?: boolean
  onPress: () => void
}

export const EmailExerciseHeader: React.FC<IProps> = ({ isPanelOpen = false, onPress }) => {
  const t = useTypedTranslation()
  const { isSmallWidth } = useDeviceTools()
  const cs = useCommonStyles()
  const { darkMode } = useTheme()
  const s = useStyles(
    ({ colors: { secondary_50, onSurface }, roundness, dimensions: { spacing }, fontMaker }) => ({
      headerContainer: {
        alignItems: "center",
        backgroundColor: secondary_50,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: roundness,
        borderTopRightRadius: roundness,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: spacing * 2,
      },
      statusText: {
        color: onSurface.button,
        textTransform: isSmallWidth ? "uppercase" : undefined,
        ...(isSmallWidth ? undefined : fontMaker({ weight: "Regular" })),
      },
      chevron: { color: darkMode ? onSurface.button : undefined },
    }),
    [isSmallWidth],
  )

  return (
    <Touchable style={s.headerContainer} {...{ onPress }} disabled={!isSmallWidth}>
      <>
        {!isSmallWidth ? null : (
          <View style={cs.marginRight}>
            {isPanelOpen ? <ChevronDownSVG {...s.chevron} /> : <ChevronUpSVG {...s.chevron} />}
          </View>
        )}
        <Subtitle1 style={s.statusText}>
          {t(`email.options.${!isSmallWidth ? "click" : isPanelOpen ? "hide" : "show"}`)}
        </Subtitle1>
      </>
    </Touchable>
  )
}
