import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"

const LightningSVG = (props?: SvgProps): JSX.Element => (
  <Svg
    {...props}
    width={props?.width ?? 24}
    height={props?.height ?? 24}
    fill="none"
    viewBox="0 0 30 60"
  >
    <Path
      d="M11.212 50.5H8.434l2.778-19.444H1.49c-1.611 0-1.584-.89-1.056-1.834.528-.944.139-.222.195-.333C4.212 22.556 9.6 13.11 16.767.5h2.778l-2.778 19.444h9.723c1.36 0 1.555.917 1.305 1.417l-.194.417C16.656 40.917 11.21 50.5 11.21 50.5Z"
      fill={props?.color ?? "#F4C974"}
    />
  </Svg>
)

export default LightningSVG
