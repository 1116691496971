/* eslint-disable no-irregular-whitespace */
import type { StackHeaderProps } from "@react-navigation/stack"
import { PROGRESS_WIDTH } from "constants/constants"
import useBackImage from "hooks/useBackImage"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import useAuthContext from "providers/AuthProvider"
import type { ContextData as EvaluationContext } from "providers/EvaluationProvider"
import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"
import { View } from "react-native"
import { IconButton, ProgressBar as PaperProgressBar } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import ProgressBar from "../ProgressBar/ProgressBar"
import { Body2, Subtitle1 } from "../Texts"
import HeaderWrapper from "./HeaderWrapper"

interface IProps {
  progress: number
  props: StackHeaderProps
  evalContext: EvaluationContext
  onPress: () => void
}

const EvaluationHeader: FC<IProps> = ({ progress, onPress, evalContext }) => {
  const {
    colors: {
      surface,
      ripple,
      surface: { surfaceSecondary: backgroundColor },
      onSurface,
    },
  } = useTheme()
  const cs = useCommonStyles()
  const t = useTypedTranslation()
  const backImage = useBackImage(onSurface.mediumEmphasis)
  const { timerElapsedTime, isTimerRunning, title, timeLimit } = evalContext
  const [prevProgressionValue, setPrevProgressionValue] = useState<number>(0)
  const [prevElapsedTime, setPrevElapsedTime] = useState<number>(0)
  const { currentUser } = useAuthContext()

  const s = useStyles(
    ({
      roundness,
      dimensions: { spacing },
      colors: {
        onSurface: { highEmphasis },
      },
    }) => ({
      extraTime: { color: highEmphasis, padding: spacing, paddingLeft: 0 },
      placeholderButton: { width: 42 },
      progress: {
        borderRadius: roundness,
        height: 8,
        marginLeft: spacing / 2,
        width: PROGRESS_WIDTH,
      },
      title: {
        color: highEmphasis,
        padding: spacing,
        paddingHorizontal: spacing / 2,
        textAlign: "center",
      },
      titleWrapper: { flexDirection: "row", width: "100%" as const, justifyContent: "center" },
    }),
    [],
  )

  // Prevent restarting the time progression from 0, because when the timer is paused elaspedTime = 0 for 1s and returns to its previous value, which restarts the progression from the beginning and reloads the progression some seconds later. Gives a gauge effect
  const progressionValue = useMemo(() => {
    if (timerElapsedTime > 0 && timerElapsedTime > prevElapsedTime) {
      const progression = (timerElapsedTime / (timeLimit * 1000)) * 100
      setPrevElapsedTime(timerElapsedTime)
      setPrevProgressionValue(progression)
      return progression
    }
    return prevProgressionValue
  }, [timerElapsedTime, prevElapsedTime, prevProgressionValue, timeLimit])

  const renderProgressView = useCallback(
    (label: string, animated?: boolean) => (
      <View style={cs.inlineRow}>
        <Body2 color={onSurface.highEmphasis}>{label}</Body2>
        <View>
          {/* animated true: for the timer, and not for the completion percentage */}
          {animated ? (
            <ProgressBar
              paused={!isTimerRunning}
              progressAnimationDuration={1000}
              viewWidth={PROGRESS_WIDTH}
              progressStyle={s.progress}
              progress={progressionValue}
              trackColor={onSurface.mediumEmphasis}
              backgroundColor={surface.overlayProgression}
            />
          ) : (
            <PaperProgressBar
              {...{ progress }}
              color={onSurface.mediumEmphasis}
              style={[s.progress, { backgroundColor: surface.overlayProgression }]}
            />
          )}
        </View>
      </View>
    ),
    [
      cs.inlineRow,
      isTimerRunning,
      onSurface.highEmphasis,
      onSurface.mediumEmphasis,
      progress,
      progressionValue,
      s.progress,
      surface.overlayProgression,
    ],
  )

  return (
    <HeaderWrapper {...{ backgroundColor }} isEvaluation={true}>
      <View style={cs.row}>
        <IconButton
          containerColor={backgroundColor}
          rippleColor={rippleColor(ripple)}
          style={cs.backButton}
          icon={() => backImage}
          {...{ onPress }}
        />
        <View style={cs.center}>
          {renderProgressView(t(`${ns.EVALUATION}.progression`))}
          {renderProgressView(t(`${ns.EVALUATION}.time`), true)}
        </View>
        <View style={s.placeholderButton} />
      </View>
      <View style={s.titleWrapper}>
        {title ? <Subtitle1 style={s.title}>{title}</Subtitle1> : null}
        {currentUser?.extraTime ? (
          <Subtitle1 style={s.extraTime}>{`+ ${Math.round(
            (timeLimit / 60) * (1 / 4),
          )} min`}</Subtitle1>
        ) : null}
      </View>
    </HeaderWrapper>
  )
}

export default EvaluationHeader
