import type { ClickOnMistakeExercise, WordBlock } from "@newpv/js-common"
import type { IBodyProps } from "components/Texts"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import type { ComponentType, Dispatch, SetStateAction } from "react"
import { Fragment, useMemo, useState } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"
import type { TypographyVariant } from "theme/types"
import {
  getFontStyle,
  splitWordblocks,
  useGetBaseFontConfigFromFontFamily,
} from "utils/parseSentence"

import CorrectionSentenceWordBlock from "./CorrectionSentenceWordBlock"

interface Props {
  wordBlocks: WordBlock[]
  Comp: ComponentType<IBodyProps>
  baseTypography: TypographyVariant
  containerStyle?: StyleProp<ViewStyle>
  isCorrectAnswer?: boolean
  isIntensiveTrainingSentence?: boolean
  exercise?: ClickOnMistakeExercise
  isDictation?: boolean
  dictationAnswers?: Record<string, string> | undefined
  setDictationAnswers?: Dispatch<SetStateAction<Record<string, string> | undefined>>
}

const CorrectionSentence = ({
  wordBlocks,
  Comp,
  baseTypography,
  isIntensiveTrainingSentence,
  isCorrectAnswer,
  containerStyle,
  isDictation,
  exercise,
  dictationAnswers,
  setDictationAnswers,
}: Props): JSX.Element => {
  const [widthOnFirstRender, setWidthOnFirstRender] = useState<number>()
  const {
    fontMaker,
    dimensions: { margin: themeMargin },
  } = useTheme()
  const s = useStyles(
    () => ({
      sentenceContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        overflow: "visible",
        alignItems: "flex-end",
      },
    }),
    [],
  )

  const parsedSentence = useMemo(() => splitWordblocks(wordBlocks), [wordBlocks])

  const exerciseHasAnExplicitClue = _.some(exercise?.sentence, wordBlock => wordBlock.clue)
  const exerciseHasAnExplicitCorrection = _.some(
    exercise?.sentence,
    wordBlock => wordBlock.correction,
  )
  const exerciseHasMistake = exercise?.hasMistake ?? false

  const baseFontConfig = useGetBaseFontConfigFromFontFamily(baseTypography)
  const fontStyle = {
    ...getFontStyle(baseFontConfig, { text: " " }, fontMaker),
    ...(isDictation ? { marginTop: themeMargin * 1.5 } : null),
  }

  return (
    <View
      style={[s.sentenceContainer, containerStyle]}
      onLayout={e => {
        if (!widthOnFirstRender) {
          setWidthOnFirstRender(e.nativeEvent.layout.width)
        }
      }}
    >
      {isDictation
        ? wordBlocks.map((wordBlock, i) => (
            <CorrectionSentenceWordBlock
              key={`${wordBlock.text}-${i}`}
              index={i}
              isDictation={true}
              parentWidth={widthOnFirstRender}
              {...{
                baseTypography,
                exerciseHasAnExplicitClue,
                exerciseHasAnExplicitCorrection,
                exerciseHasMistake,
                Comp,
                wordBlocks,
                dictationAnswers,
                isCorrectAnswer,
                isIntensiveTrainingSentence,
                setDictationAnswers,
                wordBlock,
              }}
            />
          ))
        : parsedSentence.map((block, i) => {
            if (!block.noSpaceAfter && !block.afterNoSpace) {
              return (
                <CorrectionSentenceWordBlock
                  key={`${block.text}-${i}`}
                  index={i}
                  {...{
                    baseTypography,
                    exerciseHasAnExplicitClue,
                    exerciseHasAnExplicitCorrection,
                    exerciseHasMistake,
                    Comp,
                    isCorrectAnswer,
                    isIntensiveTrainingSentence,
                  }}
                  wordBlock={block}
                  wordBlocks={parsedSentence}
                  parentWidth={widthOnFirstRender}
                />
              )
            }
            // while the following block is a afterNoSpace OR a noSpaceAfter, we keep them inside the View
            // we create a subArray from the original array, starting from the index and stopping at the first element that is not an afterNoSpace or noSpaceAfter
            if (block.noSpaceAfter && !block.afterNoSpace) {
              const noSpaceAfterAndFollowing = [
                block,
                ..._.takeWhile(
                  parsedSentence.slice(i + 1),
                  wordBlockWithExtraData => wordBlockWithExtraData.afterNoSpace,
                ),
              ]

              return (
                <Fragment key={`${block.text}-${i}`}>
                  {/* this space is usually inserted in ClickOnMistakeWordBlock, but in this case we want it outside the View */}
                  {i > 0 &&
                  !parsedSentence[i - 1].breakAfter &&
                  !parsedSentence[i - 1].endsWithSpace ? (
                    <Comp selectable={false} style={fontStyle}>
                      {" "}
                    </Comp>
                  ) : null}
                  <View
                    style={{
                      overflow: "visible",
                      flexDirection: "row",
                    }}
                  >
                    {/* only display the current block if the previous one was not a "noSpaceAfter" block */}
                    {noSpaceAfterAndFollowing.map((blockInExtraView, subArrayIndex) => (
                      <CorrectionSentenceWordBlock
                        key={subArrayIndex}
                        wordBlock={blockInExtraView}
                        index={i + subArrayIndex}
                        {...{
                          baseTypography,
                          exerciseHasAnExplicitClue,
                          exerciseHasAnExplicitCorrection,
                          exerciseHasMistake,
                          Comp,
                          isCorrectAnswer,
                          isIntensiveTrainingSentence,
                        }}
                        wordBlocks={parsedSentence}
                        parentWidth={widthOnFirstRender}
                      />
                    ))}
                  </View>
                </Fragment>
              )
            }
            return null
          })}
    </View>
  )
}

export default CorrectionSentence
