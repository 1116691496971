import type {
  Level,
  LevelId,
  LevelRevision,
  LevelType,
  ModuleId,
  ModuleProgression,
} from "@newpv/js-common"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import type { StackNavigationProp } from "@react-navigation/stack"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import { HomeHeader } from "components/Headers"
import InformationBanner from "components/InformationBanner/InformationBanner"
import { LevelsBlock } from "components/LevelsBlock/LevelsBlock"
import { HEADER_HEIGHT, HEADER_HEIGHT_SMALL, isWeb } from "constants/constants"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useHardwareButton from "hooks/useHardwareButton"
import { useModuleAndLevelModals } from "hooks/useModuleAndLevelModals"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import { getRevisionStepRules } from "models/RevisionLevelFunctions"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { BottomState, useModal } from "providers/ModalProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { ReactElement } from "react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import type { ScrollView, ViewStyle } from "react-native"
import { Animated, useWindowDimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import BottomDisplayedLevel from "screens/LevelsScreen/components/BottomDisplayedLevel"
import FinalLevels from "screens/LevelsScreen/components/FinalLevels"
import useTheme from "theme/ThemeProvider"
import { logger } from "utils/logger"
import wait from "utils/wait"
import { AccessibilityListWrapper } from "wrappers/AccessibilityListWrapper"
import ScreenWrapper from "wrappers/ScreenWrapper/ScreenWrapper"

import type { ButtonConfig } from "./components/BottomModal"
import BottomModal from "./components/BottomModal"
import { LevelsWithoutRevision } from "./components/LevelsWithoutRevision"
import ModuleList from "./components/ModuleList"
import LevelTopView from "./LevelTopView"

export const isRevisionLevelAutoValidated = (
  level?: Level,
  moduleProgression?: ModuleProgression,
): boolean | undefined =>
  level &&
  (level.type === "revision" || level.type === "finalRevision") &&
  _.isEmpty(moduleProgression?.levels?.[level.id]?.revisionRuleIds)

const LevelsScreen = (): ReactElement => {
  const t = useTypedTranslation()
  const {
    colors: { onSurface, primary_400, surface },
  } = useTheme()
  const { top } = useSafeAreaInsets()
  const { height: screenHeight } = useWindowDimensions()
  const { isSmallWidth } = useDeviceTools()
  const {
    preferenceState: { openAllLevelsAndModules },
  } = usePreferences()
  const offsetY = useRef(new Animated.Value(0)).current
  const navigation = useNavigation<StackNavigationProp<IRootParamList, "Level">>()
  /** PROVIDERS */
  const { authScenarioId: scenarioId, isQA } = useAuthContext()
  const {
    routeIndex,
    setModuleId: setGlobalModuleId,
    module,
    scenario,
    isScenarioLoading,
    scenarioError,
    progressionData,
    progressionError,
    isProgressionLoading,
    refetchUserProgression,
  } = useScenarioAndModule()
  const {
    setLevelId,
    setLevelType,
    setRevisionLevelWithRules,
    setGroupedLevels,
    setFinalLevels,
    groupedLevels,
    finalLevels,
    seeMasteredRules,
    isRuleListEmpty,
    setSeeMasteredRules,
  } = useLevelAndEvaluation()

  /** MODALS */
  const { bottomModalState, setBottomModalState } = useModal()
  const { showRetryModal, showDisabledModal } = useModuleAndLevelModals()

  const moduleProgression = useMemo(
    () => progressionData?.progressionDetail?.modules?.[module?.id ?? 0],
    [progressionData, module],
  )

  const onDismiss = useCallback(() => {
    setLevelId(undefined)
    setLevelType(undefined)
    setRevisionLevelWithRules(undefined)
    setDisplayedLevelIdAndType(undefined)
    setSeeMasteredRules(false)
  }, [setLevelId, setLevelType, setRevisionLevelWithRules, setSeeMasteredRules])

  useHardwareButton({
    beforeUnload: false,
    handler:
      bottomModalState !== BottomState.HIDE
        ? () => {
            onDismiss()
            setBottomModalState(BottomState.HIDE)
          }
        : undefined,
  })

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refetchUserProgression()
  }, [refetchUserProgression, routeIndex])

  const allLevelsAreCompleted =
    moduleProgression &&
    module &&
    _.every(module?.levels, level =>
      level.type !== "practiceTest"
        ? (moduleProgression?.levels?.[level.id]?.completionPercentage ?? 0) >= 100 ||
          isRevisionLevelAutoValidated(level, moduleProgression)
        : true,
    )

  const [displayedModuleId, setDisplayedModuleId] = useState<ModuleId>()
  const [displayedLevelIdAndType, setDisplayedLevelIdAndType] =
    useState<{ id: LevelId; type?: LevelType }>()
  const isLoading = isScenarioLoading || isProgressionLoading || routeIndex == null
  const error = scenarioError || progressionError

  if (error) {
    logger({ scenarioError, progressionError })
  }

  const moduleLevels = useMemo(() => module?.levels, [module])
  const levelsProgression = useMemo(() => moduleProgression?.levels, [moduleProgression?.levels])
  /** Style */
  const cs = useCommonStyles()
  const s = useStyles(
    ({ screenStyle, dimensions: { spacing } }) => ({
      view: {
        ...screenStyle,
        paddingBottom: spacing * 2,
        paddingHorizontal: spacing,
        paddingTop: (isSmallWidth ? HEADER_HEIGHT_SMALL * 2 : HEADER_HEIGHT) / 2,
      },
      loading: {
        paddingTop: isSmallWidth ? spacing : spacing * 4,
      },
    }),
    [top, isWeb, isSmallWidth],
  )

  /** useEffects */

  useEffect(() => {
    if (module) {
      setDisplayedModuleId(module?.id)
      return
    }
    if (routeIndex !== undefined && scenario?.routes[routeIndex] !== undefined) {
      setDisplayedModuleId(scenario.routes[routeIndex].modules[0].id)
    }
  }, [scenario, routeIndex, module])

  useEffect(() => {
    if (moduleLevels) {
      const staticOrRevisionLevels = moduleLevels.filter(
        el => el.type === "static" || el.type === "revision",
      )

      const revisionLevelsIndices = _.compact(
        staticOrRevisionLevels.map((lev, index) => (lev.type === "revision" ? index : null)),
      )
      const levelsWithRevisionLevel = _.compact(
        revisionLevelsIndices.map((el, index) =>
          _.slice(
            staticOrRevisionLevels,
            index === 0 ? 0 : revisionLevelsIndices[index - 1] + 1,
            el + 1,
          ),
        ),
      )

      if (levelsWithRevisionLevel) {
        setGroupedLevels(levelsWithRevisionLevel)
        setFinalLevels(_.difference(moduleLevels, staticOrRevisionLevels))
      }
    }
  }, [moduleLevels, displayedModuleId, routeIndex, setGroupedLevels, setFinalLevels])

  useEffect(() => {
    navigation.setOptions({
      header: props => <HomeHeader props={props} offSetY={offsetY} isQA={isQA} />,
    })
  }, [isQA, navigation, offsetY])

  useFocusEffect(
    useCallback(
      () => () => {
        setDisplayedLevelIdAndType(undefined)
        setBottomModalState(BottomState.HIDE)
      },
      [setBottomModalState],
    ),
  )

  /** useMemos */
  const focusedLevel: Level | undefined = useMemo(() => {
    if (scenario && module) {
      if (!moduleProgression || allLevelsAreCompleted) {
        return module.levels[0]
      }
      return (
        _.find(
          module.levels,
          level =>
            moduleProgression.levels?.[level.id]?.completionPercentage > 0 &&
            moduleProgression.levels?.[level.id]?.completionPercentage < 100 &&
            level.type !== "practiceTest",
        ) ??
        _.find(
          module.levels,
          level =>
            (moduleProgression.levels?.[level.id]?.completionPercentage ?? 0) < 100 &&
            level.type !== "practiceTest" &&
            // automatically validated revision levels
            !(
              (level.type === "revision" || level.type === "finalRevision") &&
              (_.isEmpty(moduleProgression.levels?.[level.id]?.revisionRuleIds) ||
                dayjs().isBefore(dayjs(moduleProgression.levels?.[level.id]?.revisionOpenDate)))
            ),
        )
      )
    }
    return undefined
  }, [scenario, module, moduleProgression, allLevelsAreCompleted])

  const displayedLevel = useMemo(
    () => module?.levels?.find(lvl => lvl.id === displayedLevelIdAndType?.id),
    [displayedLevelIdAndType, module?.levels],
  )

  const isDisplayedLevelAutoValidated = useMemo(
    () => isRevisionLevelAutoValidated(displayedLevel, moduleProgression),
    [displayedLevel, moduleProgression],
  )

  const buttonConfigs = useMemo(() => {
    if (!displayedLevelIdAndType || !scenarioId || !displayedModuleId) {
      return []
    }
    const { id, type } = displayedLevelIdAndType
    const isRevision = type === "revision" || type === "finalRevision"
    const displayedModuleProgression =
      progressionData?.progressionDetail?.modules?.[displayedModuleId]
    const displayedCompletionPercentage =
      displayedModuleProgression?.levels?.[id]?.completionPercentage ?? 0
    const trainingSessions = displayedModuleProgression?.levels?.[id]?.trainingSessions ?? 0
    const trainingDuration = displayedModuleProgression?.levels?.[id]?.trainingDuration ?? 0
    const progressionPerSteps = displayedModuleProgression?.levels?.[id]?.graph ?? []
    const isStaticLevelCompleted = displayedCompletionPercentage >= 100 && type === "static"

    const isAutoValidated =
      isRevision && _.isEmpty(displayedModuleProgression?.levels?.[id]?.revisionRuleIds)

    const isRevisionCompleted = isRevision && displayedCompletionPercentage >= 100

    return _.compact([
      _.isEmpty(progressionPerSteps) || isRuleListEmpty || isAutoValidated
        ? undefined
        : {
            mode: "outlined",
            label: seeMasteredRules ? t("bottomModal.seeProgression") : t("bottomModal.seeRules"),
            onPress: () => {
              setSeeMasteredRules(prevState => !prevState)
            },
            labelStyle: { color: onSurface.highEmphasis },
            contentStyle: {
              backgroundColor: surface.backgroundModal,
            } as ViewStyle,
          },
      !isAutoValidated && !isRevisionCompleted
        ? {
            mode: "text",
            contentStyle: { backgroundColor: primary_400 },
            label: t(
              `common.button.${
                isStaticLevelCompleted
                  ? "replay"
                  : trainingDuration > 0 ||
                    displayedCompletionPercentage > 0 ||
                    trainingSessions > 0
                  ? "continue"
                  : "start"
              }`,
            ),
            labelStyle: { color: onSurface.button },
            onPress: () => {
              if (displayedLevelIdAndType && displayedModuleId) {
                setBottomModalState(BottomState.HIDE)
                setGlobalModuleId(displayedModuleId)
                setLevelId(displayedLevelIdAndType.id)
                setLevelType(displayedLevelIdAndType.type)
                setRevisionLevelWithRules(undefined)
                setSeeMasteredRules(false)
                if (isStaticLevelCompleted) {
                  // noinspection JSIgnoredPromiseFromCall
                  showRetryModal(displayedLevelIdAndType.id)
                  return
                }
                setTimeout(() => navigation.navigate(ns.EXERCISE), 100)
              }
            },
          }
        : undefined,
    ]) as ButtonConfig[]
  }, [
    displayedLevelIdAndType,
    scenarioId,
    displayedModuleId,
    progressionData?.progressionDetail?.modules,
    isRuleListEmpty,
    seeMasteredRules,
    t,
    onSurface.highEmphasis,
    onSurface.button,
    surface.backgroundModal,
    primary_400,
    setSeeMasteredRules,
    setBottomModalState,
    setGlobalModuleId,
    setLevelId,
    setLevelType,
    setRevisionLevelWithRules,
    showRetryModal,
    navigation,
  ])

  const initializeRevisionLevel = useCallback(
    async (level: Omit<Level, "rules">) => {
      const isRevision = level.type === "revision"
      const levelProgression = levelsProgression?.[level.id]
      if (moduleLevels && levelProgression) {
        const revisionLevelWithRules = await getRevisionStepRules({
          levels: moduleLevels,
          revisionLevel: level as LevelRevision,
          stepId: levelProgression.revisionStepId || 0,
          progression: isRevision ? undefined : levelProgression,
          revisionRulesIds: isRevision ? levelProgression.revisionRuleIds : undefined,
        })
        setRevisionLevelWithRules(revisionLevelWithRules)
      }
    },
    [levelsProgression, moduleLevels, setRevisionLevelWithRules],
  )

  // useCallbacks and functions
  const onRowItemPress = useCallback(
    async (level: Omit<Level, "rules">, disabled: boolean) => {
      const levelDetails = moduleProgression?.levels[level.id]
      const levelCompletionPercentage = levelDetails?.completionPercentage ?? 0

      const isRevision = level.type === "revision" || level.type === "finalRevision"

      if (module === undefined) {
        return
      }

      if (disabled && !openAllLevelsAndModules) {
        showDisabledModal(ModalType.LOCK)
        return
      }

      if (level.type === "practiceTest") {
        setLevelId(level.id)
        setLevelType(level.type)
        setRevisionLevelWithRules(undefined)
        setGlobalModuleId(displayedModuleId)
        setTimeout(() => navigation.navigate(ns.PRACTICE_TEST), 100)
        return
      }

      if (
        isRevision &&
        levelCompletionPercentage < 100 &&
        !_.isEmpty(levelDetails?.revisionRuleIds)
      ) {
        if (dayjs().isBefore(dayjs(levelDetails?.revisionOpenDate))) {
          showDisabledModal(
            level.type === "finalRevision" ? ModalType.FINAL_COOLDOWN : ModalType.COOLDOWN,
          )
          return
        }
        await initializeRevisionLevel(level)
        setLevelId(level.id)
        setLevelType(level.type)
        setTimeout(() => navigation.navigate(ns.EXERCISE), 100)
      }
      if (
        levelCompletionPercentage >= 100 ||
        !isRevision ||
        (isRevision && _.isEmpty(levelDetails?.revisionRuleIds))
      ) {
        setDisplayedLevelIdAndType({ id: level.id, type: level.type })
        setBottomModalState(BottomState.LEVEL)
      }
    },
    [
      moduleProgression?.levels,
      module,
      openAllLevelsAndModules,
      showDisabledModal,
      setLevelId,
      setLevelType,
      setRevisionLevelWithRules,
      setGlobalModuleId,
      displayedModuleId,
      navigation,
      initializeRevisionLevel,
      setBottomModalState,
    ],
  )

  const onContinueButtonPress = useCallback(async () => {
    if (displayedModuleId && focusedLevel) {
      setLevelId(focusedLevel.id)
      setLevelType(focusedLevel.type)
      setGlobalModuleId(displayedModuleId)
      setRevisionLevelWithRules(undefined)

      if (allLevelsAreCompleted) {
        // noinspection ES6MissingAwait
        showRetryModal()
        return
      }

      if (focusedLevel.type === "revision" || focusedLevel.type === "finalRevision") {
        const revision = moduleProgression?.levels[focusedLevel.id]
        if (dayjs().isBefore(dayjs(revision?.revisionOpenDate))) {
          showDisabledModal(
            focusedLevel.type === "finalRevision" ? ModalType.FINAL_COOLDOWN : ModalType.COOLDOWN,
          )
          return
        }
        await initializeRevisionLevel(focusedLevel)
      }

      await wait(500)
      navigation.navigate(ns.EXERCISE)
    }
  }, [
    displayedModuleId,
    focusedLevel,
    setLevelId,
    setLevelType,
    setGlobalModuleId,
    setRevisionLevelWithRules,
    allLevelsAreCompleted,
    navigation,
    showRetryModal,
    moduleProgression?.levels,
    initializeRevisionLevel,
    showDisabledModal,
  ])

  const scrollRef = useRef<ScrollView>(null)

  useEffect(() => {
    if (module && scrollRef.current) {
      scrollRef.current.scrollTo({ x: 0, y: 0, animated: true })
    }
  }, [module])

  return (
    <ScreenWrapper style={isLoading ? s.loading : undefined}>
      {isLoading ? (
        <ActivityIndicator color={onSurface.disabled} style={cs.centerIndicator} />
      ) : error ? (
        <ErrorPanel
          title={error.code ?? t("Level.moduleErrors.errorTitle")}
          description={error.message ?? t("Level.moduleErrors.errorTitle")}
          style={cs.centerIndicator}
        />
      ) : (
        <Animated.ScrollView
          ref={scrollRef}
          contentContainerStyle={s.view}
          focusable={false}
          scrollEventThrottle={16}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offsetY } } }], {
            useNativeDriver: true,
          })}
        >
          <LevelTopView
            {...{ moduleProgression, focusedLevel }}
            allLevelsAreCompleted={allLevelsAreCompleted ?? false}
            moduleTitle={module?.title ?? ""}
            title={focusedLevel?.title ?? ""}
            scenarioTitle={scenario?.name ?? ""}
            onPress={onContinueButtonPress}
          />
          <AccessibilityListWrapper>
            {groupedLevels.length > 0 ? (
              <>
                {_.map(groupedLevels, (levels, index) => (
                  <LevelsBlock
                    key={`levelBlock-${index}`}
                    {...{
                      levels,
                      moduleProgression,
                      setDisplayedLevelIdAndType,
                      setBottomModalState,
                      onRowItemPress,
                    }}
                    displayedModuleId={displayedModuleId ?? 0}
                  />
                ))}
                {/* // final revision, or practice test */}
                <FinalLevels
                  levels={finalLevels}
                  {...{
                    displayedModuleId,
                    onRowItemPress,
                    moduleProgression,
                  }}
                />
              </>
            ) : module ? (
              <LevelsWithoutRevision
                {...{
                  focusedLevel,
                  moduleProgression,
                  displayedModuleId,
                  onRowItemPress,
                }}
                levels={module.levels}
              />
            ) : (
              <ErrorPanel
                title={t("Level.moduleErrors.errorTitle")}
                description={t("Level.moduleErrors.emptyLevels")}
                style={cs.centerIndicator}
              />
            )}
          </AccessibilityListWrapper>
        </Animated.ScrollView>
      )}
      <BottomModal
        {...{ onDismiss }}
        key={displayedLevelIdAndType ? "bottomLevel" : "bottomModule"}
        buttonConfigs={!displayedLevelIdAndType ? undefined : buttonConfigs}
        customHeight={
          isDisplayedLevelAutoValidated
            ? (isWeb ? 0.4 : 0.5) * screenHeight
            : displayedLevelIdAndType && isWeb
            ? undefined
            : (isWeb ? 0.6 : 0.8) * screenHeight
        }
        disablePaddingBottom={bottomModalState === BottomState.LEVEL}
      >
        {bottomModalState === BottomState.MODULE &&
        !displayedLevelIdAndType &&
        scenarioId &&
        routeIndex !== undefined ? (
          <ModuleList
            {...{
              scenarioId,
              routeIndex,
              setGlobalModuleId,
              setBottomModalState,
              setDisplayedModuleId,
              progressionData,
            }}
            scenarioName={scenario?.name ?? ""}
          />
        ) : null}
        {bottomModalState === BottomState.LEVEL &&
        scenarioId &&
        progressionData &&
        displayedModuleId &&
        displayedLevelIdAndType ? (
          <BottomDisplayedLevel
            {...{
              scenarioId,
              moduleProgression,
              displayedModuleId,
              progressionData,
              displayedLevelIdAndType,
              level: displayedLevel,
            }}
          />
        ) : null}
      </BottomModal>
      <InformationBanner />
    </ScreenWrapper>
  )
}

export default LevelsScreen
