import { Montserrat, Roboto } from "config/fonts"
import { maxScreenWidth } from "constants/constants"
import { usePreferences } from "providers/PreferenceProvider"
import type { FC, PropsWithChildren } from "react"
import { StatusBar } from "react-native"
import { colors } from "theme/colors"
import type { Theme } from "theme/ThemeProvider"
import { ThemeProvider } from "theme/ThemeProvider"
import type { ITypographyTheme } from "theme/types"

const ThemeApp: FC<PropsWithChildren> = ({ children }) => {
  const { darkTheme } = usePreferences()
  return (
    <ThemeProvider
      customTheme={{
        roundness: 8,
        darkMode: darkTheme,
        fonts: {
          default: Montserrat,
          title: Montserrat,
          button: Roboto,
        },
        typoGen: ({
          colors: { onSurface },
          fontMaker,
        }: Omit<Omit<Theme, "typography">, "typoGen">): Partial<ITypographyTheme> => ({
          h1: {
            ...fontMaker({ family: "title", weight: "SemiBold" }),
          },
          h4: {
            ...fontMaker({ family: "title", weight: "SemiBold" }),
          },
          h5: {
            color: onSurface.highEmphasis,
            ...fontMaker({ family: "title", weight: "SemiBold" }),
          },
          h6: {
            color: onSurface.highEmphasis,
            ...fontMaker({ family: "title", weight: "SemiBold" }),
          },
          exerciseSentence: {
            color: onSurface.highEmphasis,
            ...fontMaker({ family: "title", weight: "SemiBold" }),
          },
          subtitle1: {
            color: onSurface.mediumEmphasis,
          },
          subtitle2: {
            color: onSurface.mediumEmphasis,
            ...fontMaker({ weight: "Medium", style: "italic" }),
          },
          overline: {
            ...fontMaker({ weight: "Light" }),
          },
          button: {
            letterSpacing: 0,
            color: onSurface.highEmphasis,
            ...fontMaker({ family: "button", weight: "Medium" }),
          },
          body1: { color: onSurface.highEmphasis, ...fontMaker({ weight: "SemiBold" }) },
          body2: {
            color: onSurface.mediumEmphasis,
          },
          caption: {
            color: onSurface.highEmphasis,
          },
        }),
        screenStyle: {
          alignSelf: "center",
          maxWidth: maxScreenWidth,
          width: "100%" as const,
        },
      }}
    >
      <StatusBar
        translucent={true}
        barStyle={`${darkTheme ? "light" : "dark"}-content`}
        backgroundColor={colors.onSurface.transparent}
      />
      {children}
    </ThemeProvider>
  )
}

export default ThemeApp
