import { Body2, Subtitle1 } from "components/Texts"
import { iconSize, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import type { NativeSyntheticEvent, StyleProp, TextInputChangeEventData } from "react-native"
import { View } from "react-native"
import type { TextStyle } from "react-native/Libraries/StyleSheet/StyleSheetTypes"
import { IconButton, TextInput } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

interface Props {
  customStyle?: StyleProp<TextStyle>
  label?: string
  onChange: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
  onPress: () => void
  placeholder?: string
  subtitle?: string
  value: string
}
const ConsultationInput: FC<Props> = ({
  customStyle,
  label,
  onChange,
  onPress,
  placeholder,
  subtitle,
  value,
}) => {
  const {
    colors: { onPrimary, primary, ripple },
    darkMode,
  } = useTheme()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ dimensions: { margin } }) => ({
      container: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: margin,
      },
      input: {
        height: 42,
        maxHeight: 42,
        maxWidth: 100,
      },
      mobileContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      mobileInput: {
        height: 32,
        maxHeight: 32,
      },
    }),
    [],
  )

  const Typography = isWeb ? Subtitle1 : Body2

  return (
    <View style={isWeb ? s.container : s.mobileContainer}>
      {subtitle ? <Typography>{subtitle}</Typography> : null}
      <View style={cs.row}>
        <TextInput
          theme={{
            colors: {
              placeholder: darkMode ? onPrimary.disabled : undefined,
            },
          }}
          selectTextOnFocus={true}
          style={[isWeb ? s.input : s.mobileInput, customStyle]}
          {...{ onChange, value, label, placeholder }}
        />
        <IconButton
          containerColor={primary}
          icon="check"
          rippleColor={rippleColor(ripple)}
          size={isWeb ? iconSize.SMALL : iconSize.EXTRA_SMALL}
          {...{ onPress }}
        />
      </View>
    </View>
  )
}

export default ConsultationInput
