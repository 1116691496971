import type { SvgProps } from "react-native-svg"
import Svg, { Defs, G, Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const WrongSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { secondary },
  } = useTheme()
  return (
    <Svg
      {...props}
      width={props?.width ?? 24}
      height={props?.height ?? 24}
      fill="none"
      viewBox="0 0 184 184"
    >
      <G>
        <Path
          d="m145.666 49.143-10.81-10.81L92 81.19 49.143 38.333l-10.81 10.81L81.19 92l-42.857 42.856 10.81 10.81L92 102.81l42.856 42.856 10.81-10.81L102.81 92l42.856-42.857Z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          opacity="0.3"
          transform="translate(1, 2)"
        />
        <Path
          d="m149.202 52.678 3.535-3.535-3.535-3.535-10.81-10.81-3.536-3.536-3.535 3.535L92 74.12 52.678 34.797l-3.535-3.535-3.535 3.535-10.81 10.81-3.536 3.536 3.535 3.535L74.12 92 34.797 131.32l-3.535 3.535 3.535 3.536 10.81 10.81 3.536 3.535 3.535-3.535L92 109.881l39.321 39.321 3.535 3.535 3.536-3.535 10.81-10.81 3.535-3.536-3.535-3.535L109.881 92l39.321-39.322Z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          opacity="0.3"
          transform="translate(1, 2)"
        />
      </G>
      <G>
        <Path
          d="m145.666 49.143-10.81-10.81L92 81.19 49.143 38.333l-10.81 10.81L81.19 92l-42.857 42.856 10.81 10.81L92 102.81l42.856 42.856 10.81-10.81L102.81 92l42.856-42.857Z"
          fill={secondary ?? "#FF8F1F"}
        />
        <Path
          d="m149.202 52.678 3.535-3.535-3.535-3.535-10.81-10.81-3.536-3.536-3.535 3.535L92 74.12 52.678 34.797l-3.535-3.535-3.535 3.535-10.81 10.81-3.536 3.536 3.535 3.535L74.12 92 34.797 131.32l-3.535 3.535 3.535 3.536 10.81 10.81 3.536 3.535 3.535-3.535L92 109.881l39.321 39.321 3.535 3.535 3.536-3.535 10.81-10.81 3.535-3.536-3.535-3.535L109.881 92l39.321-39.322Z"
          fill={secondary ?? "#FF8F1F"}
          strokeWidth={10}
        />
      </G>
      <Defs />
    </Svg>
  )
}

export default WrongSVG
