import type { EmailClickOnMistakeExercise, Result } from "@newpv/js-common"
import { Subtitle2 } from "components/Texts"
import { buttonWidth, Keyboard, maxScreenWidth } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import useKeyboardEvent from "hooks/useKeyboardEvent"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { RefObject } from "react"
import React, { useCallback, useState } from "react"
import type { View } from "react-native"
import { Button } from "react-native-paper"
import CheckSVG from "svgs/Check"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

interface IProps {
  exercise: EmailClickOnMistakeExercise
  onSelectPress: (value: Result) => Promise<void>
}

const NoErrorButtonEmailClickOnMistake: React.FC<IProps> = ({ exercise, onSelectPress }) => {
  const t = useTypedTranslation()
  const {
    darkMode,
    colors: {
      ripple,
      onSurface: { mediumEmphasis, button: buttonOnSurface },
    },
  } = useTheme()
  const { isSmallWidth } = useDeviceTools()
  const [buttonNoMistakeRef] = useState<RefObject<View> | null>()
  const s = useStyles(
    ({ colors: { primary_400 }, dimensions: { spacing }, roundness, typography }) => ({
      buttonText: {
        ...typography.button,
        color: buttonOnSurface,
        marginLeft: isSmallWidth ? spacing / 2 : undefined,
        textTransform: "uppercase",
      },
      buttonViewLayout: {
        borderRadius: 0,
        marginTop: spacing,
      },
      buttonViewLayoutRounded: {
        alignSelf: "center",
        borderRadius: roundness * 4,
        marginVertical: spacing,
        maxWidth: maxScreenWidth,
      },
      noErrorButton: {
        backgroundColor: primary_400,
        padding: spacing,
        width: "100%" as const,
      },
      rounded: {
        backgroundColor: primary_400,
        minWidth: buttonWidth,
        paddingHorizontal: spacing * 1.5,
        paddingVertical: spacing,
      },
      buttonContent: {
        backgroundColor: primary_400,
        paddingHorizontal: spacing,
        paddingVertical: spacing / 2,
        width: "100%" as const,
      },
    }),
    [isSmallWidth],
  )

  const onNoMistakeClicked = useCallback(async () => {
    const res = exercise.hasMistake ? "error/missed-mistake" : "correct"
    await onSelectPress?.(res)
  }, [exercise, onSelectPress])

  const handleEnterKeyPress = useCallback(
    async event => {
      if (event.key === Keyboard.ENTER) {
        await onNoMistakeClicked()
      }
    },
    [onNoMistakeClicked],
  )

  useKeyboardEvent(handleEnterKeyPress, buttonNoMistakeRef)

  return (
    <Button
      focusable={true}
      ref={buttonNoMistakeRef}
      contentStyle={s.buttonContent}
      style={isSmallWidth ? s.buttonViewLayout : s.buttonViewLayoutRounded}
      mode="contained-tonal"
      onPress={onNoMistakeClicked}
      rippleColor={rippleColor(ripple)}
      icon={
        isSmallWidth
          ? () => <CheckSVG color={darkMode ? buttonOnSurface : mediumEmphasis} />
          : undefined
      }
    >
      <Subtitle2 style={s.buttonText}>{t("Exercise.no_error_click_on_email")}</Subtitle2>
    </Button>
  )
}

export default NoErrorButtonEmailClickOnMistake
