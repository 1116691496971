/* eslint-disable simple-import-sort/imports */
import i18n from "i18next"

import fr from "./fr"

import { initReactI18next } from "react-i18next"

export type Language = "fr"
export * from "./fr"
const defaultLanguage: Language = "fr"

export const initI18n = (lng: Language = defaultLanguage): void => {
  // noinspection JSIgnoredPromiseFromCall
  i18n.use(initReactI18next).init({
    // debug: true,
    compatibilityJSON: "v3",
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: "languageOnly",
    lng,
    resources: {
      fr: {
        translation: fr,
      },
    },
    saveMissing: true,
    missingKeyHandler: (lngs: string[], ns: string, key: string, fallbackValue: string) => {
      // eslint-disable-next-line no-console
      console.error({
        missing_keys: {
          lngs,
          ns,
          key,
          fallbackValue,
        },
      })
    },
    cache: {
      enabled: true,
    },
    react: {
      useSuspense: false,
    },
  })
}
