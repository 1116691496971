import type { ClickOnMistakeExercise, Result } from "@newpv/js-common"
import { Button } from "components/Button"
import CorrectionSentence from "components/Correction/CorrectionSentence"
import { H5 } from "components/Texts"
import { FEEDBACK_DELAY, maxCarouselWidth, minCarouselHeight } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { FC } from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { Animated, StyleSheet, View } from "react-native"
import CorrectSVG from "svgs/Correct"
import WrongSVG from "svgs/Wrong"
import useTheme from "theme/ThemeProvider"

const NUMBER_OF_QUESTIONS = 3

// eslint-disable-next-line no-shadow
enum BUTTON_STATES {
  "CORRECT",
  "WRONG",
}

interface IProps {
  index: number
  item: ClickOnMistakeExercise
  width: number
  onLevelPress?: (
    value: Result,
    providedHeight?: number,
    intensiveTraining?: boolean,
  ) => Promise<void> | undefined
  swipeNextIntensiveExercise: () => void
}

const IntensiveTrainingItem: FC<IProps> = ({
  index,
  item,
  width,
  onLevelPress,
  swipeNextIntensiveExercise,
}) => {
  const t = useTypedTranslation()
  const {
    colors: {
      primary_100,
      secondary_200,
      onSurface: { highEmphasis, button: buttonEmphasis },
    },
  } = useTheme()
  const { isSmallWidth } = useDeviceTools()
  const [isAnswered, setIsAnswered] = useState<boolean>(false)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean | undefined>(undefined)
  const [buttonPressed, setButtonPressed] = useState<BUTTON_STATES>()
  const progress = useRef(new Animated.Value(0)).current
  const animation = useMemo(
    () =>
      Animated.timing(progress, {
        toValue: 1,
        duration: FEEDBACK_DELAY,
        useNativeDriver: true,
      }),
    [progress],
  )
  const s = useStyles(
    ({ colors: { surface }, roundness, dimensions: { margin, spacing } }) => ({
      button: {
        borderColor: surface.outline,
        borderRadius: roundness * 4,
        borderWidth: 1,
        margin: margin / 2,
      },
      buttonContent: {
        marginHorizontal: margin * 1.5,
        marginVertical: margin,
      },
      buttonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: isSmallWidth ? margin : margin * 1.5,
        flexWrap: "wrap",
      },
      cardContainer: {
        alignSelf: "center",
        backgroundColor: surface.surface,
        borderWidth: 1,
        borderRadius: roundness * 5,
        borderColor: surface.outline,
        justifyContent: "space-evenly",
        marginHorizontal: margin / 2,
        maxWidth: maxCarouselWidth,
        minHeight: minCarouselHeight,
        padding: 2 * spacing,
        width: width - 2 * spacing,
      },
      resultIconContainer: {
        ...StyleSheet.absoluteFillObject,
        alignItems: "center",
        justifyContent: "center",
      },
      wordsContainer: {
        alignItems: "center",
        justifyContent: "center",
      },
    }),
    [width, isSmallWidth],
  )

  const animatedStyle = useMemo(
    () => ({
      opacity: progress,
      transform: [
        {
          scale: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0.85, 1],
          }),
        },
      ],
    }),
    [progress],
  )

  const checkAnswer = useCallback(
    (exercise: ClickOnMistakeExercise, answer: BUTTON_STATES, answerIndex: number) => {
      setButtonPressed(answer)
      const isCorrect =
        (answer === BUTTON_STATES.CORRECT && !exercise.hasMistake) ||
        (answer === BUTTON_STATES.WRONG && exercise.hasMistake)
      setIsCorrectAnswer(isCorrect)
      setIsAnswered(true)
      animation.start(({ finished }) => {
        if (finished) {
          if (isCorrect) {
            const newIndex = answerIndex + 1
            if (newIndex !== NUMBER_OF_QUESTIONS) {
              swipeNextIntensiveExercise()
            } else {
              setTimeout(async () => {
                await onLevelPress?.("correct", undefined, true)
              }, 500)
            }
            return
          }
          animation.reset()
          setIsAnswered(false)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swipeNextIntensiveExercise, onLevelPress],
  )

  const buttonBackgroundColor = useCallback(
    (button: BUTTON_STATES) => {
      if (isAnswered && button === buttonPressed) {
        if (isCorrectAnswer) {
          return primary_100
        }
        return secondary_200
      }
      return undefined
    },
    [isAnswered, buttonPressed, isCorrectAnswer, secondary_200, primary_100],
  )

  const buttonLabelColor = useCallback(
    (button: BUTTON_STATES) => {
      if (isAnswered && button === buttonPressed) {
        return buttonEmphasis
      }
      return highEmphasis
    },
    [isAnswered, buttonPressed, highEmphasis, buttonEmphasis],
  )

  return (
    <View key={index} style={s.cardContainer}>
      <CorrectionSentence
        exercise={item}
        baseTypography="h5"
        containerStyle={s.wordsContainer}
        Comp={H5}
        {...{ isCorrectAnswer }}
        isIntensiveTrainingSentence={true}
        wordBlocks={item.sentence}
      />
      <View style={s.buttonContainer}>
        {[BUTTON_STATES.CORRECT, BUTTON_STATES.WRONG].map(value => {
          const isWrong = value === BUTTON_STATES.WRONG
          return (
            <Button
              key={value}
              mode="outlined"
              style={[s.button, { backgroundColor: buttonBackgroundColor(value) }]}
              contentStyle={s.buttonContent}
              labelStyle={{ margin: 0, color: buttonLabelColor(value) }}
              onPress={() => checkAnswer(item, value, index)}
            >
              {t(`common.button.${isWrong ? "wrong" : "correct"}`)}
            </Button>
          )
        })}
      </View>
      {isAnswered && (
        <Animated.View style={[s.resultIconContainer, animatedStyle]}>
          {isCorrectAnswer ? <CorrectSVG width={200} height={200} /> : null}
          {!isCorrectAnswer ? <WrongSVG width={200} height={200} /> : null}
        </Animated.View>
      )}
    </View>
  )
}
export default IntensiveTrainingItem
