import type { EvaluationType } from "@newpv/js-common"
import { Body2, Caption, H5, Subtitle1 } from "components/Texts"
import { iconSize } from "constants/constants"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { View } from "react-native"
import ClockSVG from "svgs/Clock"
import StarSVG from "svgs/Star"
import TrophySVG from "svgs/Trophy"
import useTheme from "theme/ThemeProvider"

interface IProps {
  timeIsUp?: boolean
  score?: number
  total?: number
  timerElapsedTime?: number
  evaluationType?: EvaluationType
}

const ModalScoreContent: FC<IProps> = ({
  total,
  score,
  timeIsUp,
  timerElapsedTime,
  evaluationType,
}) => {
  const cs = useCommonStyles()
  const {
    fontMaker,
    colors: { icon, onSurface },
  } = useTheme()
  const t = useTypedTranslation()

  const s = useStyles(({ dimensions: { spacing }, colors: { surface } }) => ({
    evaluationContentContainer: {
      paddingHorizontal: spacing,
      paddingTop: spacing,
    },
    divider: {
      backgroundColor: surface.overlay,
      height: 1,
      width: "100%" as const,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
      marginVertical: spacing,
    },
    rowContent: {
      marginLeft: spacing,
    },
    score: {
      alignSelf: "center",
      flexDirection: "column-reverse",
    },
  }))

  const commonIconProps = useMemo(
    () => ({
      width: iconSize.SMALL,
      height: iconSize.SMALL,
      color: icon,
    }),
    [icon],
  )

  const renderScoreRow = useCallback(
    (type: "score" | "clock" | "trophy") => {
      if (!_.isNumber(score) || !total || timerElapsedTime == null) {
        return null
      }
      const percentage = Math.floor((score / total) * 100)
      const convertedTime = dayjs.duration(timerElapsedTime, "milliseconds")
      const spendTime = convertedTime.format(
        t(`common.${convertedTime.hours() < 1 ? "durationMin" : "durationFormat"}`),
      )
      const Icon = type === "score" ? StarSVG : type === "clock" ? ClockSVG : TrophySVG
      return (
        <View style={s.row}>
          <Icon {...commonIconProps} />
          <View style={s.rowContent}>
            <Subtitle1 color={onSurface.highEmphasis}>
              <H5 style={{ ...fontMaker({ weight: "SemiBold" }) }}>
                {t(`${ns.MODAL}.${ModalType.EVALUATION}.${type}`, {
                  score,
                  spendTime,
                  percentage,
                  total,
                })}
              </H5>
            </Subtitle1>
            <Caption color={onSurface.mediumEmphasis}>
              {t(`${ns.MODAL}.${ModalType.EVALUATION}.${type}_caption`, { count: score })}
            </Caption>
          </View>
        </View>
      )
    },
    [
      commonIconProps,
      timerElapsedTime,
      fontMaker,
      onSurface.highEmphasis,
      onSurface.mediumEmphasis,
      s.row,
      s.rowContent,
      score,
      t,
      total,
    ],
  )

  return (
    <View style={s.evaluationContentContainer}>
      <Body2 color={onSurface.highEmphasis} style={cs.paddingBottom}>
        {t(
          `${ns.MODAL}.${evaluationType ?? ModalType.INITIAL_EVALUATION}.${
            timeIsUp ? "finishTimeUp" : "finish"
          }`,
        )}
      </Body2>
      {renderScoreRow("score")}
      <View style={s.divider} />
      {renderScoreRow("clock")}
      <View style={s.divider} />
      {renderScoreRow("trophy")}
    </View>
  )
}

export default ModalScoreContent
