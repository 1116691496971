import type { ApiError } from "@newpv/js-common"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import { QuotesLoading } from "components/QuotesLoading/QuotesLoading"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { FC } from "react"
import type { ViewStyle } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

interface IProps {
  error: ApiError | null
  loading: boolean
}

const LoadingScreen: FC<IProps> = ({ error, loading }) => {
  const cs = useCommonStyles()

  const t = useTypedTranslation()
  const { safeAreaView } = useStyles(() => ({
    safeAreaView: {
      alignItems: "center",
      justifyContent: "center",
    } as ViewStyle,
  }))
  return (
    <SafeAreaView style={[cs.fullFlex, safeAreaView]}>
      {error ? (
        <ErrorPanel title={error.message ?? t("common.errors.unknown")} code={error.code} />
      ) : loading ? (
        <QuotesLoading />
      ) : null}
    </SafeAreaView>
  )
}

export default LoadingScreen
