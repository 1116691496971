import { decodeJwt } from "@newpv/js-common"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { isWeb, LOCAL_STORAGE } from "constants/constants"
import { ns } from "i18n/fr"
import _ from "lodash"
import type { ReloadStorage } from "models/StorageInterfaces"
import useAuthContext from "providers/AuthProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useEffect, useState } from "react"
import { Linking } from "react-native"
import { logger } from "utils/logger"

export const useRootStorage = (): {
  loadingStorage: boolean
  initialRouteName?: string
  initialUrl?: string | null
} => {
  const [loadingStorage, setLoadingStorage] = useState(false)
  const [initialRouteName, setInitialRouteName] = useState<string>()
  const [initialUrl, setInitialUrl] = useState<string | null>()

  const { setModuleId, setRouteIndex } = useScenarioAndModule()
  const { setToken, setScenarioId } = useAuthContext()

  // Useful for Profile and Level screens navigation
  useEffect(() => {
    ;(async () => {
      // TODO: fix also on native apps, for the connexion on a different scenario
      if (!isWeb) {
        return
      }

      setLoadingStorage(true)

      const initUrl = await Linking.getInitialURL()
      setInitialUrl(initUrl)

      // for connexion on a different scenario, for an already logged in user
      if (initUrl) {
        const url = new URL(initUrl)
        const searchParams = new URLSearchParams(url.search)

        if (searchParams.get("jwt") != null && searchParams.get("scenarioId") != null) {
          const authType = decodeJwt(searchParams.get("jwt") ?? "")?.identities[0]
            .authenticationType
          if (authType === "ecoledirecte" || authType === "gar") {
            logger(
              "setting new values for scenario id and jwt in useRootStorage:",
              searchParams.get("scenarioId"),
              searchParams.get("jwt"),
            )
            const scenarioIdNum = parseInt(searchParams.get("scenarioId") ?? "0", 10)

            setScenarioId(scenarioIdNum)
            setToken(searchParams.get("jwt"))

            // we don't want to reload from the local storage so we return early
            setInitialRouteName(ns.HOME)
            setLoadingStorage(false)
            await AsyncStorage.removeItem(LOCAL_STORAGE.reload)
            return
          }
        }
      }

      const auth = isWeb
        ? // @ts-ignore
          await window.sessionStorage.getItem(LOCAL_STORAGE.auth)
        : await AsyncStorage.getItem(LOCAL_STORAGE.auth)

      // if it's not a reload, but a new session
      if (auth == null) {
        // we don't want to reload from the local storage so we return early
        // we don't have a token, we are displaying the login page
        setInitialRouteName(ns.HOME)
        setLoadingStorage(false)
        await AsyncStorage.removeItem(LOCAL_STORAGE.reload)
        return
      }

      const jsonStorage = await AsyncStorage.getItem(LOCAL_STORAGE.reload)
      if (!jsonStorage) {
        setInitialRouteName(ns.HOME)
        setLoadingStorage(false)
        return
      }
      const storage = jsonStorage ? (JSON.parse(jsonStorage) as ReloadStorage) : undefined
      if (storage?.isReload) {
        logger("setting from storage because reload", {
          moduleId: storage?.moduleId,
          routeIndex: storage?.routeIndex,
        })
        setModuleId(storage?.moduleId)
        setRouteIndex(storage?.routeIndex)
        setInitialRouteName(
          _.isUndefined(storage?.routeIndex) || _.isUndefined(storage?.scenarioId)
            ? ns.HOME
            : ns.LEVEL,
        )
      }
      // Useful to avoid getting blocked inside profile screen => return Home
      await AsyncStorage.removeItem(LOCAL_STORAGE.reload)
      setLoadingStorage(false)
    })()
  }, [setModuleId, setRouteIndex, setScenarioId, setToken])

  return { loadingStorage, initialRouteName, initialUrl }
}
