import { isWeb } from "constants/constants"

export const hexToRgba = (hex: string, alpha: number): string | undefined => {
  let parseString = hex
  if (hex.startsWith("#")) {
    parseString = hex.slice(1, 7)
  }
  if (parseString.length !== 6) {
    return undefined
  }
  const r = parseInt(parseString.slice(0, 2), 16)
  const g = parseInt(parseString.slice(2, 4), 16)
  const b = parseInt(parseString.slice(4, 6), 16)
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return undefined
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const rippleColor = (color: string): string | undefined =>
  isWeb ? hexToRgba(color, 0.32) : color
