import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ArrowLeftSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: {
      onSurface: { mediumEmphasis },
    },
  } = useTheme()
  return (
    <Svg {...props} width={props?.width ?? 24} height={props?.height ?? 24} fill="none">
      <Path
        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2Z"
        fill={props?.color ?? mediumEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default ArrowLeftSVG
