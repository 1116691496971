import type { Step } from "@newpv/js-common"
import { lett } from "@newpv/js-common"
import { H6 } from "components/Texts"
import dayjs from "dayjs"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { View } from "react-native"
import { Rect } from "react-native-svg"
import useTheme from "theme/ThemeProvider"
import type { IColorTheme } from "theme/types"
import type { VictoryLabelStyleObject } from "victory"
import type { VictoryAxisCommonProps } from "victory-core"
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
} from "victory-native"

import { GradientFill } from "./GradientFill"
import { TickLabel } from "./TickLabel"

interface IGraphProps {
  clipOptions?: {
    clipHeight: number
    clipStrokeColor: string
    clipStrokeLinecap: "butt" | "round" | "square"
  }
  withGradient?: boolean
  graphColor?: string
  data: Step[]
  height?: number
  width?: number
}
export const Graph: React.FC<IGraphProps> = ({
  clipOptions,
  graphColor = "red",
  withGradient,
  data,
  height = 300,
  width,
}) => {
  const {
    darkMode,
    colors,
    colors: { onSurface, surface },
    typography,
    dimensions: { spacing },
  } = useTheme()

  const t = useTypedTranslation()

  const limitedHeight = Math.max(height, 300)

  const percentageValue = data[data.length - 1]?.percentage
  const isPercentageFull = data && data?.[data.length - 1]?.percentage && percentageValue >= 100
  const isPercentageOver90 = data && data?.[data.length - 1]?.percentage && percentageValue > 90

  const s = useStyles(
    ({ typography: themeTypo }) => ({
      emptyStateContainer: {
        alignItems: "center",
        bottom: "50%",
        position: "absolute",
        width: "100%" as const,
      },
      emptyStateText: {
        textAlign: "center",
        transform: [{ translateY: themeTypo.h6.fontSize ?? 24 }],
        width: "50%",
      },
      victoryPadding: {
        left: isPercentageFull || percentageValue > 10 ? 8 : 10,
        top: 12,
      },
    }),
    [isPercentageFull, percentageValue],
  )

  const singleDay = lett(data?.[0]?.date, d => dayjs(new Date(d)))?.isSame(
    lett(data?.[data.length - 1]?.date, d => dayjs(new Date(d))),
    "day",
  )

  // necessary because of this: https://github.com/FormidableLabs/victory/issues/1929
  const createGradientFill = useCallback(
    (colorsProp: IColorTheme) => <GradientFill colorsProp={colorsProp} />,
    [],
  )

  const style = useMemo<(axis: "x" | "y") => Pick<VictoryAxisCommonProps, "style">>(
    () => (axis: "x" | "y") => ({
      style: {
        axis: { stroke: "transparent" },
        grid: {
          stroke: () =>
            axis === "y" ? (darkMode ? surface.outline : surface.overlay) : surface.backgroundModal,
        },
      },
    }),
    [darkMode, surface.backgroundModal, surface.outline, surface.overlay],
  )

  const rect = useMemo(
    () => (
      <Rect
        // We use specific values for w. and h. to avoid the svg from being too big
        height={31}
        width={isPercentageFull ? 44 : percentageValue > 10 ? 40 : 36}
        rx={spacing}
      />
    ),
    [isPercentageFull, percentageValue, spacing],
  )

  // noinspection JSSuspiciousNameCombination for strokeWidth clipHeight
  return (
    <View>
      <VictoryChart {...{ width }} height={limitedHeight}>
        <VictoryAxis
          key="y-axis"
          dependentAxis
          crossAxis
          {...{ ...style("y") }}
          tickFormat={(tick, index) => {
            if (index % 2 !== 1) {
              return `${tick}%`
            }
            return ""
          }}
          tickValues={[0, 25, 50, 75, 100]}
          tickCount={3}
          tickLabelComponent={<TickLabel />}
        />
        {_.isEmpty(data) ? null : (
          <VictoryAxis
            key="x-axis"
            crossAxis
            tickCount={singleDay ? 1 : 10}
            {...{ ...style("x") }}
            tickFormat={(tick, index, ticks) => {
              const length = ticks.length

              if (
                ((length < 10 || singleDay) && index !== 0 && index !== length - 1) ||
                !(
                  index === 0 ||
                  Math.floor(length * 0.25) === index ||
                  Math.floor(length * 0.5) === index ||
                  Math.floor(length * 0.75) === index ||
                  index === length - 1
                )
              ) {
                return ""
              }
              return dayjs(new Date(tick)).format("DD[/]MM")
            }}
            tickLabelComponent={<TickLabel dy={5} />}
          />
        )}
        {data ? (
          <VictoryArea
            data={data}
            interpolation="basis"
            style={{
              data: {
                stroke: withGradient ? "url(#clipGradient)" : clipOptions?.clipStrokeColor,
                strokeWidth: clipOptions?.clipHeight,
                strokeLinecap: clipOptions?.clipStrokeLinecap,
                fill: withGradient ? "url(#areaGradient)" : graphColor,
              },
              labels: {
                ...typography.caption,
                fill: darkMode ? onSurface.button : onSurface.mediumEmphasis,
              } as VictoryLabelStyleObject,
            }}
            x="date"
            y="percentage"
            groupComponent={
              clipOptions != null ? (
                <VictoryClipContainer clipPadding={{ top: 20, right: 10, bottom: 10, left: 10 }} />
              ) : undefined
            }
            labels={props => {
              if (props.data?.length === Number.parseInt(props.index, 10) + 1) {
                return `${Math.floor(props.datum.percentage)}%`
              }
              return null
            }}
            labelComponent={
              <VictoryLabel
                dx={-10}
                dy={isPercentageOver90 ? 12 : -24}
                textAnchor="end"
                backgroundStyle={{ fill: colors.primary_400 }}
                backgroundPadding={s.victoryPadding}
                backgroundComponent={rect}
              />
            }
          />
        ) : null}
        {createGradientFill(colors)}
      </VictoryChart>
      {data.length === 0 ? (
        <View style={s.emptyStateContainer}>
          <H6 style={s.emptyStateText}>{t("Profile.graphEmptyState")}</H6>
        </View>
      ) : null}
    </View>
  )
}
