import type { Exercise, ExerciseId, ExerciseWithRuleId, Rule } from "@newpv/js-common"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { useDebug } from "providers/DebugProvider"
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"

export type SetState<T> = Dispatch<SetStateAction<T>>

interface ConsultationData {
  previousExercise?: Exercise
  previousRule?: Rule
}

interface ContextData {
  ExercisesState: [ExerciseWithRuleId[] | undefined, SetState<ExerciseWithRuleId[] | undefined>]
  clearConsultationData: () => void
  crucialQuestionSwitch: [boolean, SetState<boolean>]
  getExerciseIndex: (currentExercise?: Exercise) => number | undefined
  isConsultation: boolean
  previousConsultationData?: ConsultationData
  searchExerciseByText: (text: string) =>
    | {
        firstExercise: ExerciseWithRuleId | undefined
        exerciseIds: ExerciseId[]
      }
    | undefined
  searchExerciseByKey: (id?: ExerciseId, index?: number) => ExerciseWithRuleId | undefined
  searchExerciseByNavigation: (isNext: boolean) => ExerciseWithRuleId | undefined
  setExerciseIndex: SetState<number>
  showConsultationMode: (
    numberOfExercises: number,
    previousData?: ConsultationData,
  ) => ExerciseWithRuleId | undefined
}

export const ConsultationContext = createContext<ContextData>({} as ContextData)
export const ConsultationModeProvider: FC<PropsWithChildren> = ({ children }) => {
  const t = useTypedTranslation()
  const { showSnack } = useDebug()
  const [isConsultation, setIsConsultation] = useState<boolean>(false)
  // Exercise Redirections
  const [allExercises, setAllExercises] = useState<ExerciseWithRuleId[]>([])
  const [allContent, setAllContent] = useState<Record<ExerciseId, string>>({})
  const [exerciseIndex, setExerciseIndex] = useState<number>(0)
  const [exercisesLength, setExercisesLength] = useState(0)
  const [previousConsultationData, setPreviousConsultationData] = useState<ConsultationData>()
  const crucialQuestionSwitch = useState(false)

  useEffect(() => {
    if (_.isEmpty(allExercises)) {
      return
    }
    setAllContent(
      _.reduce(
        allExercises,
        (acc, exercise) => ({
          ...acc,
          [exercise.id]: _.deburr(JSON.stringify(exercise)).toLowerCase(),
        }),
        {},
      ),
    )
  }, [allExercises])

  const getExerciseIndex = useCallback(
    (currentExercise?: Exercise) => {
      if (currentExercise) {
        const index = _.findIndex(allExercises, ex => ex.id === currentExercise.id)
        return index === -1 ? undefined : index
      }
      return undefined
    },
    [allExercises],
  )

  const searchExerciseByNavigation = useCallback(
    (isNext: boolean): ExerciseWithRuleId | undefined => {
      if (!isNext && exerciseIndex <= 0) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.start`))
        return undefined
      }
      if (isNext && exerciseIndex >= exercisesLength - 1) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.end`))
        return undefined
      }
      const newExercise = allExercises?.[isNext ? exerciseIndex + 1 : exerciseIndex - 1]
      setExerciseIndex(prev => (isNext ? prev + 1 : prev - 1))
      return newExercise
    },
    [allExercises, exerciseIndex, exercisesLength, showSnack, t],
  )

  const searchExerciseByKey = useCallback(
    (id?: ExerciseId, index?: number) => {
      if (_.isNumber(id)) {
        const searchedExercise = _.find(allExercises, ["id", id])
        const ind = getExerciseIndex(searchedExercise)
        if (!ind) {
          return undefined
        }
        setExerciseIndex(ind)
        return searchedExercise
      }
      if (_.isNumber(index) && !_.isEmpty(allExercises?.[index])) {
        setExerciseIndex(index)
        return allExercises?.[index]
      }
      return undefined
    },
    [allExercises, getExerciseIndex],
  )

  const searchExerciseByText = useCallback(
    (text: string) => {
      const exerciseIds = _(allContent)
        .map((json: string, exerciseId: ExerciseId) => {
          if (json.includes(text)) {
            return Number(exerciseId)
          }
          return undefined
        })
        .compact()
        .value() as unknown as ExerciseId[]
      const firstExercise = _.find(allExercises, ["id", _.first(exerciseIds)])
      const firstIndex = getExerciseIndex(firstExercise)
      if (_.isUndefined(firstIndex)) {
        return undefined
      }
      setExerciseIndex(firstIndex)
      return {
        firstExercise,
        exerciseIds,
      }
    },
    [allContent, allExercises, getExerciseIndex],
  )

  const clearConsultationData = useCallback((): void => {
    setExerciseIndex(0)
    setIsConsultation(false)
    setExercisesLength(0)
    setPreviousConsultationData(undefined)
    // noinspection JSIgnoredPromiseFromCall
    showSnack(t(`${ns.CONSULTATION}.deactivate`))
  }, [showSnack, t])

  const showConsultationMode = useCallback(
    (
      numberOfExercises: number,
      currentExerciseAndRule?: ConsultationData,
    ): ExerciseWithRuleId | undefined => {
      setIsConsultation(true)
      setExercisesLength(numberOfExercises)
      setPreviousConsultationData(currentExerciseAndRule)
      showSnack("Mode consultation activé")
      return _.head(allExercises)
    },
    [allExercises, showSnack],
  )

  const contextValues: ContextData = {
    ExercisesState: [allExercises, setAllExercises],
    clearConsultationData,
    crucialQuestionSwitch,
    getExerciseIndex,
    isConsultation,
    previousConsultationData,
    searchExerciseByText,
    searchExerciseByKey,
    searchExerciseByNavigation,
    setExerciseIndex,
    showConsultationMode,
  }

  return (
    <ConsultationContext.Provider value={contextValues}>{children}</ConsultationContext.Provider>
  )
}

export const useConsultationMode = (): ContextData => {
  const context = useContext(ConsultationContext)
  if (_.isEmpty(context)) {
    throw new Error("useConsultationMode must be used within a ConsultationModeProvider")
  }
  return context
}
