import { colors } from "./colors"
import dimensions from "./dimensions"
import fonts from "./fonts"
import type { Theme } from "./ThemeProvider"

// @ts-ignore
const theme: Omit<Theme, "typography" | "typeGen" | "fontMaker"> = {
  fonts,
  colors,
  mode: "exact",
  dimensions,
  darkMode: false,
}

export default theme
