import type { SvgProps } from "react-native-svg"
import { Path, Svg } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

export const ChevronUpSVG = (props: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg
      {...props}
      width={props?.width ?? 24}
      height={props?.height ?? 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z"
        fill={props?.color ?? onSurface.mediumEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}
