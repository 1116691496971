import type {
  ClickOnMistakeExercise,
  ClickOnWordExercise,
  EmailClickOnMistakeExercise,
  EmailPickOneOutExercise,
  PickOutNearestExercise,
  PickOutOneExercise,
  Result,
} from "@newpv/js-common"
import {
  isClickOnEmail,
  isClickOnMistake,
  isClickOnWord,
  isEmailPickOutOne,
  isPickOutOne,
} from "@newpv/js-common"
import { Button } from "components/Button"
import { DOT_SIZE, FEEDBACK_DELAY } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useSound } from "hooks/useSound"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import PlayIconSVG from "svgs/PlayIcon"
import useTheme from "theme/ThemeProvider"
import { generateAudioExerciseUrl, speak } from "utils/audio-exercice"
import { rippleColor } from "utils/hexToRgba"

import CustomRenderHTML from "../CustomRenderHTML"
import { Overline, Subtitle1 } from "../Texts"
import CorrectionSentence from "./CorrectionSentence"

interface Props {
  exercise:
    | ClickOnMistakeExercise
    | PickOutOneExercise
    | PickOutNearestExercise
    | ClickOnWordExercise
    | EmailClickOnMistakeExercise
    | EmailPickOneOutExercise
  isDictation?: boolean
  result?: Result
}

const CorrectionContainer = ({ exercise, isDictation, result }: Props): JSX.Element => {
  const {
    colors: { onSurface, ripple },
  } = useTheme()
  const t = useTypedTranslation()
  const { onLayout, width } = useLayout()

  const cs = useCommonStyles()

  const { playMP3FromUrl } = useSound()
  const isEmailExercise = isEmailPickOutOne(exercise) || isClickOnEmail(exercise)

  const correctionIsSentence = isClickOnMistake(exercise) && isDictation && !exercise.hasMistake

  const hasCorrections =
    (isClickOnMistake(exercise) ||
      isClickOnWord(exercise) ||
      isEmailExercise ||
      isPickOutOne(exercise)) &&
    (exercise?.corrections?.length ?? 0) > 0

  const corrections = useMemo(
    () =>
      isClickOnMistake(exercise) ||
      isClickOnWord(exercise) ||
      isPickOutOne(exercise) ||
      isEmailExercise
        ? exercise?.corrections
        : undefined,
    [exercise, isEmailExercise],
  )

  const displayComment =
    result === "correct" && (isPickOutOne(exercise) || isEmailExercise) && !!exercise.comment

  const hasCommentOrCorrection = displayComment || hasCorrections || correctionIsSentence

  const s = useStyles(
    ({
      dimensions: { margin, spacing },
      colors: {
        primary_400,
        surface: { outline, surface },
      },
    }) => ({
      contentButton: {
        paddingHorizontal: spacing / 2,
        paddingVertical: spacing / 4,
      },
      button: {
        marginTop: margin / 2,
        borderRadius: 32,
        alignSelf: "flex-start",
        backgroundColor: surface,
        borderColor: outline,
        borderWidth: 1,
        color: onSurface.button,
      },
      correctionsContainer: {
        marginBottom: hasCommentOrCorrection ? 1.5 * margin : 0,
      },
      greenDot: {
        backgroundColor: primary_400,
        borderRadius: DOT_SIZE,
        height: DOT_SIZE,
        width: DOT_SIZE,
      },
    }),
    [hasCommentOrCorrection],
  )

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, FEEDBACK_DELAY / 2.5)
  }, [])

  const playPickOutOneAnswerSound = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    playMP3FromUrl(
      generateAudioExerciseUrl(
        exercise?.type,
        undefined,
        (exercise as PickOutOneExercise).answerSound,
      ),
      () => speak(exercise),
    )
  }, [exercise, playMP3FromUrl])

  return (
    <>
      {hasCorrections || correctionIsSentence || displayComment ? (
        <View style={cs.bottomSheetTitleContainer}>
          <View style={s.greenDot} />
          <Overline selectable={false} style={cs.bottomSheetTitle}>
            {t(displayComment ? "bottomSheet.comment" : "bottomSheet.correction")}
          </Overline>
        </View>
      ) : null}
      <View {...{ onLayout }} style={s.correctionsContainer}>
        {displayComment ? (
          <CustomRenderHTML
            customDefaultTextProps={{ selectable: false }}
            content={exercise.comment ?? ""}
            typographyVariant="subtitle1"
            width={width}
            overrideColor={onSurface.highEmphasis}
          />
        ) : hasCorrections ? (
          !mounted ? (
            <ActivityIndicator />
          ) : (
            corrections?.map((correction, correctionIndex) => (
              <CorrectionSentence
                wordBlocks={correction}
                baseTypography="subtitle1"
                Comp={Subtitle1}
                key={correctionIndex}
              />
            ))
          )
        ) : correctionIsSentence ? (
          <CorrectionSentence
            wordBlocks={exercise.sentence}
            baseTypography="subtitle1"
            Comp={Subtitle1}
          />
        ) : null}
        {isPickOutOne(exercise) && exercise.answerSound ? (
          <Button
            mode="contained-tonal"
            rippleColor={rippleColor(ripple)}
            onPress={playPickOutOneAnswerSound}
            icon={() => <PlayIconSVG color={onSurface.mediumEmphasis} />}
            style={s.button}
            contentStyle={s.contentButton}
          >
            {t(`common.button.listen`)}
          </Button>
        ) : null}
      </View>
    </>
  )
}

export default CorrectionContainer
