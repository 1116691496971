import type {
  EmailClickOnMistakeExercise,
  EmailContentData,
  EmailHeaderField,
  EmailPickOneOutExercise,
  Result,
} from "@newpv/js-common"
import { isClickOnEmail, isEmailPickOutOne } from "@newpv/js-common"
import BottomSheetAnswerOptions from "components/BottomSheet/customComponents/BottomSheetAnswerOptions"
import { useEmailButtonConfig } from "components/BottomSheet/hooks/useEmailButtonConfig"
import ClueView from "components/ClueView/ClueView"
import NoErrorButtonEmailClickOnMistake from "components/ExerciseContents/NoErrorButtonEmailClickOnMistake"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { TranslationKeysFR } from "i18n/i18next.fr.keys"
import _ from "lodash"
import { useExercise } from "providers/ExerciseProvider"
import type { FC } from "react"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"

import EmailClickOnMistakeRenderer from "./EmailClickOnMistakeRenderer"

interface IProps {
  exercise: EmailPickOneOutExercise | EmailClickOnMistakeExercise
  onSelectPress: (value: Result) => Promise<void>
  result?: Result
  isEvaluation?: boolean
}

// to generate the text value of the class attribute of the span
const generateClass = (
  bodyTextBlock: EmailContentData,
): "" | "mistake" | "clue" | "mistakeclue" => {
  let resultClass = ""
  if (bodyTextBlock?.mistake) {
    resultClass += "mistake"
  }
  if (bodyTextBlock?.clue) {
    resultClass += "clue"
  }
  return resultClass as "" | "mistake" | "clue" | "mistakeclue"
}

const emailHeaderFieldLabels: EmailHeaderField[] = [
  "from",
  "to",
  "cc",
  "cci",
  "object",
  "attachment",
]

const unremovableHeaderFields: EmailHeaderField[] = ["from", "to", "object"]

const EmailExercise: FC<IProps> = ({ exercise, onSelectPress, isEvaluation }) => {
  const t = useTypedTranslation()
  const { isSmallWidth } = useDeviceTools()
  const { onLayout, width } = useLayout()
  const {
    currentExercise: { isClueAllowed, isIntensiveTraining, intensiveTrainingExercises },
    emailPickOutOneState: { selectedOption, setSelectedOption },
  } = useExercise()
  const [headerPartWidth, setHeaderPartWidth] = useState<number>(0)

  const displayClue =
    isClueAllowed &&
    !isEvaluation &&
    !isIntensiveTraining &&
    (intensiveTrainingExercises == null || intensiveTrainingExercises?.length === 0)

  const cs = useCommonStyles()
  const s = useStyles(
    ({ colors: { secondary, surface, onSurface }, dimensions: { spacing } }) => ({
      area: {
        borderColor: surface.outline,
      },
      column: {
        flex: 1,
        flexDirection: "column",
        paddingRight: isEmailPickOutOne(exercise) ? spacing : undefined,
      },
      box: {
        borderRadius: spacing / 2,
        borderWidth: 1,
        padding: spacing,
      },
      clickOnEmailButtonContainer: {
        justifyContent: displayClue ? "space-between" : "flex-end",
      },
      fontHtml: {
        color: onSurface.highEmphasis,
      },
      headerContainer: {
        marginBottom: spacing,
        marginTop: isSmallWidth ? spacing : undefined,
      },
      headerValue: {
        paddingLeft: spacing / 4,
      },
      inlineHeaderItem: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },
      interestArea: {
        borderColor: secondary,
      },
      row: {
        flex: 1,
        flexDirection: "row",
        paddingTop: spacing,
      },
    }),
    [isSmallWidth, exercise, displayClue],
  )

  const isEmailPickOutOneExercise = isEmailPickOutOne(exercise)

  const focusArea = !isEmailPickOutOneExercise ? exercise.emailContent.isHighlighted : undefined

  const orderedHeaderFields: EmailContentData[][] = useMemo(
    () =>
      emailHeaderFieldLabels.map(
        fieldName => exercise.emailContent.header[fieldName] ?? [{ text: "" }],
      ),
    [exercise.emailContent.header],
  )

  const [pickedEmptyFieldsIndices, setPickEmptyFieldsIndices] = useState<number[]>()

  useEffect(() => {
    if (!isEmailPickOutOneExercise && exercise.emailContent && !pickedEmptyFieldsIndices) {
      setPickEmptyFieldsIndices(
        emailHeaderFieldLabels
          .map((label, index) => {
            const isThisFieldPicked = Math.random() < 1 / 3
            if (
              (!exercise.emailContent.header[label] ||
                exercise.emailContent.header[label]?.every(
                  e => !e.text && !e.mistake && !e.clue,
                )) &&
              isThisFieldPicked
            ) {
              return index
            }
            return
          })
          .filter(e => e != null) as number[],
      )
    }
  }, [
    exercise.emailContent,
    isEmailPickOutOneExercise,
    orderedHeaderFields,
    pickedEmptyFieldsIndices,
  ])

  const htmlHeaderContent = useMemo(
    () =>
      _.trimEnd(
        orderedHeaderFields
          .map((headerSection, index) => {
            const sectionLabel = emailHeaderFieldLabels[index]

            // if it's not an empty field OR if it's one of the randomly picked empty fields OR it's one of the mandatory header fields
            if (
              _.includes(unremovableHeaderFields, sectionLabel) ||
              headerSection.some(
                fieldContent => fieldContent.text || fieldContent.mistake || fieldContent.clue,
              ) ||
              (pickedEmptyFieldsIndices && pickedEmptyFieldsIndices.includes(index))
            ) {
              const fieldTranslationKey = `email.headers.${sectionLabel}` as TranslationKeysFR
              return `<span class="section"><span class="emailHeaderField">${t(
                fieldTranslationKey,
              )} </span>${headerSection
                .map(block => {
                  // mistake or clue or mistakeclue
                  const classes = generateClass(block)
                  const isBlockEmpty = block?.text === "" || !block.text
                  return `<span class="${
                    classes + (isBlockEmpty ? "empty" : "")
                    // ugly hack to be able to click on the area and more importantly to be able to underline and highlight it
                  }">${isBlockEmpty ? "__________________" : block.text}</span>`
                })
                ?.join("")}</span>${!isWeb ? "<br/>" : ""}`
            }
            return
          })
          .filter(e => e != null)
          .join(""),
        "<br/>",
      ),
    [orderedHeaderFields, t, pickedEmptyFieldsIndices],
  )

  const htmlBodyContent = useMemo(
    () =>
      exercise.emailContent.body
        .map(value => `<span class="${generateClass(value)}">${value.text}</span>`)
        .join(""),
    [exercise.emailContent.body],
  )

  const onValidationPress = useCallback(async () => {
    await onSelectPress(selectedOption?.answer.isAnswer ? "correct" : "wrong")
    setSelectedOption(undefined)
  }, [onSelectPress, selectedOption?.answer.isAnswer, setSelectedOption])

  const emailPickOutOneButtonConfig = useEmailButtonConfig({
    onPress: onValidationPress,
    disabled: selectedOption === undefined,
  })

  return (
    <View style={!isSmallWidth ? s.row : undefined}>
      <View style={!isSmallWidth ? s.column : undefined}>
        <View style={[s.box, s.headerContainer, focusArea === "header" ? s.interestArea : s.area]}>
          <View
            onLayout={({
              nativeEvent: {
                layout: { width: w },
              },
            }) => {
              setHeaderPartWidth(w)
            }}
          >
            <EmailClickOnMistakeRenderer
              key="header"
              htmlContent={htmlHeaderContent}
              width={headerPartWidth}
              isClickable={focusArea === "header"}
              {...{ onSelectPress, isEvaluation }}
            />
          </View>
        </View>
        <View style={[s.box, focusArea === "body" ? s.interestArea : s.area]}>
          <View {...{ onLayout }}>
            <EmailClickOnMistakeRenderer
              key="body"
              htmlContent={htmlBodyContent}
              isClickable={focusArea === "body"}
              {...{ width, onSelectPress, isEvaluation }}
            />
          </View>
        </View>
        {isClickOnEmail(exercise) ? (
          <View style={[cs.flexRow, s.clickOnEmailButtonContainer]}>
            {displayClue ? <ClueView isEmailExercise={isClickOnEmail(exercise)} /> : null}
            {!isSmallWidth ? (
              <NoErrorButtonEmailClickOnMistake {...{ exercise, onSelectPress }} />
            ) : null}
          </View>
        ) : null}
      </View>
      {!isSmallWidth && isEmailPickOutOne(exercise) ? (
        <View style={cs.fullFlex}>
          <BottomSheetAnswerOptions
            exerciseAnswerOptions={exercise.options}
            {...{ displayClue, emailPickOutOneButtonConfig }}
          />
        </View>
      ) : null}
    </View>
  )
}

export default memo(EmailExercise)
