import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"

export const ChartSVG = (props?: SvgProps): JSX.Element => (
  <Svg
    width={props?.width ?? iconSize.SMALL}
    height={props?.height ?? iconSize.SMALL}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <Path
      d="M21.333 15.707l5.654-9.774 2.306 1.334-6.973 12.066-8.68-5-6.36 11h22.053V28H2.667V4h2.666v19.387l7.333-12.72 8.667 5.04z"
      fill={props?.color ?? "#A2D416"}
    />
  </Svg>
)

export default ChartSVG
