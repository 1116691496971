/* eslint-disable no-underscore-dangle */
import Touchable from "components/Touchable/Touchable"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { usePreferences } from "providers/PreferenceProvider"
import { useCallback, useRef } from "react"
import { Animated, PanResponder, View } from "react-native"

import { Caption, H6 } from "../../Texts"

interface Props {
  isCorrect: boolean
  title?: string
  hiddenPartHeight: Animated.Value
}

const HANDLE_WIDTH = 80
const HANDLE_HEIGHT = 6

const TopSection = ({ hiddenPartHeight, isCorrect, title: propsTitle }: Props): JSX.Element => {
  const t = useTypedTranslation()
  const {
    preferenceState: { seeCorrection },
    dispatchPreferences,
  } = usePreferences()
  const title = propsTitle ?? t(`bottomSheet.${isCorrect ? "congrats" : "wrongAnswer"}`)
  const cs = useCommonStyles()

  const s = useStyles(
    ({
      colors: { primary_400, secondary_300, surface, onSurface, neutral_200 },
      dimensions: { spacing, margin },
      roundness,
    }) => ({
      caption: {
        maxWidth: 100,
        textAlign: "center",
        color: onSurface.button,
      },
      handle: {
        backgroundColor: surface.overlay,
        borderRadius: roundness,
        height: HANDLE_HEIGHT,
        marginVertical: margin / 2,
        width: HANDLE_WIDTH,
      },
      touchable: {
        left: spacing / 2,
        position: "absolute",
        top: spacing,
      },
      topSectionContainer: {
        alignItems: "center",
        backgroundColor: propsTitle ? neutral_200 : isCorrect ? primary_400 : secondary_300,
        width: "100%" as const,
      },
      title: {
        color: onSurface.button,
      },
    }),
    [isCorrect],
  )

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        // @ts-expect-error "hiddenPartHeight._value" exists
        hiddenPartHeight?.setOffset(hiddenPartHeight._value)
      },
      onPanResponderMove: (e, gesture) => {
        Animated.event([null, { dy: hiddenPartHeight }], {
          useNativeDriver: false,
        })(e, gesture)
      },
      onPanResponderRelease: () => {
        hiddenPartHeight.flattenOffset()
      },
    }),
  ).current

  const leaveSeeCorrectionMode = useCallback(() => {
    dispatchPreferences({ type: "set", name: "seeCorrection", value: false })
  }, [dispatchPreferences])

  return (
    <View
      {...panResponder.panHandlers}
      // @ts-expect-error
      style={[isWeb ? { cursor: "grab" } : {}, s.topSectionContainer]}
    >
      {seeCorrection && (
        <Touchable onPress={leaveSeeCorrectionMode} style={s.touchable}>
          <Caption selectable={false} style={s.caption}>
            {t("bottomSheet.studentMode")}
          </Caption>
        </Touchable>
      )}
      <View style={s.handle} />
      <H6 selectable={false} style={[cs.margin, s.title]}>
        {title}
      </H6>
    </View>
  )
}

export default TopSection
