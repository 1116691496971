import { Subtitle1 } from "components/Texts"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import type { TranslationKeys } from "hooks/useTypedTranslation"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { FC, ReactElement } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { Switch, View } from "react-native"
import useTheme from "theme/ThemeProvider"

interface IDeveloperModeSwitchItemProps {
  value: boolean
  onValueChange: (value: boolean) => void
  description: TranslationKeys
  icon?: ReactElement
  style?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<ViewStyle>
}

export const SwitchBasedItem: FC<IDeveloperModeSwitchItemProps> = ({
  onValueChange,
  value,
  description,
  icon,
  style,
  titleStyle,
}) => {
  const { darkMode, colors: themeColors } = useTheme()
  const t = useTypedTranslation()

  const switchTrackColor = themeColors.primary_400 + "61"
  const cs = useCommonStyles()

  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      title: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
      },
      switch: {
        marginLeft: spacing / 2,
      },
    }),
    [],
  )

  return (
    <View style={[cs.padding, cs.inlineRow, style]}>
      <View style={[s.title, titleStyle]}>
        {icon && <View style={cs.marginRight}>{icon}</View>}
        <Subtitle1 color={themeColors.onSurface.highEmphasis}>{t(description)}</Subtitle1>
      </View>
      <Switch
        // @ts-ignore
        activeThumbColor={themeColors.primary_400}
        ios_backgroundColor={value ? "transparent" : switchTrackColor}
        onValueChange={onValueChange}
        style={[cs.ripple, s.switch]}
        thumbColor={
          value
            ? themeColors.primary_400
            : darkMode
            ? themeColors.onSurface.highEmphasis
            : themeColors.surface.surface
        }
        trackColor={{ true: switchTrackColor, false: switchTrackColor }}
        value={value}
      />
    </View>
  )
}
