import type { ModuleId, ProgressionData, ScenarioId } from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import ModuleItem from "components/ModuleItem/ModuleItem"
import { H6 } from "components/Texts"
import { isWeb, scenarioImages } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { BottomState } from "providers/ModalProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { Dispatch, SetStateAction } from "react"
import { useCallback, useMemo } from "react"
import type { DimensionValue } from "react-native"
import { ScrollView, View } from "react-native"
import wait from "utils/wait"

// todo: most of these now come from `useScenarioAndModule`
interface Props {
  scenarioId: ScenarioId
  routeIndex: number
  scenarioName: string
  setGlobalModuleId: Dispatch<SetStateAction<ModuleId | undefined>>
  setDisplayedModuleId: Dispatch<SetStateAction<ModuleId | undefined>>
  setBottomModalState: Dispatch<SetStateAction<BottomState>>
  progressionData: ProgressionData | undefined
}

const ModuleList = ({
  scenarioName,
  setGlobalModuleId,
  setDisplayedModuleId,
  setBottomModalState,
  progressionData,
}: Props): JSX.Element => {
  const {
    preferenceState: { openAllLevelsAndModules },
  } = usePreferences()
  const { scenario, routeIndex, isProgressionLoading, progressionError } = useScenarioAndModule()

  const moduleList = useMemo(
    () =>
      scenario
        ? scenario?.routes?.[routeIndex ?? 0]?.modules.map(m => ({ id: m.id, title: m.title }))
        : [],
    [routeIndex, scenario],
  )

  const t = useTypedTranslation()
  const currentScenarioProgressionForModules = useMemo(
    () => progressionData?.progressionDetail?.modules,
    [progressionData],
  )
  const cs = useCommonStyles()
  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      container: {
        alignItems: "center",
        height: "100%",
        padding: spacing / 2,
        paddingBottom: 0,
        width: "100%" as const,
      },
      header: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        paddingBottom: spacing,
        paddingTop: spacing * 1.5,
        width: "100%" as const,
        flexWrap: "wrap",
      },
      title: {
        paddingLeft: spacing * 1.5,
        textAlign: "center",
        maxWidth: isWeb ? ("fit-content" as DimensionValue) : undefined,
        textAlignVertical: "center",
        alignItems: "center",
        flex: 1,
      },
    }),
    [],
  )

  const handleOnPress = useCallback(
    async (id: ModuleId) => {
      setDisplayedModuleId(id)
      setGlobalModuleId(id)
      await wait(400)
      setBottomModalState(BottomState.HIDE)
    },
    [setDisplayedModuleId, setGlobalModuleId, setBottomModalState],
  )

  const isModuleDisabled = useCallback(
    (moduleId: ModuleId) => {
      const interlockMode = scenario ? scenario?.routes?.[routeIndex ?? 0]?.interlock : "free"
      const pvIndex = _.findIndex(moduleList, ["id", moduleId]) - 1
      if (pvIndex < 0 || openAllLevelsAndModules) {
        return false
      }
      if (currentScenarioProgressionForModules && interlockMode === "interblock") {
        return (
          (currentScenarioProgressionForModules?.[moduleList[pvIndex].id]?.completionPercentage ??
            0) < 100
        )
      }
      return false
    },
    [
      currentScenarioProgressionForModules,
      moduleList,
      openAllLevelsAndModules,
      routeIndex,
      scenario,
    ],
  )

  return (
    <View style={s.container}>
      {isProgressionLoading ? (
        <ActivityIndicator />
      ) : progressionError ? (
        <ErrorPanel
          title={t("Level.moduleErrors.errorTitle")}
          description={t("Level.moduleErrors.errorTitle")}
        />
      ) : (
        <>
          <View style={s.header}>
            {scenario?.applicationCode
              ? scenarioImages[scenario.applicationCode]
                ? scenarioImages[scenario.applicationCode]()
                : null
              : null}
            <H6 style={s.title}>{scenarioName}</H6>
          </View>
          {moduleList && moduleList?.length > 0 ? (
            <ScrollView
              style={cs.viewContainerWithMarginTop}
              contentContainerStyle={cs.paddingBottom}
            >
              {moduleList.map(module => (
                <ModuleItem
                  moduleId={module.id}
                  key={module.id}
                  percent={
                    progressionData?.progressionDetail.modules[module.id ?? ""]
                      ?.completionPercentage ?? 0
                  }
                  title={module.title}
                  onPress={() => handleOnPress(module.id)}
                  disabled={isModuleDisabled(module?.id)}
                />
              ))}
            </ScrollView>
          ) : null}
        </>
      )}
    </View>
  )
}

export default ModuleList
