import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg"

const RevisionLevelSVG = (props?: SvgProps): JSX.Element => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <G clipPath="url(#clip0_4222_239995)">
      <Rect width={56} height={56} rx={8} fill={props?.color ?? "#FFE7B4"} />
      <Path
        d="M116.134 77.397c-7.376-12.907-18.93-22.939-31.672-30.083-12.663-7.31-26.843-12.296-41.327-14.987-7.201-1.223-14.56-2.116-21.828-1.54-3.552.326-7.185.818-10.323 2.361-1.53.69-2.652 1.778-3.126 3.178-.473 1.4-.38 2.87.52 4.32 1.718 2.657 4.883 4.382 8.203 5.368 3.321.987 6.877 1.068 10.347.498 3.47-.57 6.853-1.792 9.747-3.657 2.893-1.864 5.377-4.536 7.21-7.52a35.256 35.256 0 004.124-9.821c.86-3.453 1.155-6.974.563-10.311-.592-3.337-2.151-6.325-4.515-8.886-2.202-2.483-5.208-4.539-8.21-6.186-6.168-3.373-13.213-5.336-20.255-6.89A212.223 212.223 0 00-6.087-9.856c-14.554-1.3-29.343-1.696-44.208-1.517-7.433.089-14.704.256-22.133.754-7.35.332-14.78.83-22.209 1.328l-.324-.156.159-.33c7.423-1.316 14.687-2.3 22.113-3.206 7.346-.741 14.855-1.404 22.284-1.902 7.43-.498 14.863-.587 22.295-.676 7.433-.09 14.948.065 22.388.793 7.439.728 14.878 1.455 22.244 3.166 7.367 1.71 14.736 3.83 21.474 7.679 3.327 1.803 6.578 4.18 9.269 7.306 2.691 3.125 4.499 6.843 5.34 10.91.678 3.99.228 8.25-.792 12.032-1.02 3.783-2.607 7.497-4.842 10.9-2.235 3.402-5.039 6.327-8.416 8.366-3.457 2.204-7.244 3.436-11.118 4.015-3.873.58-7.916.264-11.56-.878-1.944-.528-3.729-1.386-5.434-2.409s-3.25-2.377-4.317-4.314c-.572-.886-.824-2.024-1.075-3.163-.09-1.06.142-2.373.457-3.443.87-2.226 2.797-3.742 4.486-4.761 3.702-1.884 7.496-2.298 11.373-2.469 7.591-.42 15.113.552 22.397 2.02 14.567 2.933 28.75 8.328 41.496 15.88 12.746 7.554 24.22 17.75 31.437 30.988.162.078.083.243-.159.33-.242.088-.404.01-.404.01z"
        fill={props?.color ?? "#F08C03"}
      />
      <Path
        d="M9.911-23.838c4.92 3.244 8.978 7.618 11.865 13.399 1.289 3.028 2.393 6.426 2.697 10.49a28.855 28.855 0 01-.437 7.077c-.495 2.688-1.544 5.976-3.886 9.81-2.342 3.833-5.792 7.333-8.748 9.42-2.956 2.088-5.357 3.067-7.634 3.883-4.494 1.426-8.063 1.257-11.262.604-3.077-.815-5.845-2.161-8.058-4.106-2.337-1.784-4.18-4.213-5.778-6.965-.062-.047.061-.209.185-.37.123-.162.184-.115.184-.115 4.92 3.244 10.025 6.119 16.363 7.123 1.6.199 3.2.398 4.923.435a18.737 18.737 0 005.477-.674c1.97-.54 4.002-1.29 6.28-2.873 2.155-1.42 4.496-3.723 6.098-6.332 1.664-2.563 2.652-5.387 3.146-7.82.495-2.433.62-4.637.56-6.727-.057-4.132-.977-7.645-2.082-10.787C17.534-14.7 13.846-19.558 9.48-23.4c-.061-.047.062-.209.062-.209l.37-.229z"
        fill={props?.color ?? "#F08C03"}
      />
    </G>
    <Rect
      x={0.5}
      y={0.5}
      width={55}
      height={55}
      rx={7.5}
      stroke={props?.color ?? "#050628"}
      strokeOpacity={0.16}
    />
    <Defs>
      <ClipPath id="clip0_4222_239995">
        <Rect width={56} height={56} rx={8} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default RevisionLevelSVG
