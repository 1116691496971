import type { EmailPickOutOneOption, Exercise, Result, Rule } from "@newpv/js-common"
import {
  isClickOnEmail,
  isClickOnMistake,
  isClickOnWord,
  isEmailPickOutOne,
  isPickOutNearest,
  isPickOutOne,
} from "@newpv/js-common"
import CustomRenderHTML from "components/CustomRenderHTML"
import { Body2 } from "components/Texts"
import { maxScreenWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { TabBottomSheetKeys } from "models/TabInterfaces"
import useAuthContext from "providers/AuthProvider"
import { useTab } from "providers/TabProvider"
import type { FC } from "react"
import { useMemo } from "react"
import { View } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { removeAllMarkup } from "utils/parseSentence"

import CorrectionContainer from "../../Correction/CorrectionContainer"
import DefinitionList from "../../DefinitionList"
import MemoContainer from "./MemoContainer"
import RuleContainer from "./RuleContainer"
import VideoContainer from "./VideoContainer"

interface IProps {
  result?: Result
  rule?: Omit<Rule, "exercises">
  exercise?: Exercise
  isIntensiveTraining?: boolean
  intensiveCount?: number
  isDictation?: boolean
  emailPickOutOneOptions?: EmailPickOutOneOption[]
  selectedOption?: {
    answer: EmailPickOutOneOption
    index: number
  }
}

const BottomSheetTabsContent: FC<IProps> = ({
  result,
  exercise,
  rule,
  isIntensiveTraining: isIntensiveTrainingProp = false,
  intensiveCount,
  isDictation,
  selectedOption,
  emailPickOutOneOptions,
}) => {
  const {
    darkMode,
    colors: { onSurface },
  } = useTheme()
  const { onLayout, width } = useLayout()
  const { currentTab } = useTab()
  const cs = useCommonStyles()
  const { authenticationType } = useAuthContext()

  const s = useStyles(
    ({
      dimensions: { margin, spacing },
      colors: { secondary, secondary_50, surface, primary_50, primary },
      roundness,
    }) => ({
      correctOption: {
        backgroundColor: darkMode ? primary : primary_50,
        borderColor: primary,
      },
      optionContainer: {
        marginHorizontal: margin,
        maxWidth: maxScreenWidth,
        marginTop: margin,
      },
      option: {
        padding: spacing / 2,
        borderWidth: 1,
        borderColor: surface.outline,
        borderRadius: roundness,
        marginBottom: margin / 2,
        width: "100%" as const,
      },
      optionText: {
        textAlign: "center",
      },
      wrongAnswer: {
        backgroundColor: secondary_50,
        borderColor: secondary,
      },
    }),
    [],
  )

  const videoIndex = useMemo(() => {
    const numberOfAvailableVideo = rule?.resources?.videos?.length ?? 0
    if (intensiveCount && numberOfAvailableVideo > 0) {
      const intensiveCountModulo = intensiveCount % 4
      if (intensiveCountModulo === 0 || intensiveCountModulo === 3) {
        return 0
      } else if (numberOfAvailableVideo > 1) {
        return 1
      }
    }
    return 0
  }, [intensiveCount, rule?.resources])

  const renderInnerContent = useMemo(() => {
    if (!currentTab) {
      return null
    }
    switch (currentTab?.index as TabBottomSheetKeys) {
      case TabBottomSheetKeys.rule: {
        return (
          <>
            {isEmailPickOutOne(exercise) && emailPickOutOneOptions ? (
              <View style={s.optionContainer}>
                {emailPickOutOneOptions.map((option, index) => (
                  <View
                    key={`option-${index}`}
                    style={[
                      s.option,
                      selectedOption?.index === index
                        ? result === "correct"
                          ? s.correctOption
                          : s.wrongAnswer
                        : null,
                      option.isAnswer ? s.correctOption : null,
                    ]}
                  >
                    <Body2
                      style={[s.optionText]}
                      color={
                        option.isAnswer || selectedOption?.answer.text === option.text
                          ? onSurface.button
                          : onSurface.mediumEmphasis
                      }
                    >
                      {option.text}
                    </Body2>
                  </View>
                ))}
              </View>
            ) : null}
            {!isIntensiveTrainingProp &&
            (isClickOnMistake(exercise) ||
              isPickOutOne(exercise) ||
              isClickOnWord(exercise) ||
              isClickOnEmail(exercise) ||
              isEmailPickOutOne(exercise)) ? (
              <CorrectionContainer {...{ exercise, isDictation, result }} />
            ) : null}
            {rule ? (
              <RuleContainer
                isDefinition={isPickOutNearest(exercise)}
                rule={
                  isPickOutNearest(exercise)
                    ? {
                        ...rule,
                        title: exercise.instructionWord,
                        description: exercise.exampleSentence ?? "",
                      }
                    : rule
                }
              />
            ) : null}
          </>
        )
      }
      case TabBottomSheetKeys.memo: {
        return rule?.id ? <MemoContainer ruleId={rule.id} /> : null
      }
      case TabBottomSheetKeys.video: {
        return <VideoContainer uri={rule?.resources?.videos?.[videoIndex] ?? undefined} />
      }
      case TabBottomSheetKeys.note: {
        // TODO: remove this when possible. It is a temporary workaround because the data for definitions was not parsed in the provided data
        if (isPickOutNearest(exercise)) {
          const parsedStringToDefinitions = (exercise.definitions?.[0] as unknown as string)
            .replace("\n", "")
            .split('<div class="def">')
            .filter(e => e)
            .map(subString => {
              const subStringSplit = subString.split('<div class="ex">')
              const obj: any = {}
              obj.definition = removeAllMarkup(subStringSplit[0].trim())
              if (subStringSplit[1]) {
                obj.example = removeAllMarkup(subStringSplit[1].split("</div>")[0].trim())
              }

              return obj
            })

          return <DefinitionList definitions={parsedStringToDefinitions} />
        }

        return rule?.resources?.lesson ? (
          <CustomRenderHTML
            content={rule?.resources?.lesson}
            width={width}
            typographyVariant="body2"
            overrideColor={onSurface.highEmphasis}
            hideExternalLinks={
              authenticationType === "ecoledirecte" || authenticationType === "gar"
            }
          />
        ) : null
      }
    }
  }, [
    currentTab,
    exercise,
    emailPickOutOneOptions,
    s.optionContainer,
    s.option,
    s.correctOption,
    s.wrongAnswer,
    s.optionText,
    isIntensiveTrainingProp,
    isDictation,
    result,
    rule,
    selectedOption?.index,
    selectedOption?.answer.text,
    onSurface.button,
    onSurface.mediumEmphasis,
    onSurface.highEmphasis,
    videoIndex,
    width,
    authenticationType,
  ])

  return !isIntensiveTrainingProp && result === undefined ? (
    <ActivityIndicator color={onSurface.disabled} size="large" />
  ) : (
    <View {...{ onLayout }} style={cs.padding}>
      {renderInnerContent}
    </View>
  )
}
export default BottomSheetTabsContent
