import { CIRCLE_STEP } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import type { FC } from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

interface IProps {
  size?: number
  disabled?: boolean
}

const CircleStep: FC<IProps> = ({ size, disabled = true, ...props }) => {
  const {
    darkMode,
    colors: { surface, primary_400 },
  } = useTheme()
  const cs = useCommonStyles()
  return (
    <View style={cs.center}>
      <Svg
        {...props}
        width={size ?? CIRCLE_STEP}
        height={size ?? CIRCLE_STEP}
        viewBox={`0 0 ${CIRCLE_STEP} ${CIRCLE_STEP}`}
      >
        <Path
          d="M0 8c0-4.416 3.584-8 8-8s8 3.584 8 8-3.584 8-8 8-8-3.584-8-8zm3 0c0 2.762 2.237 5 5 5 2.762 0 5-2.238 5-5 0-2.763-2.238-5-5-5-2.763 0-5 2.237-5 5z"
          fill={disabled ? (darkMode ? surface.overlayProgression : surface.overlay) : primary_400}
        />
      </Svg>
    </View>
  )
}

export default CircleStep
