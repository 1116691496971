import Snackbar from "components/Snackbar/Snackbar"
import { edges, FEEDBACK_DELAY } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import type { FC, PropsWithChildren } from "react"
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { StyleSheet, View } from "react-native"
import { Snackbar as PaperSnackbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

export interface ISnackInternal {
  message: string
  duration: number
  visible: boolean
  onDismiss: () => void
  resolve?: (value: boolean) => void
}

interface ContextData {
  showSnack: (text: string) => Promise<boolean>
  notImplemented: () => void
}

const debugContext = createContext<ContextData>({} as ContextData)

export const DebugProvider: FC<PropsWithChildren<{ hasInset?: boolean }>> = ({
  children,
  hasInset,
}) => {
  const mounted = useRef(false)
  const t = useTypedTranslation()
  const cs = useCommonStyles()
  const [snackList, setSnackList] = useState<ISnackInternal[]>([])
  const [currentSnack, setCurrentSnack] = useState<ISnackInternal | undefined>(undefined)

  /** Available only for debug/test mode */
  useEffect(() => {
    if (snackList.length >= 1) {
      setCurrentSnack({ ...snackList[0], visible: true })
    }
    // in this case we really want to depend on snackList, that's what triggers showing the snack
  }, [snackList])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const onSnackInternalDismiss = useCallback(() => {
    setCurrentSnack(s => (s ? { ...s, visible: false } : s))
    if (mounted.current) {
      setTimeout(() => setSnackList(oldSnackList => _.slice(oldSnackList, 1)), FEEDBACK_DELAY)
    }
  }, [setCurrentSnack])

  const showSnack = useCallback(
    (text: string) =>
      new Promise<boolean>(resolve => {
        const newSnack: ISnackInternal = {
          message: text,
          visible: true,
          duration: PaperSnackbar.DURATION_SHORT,
          onDismiss: () => {
            onSnackInternalDismiss()
            resolve(true)
          },
        }
        setSnackList(oldSnackList => [...oldSnackList, newSnack])
      }),
    [onSnackInternalDismiss],
  )

  const notImplemented = useCallback(() => showSnack(t("common.notImplemented")), [showSnack, t])

  return (
    <debugContext.Provider value={{ notImplemented, showSnack }}>
      <View style={cs.fullFlex}>{children}</View>
      {currentSnack?.visible ? (
        <SafeAreaView edges={edges.BOTTOM} style={styles.snack}>
          <Snackbar snack={currentSnack} {...{ hasInset }} />
        </SafeAreaView>
      ) : null}
    </debugContext.Provider>
  )
}

const styles = StyleSheet.create({
  snack: {
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
  },
})

export const useDebug = (): ContextData => {
  const context = useContext(debugContext)
  if (_.isEmpty(context)) {
    throw new Error("useDebug must be used within a DebugProvider")
  }
  return context
}

export default DebugProvider
