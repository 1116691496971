import type { ParamListBase } from "@react-navigation/core"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import type { StackNavigationOptions } from "@react-navigation/stack"
import { createStackNavigator } from "@react-navigation/stack"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import HomeHeader from "components/Headers/HomeHeader"
import { ModalHeader } from "components/ModalScreen/ModalHeader"
import ModalScreen from "components/ModalScreen/ModalScreen"
import WebPage from "components/WebPage"
import useBackImage from "hooks/useBackImage"
import { useRootEvaluation } from "hooks/useRootEvaluation"
import { useRootStorage } from "hooks/useRootStorage"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import useAuthContext from "providers/AuthProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { IconButton } from "react-native-paper"
import EvaluationScreen from "screens/EvaluationScreen/EvaluationScreen"
import { ExerciseWrapper } from "screens/ExerciseScreen/ExerciseScreen"
import HomeScreen from "screens/HomeScreen/HomeScreen"
import LevelsScreen from "screens/LevelsScreen/LevelsScreen"
import LoadingScreen from "screens/LoadingScreen/LoadingScreen"
import { LoggedOutScreen } from "screens/LoggedOutScreen/LoggedOutScreen"
import { LoginScreen } from "screens/LoginScreen/LoginScreen"
import OAuthLoginScreen from "screens/OAuthLoginScreen/OAuthLoginScreen"
import PresentialScreen from "screens/PresentialScreen/PresentialScreen"
import { ProfileScreen } from "screens/ProfileScreen/ProfileScreen"
import { SettingsScreen } from "screens/SettingsScreen/SettingsScreen"
import { TokenLoginScreen } from "screens/TokenLoginScreen/TokenLoginScreen"
import useTheme from "theme/ThemeProvider"
import Url from "url-parse"
import { logger } from "utils/logger"
import CommonHeader from "wrappers/HeaderWrapper/CommonHeader"

import type { RootRouteNames } from "./Constants"

export interface IRootParamList extends ParamListBase {
  Home: undefined
  Level: undefined
  Exercise: undefined
  Evaluation: undefined
  PracticeTest: undefined
  Profile: undefined
  Settings: undefined
  Login: { login?: string; password?: string } | undefined
  TokenLogin: { jwt: string; scenarioId: string }
  GarLogin: { scenarioId?: string; ticket?: string }
  OAuthLogin: undefined
  Loading: undefined
  Presential: { rules: string }
  WebPage: { uri: string }
}

const rootNavigator = createStackNavigator<IRootParamList>()

const RootNavigator: FC = () => {
  const t = useTypedTranslation()
  const {
    colors: { onSurface, secondary, surface },
  } = useTheme()
  const backImage = useBackImage(onSurface.mediumEmphasis)
  const { authError, token, authenticationType, authScenarioId: scenarioId } = useAuthContext()
  const { scenario, routeIndex, isScenarioLoading, scenarioError, isProgressionLoading, moduleId } =
    useScenarioAndModule()
  const { isInitialEvaluationCompleted, examination, levelId } = useLevelAndEvaluation()
  const { loadingStorage, initialRouteName, initialUrl } = useRootStorage()
  const { isEvalLoading } = useRootEvaluation(token)
  const loading =
    isEvalLoading ||
    isScenarioLoading ||
    isProgressionLoading ||
    isInitialEvaluationCompleted == null
  const linkTo = useLinkTo()

  if (loading) {
    logger("RootNavigator", {
      isEvalLoading,
      isScenarioLoading,
      isProgressionLoading,
      isInitialEvaluationCompleted,
    })
  }

  const nav = useNavigation()
  const { isQA } = useAuthContext()
  const initialRouteSet = useRef(false)

  const getOptions = useCallback(
    (screenName: RootRouteNames) =>
      ({
        title: t(`common.title.${screenName}`),
        headerShown: false,
      } as StackNavigationOptions),
    [t],
  )

  const navScreens = useMemo(() => {
    logger("RootNavigator states", {
      loadingStorage,
      initialRouteName,
      isInitialEvaluationCompleted,
      levelId,
      loading,
      moduleId,
      examination,
      routeIndex,
      hasScenario: !!scenario,
      scenarioError,
      authError,
      scenarioId,
      hasToken: token !== null,
      authenticationType,
    })

    const s = _([
      token
        ? [
            isQA && scenarioId == null ? (
              <rootNavigator.Screen
                key="home"
                name={ns.HOME}
                component={HomeScreen}
                options={{
                  title: t("common.title.Home"),
                  header: props => <HomeHeader props={props} isHome={true} isQA={isQA} />,
                }}
              />
            ) : null,
            (scenario == null && scenarioId != null) || loading || scenarioError || authError ? (
              <rootNavigator.Screen
                key="loading"
                name={ns.LOADING}
                options={getOptions(ns.LOADING)}
              >
                {() => <LoadingScreen error={authError ?? scenarioError} {...{ loading }} />}
              </rootNavigator.Screen>
            ) : null,
            examination || isInitialEvaluationCompleted === false ? (
              /* next evaluation (start or resume), or initial evaluation */
              <rootNavigator.Screen
                key="eval"
                name={ns.EVALUATION}
                options={getOptions(ns.EVALUATION)}
              >
                {() => (
                  <EvaluationScreen
                    // initial evaluation has priority over next evaluation
                    evaluationType={
                      isInitialEvaluationCompleted === false
                        ? "initial_evaluation"
                        : "next_evaluation"
                    }
                  />
                )}
              </rootNavigator.Screen>
            ) : null,
            scenario != null &&
            examination == null &&
            isInitialEvaluationCompleted === true &&
            !loading
              ? [
                  <rootNavigator.Screen
                    key="level"
                    name={ns.LEVEL}
                    component={LevelsScreen}
                    options={{
                      header: props => <HomeHeader props={props} isQA={isQA} />,
                      title: t("common.title.Level"),
                    }}
                  />,
                  <rootNavigator.Screen
                    key="webpage"
                    options={{
                      title: t("common.title.WebPage"),
                      headerStatusBarHeight: undefined,
                    }}
                    name={ns.WEB_PAGE}
                    component={WebPage}
                  />,
                  levelId ? (
                    <rootNavigator.Screen
                      key="exercise"
                      name={ns.EXERCISE}
                      options={getOptions(ns.EXERCISE)}
                    >
                      {() => <ExerciseWrapper />}
                    </rootNavigator.Screen>
                  ) : null,

                  routeIndex != null ? (
                    /* Check on routeIndex because it's necessary to fetch list of modules */ <rootNavigator.Screen
                      key="profile"
                      name={ns.PROFILE}
                      options={() => ({
                        header: () => null,
                        title: t("common.title.Profile"),
                      })}
                    >
                      {({ navigation }) => (
                        <ModalScreen
                          headerShown={true}
                          modalHeader={() => (
                            <ModalHeader navigation={navigation} routeName="profile" />
                          )}
                        >
                          <ProfileScreen />
                        </ModalScreen>
                      )}
                    </rootNavigator.Screen>
                  ) : null,
                  <rootNavigator.Screen
                    key="settings"
                    name={ns.SETTINGS}
                    options={() => ({
                      header: () => null,
                      title: t("common.title.Settings"),
                    })}
                  >
                    {({ navigation }) => (
                      <ModalScreen
                        route="settings"
                        headerShown={true}
                        modalHeader={() => (
                          <ModalHeader navigation={navigation} routeName="settings" />
                        )}
                      >
                        <SettingsScreen isModal={true} />
                      </ModalScreen>
                    )}
                  </rootNavigator.Screen>,
                  <rootNavigator.Screen
                    key="practice"
                    name={ns.PRACTICE_TEST}
                    options={getOptions(ns.PRACTICE_TEST)}
                  >
                    {() => <EvaluationScreen evaluationType="practice_test" />}
                  </rootNavigator.Screen>,
                ]
              : null,
            scenarioId == null ? (
              <rootNavigator.Screen
                key="no_scenario_settings"
                name={ns.SETTINGS}
                component={SettingsScreen}
                options={{
                  title: t("common.title.Settings"),
                  headerLeft: ({ onPress }) => (
                    <IconButton
                      {...{ onPress }}
                      containerColor={surface.surface}
                      icon={() => backImage}
                    />
                  ),
                  header: CommonHeader,
                }}
              />
            ) : null,
          ]
        : null,
      token == null && (authenticationType === "ecoledirecte" || authenticationType === "gar") ? (
        <rootNavigator.Screen
          key="loggedOutScreen"
          name={ns.LOGGED_OUT}
          component={LoggedOutScreen}
          options={getOptions(ns.LOGGED_OUT)}
        />
      ) : null,
      token == null && isQA ? (
        <rootNavigator.Screen
          key="login"
          name={ns.LOGIN}
          component={LoginScreen}
          options={getOptions(ns.LOGIN)}
        />
      ) : null,
      token == null || scenarioId == null
        ? [
            <rootNavigator.Screen
              key="oauth_login"
              name={ns.OAUTH_LOGIN}
              component={OAuthLoginScreen}
              options={getOptions(ns.OAUTH_LOGIN)}
            />,
            <rootNavigator.Screen
              key="token_login"
              name={ns.TOKEN_LOGIN}
              component={TokenLoginScreen}
              options={getOptions(ns.TOKEN_LOGIN)}
            />,
          ]
        : null,
      <rootNavigator.Screen
        key="presential"
        name={ns.PRESENTIAL}
        component={PresentialScreen}
        options={getOptions(ns.PRESENTIAL)}
      />,
    ])
      .flattenDeep()
      .compact()
      .value()

    logger(
      "RootNavigator screen keys",
      s.map(ss => ss.key),
    )

    return s
  }, [
    loadingStorage,
    initialRouteName,
    isInitialEvaluationCompleted,
    levelId,
    loading,
    moduleId,
    examination,
    routeIndex,
    scenario,
    scenarioError,
    authError,
    scenarioId,
    token,
    authenticationType,
    isQA,
    t,
    getOptions,
    surface.surface,
    backImage,
  ])
  useEffect(() => {
    if (
      initialRouteName &&
      !initialRouteSet.current &&
      navScreens.find(s => s.props.name === initialRouteName)
    ) {
      initialRouteSet.current = true
      // nav.navigate(initialRouteName)
      logger("RootNavigator disabled auto nav to", initialRouteName)
    }
  }, [initialRouteName, nav, navScreens])

  useEffect(() => {
    if (initialUrl != null && !loadingStorage) {
      const url = new Url(initialUrl, {})
      try {
        linkTo(`${url.pathname}${url.query}`)
      } catch (e) {
        logger(`Tried navigating to ${url} but failed (usually not a problem)`)
      }
    }
  }, [initialUrl, linkTo, loadingStorage])

  return loadingStorage && !initialRouteName ? (
    <ActivityIndicator color={secondary} />
  ) : (
    <rootNavigator.Navigator
      screenOptions={{
        headerStatusBarHeight: 0,
        headerBackTitleVisible: false,
      }}
    >
      {navScreens}
    </rootNavigator.Navigator>
  )
}

export default RootNavigator
