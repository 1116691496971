import "react-native-get-random-values"

import type {
  BackExamination,
  BaseEngine,
  EmailPickOutOneOption,
  EvaluationEngine,
  ExaminationId,
  Exercise,
  ExerciseId,
  IntensiveTrainingExercise,
  Interaction,
  LevelStatus,
  MilliSeconds,
  PickOutOneWordBlock,
  RecordInteractionResult,
  Rule,
  RuleMetadata,
  Seconds,
  TrainingBlockId,
  TrainingInteraction,
  UniqueCopyId,
} from "@newpv/js-common"
import {
  apiUrl,
  axios,
  gradeOn20Improved,
  makeEngine,
  makeEvaluationEngine,
  on20withHalfPoints,
} from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import type { HeaderHandle } from "components/Headers"
import { isAxiosError } from "constants/constants"
import useExerciseStates from "hooks/useExerciseStates"
import useTrainingOrEvalModals from "hooks/useTrainingOrEvalModals"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { isEvaluationEngine } from "models/EngineHelperFunctions"
import {
  loadAllPreviousTrainingInteractions,
  loadResumedInitialEvaluationInteractions,
  loadResumedNextEvaluationInteractions,
  loadResumedPracticeTestInteractions,
  sendExaminationTickleSafe,
} from "models/ExerciseProviderFunctions"
import type { ModalResponse } from "models/ModalInterfaces"
import { ButtonType, ModalType } from "models/ModalInterfaces"
import { getExercisesByRuleId, getExercisesWithRuleId } from "models/ModuleFunctions"
import useAuthContext from "providers/AuthProvider"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import { useDebug } from "providers/DebugProvider"
import { useEvaluation } from "providers/EvaluationProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useServer } from "providers/ServerProvider"
import type {
  Dispatch,
  FC,
  MutableRefObject,
  PropsWithChildren,
  RefObject,
  SetStateAction,
} from "react"
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { logger } from "utils/logger"
import { v4 as uuidv4 } from "uuid"

export interface ContextData {
  currentExercise: {
    uniqueCopyId?: TrainingBlockId | ExaminationId // both are strings
    rule?: Rule
    exercise?: Exercise
    isCrucialQuestion?: boolean
    isExplanationAllowed?: boolean
    getRandomWordsFromOtherExercises: (n: number) => PickOutOneWordBlock[] | undefined
    isClueAllowed?: boolean
    isClueVisible: boolean
    setIsClueVisible: Dispatch<SetStateAction<boolean>>
    isIntensiveTraining: boolean
    setIsIntensiveTraining: Dispatch<SetStateAction<boolean>>
    intensiveTrainingExercises?: IntensiveTrainingExercise[]
    resetIntensiveTrainingExercises: () => void
    intensiveCount?: number
  }
  levelState: {
    progress?: number
    nbOfAcquiredRules: number
    totalNumberOfRulesInLevel: number
    initialNbOfAcquiredRulesRef?: MutableRefObject<number | undefined>
    totalNumberOfExercises: number
  }
  consultation?: {
    closeConsultationView: () => void
    openConsultationView: () => void
    searchExercise: (isNext?: boolean) => void
    searchExerciseByIdOrIndex: (id?: ExerciseId, index?: number) => void
    searchExerciseByContent: (text: string) => ExerciseId[] | undefined
  }
  audio: {
    exerciseAudio: MutableRefObject<boolean | undefined>
    playExerciseAudio: (rule?: Omit<Rule, "exercises">, exercise?: Exercise) => void
    toggleLevelAudio: () => void
  }
  emailPickOutOneState: {
    setSelectedOption: Dispatch<
      SetStateAction<
        | {
            answer: EmailPickOutOneOption
            index: number
          }
        | undefined
      >
    >
    emailPickOutOneOptions: EmailPickOutOneOption[]
    selectedOption?: {
      answer: EmailPickOutOneOption
      index: number
    }
  }
  headerRef: RefObject<HeaderHandle>
  getResults: (isUncompleted: boolean) => {
    score?: number
    total?: number
    masteredRules: string[]
    unmasteredRules: string[]
  }
  displayNextTrainingExercise: (isAnswerCorrect?: boolean) => Promise<void>
  displayNextEvaluationExercise: (isAnswerCorrect?: boolean) => Promise<void>
  closeEvaluation: () => Promise<boolean>
  closeTraining: ({
    isTraining,
    status,
    overrideUniqueCopyId,
  }: {
    isTraining: boolean
    status?: LevelStatus
    overrideUniqueCopyId?: UniqueCopyId
  }) => void
}

export const exerciseContext = createContext<ContextData>({} as ContextData)

export const ExerciseProvider: FC<
  PropsWithChildren<{
    isEvaluation?: boolean
  }>
> = ({ children, isEvaluation }) => {
  const t = useTypedTranslation()
  const { isQA } = useAuthContext()

  // _ OTHER CONTEXTS _______________________________________________________________________________
  const {
    ExercisesState: [, setAllExercises],
    clearConsultationData,
    crucialQuestionSwitch: [consultationCritical],
    isConsultation,
    previousConsultationData,
    searchExerciseByKey,
    searchExerciseByNavigation,
    searchExerciseByText,
    showConsultationMode,
  } = useConsultationMode()
  const { showSnack } = useDebug()
  const {
    timeIsUp,
    timerElapsedTime,
    updateRemainingTime,
    evaluationType,
    timeLimit,
    evalRules,
    evalExercises,
    isTickleOn,
    setIsTickleOn,
    start: startTimer,
    stop: stopTimer,
  } = useEvaluation()
  const {
    levelId,
    examination,
    isRetryLevel,
    revisionLevelWithRules,
    currentStaticLevel: { currentLevel: staticLevel },
    levelType,
  } = useLevelAndEvaluation()
  const {
    preferenceState: { hints },
  } = usePreferences()
  const { progressionData, moduleId, module, scenario } = useScenarioAndModule()
  const {
    storeEvaluationInteractions,
    storeTrainingInteractions,
    startNextEvaluation,
    startInitialEvaluation,
    startLevelOrPracticeTest,
    closeInitialOrNextEvaluation,
    closeLevelOrPracticeTest,
  } = useServer()

  const stepId = revisionLevelWithRules ? revisionLevelWithRules.stepId : undefined

  // _ OWN STATES AND REFS _______________________________________________________________________________
  /** `uniqueCopyId`is `examinationId`for next eval,
   * `initialEvaluationCopyId` for initial evaluation,
   * `trainingBlockId` for practice test,
   * and a uid for training, also called `trainingBlockId` in the interactions  */
  const [uniqueCopyId, setUniqueCopyId] = useState<UniqueCopyId>()
  const [rules, setRules] = useState<Rule[]>()

  useEffect(() => {
    setRules(
      isEvaluation
        ? evalRules
        : (levelType === "static" || levelType === "practiceTest") && staticLevel
        ? staticLevel.rules
        : (levelType === "finalRevision" || levelType === "revision") && revisionLevelWithRules
        ? revisionLevelWithRules.rules
        : [],
    )
  }, [evalRules, isEvaluation, levelType, revisionLevelWithRules, staticLevel])

  useEffect(() => {
    if ((isEvaluation && evalExercises) || rules) {
      setAllExercises(isEvaluation ? evalExercises : getExercisesWithRuleId(rules))
    }
  }, [evalExercises, isEvaluation, rules, setAllExercises])

  const totalNumberOfExercises = useMemo(
    () => (isEvaluation ? evalExercises : getExercisesWithRuleId(rules))?.length ?? 0,
    [evalExercises, isEvaluation, rules],
  )

  // Common ExerciseStates
  const {
    currentExercise: {
      rule,
      exercise,
      ruleMetaData,
      setCurrentExercise,
      setCurrentRule,
      ...currentExerciseStateRest
    },
    levelState: { progress, nbOfAcquiredRules, setProgress, setNbOfAcquiredRules },
    audio: { exerciseAudio, playExerciseAudio, toggleLevelAudio },
    emailPickOutOneState,
    engine: { runEngine },
  } = useExerciseStates({ rules })

  const {
    showAcquiredRuleModal,
    showScoreModal,
    showLevelCongratsModal,
    showErrorModal,
    showOpeningOrResumeEvaluationModal,
    showTrainingResultModal,
  } = useTrainingOrEvalModals()

  // Engine
  const engine = useRef<BaseEngine<RuleMetadata>>()
  const engineInitialization = useRef<boolean>()

  const initialNbOfAcquiredRulesRef = useRef<number>()
  const isFirstTrainingInteraction = useRef(false)
  const timeRef = useRef(false)
  const headerRef = useRef<HeaderHandle>(null)

  /** If we resume a next evaluation, examinationId is defined */
  const examinationId = examination?.examinationId

  // _ METHODS (useCallbacks) NEEDING THE ENGINE _______________________________________________________________________________
  const getResults = useCallback(
    (
      isUncompleted: boolean,
    ): {
      masteredRules: string[]
      unmasteredRules: string[]
      score?: number
      total?: number
      ratio?: number
    } => {
      if (!rules || _.isEmpty(rules)) {
        logger("error, no rules in getResults")
        return { masteredRules: [], unmasteredRules: [] }
      }

      const rulesById = _.keyBy(rules, "id")
      if (isUncompleted && engine.current) {
        return _.mapValues(engine.current.getRuleStatuses(), ruleIds =>
          ruleIds.map(id => rulesById[id]?.title),
        )
      }
      if (isEvaluation && engine.current && isEvaluationEngine(engine.current)) {
        const { masteredRules, unmasteredRules } = engine.current.getRuleStatuses()
        const unShowedRules = _(rules.map(r => r.id))
          .difference([...masteredRules, ...unmasteredRules])
          .value()
        return {
          masteredRules: _.map(masteredRules, id => rulesById[id]?.title),
          unmasteredRules: _.map(
            [...unShowedRules, ...unmasteredRules],
            id => rulesById[id]?.title,
          ),
          ratio: isEvaluationEngine(engine.current) ? engine.current.getScore().ratio : undefined,
          score: isEvaluationEngine(engine.current) ? engine.current.getScore().score : undefined,
          total: isEvaluationEngine(engine.current) ? rules.length : undefined,
        }
      }
      return {
        masteredRules: _.map(rules, r => r.title),
        unmasteredRules: [],
      }
    },
    [isEvaluation, rules],
  )

  /** Close initial or next evaluation */
  const closeEvaluation = useCallback(
    async (evaluationId?: UniqueCopyId) => {
      if (!engine.current || !evaluationType) {
        logger("closeEvaluation no engine or cannot determine evaluation type")
        return false
      }
      try {
        await closeInitialOrNextEvaluation(
          evaluationType,
          engine.current.getInteractions(),
          evaluationId ?? uniqueCopyId,
        )
        return true
      } catch (error) {
        logger("closeEvaluation error:", error)
        // TODO: should we display an error modal ?
        return false
      }
    },
    [closeInitialOrNextEvaluation, evaluationType, uniqueCopyId],
  )

  /** Close training, or practice test */
  const closeTraining = useCallback(
    ({
      isTraining,
      status,
      overrideUniqueCopyId,
    }: {
      isTraining: boolean
      status?: LevelStatus
      overrideUniqueCopyId?: UniqueCopyId
    }) => {
      if (uniqueCopyId == null && overrideUniqueCopyId == null) {
        logger("close training: no uniqueCopyId")
        return
      }
      if (isTraining) {
        // training levels, static or revision
        closeLevelOrPracticeTest({
          status,
          uniqueCopyId: overrideUniqueCopyId ?? uniqueCopyId ?? "",
          interactions: engine.current
            ?.getInteractions()
            .map(interaction => ({ ...interaction, levelId, moduleId })),
        })
      } else {
        // practice test
        const { total, masteredRules, ratio } = getResults(false)
        const completionPercentage = progressionData?.progressionDetail.completionPercentage
        if (completionPercentage == null || ratio == null || total == null) {
          logger("close training: missing completionPercentage | ratio | total")
          return
        }
        const rulesKnown = masteredRules.length
        const gradeOn20 = on20withHalfPoints(ratio)
        const grade = {
          ratio: `${rulesKnown}/${total}`,
          totalRules: total,
          rulesKnown,
          gradeOn20,
          gradeOn20Improved: gradeOn20Improved(ratio, completionPercentage / 100),
        }
        closeLevelOrPracticeTest({
          uniqueCopyId: overrideUniqueCopyId ?? uniqueCopyId ?? "",
          grade,
          interactions: engine.current
            ?.getInteractions()
            .map(interaction => ({ ...interaction, levelId, moduleId })),
        })
      }
      return
    },
    [
      closeLevelOrPracticeTest,
      getResults,
      levelId,
      moduleId,
      progressionData?.progressionDetail.completionPercentage,
      uniqueCopyId,
    ],
  )

  // _ METHODS (useCallbacks) REGARDING THE INTERACTIONS _______________________________________________________________________________
  /** Records all the provided interactions in the engine,
   ** Returns the last progression info, to allow setting states for progress and acquired rules */
  const loadInteractions = useCallback(
    (interactions: Interaction[]): RecordInteractionResult | undefined => {
      const progressions = interactions?.map(int => engine.current?.recordInteraction(int, true))

      return _.last(progressions)
    },
    [],
  )

  const closeLevelIfErrorFromStart = useCallback(async () => {
    await showErrorModal({
      subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.levelStart.title`),
      positive: true,
    })
    const { masteredRules, unmasteredRules } = getResults(true)
    showTrainingResultModal({
      masteredRules,
      unmasteredRules,
      nbOfAcquiredRules: nbOfAcquiredRules - (initialNbOfAcquiredRulesRef?.current ?? 0),
    })
  }, [getResults, nbOfAcquiredRules, showErrorModal, showTrainingResultModal, t])

  /**
   ** Starts an evaluation, or a training level, by a POST to the API
   ** Sets the `uniqueCopyId` state
   ** Returns the unique copy id
   */
  const serverInitialization: () => Promise<UniqueCopyId | undefined> = useCallback(async () => {
    // Next evaluation
    if (evaluationType === "next_evaluation") {
      try {
        // next evaluation is the only case where we get the unique copy id from the back, and not create it in the front
        const evalUniqueId = await startNextEvaluation()
        setUniqueCopyId(evalUniqueId as UniqueCopyId)
        return evalUniqueId
      } catch (e) {
        logger("serverInit nextEvaluation error", e)
        await showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.evaluation.title`),
        })
        return
      }
    } else if (evaluationType === "initial_evaluation") {
      try {
        await startInitialEvaluation(timeLimit)
        const initialEvaluationCopyId = uuidv4()
        setUniqueCopyId(initialEvaluationCopyId)
        return initialEvaluationCopyId
      } catch (e) {
        logger("serverInit initialEvaluation error", e)
        await showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.evaluation.title`),
        })
        return
      }
    } else {
      try {
        // training or practice test (same API route /training/progress)
        const uid = uuidv4()
        setUniqueCopyId(uid)
        // noinspection ES6MissingAwait
        startLevelOrPracticeTest(uid)
        return uid
      } catch (e) {
        logger("serverInit training/practiceTest error", e)
        await closeLevelIfErrorFromStart()
        return
      }
    }
  }, [
    closeLevelIfErrorFromStart,
    evaluationType,
    showErrorModal,
    startInitialEvaluation,
    startLevelOrPracticeTest,
    startNextEvaluation,
    t,
    timeLimit,
  ])

  /** Start or resume an evaluation (initial, next or practice test)
   * 1. Load the resumed evaluation interactions
   * 2. Update the remaining time
   * 3. Show a modal, with a `isResumed` prop
   * 4. If "yes" is clicked, `serverInitialization` is called if it's a new evaluation
   */
  const startOrResumeEvaluation = useCallback(
    async () => {
      if (!rules || _.isEmpty(rules)) {
        logger("startOrResumeEvaluation was called too early")
        return
      }

      const scenarioId = scenario?.id
      /** Two ways:
       * - next evaluation: already existing info in `examination`, still need to load interactions in engine
       * - initial or practice test: retrieve infos from the API, and interactions */
      const resumedEvaluation =
        evaluationType === "practice_test" && progressionData
          ? await loadResumedPracticeTestInteractions({
              loadInteractions,
              showErrorModal: () =>
                showErrorModal({
                  subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.resumeLevelOrEval.title`),
                }),
              isFirstTrainingInteraction,
              levelId,
              scenarioId,
            })
          : evaluationType === "initial_evaluation"
          ? await loadResumedInitialEvaluationInteractions({
              loadInteractions,
              scenarioId,
            })
          : await loadResumedNextEvaluationInteractions({
              loadInteractions,
              showErrorModal: () =>
                showErrorModal({
                  subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.resumeLevelOrEval.title`),
                }),
              examinationId,
              startDate: examination?.startDate,
            })

      if (
        (evaluationType === "initial_evaluation" || evaluationType === "practice_test") &&
        resumedEvaluation &&
        resumedEvaluation.allLearned
      ) {
        const { masteredRules, unmasteredRules, score, total } = getResults(false)
        stopTimer()
        if (evaluationType === "practice_test") {
          closeTraining({
            isTraining: false,
            overrideUniqueCopyId: resumedEvaluation.previousUniqueCopyId,
          })
        } else {
          const success = await closeEvaluation(resumedEvaluation.previousUniqueCopyId)
          if (!success) {
            // noinspection ES6MissingAwait
            showSnack(t("common.errors.server_error"))
            logger("error when calling closeLevelOrEval in onEndEvaluation")
            // TODO: error case
          }
        }

        // setIsTickleOn false => after tickle for initial eval

        await showScoreModal({
          score,
          total,
          // will be improved after tickle
          timerElapsedTime: engine.current?.getDuration().duration ?? 0,
          masteredRules,
          unmasteredRules,
        })

        return
      }

      if (resumedEvaluation) {
        setProgress(resumedEvaluation.progress)
        setNbOfAcquiredRules(resumedEvaluation.nbOfAcquiredRules)
        initialNbOfAcquiredRulesRef.current = resumedEvaluation.nbOfAcquiredRules
      }

      // TODO: see if we want to set it only on positive button click?
      if (resumedEvaluation?.previousUniqueCopyId) {
        setUniqueCopyId(resumedEvaluation.previousUniqueCopyId)
      }

      const remainingQuestionsNbr = rules.length * 2 - (engine.current?.getProgression() ?? 0)

      const isResumed = resumedEvaluation?.previousUniqueCopyId != null

      const buttonClicked = await showOpeningOrResumeEvaluationModal({
        isResumed,
        remainingQuestionsNbr,
        dueDate: examination?.sessionDueDate,
      })

      if ((buttonClicked as ModalResponse)?.button === ButtonType.POSITIVE) {
        const newlySetCopyId = isResumed ? undefined : await serverInitialization()

        let consumedTime: Seconds = 0

        if (evaluationType === "next_evaluation") {
          // send one first tickle
          await sendExaminationTickleSafe(
            isResumed ? resumedEvaluation.previousUniqueCopyId : newlySetCopyId,
          )

          // then trigger one every 2 minutes
          setIsTickleOn(true)

          if (isResumed) {
            try {
              const { data: updatedResumedExamination } = await axios.get<BackExamination>(
                `${apiUrl}/evaluation/examination/${resumedEvaluation?.previousUniqueCopyId}`,
              )
              consumedTime = updatedResumedExamination.consumedTime ?? 0
            } catch (err) {
              logger("error getting previous exam detail", err)
            }
          }
        } else {
          // initial evaluation and practice test
          consumedTime = (engine.current?.getDuration().duration ?? 0) / 1000
        }

        const clientStart: MilliSeconds | undefined = resumedEvaluation?.clientStart ?? undefined

        if (clientStart != null && timeLimit != null) {
          updateRemainingTime(clientStart, timeLimit, consumedTime)
        }

        // start timer
        startTimer()

        runEngine(engine.current)
      }
    },
    // Do not add start, stop or pause in the dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeEvaluation,
      evaluationType,
      examination,
      examinationId,
      getResults,
      levelId,
      loadInteractions,
      progressionData,
      rules,
      runEngine,
      scenario?.id,
      serverInitialization,
      setIsTickleOn,
      setNbOfAcquiredRules,
      setProgress,
      showErrorModal,
      showOpeningOrResumeEvaluationModal,
      showScoreModal,
      showSnack,
      t,
      timeLimit,
      updateRemainingTime,
    ],
  )

  /** Server initialization, load all previous interactions, and run engine
   ** If retry level, only run engine
   */
  const startOrResumeTraining = useCallback(async () => {
    if (!isRetryLevel) {
      // for training => automatic serverInitialization, we do not wait for user action
      // noinspection ES6MissingAwait
      serverInitialization()

      if (progressionData && !_.isEmpty(progressionData)) {
        const previousTraining = await loadAllPreviousTrainingInteractions({
          loadInteractions,
          showErrorModal: closeLevelIfErrorFromStart,
          isFirstTrainingInteraction,
          levelId,
          scenarioId: scenario?.id,
          stepId,
        })

        if (previousTraining) {
          setProgress(previousTraining.progress)
          setNbOfAcquiredRules(previousTraining.nbOfAcquiredRules)
          initialNbOfAcquiredRulesRef.current = previousTraining.nbOfAcquiredRules
        }
      }
    }

    runEngine(engine.current)
  }, [
    closeLevelIfErrorFromStart,
    isRetryLevel,
    levelId,
    loadInteractions,
    progressionData,
    runEngine,
    scenario?.id,
    serverInitialization,
    setNbOfAcquiredRules,
    setProgress,
    stepId,
  ])

  /** Show relevant modals (acquired rule, end of level etc) depending on engine res */
  const displayEndingModals = useCallback(
    async (alreadyEnded?: boolean) => {
      const { masteredRules, unmasteredRules, score, total } = getResults(false)
      if (isEvaluation && isEvaluationEngine(engine.current)) {
        stopTimer()
        if (!alreadyEnded) {
          if (evaluationType === "practice_test") {
            closeTraining({ isTraining: false })
          } else {
            if (evaluationType === "next_evaluation") {
              // clear interval
              setIsTickleOn(false)
            }
            const success = await closeEvaluation()
            if (!success) {
              // noinspection ES6MissingAwait
              showSnack(t("common.errors.server_error"))
              logger("error when calling closeLevelOrEval in onEndEvaluation")
            }
          }
        }
        await showScoreModal({
          score,
          total,
          timerElapsedTime,
          masteredRules,
          unmasteredRules,
        })
      }
    },
    // Do not add start, stop or pause in the dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeEvaluation,
      closeTraining,
      timerElapsedTime,
      evaluationType,
      getResults,
      isEvaluation,
      showScoreModal,
      setIsTickleOn,
      showSnack,
      t,
    ],
  )

  /**
   ** Create an interaction, record it in the engine
   ** Call `storeTrainingInteractions`,
   *  responsible for the buffer and the API call
   ** Update progress bar
   **
   */
  const displayNextTrainingExercise = useCallback(
    async (isAnswerCorrect?: boolean) => {
      if (!engine.current || !rule || !exercise) {
        // noinspection ES6MissingAwait
        showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.nextExercise.title`),
        })
        return
      }
      const baseInteraction: Omit<TrainingInteraction, "levelId" | "moduleId" | "scenarioId"> = {
        trainingBlockId: uniqueCopyId,
        timestamp: Date.now(),
        ruleId: rule.id,
        exerciseId: exercise.id,
        correct: isAnswerCorrect ?? false,
        metadata: { ...exercise, progression: engine.current.getProgression() },
        stepId,
      }
      const res = engine.current.recordInteraction(
        baseInteraction,
        _.isEmpty(previousConsultationData?.previousExercise),
      )
      if (isQA) {
        // eslint-disable-next-line no-console
        console.log(engine.current.dump?.(true))
      }
      const isRuleNewlyAcquired = nbOfAcquiredRules !== res.learned
      if (!isRetryLevel) {
        try {
          storeTrainingInteractions({
            interaction: baseInteraction,
            isRuleNewlyAcquired,
            isEnd: res.allLearned,
            trainingBlockId: uniqueCopyId,
            isFirstTrainingInteraction,
          })
        } catch (e) {
          logger("storeTrainingInteractions error", e)
        }
      }
      /** update screen and modal status after exercise result */
      setProgress(res.progression)
      if (isRuleNewlyAcquired && !isEvaluation) {
        setNbOfAcquiredRules(res.learned)
        await showAcquiredRuleModal(rule.title, () => headerRef.current?.animate())
      }
      if (res.allLearned) {
        const { masteredRules } = getResults(false)
        showLevelCongratsModal({ masteredRules, closeTraining })
        return
      }
      runEngine(engine.current)
    },
    [
      rule,
      exercise,
      uniqueCopyId,
      stepId,
      previousConsultationData?.previousExercise,
      isQA,
      nbOfAcquiredRules,
      isRetryLevel,
      setProgress,
      isEvaluation,
      runEngine,
      showErrorModal,
      t,
      storeTrainingInteractions,
      setNbOfAcquiredRules,
      showAcquiredRuleModal,
      getResults,
      showLevelCongratsModal,
      closeTraining,
    ],
  )

  /**
   ** Create an interaction, record it in the engine
   ** Call `storeEvaluationInteractions`,
   ** Update progress bar
   **
   */
  const displayNextEvaluationExercise = useCallback(
    async (isAnswerCorrect?: boolean) => {
      if (!engine.current || !rule || !exercise || !uniqueCopyId) {
        // noinspection ES6MissingAwait
        showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.nextExercise.title`),
        })
        return
      }
      const baseInteraction: Interaction = {
        timestamp: Date.now(),
        ruleId: rule.id,
        exerciseId: exercise.id,
        correct: isAnswerCorrect ?? false,
        metadata: {
          ...exercise,
          progression: engine.current.getProgression(),
          evalScore: (engine.current as EvaluationEngine).getScore(),
        },
      }
      /** Remove duplicate verif system  */
      const res = engine.current?.recordInteraction(
        baseInteraction,
        _.isEmpty(previousConsultationData?.previousExercise),
      )
      if (isQA) {
        // eslint-disable-next-line no-console
        console.log(engine.current.dump?.(true))
      }
      try {
        await storeEvaluationInteractions({
          interaction: baseInteraction,
          evaluationType,
          uniqueCopyId,
        })
      } catch (e) {
        const isEvaluationEnded = isAxiosError(e) && e.message === "Session has been timed out"
        if (isEvaluationEnded) {
          await displayEndingModals(true)
          return
        }
        logger("storeEvaluationInteraction error", e)
        const isNetworkError = isAxiosError(e) && e.code === "ERR_NETWORK"
        // noinspection ES6MissingAwait
        showErrorModal({
          headerTitle: isNetworkError
            ? t(`${ns.MODAL}.${ModalType.ERROR}.internet.headerTitle`)
            : undefined,
          subtitle: t(
            `${ns.MODAL}.${ModalType.ERROR}.${isNetworkError ? "internet" : "generic"}.title`,
          ),
        })
        return
      }
      setProgress(res.progression)
      if (res.allLearned) {
        await displayEndingModals()
        return
      }
      runEngine(engine.current)
    },
    [
      rule,
      exercise,
      uniqueCopyId,
      previousConsultationData?.previousExercise,
      isQA,
      setProgress,
      runEngine,
      showErrorModal,
      t,
      storeEvaluationInteractions,
      evaluationType,
      displayEndingModals,
    ],
  )

  // _ MAIN USE EFFECT _____________________________________________________________________________________
  useEffect(() => {
    // this effect is run when the provider starts and initializes the engine
    const effect = async (): Promise<void> => {
      if (engineInitialization.current || !rules) {
        // keep this for future debugging, but it is a bit verbose because of the dependencies
        // logger("Engine already initialized or rules not ready")
        return
      }
      engineInitialization.current = true
      engine.current = (isEvaluation ? makeEvaluationEngine({ logger }) : makeEngine()).init(
        getExercisesByRuleId(rules),
      )

      if (!isEvaluation) {
        await startOrResumeTraining()
      } else {
        await startOrResumeEvaluation()
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    effect()
  }, [isEvaluation, rules, startOrResumeEvaluation, startOrResumeTraining])

  // Timer useEffect for evaluation
  useEffect(() => {
    if (!isEvaluation) {
      return
    }
    const launchTimeIsUp = async (): Promise<void> => {
      if (timeIsUp && !timeRef.current) {
        timeRef.current = true
        const { score, total, masteredRules, unmasteredRules } = getResults(false)
        if (evaluationType === "practice_test") {
          closeTraining({ isTraining: false })
        } else {
          if (evaluationType === "next_evaluation") {
            // clear interval
            setIsTickleOn(false)
          }
          const success = await closeEvaluation()
          if (!success) {
            // noinspection ES6MissingAwait
            showSnack(t("common.errors.server_error"))
            logger("error when calling closeLevelOrEval in displayEndingModals")
          }
        }
        // noinspection ES6MissingAwait
        showScoreModal({
          score,
          total,
          masteredRules,
          unmasteredRules,
          timerElapsedTime,
        })
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    launchTimeIsUp()
  }, [
    closeEvaluation,
    evaluationType,
    getResults,
    showScoreModal,
    timeIsUp,
    timerElapsedTime,
    closeTraining,
    showSnack,
    t,
    isEvaluation,
    setIsTickleOn,
  ])

  useEffect(() => {
    if (!isTickleOn || uniqueCopyId === undefined) {
      return
    }

    const interval = setInterval(async () => {
      await sendExaminationTickleSafe(uniqueCopyId)
      //  2 minutes
    }, 2 * 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isTickleOn, uniqueCopyId])

  // _ QA METHODS (useCallbacks) _______________________________________________________________________________
  const searchExercise = useCallback(
    (isNext: boolean) => {
      if (!rules || _.isEmpty(rules)) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack("Erreur sur la liste des règles")
        return
      }
      const exerciseByNavigation = searchExerciseByNavigation(isNext)
      if (exerciseByNavigation) {
        setCurrentExercise(exerciseByNavigation)
        setCurrentRule(rules.find(r => r.id === exerciseByNavigation?.ruleId))
      }
    },
    [rules, searchExerciseByNavigation, setCurrentExercise, setCurrentRule, showSnack],
  )

  const searchExerciseByIdOrIndex = useCallback(
    (id?: number, index?: number) => {
      if (!rules) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.error.rule`))
        return
      }
      const exerciseByKey = searchExerciseByKey(id, index)
      if (!exerciseByKey) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.error.key`))
        return
      }
      setCurrentExercise(exerciseByKey)
      setCurrentRule(rules.find(r => r.id === exerciseByKey?.ruleId))
    },
    [rules, searchExerciseByKey, setCurrentExercise, setCurrentRule, showSnack, t],
  )

  const searchExerciseByContent = useCallback(
    (text: string) => {
      if (!rules) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.error.rule`))
        return
      }
      const result = searchExerciseByText(text)
      if (!result?.firstExercise) {
        // noinspection JSIgnoredPromiseFromCall
        showSnack(t(`${ns.CONSULTATION}.error.content`))
        return
      }
      setCurrentExercise(result.firstExercise)
      setCurrentRule(rules.find(r => r.id === result.firstExercise?.ruleId))
      return result.exerciseIds
    },
    [rules, searchExerciseByText, setCurrentExercise, setCurrentRule, showSnack, t],
  )

  const openConsultationView = useCallback(() => {
    if (!rules) {
      // noinspection JSIgnoredPromiseFromCall
      showSnack("Erreur sur la liste des règles")
      return
    }
    const firstExercise = showConsultationMode(totalNumberOfExercises, {
      previousExercise: exercise,
      previousRule: rule,
    })
    setCurrentExercise(firstExercise)
    setCurrentRule(rules.find(r => r.id === firstExercise?.ruleId))
  }, [
    exercise,
    rule,
    rules,
    setCurrentExercise,
    setCurrentRule,
    showConsultationMode,
    showSnack,
    totalNumberOfExercises,
  ])

  const closeConsultationView = useCallback(() => {
    setCurrentExercise(previousConsultationData?.previousExercise)
    setCurrentRule(previousConsultationData?.previousRule)
    clearConsultationData()
  }, [
    clearConsultationData,
    previousConsultationData?.previousExercise,
    previousConsultationData?.previousRule,
    setCurrentExercise,
    setCurrentRule,
  ])

  const contextValue: ContextData = {
    currentExercise: {
      uniqueCopyId,
      rule,
      exercise,
      isCrucialQuestion: isConsultation ? consultationCritical : ruleMetaData?.critical,
      isClueAllowed: hints && ruleMetaData?.clue,
      isExplanationAllowed: ruleMetaData?.explanation && !isEvaluation && module?.ruleAlwaysShown,
      ...currentExerciseStateRest,
    },
    levelState: {
      nbOfAcquiredRules,
      progress,
      initialNbOfAcquiredRulesRef,
      totalNumberOfRulesInLevel: _.size(rules),
      totalNumberOfExercises,
    },
    headerRef,
    closeEvaluation,
    closeTraining,
    getResults,
    audio: {
      exerciseAudio,
      playExerciseAudio,
      toggleLevelAudio: () => toggleLevelAudio(engine.current),
    },
    consultation: {
      closeConsultationView,
      openConsultationView,
      searchExercise,
      searchExerciseByContent,
      searchExerciseByIdOrIndex,
    },
    emailPickOutOneState,
    displayNextTrainingExercise,
    displayNextEvaluationExercise,
  }
  return rules ? (
    <exerciseContext.Provider value={contextValue}>{children}</exerciseContext.Provider>
  ) : (
    <ActivityIndicator />
  )
}

export const useExercise = (): ContextData => {
  const context = useContext(exerciseContext)
  if (_.isEmpty(context)) {
    throw new Error("useExercise must be used within a ExerciseProvider")
  }
  return context
}
export default ExerciseProvider
