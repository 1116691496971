import type { LevelStatus, MilliSeconds } from "@newpv/js-common"
import { lett } from "@newpv/js-common"
import { useNavigation } from "@react-navigation/native"
import type { StackNavigationProp } from "@react-navigation/stack"
import { ModalListContent, ModalRulesStatusContent } from "components/Modal"
import ModalScoreContent from "components/Modal/ModalScoreContent"
import { iconSize, isWeb } from "constants/constants"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import { useModuleAndLevelModals } from "hooks/useModuleAndLevelModals"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import type { ModalResponse } from "models/ModalInterfaces"
import { ModalType } from "models/ModalInterfaces"
import { rootRoutes } from "navigation/Constants"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import { useEvaluation } from "providers/EvaluationProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useModal } from "providers/ModalProvider"
import { useServer } from "providers/ServerProvider"
import { useCallback, useMemo } from "react"
import LightningSVG from "svgs/Lightning"
import StarSVG from "svgs/Star"
import TrophySVG from "svgs/Trophy"
import useTheme from "theme/ThemeProvider"
import type { TypographyVariant } from "theme/types"
import { formatTimeByDuration } from "utils/formatTime"
import { logger } from "utils/logger"
import wait from "utils/wait"
import { closeBrowserTab } from "utils/webFunctions"

type PositiveAction = () => Promise<void> | void

export interface EvaluationModalParams {
  dueDate?: MilliSeconds
  isResumed?: boolean
  remainingQuestionsNbr?: number
  /** in seconds - only used to display as text in modal */
  timeLeft?: number
}

interface ScoreModalParams {
  score?: number
  total?: number
  timerElapsedTime?: number
  masteredRules: string[]
  unmasteredRules: string[]
}

interface TrainingResultModalParams {
  isPresential?: boolean
  nbOfAcquiredRules: number
  masteredRules: string[]
  unmasteredRules: string[]
}

interface LevelCongratsModalParams {
  isPresential?: boolean
  masteredRules: string[]
  closeTraining?: ({ isTraining, status }: { isTraining: boolean; status?: LevelStatus }) => void
}

export interface ErrorModalParams {
  dismissable?: boolean
  positive?: boolean
  refresh?: boolean
  subtitle: string
  headerTitle?: string
}

interface TrainingOrEvalModals {
  showAcquiredRuleModal: (ruleTitle: string, positive: PositiveAction) => Promise<ModalResponse>
  showScoreModal: (params: ScoreModalParams) => Promise<void>
  showLevelCongratsModal: (params: LevelCongratsModalParams) => void
  showConfirmationCloseModal: () => Promise<ModalResponse>
  showCrucialModal: (positive: PositiveAction) => void
  showErrorModal: (params: ErrorModalParams) => Promise<void>
  showOpeningOrResumeEvaluationModal: (
    params: EvaluationModalParams,
  ) => Promise<ModalResponse | undefined>
  showIntensiveTrainingModal: (positive: PositiveAction) => void
  showTrainingResultModal: (params: TrainingResultModalParams) => void
}

const useTrainingOrEvalModals = (): TrainingOrEvalModals => {
  const t = useTypedTranslation()
  const { showModal } = useModal()
  const { authenticationType, isQA } = useAuthContext()
  const {
    typography,
    fontMaker,
    colors: {
      secondary_400,
      onSurface: { mediumEmphasis },
    },
  } = useTheme()
  const cs = useCommonStyles()
  const { logout } = useServer()
  const { evaluationType, nbOfQuestions, timeLimit, timeIsUp } = useEvaluation()
  const {
    setIsRetryLevel,
    setLevelId,
    setRevisionLevelWithRules,
    setLevelType,
    setExamination,
    setIsInitialEvaluationCompleted,
  } = useLevelAndEvaluation()
  const { showWelcomeModal } = useModuleAndLevelModals()
  const navigation = useNavigation<StackNavigationProp<IRootParamList, "Exercise">>()
  const isInitial = evaluationType === "initial_evaluation"
  const isPracticeTest = evaluationType === "practice_test"
  const isNextEvaluation = evaluationType === "next_evaluation"

  const commonSubtitleStyle = useMemo(
    () => [typography.body1, fontMaker({ weight: "SemiBold" })],
    [fontMaker, typography.body1],
  )

  const showIntensiveTrainingModal = useCallback(
    (positive: PositiveAction) => {
      // TODO: add condition on application (only Courriel and Orthographe?) when available. See with woonoz
      // noinspection JSIgnoredPromiseFromCall
      showModal({
        type: ModalType.INTENSIVE,
        dismissable: false,
        headerImage: () => (
          <LightningSVG height={iconSize.LARGE} width={iconSize.LARGE} style={cs.alignCenter} />
        ),
        positive: {
          label: t("common.button.continue"),
          onPress: positive,
        },
      })
    },
    [cs.alignCenter, showModal, t],
  )

  const showCrucialModal = useCallback(
    (positive: PositiveAction) => {
      // noinspection JSIgnoredPromiseFromCall
      showModal({
        type: ModalType.CRUCIAL,
        dismissable: true,
        dismissPositiveAction: true,
        headerImage: props => <StarSVG {...props} width={iconSize.LARGE} height={iconSize.LARGE} />,
        positive: {
          label: t("common.button.continue"),
          onPress: positive,
        },
      })
    },
    [showModal, t],
  )

  const showAcquiredRuleModal = useCallback(
    async (ruleTitle: string, positive: PositiveAction) =>
      showModal({
        type: ModalType.RULE_ACQUIRED,
        numberOfMasteredRules: 1,
        dismissable: true,
        dismissPositiveAction: true,
        headerImage: props => <StarSVG {...props} width={iconSize.LARGE} height={iconSize.LARGE} />,
        positive: {
          label: t("common.button.continue"),
          onPress: positive,
        },
        bodySubtitle: t(`${ns.MODAL}.${ModalType.RULE_ACQUIRED}.subtitle`, {
          rule: ruleTitle,
        }),
      }),
    [showModal, t],
  )

  const showTrainingResultModal = useCallback(
    ({
      nbOfAcquiredRules,
      masteredRules,
      unmasteredRules,
      isPresential,
    }: TrainingResultModalParams) => {
      // noinspection JSIgnoredPromiseFromCall
      showModal({
        dismissable: false,
        type: ModalType.RESULTS,
        headerImage: props => <StarSVG {...props} width={iconSize.LARGE} height={iconSize.LARGE} />,
        children: <ModalRulesStatusContent {...{ masteredRules, unmasteredRules }} />,
        numberOfMasteredRules: nbOfAcquiredRules,
        positive: {
          label: t(`common.button.${isPresential ? "closeTab" : "menu"}`),
          onPress: () => {
            if (isPresential && isWeb) {
              closeBrowserTab()
            } else {
              setLevelId(undefined)
              setRevisionLevelWithRules(undefined)
              setLevelType(undefined)
              navigation.navigate(rootRoutes.LEVEL)
            }
          },
        },
      })
    },
    [navigation, setLevelId, setRevisionLevelWithRules, setLevelType, showModal, t],
  )

  const showErrorModal = useCallback(
    async (params?: ErrorModalParams) => {
      await showModal({
        dismissable: params?.dismissable ?? false,
        headerTitle: params?.headerTitle ?? t(`${ns.MODAL}.${ModalType.ERROR}.generic.headerTitle`),
        type: ModalType.ERROR,
        bodySubtitle: params?.subtitle,
        bodySubtitleStyle: typography.body1,
        positive: params?.positive
          ? {
              label: t("common.button.ok"),
            }
          : undefined,
      })
      // TODO: update when mobile will be ready again
      if (params?.refresh && isWeb) {
        logger("reload page")
        // @ts-ignore
        window.location.reload()
      }
    },
    [showModal, t, typography.body1],
  )

  // Evaluation
  const onCancelPress = useCallback(() => {
    if (isPracticeTest) {
      navigation.goBack()
      return
    }
    logout(!(isQA && authenticationType === "credentials"))
    if (isQA) {
      setTimeout(() => navigation.navigate(ns.HOME), 200)
    }
  }, [authenticationType, isPracticeTest, isQA, logout, navigation])

  const setCompletedEvaluationStates = useCallback(async () => {
    setExamination(undefined)
    setIsInitialEvaluationCompleted(true)
    setLevelId(undefined)
    if (isPracticeTest) {
      await wait(100)
      navigation.navigate(ns.LEVEL)
    }
  }, [setExamination, setIsInitialEvaluationCompleted, setLevelId, isPracticeTest, navigation])

  const showOpeningOrResumeEvaluationModal = useCallback(
    async ({
      remainingQuestionsNbr,
      timeLeft,
      isResumed,
      dueDate,
    }: EvaluationModalParams): Promise<ModalResponse | undefined> => {
      if (!evaluationType) {
        logger("Can't show modal for undefined evaluationType")
        return
      }
      const allocatedTime = formatTimeByDuration(isResumed && timeLeft ? timeLeft : timeLimit, t)
      const numberOfQuestions = String(isResumed ? remainingQuestionsNbr : nbOfQuestions)

      const formattedDueDate = lett(dueDate, d => dayjs(d).format("DD/MM/YYYY HH:mm"))

      const bodyProps = isNextEvaluation
        ? {
            bodyTitle: t(isResumed ? "common.empty" : `${ns.MODAL}.${evaluationType}.bodyTitle`),
            bodyTitleStyle: commonSubtitleStyle,
            bodySubtitleTypographyVariant: isResumed ? ("body1" as TypographyVariant) : undefined,
          }
        : {
            bodySubtitleStyle: commonSubtitleStyle,
            bodySubtitleTypographyVariant: "body1" as TypographyVariant,
          }

      return showModal({
        type: evaluationType as ModalType,
        dismissable: false,
        isEvaluation: true,
        headerTitle: t(`${ns.MODAL}.${evaluationType}.headerTitle`),
        bodySubtitle: isResumed ? t(`${ns.MODAL}.${evaluationType}.subtitleResume`) : undefined,
        ...bodyProps,
        positive: {
          label: t(`common.button.${isResumed ? "continue" : "yes"}`),
        },
        negative: {
          label: t(`common.button.${isPracticeTest ? "no" : "quit"}`),
          onPress: onCancelPress,
        },
        children: (
          <ModalListContent
            type={evaluationType as ModalType}
            iconColor={mediumEmphasis}
            {...{ isResumed, allocatedTime, numberOfQuestions, dueDate: formattedDueDate }}
            question={
              evaluationType
                ? t(`${ns.MODAL}.${evaluationType}.question${isResumed ? "Resume" : ""}`)
                : ""
            }
          />
        ),
      })
    },
    [
      commonSubtitleStyle,
      evaluationType,
      isNextEvaluation,
      isPracticeTest,
      mediumEmphasis,
      nbOfQuestions,
      onCancelPress,
      showModal,
      t,
      timeLimit,
    ],
  )

  const showLevelCongratsModal = useCallback(
    ({ masteredRules, closeTraining, isPresential }: LevelCongratsModalParams) => {
      // noinspection JSIgnoredPromiseFromCall
      showModal({
        type: ModalType.CONGRATS,
        dismissable: false,
        headerImage: () => <TrophySVG height={iconSize.LARGE} width={iconSize.LARGE} />,
        children: <ModalRulesStatusContent {...{ masteredRules }} />,
        positive: {
          label: t(`common.button.${isPresential ? "closeTab" : "menu"}`),
          onPress:
            isPresential && isWeb
              ? closeBrowserTab
              : () => {
                  setIsRetryLevel(false)
                  closeTraining?.({ isTraining: true, status: "completed" })
                  setLevelId(undefined)
                  setRevisionLevelWithRules(undefined)
                  setLevelType(undefined)
                  navigation.goBack()
                },
        },
      })
    },
    [
      navigation,
      setIsRetryLevel,
      setLevelId,
      setRevisionLevelWithRules,
      setLevelType,
      showModal,
      t,
    ],
  )

  const showEvaluationResultModal = useCallback(
    async (masteredRules: string[], unmasteredRules: string[]) => {
      await showModal({
        type: ModalType.EVALUATION,
        dismissable: false,
        headerTitle: t(
          `${ns.MODAL}.${evaluationType ?? ModalType.INITIAL_EVALUATION}.headerScoreTitle`,
        ),
        positive: {
          label: t("common.button.continue"),
          onPress: async () => {
            await setCompletedEvaluationStates()
          },
        },
        children: <ModalRulesStatusContent {...{ masteredRules, unmasteredRules }} />,
      })
      // Show Modal after result modals
      if (evaluationType === "initial_evaluation") {
        showWelcomeModal()
      }
    },
    [showModal, t, evaluationType, setCompletedEvaluationStates, showWelcomeModal],
  )

  const showScoreModal = useCallback(
    async ({
      score,
      total,
      masteredRules,
      unmasteredRules,
      timerElapsedTime,
    }: ScoreModalParams) => {
      await showModal({
        type: ModalType.EVALUATION,
        dismissable: false,
        isEvaluation: true,
        headerTitle: t(
          `${ns.MODAL}.${evaluationType ?? ModalType.INITIAL_EVALUATION}.headerScoreTitle`,
        ),
        positive: {
          label: t("common.button.continue"),
        },
        children: (
          <ModalScoreContent {...{ total, timeIsUp, score, timerElapsedTime, evaluationType }} />
        ),
      })
      await showEvaluationResultModal(masteredRules, unmasteredRules)
    },
    [evaluationType, showEvaluationResultModal, showModal, t, timeIsUp],
  )

  // TopBar Header Action
  const showConfirmationCloseModal = useCallback(
    async () =>
      showModal({
        type: evaluationType as ModalType,
        isEvaluation: true,
        dismissable: false,
        bodyTitle: evaluationType ? t(`${ns.MODAL}.${evaluationType}.leaveTitle`) : undefined,
        bodyTitleStyle: typography.body1,
        bodySubtitle:
          evaluationType && !isPracticeTest
            ? t(`${ns.MODAL}.${evaluationType}.leaveSubtitle`)
            : t("common.empty"),
        positive: {
          label: t(`common.button.${isPracticeTest ? "continue" : "leave"}`),
          color: isPracticeTest ? undefined : secondary_400,
        },
        ...(isPracticeTest
          ? {
              neutral: {
                label: t("common.button.pause"),
              },
            }
          : undefined),
        negative: {
          label: t(`common.button.${isPracticeTest ? "stop" : "cancel"}`),
        },
      }),
    // Do not add start, stop or pause in the dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cs.marginBottom,
      cs.padding,
      isInitial,
      isPracticeTest,
      navigation,
      secondary_400,
      setLevelId,
      showModal,
      t,
      typography,
      isNextEvaluation,
    ],
  )

  return {
    showAcquiredRuleModal,
    showScoreModal,
    showLevelCongratsModal,
    showConfirmationCloseModal,
    showCrucialModal,
    showErrorModal,
    showOpeningOrResumeEvaluationModal,
    showIntensiveTrainingModal,
    showTrainingResultModal,
  }
}

export default useTrainingOrEvalModals
