import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ArrowDownSVG = (props?: SvgProps): JSX.Element => {
  const { colors } = useTheme()
  return (
    <Svg
      width={props?.width ?? iconSize.SMALL}
      height={props?.height ?? iconSize.SMALL}
      fill="none"
      {...props}
    >
      <Path d="m7 10 5 5 5-5H7Z" fill={colors.onSurface.mediumEmphasis} fillOpacity={0.6} />
    </Svg>
  )
}

export default ArrowDownSVG
