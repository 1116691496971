import type { NavigationProp } from "@react-navigation/core/lib/typescript/src/types"
import { useNavigation } from "@react-navigation/native"
import type { ParamListBase } from "@react-navigation/routers"
import { TransitionPresets } from "@react-navigation/stack"
import { isWeb, maxScreenWidth } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import type { FC, PropsWithChildren } from "react"
import { useLayoutEffect, useMemo } from "react"
import type { ViewStyle } from "react-native"
import { Platform, Pressable, StyleSheet, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"

interface Props extends PropsWithChildren {
  modalHeader?: (navigation?: NavigationProp<ParamListBase>) => JSX.Element
  headerShown?: boolean
  route?: "profile" | "settings"
}
const ModalScreen: FC<Props> = ({
  children,
  modalHeader,
  headerShown = false,
  route = "profile",
}) => {
  const { isSmallWidth: isMobileBrowser } = useDeviceTools()
  const {
    darkMode,
    colors: {
      surface: { surface, background, backdropWeb },
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation()
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown,
      detachPreviousScreen: false,
      cardStyle: { backgroundColor: "transparent" },
      // see https://github.com/react-navigation/react-navigation/issues/9177 | otherwise, didn't work on android tablets
      ...(isWeb
        ? {}
        : {
            ...Platform.select({
              android: TransitionPresets.FadeFromBottomAndroid,
              default: TransitionPresets.DefaultTransition,
            }),
          }),
    })
  }, [headerShown, navigation])

  const s = useMemo(
    () => ({
      backdrop: { backgroundColor: route === "profile" ? backdropWeb : undefined },
      innerView: {
        backgroundColor: darkMode ? background : surface,
        bottom: 0,
        flex: 1,
        height: "100%",
        maxHeight: isWeb && !isMobileBrowser ? 700 : undefined,
        maxWidth: maxScreenWidth,
        position: "absolute",
        width: "100%" as const,
      } as ViewStyle,
      outerContainer: {
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
      } as ViewStyle,
      contentView: {
        flex: 1,
        marginTop: isWeb ? undefined : spacing,
      } as ViewStyle,
    }),
    [route, backdropWeb, darkMode, background, surface, isMobileBrowser, spacing],
  )
  return (
    <SafeAreaView edges={["left", "right"]} style={s.outerContainer}>
      <Pressable style={[StyleSheet.absoluteFill, s.backdrop]} onPress={navigation.goBack} />
      <View style={s.innerView}>
        <View style={s.contentView}>
          {modalHeader ? modalHeader() : null}
          {children}
        </View>
      </View>
    </SafeAreaView>
  )
}
export default ModalScreen
