import type { RouteProp } from "@react-navigation/native"
import { useRoute } from "@react-navigation/native"
import { useStyles } from "hooks/useStyles"
import type { IRootParamList } from "navigation/RootNavigator"
import type { FC } from "react"
import { SafeAreaView } from "react-native-safe-area-context"
import WebView from "react-native-webview"

const WebPage: FC = () => {
  const {
    params: { uri },
  } = useRoute<RouteProp<IRootParamList, "WebPage">>()

  const { style } = useStyles(
    () => ({
      style: {
        flex: 1,
      },
    }),
    [],
  )

  return (
    <SafeAreaView {...{ style }}>
      <WebView {...{ style }} source={{ uri }} javaScriptEnabled={true} />
    </SafeAreaView>
  )
}

export default WebPage
