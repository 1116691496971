import type { Level, ModuleProgression } from "@newpv/js-common"
import { Button } from "components/Button"
import { Body2, H5, Subtitle1 } from "components/Texts"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import { isEmpty } from "lodash"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { ProgressBar } from "react-native-paper"
import PlayIconSVG from "svgs/PlayIcon"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

interface IProps {
  moduleTitle: string
  moduleProgression?: ModuleProgression
  title: string
  scenarioTitle: string
  onPress: () => void
  allLevelsAreCompleted: boolean
  focusedLevel?: Level
}

const LevelTopView: FC<IProps> = ({
  moduleProgression,
  onPress,
  title,
  scenarioTitle,
  moduleTitle,
  allLevelsAreCompleted,
  focusedLevel,
}) => {
  const {
    colors: { primary, ripple, secondary_300, onSurface },
  } = useTheme()
  const { t } = useTranslation()
  const moduleCompletion = moduleProgression?.completionPercentage ?? 0
  const moduleDuration = moduleProgression?.trainingDuration ?? 0
  const label = allLevelsAreCompleted
    ? "restart"
    : moduleDuration > 0 || moduleCompletion > 0
    ? "continue"
    : "start"

  const cs = useCommonStyles()
  const s = useStyles(
    ({ roundness, dimensions: { margin, spacing }, colors: { surface, primary_400 } }) => ({
      buttonContentStyle: {
        flexDirection: "row-reverse",
        padding: spacing / 2,
        backgroundColor: primary_400,
      },
      level: {
        paddingBottom: spacing * 1.5,
        paddingTop: spacing / 2,
      },
      scenarioTitle: {
        marginHorizontal: margin,
        paddingBottom: spacing / 2,
        textAlign: "center",
      },
      percent: {
        paddingBottom: spacing / 2,
        paddingTop: spacing,
      },
      progress: {
        backgroundColor: surface.background,
        borderRadius: roundness,
        height: 8,
        width: 100,
        alignSelf: "center",
      },
      top: {
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: margin * 3,
        marginTop: margin * 1.5,
      },
    }),
    [],
  )

  const focusedLevelIsRevisionAndOnCooldown =
    (focusedLevel?.type === "revision" || focusedLevel?.type === "finalRevision") &&
    !isEmpty(moduleProgression?.levels?.[focusedLevel?.id]?.revisionRuleIds) &&
    dayjs().isBefore(dayjs(moduleProgression?.levels?.[focusedLevel?.id]?.revisionOpenDate))

  return (
    <View style={s.top}>
      <Subtitle1 style={s.scenarioTitle}>{scenarioTitle}</Subtitle1>
      <H5
        style={cs.textCenter}
        accessibilityRole="header"
        // @ts-ignore
        accessibilityLevel={2}
      >
        {moduleTitle}
      </H5>
      <Subtitle1 style={s.percent}>
        {t("Level.percent", {
          progress: Math.round(moduleProgression?.completionPercentage ?? 0),
        })}
      </Subtitle1>
      {/** Necessary to keep the correct progress bar size: issue with new paper version */}
      <View>
        <ProgressBar
          color={moduleProgression?.completionPercentage === 100 ? primary : secondary_300}
          progress={(moduleProgression?.completionPercentage ?? 0) / 100}
          style={s.progress}
          focusable={false}
        />
      </View>
      {focusedLevel && !allLevelsAreCompleted && !focusedLevelIsRevisionAndOnCooldown ? (
        <>
          <Body2 color={onSurface.mediumEmphasis} style={s.level}>
            {title}
          </Body2>
          <Button
            accessibilityLabel={t(`common.button.${label}`) ?? undefined}
            {...{ onPress }}
            rippleColor={rippleColor(ripple)}
            style={cs.buttonBorderRadius}
            labelStyle={{ color: onSurface.button }}
            contentStyle={s.buttonContentStyle}
            icon={() => <PlayIconSVG />}
          >
            {t(`common.button.${label}`)}
          </Button>
        </>
      ) : null}
    </View>
  )
}
export default LevelTopView
