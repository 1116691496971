import type { ExerciseId } from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ConsultationInput from "components/Consultation/ConsultationInput"
import ConsultationList from "components/Consultation/ConsultationList"
import CustomRenderHTML from "components/CustomRenderHTML"
import QAConsultationButtons from "components/ExerciseContents/QAConsultationButtons"
import { Subtitle1 } from "components/Texts"
import { isWeb } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import { useExercise } from "providers/ExerciseProvider"
import type { FC } from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"
import { IconButton } from "react-native-paper"
import { SwitchBasedItem } from "screens/SettingsScreen/SwitchBasedItem"
import useTheme from "theme/ThemeProvider"
import generateShadow from "utils/generateShadow"

const ConsultationView: FC = () => {
  const t = useTypedTranslation()
  const {
    typography: { body2 },
  } = useTheme()
  const {
    crucialQuestionSwitch: [isCrucial, setIsCrucial],
  } = useConsultationMode()
  const {
    currentExercise: { exercise, rule },
    consultation,
  } = useExercise()
  const { getExerciseIndex } = useConsultationMode()

  const [hide, setHide] = useState(false)

  const [exerciseIndex, setExerciseIndex] = useState<number>()
  const [exerciseId, setExerciseId] = useState<ExerciseId>()
  // List of ids that contains searched content
  const [exerciseIds, setExerciseIds] = useState<ExerciseId[]>()
  const [exerciseContent, setExerciseContent] = useState<string>("")

  const s = useStyles(
    ({ dimensions: { spacing, margin }, colors: { surface } }) => ({
      icon: {
        backgroundColor: surface.surfaceSecondary,
        bottom: isWeb ? undefined : 0,
        position: "absolute",
        right: 0,
        top: isWeb ? 0 : undefined,
        zIndex: 1,
      },
      hideIcon: {
        backgroundColor: surface.backgroundModal,
        margin: 0,
        zIndex: 1,
        alignSelf: "center",
      },
      subtitle: {
        marginVertical: margin / 2,
      },
      switch: {
        marginVertical: isWeb ? margin / 2 : 0,
        paddingLeft: 0,
        paddingVertical: 0,
      },
      view: {
        backgroundColor: surface.backgroundModal,
        borderRadius: spacing,
        maxWidth: 400,
        zIndex: 1,
        ...generateShadow(2),
      },
      webView: {
        left: spacing * 4,
        padding: hide ? undefined : spacing * 2,
        top: spacing * 4,
      },
      mobileView: {
        padding: hide ? undefined : spacing,
      },
      mobileRow: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      mobileButton: {
        flexDirection: isWeb ? "column" : "row",
      },
    }),
    [hide],
  )

  useEffect(() => {
    if (!exercise) {
      return
    }
    setExerciseId(exercise?.id)
    setExerciseIndex(getExerciseIndex(exercise))
  }, [exercise, getExerciseIndex])

  const onPress = useCallback(() => {
    setExerciseIds(consultation?.searchExerciseByContent(_.deburr(exerciseContent).toLowerCase()))
  }, [consultation, exerciseContent])

  const MemoizeRenderHtml = useMemo(
    () => (
      <CustomRenderHTML
        width={10}
        content={t(`${ns.CONSULTATION}.${isWeb ? "ruleTitle" : "mobile.title"}`, {
          ruleTitle: rule?.title,
        })}
        customStyle={{ marginVertical: isWeb ? 8 : 0 }}
        typographyVariant={isWeb ? "subtitle1" : "body2"}
      />
    ),
    [rule?.title, t],
  )

  return exercise !== null && rule !== null ? (
    <View style={{ zIndex: 1, position: "absolute" }}>
      <View style={[s.view, isWeb ? s.webView : s.mobileView]}>
        <IconButton
          icon={hide ? "window-restore" : "minus"}
          onPress={() => setHide(oldValue => !oldValue)}
          style={hide ? s.hideIcon : s.icon}
        />
        {hide ? null : _.isUndefined(exercise) ? null : (
          <>
            <View style={isWeb ? undefined : s.mobileRow}>
              <ConsultationInput
                onChange={e => setExerciseId(Number(e.nativeEvent.text))}
                onPress={() => consultation?.searchExerciseByIdOrIndex(exerciseId)}
                subtitle={t(`${ns.CONSULTATION}.${isWeb ? "exId" : "mobile.id"}`)}
                value={exerciseId?.toString() ?? exercise.id.toString()}
              />
              <ConsultationInput
                onChange={e => setExerciseIndex(Number(e.nativeEvent.text))}
                onPress={() => consultation?.searchExerciseByIdOrIndex(undefined, exerciseIndex)}
                subtitle={t(`${ns.CONSULTATION}.${isWeb ? "exIndex" : "mobile.index"}`)}
                value={exerciseIndex?.toString() ?? getExerciseIndex(exercise)?.toString() ?? ""}
              />
              <Subtitle1 style={[s.subtitle, isWeb ? undefined : body2]}>
                {t(`${ns.CONSULTATION}.${isWeb ? "ruleId" : "mobile.rule"}`, { ruleId: rule?.id })}
              </Subtitle1>
            </View>
            <View style={isWeb ? undefined : s.mobileRow}>
              <ConsultationInput
                customStyle={isWeb ? { maxWidth: undefined, width: "100%" } : { marginVertical: 8 }}
                label={isWeb ? t(`${ns.CONSULTATION}.content`) : undefined}
                placeholder={isWeb ? undefined : t(`${ns.CONSULTATION}.mobile.content`)}
                onChange={e => setExerciseContent(e.nativeEvent.text)}
                value={exerciseContent}
                {...{ onPress }}
              />
              {MemoizeRenderHtml}
            </View>
            <View style={s.mobileButton}>
              <SwitchBasedItem
                description={`${ns.CONSULTATION}.crucial`}
                onValueChange={setIsCrucial}
                style={s.switch}
                titleStyle={{ flex: undefined }}
                value={isCrucial}
              />
              <QAConsultationButtons arrows={!isWeb} />
            </View>
          </>
        )}
      </View>
      {hide ? null : <ConsultationList {...{ exerciseIds }} />}
    </View>
  ) : (
    <ActivityIndicator />
  )
}

export default ConsultationView
