import type { TranslationKeysFR } from "i18n/i18next.fr.keys"

export const quotes: Array<{ content: TranslationKeysFR; author: string }> = [
  {
    content: "Loading.quotes.0",
    author: "Claude WEILL (journaliste)",
  },
  {
    content: "Loading.quotes.1",
    author: "Nina CATACH (linguiste)",
  },
]
