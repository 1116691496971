import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const StarSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { icon },
  } = useTheme()

  return (
    <Svg
      {...props}
      width={props?.width ?? 22}
      height={props?.height ?? 22}
      viewBox="0 0 22 22"
      fill="none"
    >
      <Path
        d="M16.64 21.007a.536.536 0 0 1-.253-.068l-5.884-3.09-5.885 3.09a.558.558 0 0 1-.588-.044.55.55 0 0 1-.224-.544l1.125-6.547-4.76-4.641a.566.566 0 0 1-.141-.574.559.559 0 0 1 .454-.38l6.578-.975 2.98-5.96a.581.581 0 0 1 .997 0l2.98 5.96 6.577.96a.566.566 0 0 1 .454.38.581.581 0 0 1-.141.574l-4.76 4.64 1.117 6.548a.543.543 0 0 1-.216.544.566.566 0 0 1-.41.127Z"
        fill={icon}
      />
    </Svg>
  )
}

export default StarSVG
