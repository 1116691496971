import { axios, validateStatus } from "@newpv/js-common"
import type { AxiosError } from "axios"
import { TIME_INTERVAL, TIME_INTERVAL_MAX } from "constants/constants"
import { ConsultationModeProvider } from "providers/ConsultationModeProvider"
import LevelAndEvaluationProvider from "providers/LevelAndEvaluationProvider"
import ServerProvider from "providers/ServerProvider"
import type { FC, PropsWithChildren } from "react"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { QueryClient, QueryClientProvider } from "react-query"
import AppLayout from "wrappers/AppLayout/AppLayout"
import MaintenanceWrapper from "wrappers/MaintenanceWrapper/MaintenanceWrapper"

import ThemeApp from "../../ThemeApp"
import DebugProvider from "./DebugProvider"
import ModalProvider from "./ModalProvider"
import ScenarioAndModuleProvider from "./ScenarioAndModuleProvider"

axios.defaults.validateStatus = validateStatus

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

// React query docs - "If mutations fail because the device is offline, they will be retried in the same order when the device reconnects."
queryClient.setMutationDefaults("sendInteractions", {
  retryDelay: (failureCount: number) =>
    Math.min(TIME_INTERVAL * 2 ** failureCount, TIME_INTERVAL_MAX),
  retry: (failureCount: number, error: AxiosError) => {
    const { isAxiosError, response } = error
    if (isAxiosError && response && response.status >= 300 && response.status <= 499) {
      return false
    }
    return failureCount < 4
  },
})

const RootProvider: FC<PropsWithChildren> = ({ children }) => (
  /* // TODO: see https://codevoweb.com/type-children-element-client-queryclient-is-not-assignable-to-type-intrinsicattributes/ */
  /* @ts-expect-error */
  <QueryClientProvider client={queryClient}>
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <ScenarioAndModuleProvider>
          <LevelAndEvaluationProvider>
            <ServerProvider>
              <ThemeApp>
                <AppLayout>
                  <DebugProvider>
                    <ModalProvider>
                      <MaintenanceWrapper>
                        <ConsultationModeProvider>{children}</ConsultationModeProvider>
                      </MaintenanceWrapper>
                    </ModalProvider>
                  </DebugProvider>
                </AppLayout>
              </ThemeApp>
            </ServerProvider>
          </LevelAndEvaluationProvider>
        </ScenarioAndModuleProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  </QueryClientProvider>
)

export default RootProvider
