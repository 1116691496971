import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { ResizeMode, Video } from "expo-av"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import { View } from "react-native"

// TODO:complete when design will be done
/** work on real android device but not on the android emulator (ios/web ok)  */
/** Youtube link for the video
 *  Web link for article
 * */
const VideoContainer: FC<{ uri?: string }> = ({ uri }) => {
  const { onLayout, width } = useLayout()

  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      container: {
        width,
        height: width / 2 + 2 * spacing,
      },
    }),
    [width],
  )

  return width == null ? (
    <ActivityIndicator size="large" />
  ) : (
    <View {...{ onLayout }}>
      {uri ? (
        <Video
          style={s.container}
          videoStyle={s.container}
          source={{
            uri,
          }}
          useNativeControls={true}
          resizeMode={ResizeMode.CONTAIN}
        />
      ) : null}
    </View>
  )
}

export default VideoContainer
