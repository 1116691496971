import { isAndroid, isiOS } from "constants/constants"
import type { ComponentProps, FC } from "react"
import { useMemo } from "react"
import { Button as PaperButton } from "react-native-paper"
import useTheme from "theme/ThemeProvider"

export type ButtonProps = ComponentProps<typeof PaperButton>

const Button: FC<ButtonProps> = ({ mode: buttonMode = "contained", labelStyle, ...props }) => {
  const {
    typography,
    colors: { primary_400 },
  } = useTheme()

  const color = props.color
    ? props.color
    : isAndroid && buttonMode !== "contained"
    ? undefined
    : primary_400 // affect ripple and contained background color on Android

  const { style, contentStyle } = useMemo(
    () => ({
      style: [
        isiOS && !props.disabled
          ? {
              backgroundColor: undefined,
            }
          : undefined,
        props.style,
      ],
      contentStyle: [
        isiOS && buttonMode === "contained" && !props.disabled
          ? {
              backgroundColor: color ?? primary_400,
            }
          : undefined,
        props.contentStyle,
      ],
    }),
    [props.disabled, props.style, props.contentStyle, buttonMode, color, primary_400],
  )

  return (
    <PaperButton
      {...props}
      mode={buttonMode}
      {...{ color, style, contentStyle }}
      labelStyle={[typography.button, labelStyle]}
    />
  )
}

export default Button
