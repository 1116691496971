import type { EvaluationType } from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import useCommonStyles from "hooks/useCommonStyles"
import EvaluationProvider from "providers/EvaluationProvider"
import { ExerciseProvider } from "providers/ExerciseProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"

import { ExerciseScreen } from "../ExerciseScreen/ExerciseScreen"

const EvaluationScreen: FC<{
  evaluationType: EvaluationType
}> = ({ evaluationType }) => {
  const cs = useCommonStyles()
  const { scenario } = useScenarioAndModule()
  const { levelId } = useLevelAndEvaluation()

  // TODO: handle loading and error state from evalExercises and evalRules
  return scenario && levelId ? (
    <EvaluationProvider {...{ evaluationType }}>
      <ExerciseProvider isEvaluation={true}>
        <ExerciseScreen isEvaluation={true} />
      </ExerciseProvider>
    </EvaluationProvider>
  ) : (
    <ActivityIndicator style={cs.paddingTop} />
  )
}

export default EvaluationScreen
