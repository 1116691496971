import { axiosCache } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import { apiUrl } from "constants/constants"
import type { IAuthentication } from "models/AuthenticationInterface"

interface SendLoginParams {
  username: string
  password: string
}

export const postLogin = async (params: SendLoginParams): Promise<AxiosResponse<IAuthentication>> =>
  axiosCache.post(`${apiUrl}/authentication/login`, params)

interface SendShowcaseLoginParams {
  jwt: string
  scenarioId: string
  service: string
}

export const postShowcaseLogin = ({
  jwt,
  scenarioId,
  service,
}: SendShowcaseLoginParams): Promise<AxiosResponse<IAuthentication>> =>
  axiosCache.post(`${apiUrl}/authentication/login/showcase`, {
    jwt,
    scenarioId,
    service,
  })
