import { logger } from "@newpv/js-common"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useRoute } from "@react-navigation/native"
import { isAndroid, isWeb, LOCAL_STORAGE } from "constants/constants"
import _ from "lodash"
import type { ReloadStorage } from "models/StorageInterfaces"
import useAuthContext from "providers/AuthProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useCallback, useEffect } from "react"
import { BackHandler } from "react-native"

interface IProps {
  beforeUnload?: boolean
  isExercise?: boolean
  // Actions to call when using Android BackHandler / Web Back Button or refresh
  handler?: () => void
  // Optional RN navigation method when using Android BackHandler
  navigation?: () => void
}

/**
 * For BackHandler: Do not fix react-native-web with a patch that manipulate the browser's history.
 * It causes many issues with the web navigation and react-navigation
 * For the moment, the backhandler is ignored on the web.
 * A patch is needed inside Paper to avoid calling backHandler when a modal is showed.
 * */
const useHardwareButton = ({
  handler,
  navigation,
  isExercise = false,
  beforeUnload,
}: IProps): void => {
  const route = useRoute()
  const { authScenarioId: scenarioId, hasAuth } = useAuthContext()
  const { moduleId, routeIndex } = useScenarioAndModule()
  const storeBeforeUnload = useCallback((data: ReloadStorage) => {
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem(LOCAL_STORAGE.reload, JSON.stringify(data))
  }, [])

  const eventCallback = useCallback(
    (event: any) => {
      // Necessary to force a user to make a choice and send the data when reload.
      event.preventDefault()
      // Issue with Safari when reload the page. The request are interrupted
      handler?.()
      if (event.type === "beforeunload") {
        // TODO: if we store this data, profile screen is accessible. The module list is fetched with scenarioId and routeIndex
        logger("storing in local storage", {
          moduleId,
          scenarioId,
          routeIndex,
          isReload: true,
        })
        storeBeforeUnload({
          moduleId,
          scenarioId,
          routeIndex,
          isReload: true,
        })
        // Needed for Chrome to prompt the alert box
        event.returnValue = ""
        return
      }
    },
    [handler, moduleId, scenarioId, routeIndex, storeBeforeUnload],
  )

  const androidBackHandler = useCallback(() => {
    handler?.()
    navigation?.()
    return true
  }, [handler, navigation])

  const removeListeners = useCallback(() => {
    global.window.removeEventListener("popstate", eventCallback)
    global.window.removeEventListener("beforeunload", eventCallback)
  }, [eventCallback])

  useEffect(() => {
    if (!isAndroid) {
      return
    }
    BackHandler.addEventListener("hardwareBackPress", androidBackHandler)
    return () => BackHandler.removeEventListener("hardwareBackPress", androidBackHandler)
  }, [androidBackHandler])

  useEffect(() => {
    if (!isWeb || !hasAuth) {
      return
    }
    const href = global.window.location.href
    const target: string | undefined = _.last(href?.split("/"))
    if (isExercise && route?.name?.toLowerCase() !== target?.toLowerCase()) {
      /** React navigation should be handled the history, however reload
       * seems to "pause" the history in a state where listeners are not removed
       * Add a state to force web browser to get a state without active listeners
       */
      // @ts-ignore
      const url = new URL(global.window.location)
      global.window.history.pushState({}, "", url)
      return removeListeners
    }
    global.window.addEventListener("popstate", eventCallback)
    if (beforeUnload) {
      global.window.addEventListener("beforeunload", eventCallback)
    }
    return removeListeners
  }, [beforeUnload, eventCallback, hasAuth, isExercise, removeListeners, route?.name])
}

export default useHardwareButton
