import { Button } from "components/Button"
import { bottomSheet, Keyboard } from "constants/constants"
import { LinearGradient } from "expo-linear-gradient"
import useKeyboardEvent from "hooks/useKeyboardEvent"
import { useStyles } from "hooks/useStyles"
import { useBottomSheet } from "providers/BottomSheetProvider"
import type { FC, KeyboardEvent, RefObject } from "react"
import { useCallback, useMemo, useState } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"

import type { ButtonProps } from "../../Button"

interface Props {
  buttonConfig: ButtonConfigProp
  closeBottomSheet: () => void
  customButtonContainerStyle?: StyleProp<ViewStyle>
  hideBottomSheet: () => void
  showBottomSheet: () => void
}

export interface BottomSheetButtonConfig {
  onPress?: (() => void) | (() => Promise<void>)
  label: string
  actionsAfterOnPress: Array<
    "closeBottomSheet" | "hideBottomSheet" | "showBottomSheet" | "togglePanel"
  >
  disabled?: boolean
  /** mode, color, etc */
  extraButtonProps?: Omit<ButtonProps, "children">
  customButtonStyle?: StyleProp<ViewStyle>
  bottomSheetMaxHeight?: number
}

type ButtonConfigKey = "smallContent" | "ifPanelOpen" | "ifPanelClosed" | "default"

export type ButtonConfigProp = Array<Partial<Record<ButtonConfigKey, BottomSheetButtonConfig>>>

const BottomSheetButtons: FC<Props> = ({
  buttonConfig,
  closeBottomSheet,
  customButtonContainerStyle,
  hideBottomSheet,
  showBottomSheet,
}) => {
  const {
    darkMode,
    colors: { surface: themeSurface },
  } = useTheme()
  const [buttonViewRef] = useState<RefObject<View> | null>()
  const { bottom } = useSafeAreaInsets()
  // togglePanel and bsState.panelOpen have to come from the closest context
  const { togglePanel, bsState, childrenHeight } = useBottomSheet()
  const isPanelOpen = bsState.panelOpen

  const s = useStyles(
    ({ dimensions: { spacing }, colors: { surface, onSurface } }) => ({
      bottom: {
        backgroundColor: surface.backgroundModal,
        height: bottom,
        top: bottom,
        zIndex: 1,
      },
      button: {
        borderWidth: 0,
        marginHorizontal: spacing / 2,
      },
      buttonContainer: {
        alignSelf: "center",
        bottom: 0,
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: bottomSheet.MAX_WIDTH,
        paddingHorizontal: spacing / 2,
        paddingVertical: spacing * 0.75,
        position: "absolute",
        width: "100%" as const,
      },
      disabled: {
        backgroundColor: onSurface.disabled,
      },
      buttonLabel: {
        color: onSurface.button,
      },
      buttonLabelDisabled: {
        color: onSurface.highEmphasis,
      },
    }),
    [bottom],
  )

  const currentButtonConfig = useMemo(
    () =>
      buttonConfig.map(config => {
        const isSmallContent =
          config?.smallContent?.bottomSheetMaxHeight &&
          childrenHeight < config?.smallContent?.bottomSheetMaxHeight

        const key = (
          config.default
            ? "default"
            : isSmallContent
            ? "smallContent"
            : isPanelOpen
            ? "ifPanelOpen"
            : "ifPanelClosed"
        ) as ButtonConfigKey
        return config[key]
      }) as BottomSheetButtonConfig[],
    [buttonConfig, childrenHeight, isPanelOpen],
  )

  const mapFunctions = useMemo(
    () => ({
      closeBottomSheet,
      hideBottomSheet,
      showBottomSheet,
      togglePanel,
    }),
    [closeBottomSheet, hideBottomSheet, showBottomSheet, togglePanel],
  )

  const handleEnterKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === Keyboard.ENTER && currentButtonConfig?.[1]) {
        await currentButtonConfig[1].onPress?.()
        closeBottomSheet()
        hideBottomSheet()
      }
    },
    [closeBottomSheet, currentButtonConfig, hideBottomSheet],
  )
  useKeyboardEvent(handleEnterKeyPress, buttonViewRef)
  return (
    <>
      <LinearGradient
        style={[s.buttonContainer, customButtonContainerStyle]}
        colors={[
          darkMode ? themeSurface.translucentIntensiveTraining : themeSurface.translucent,
          themeSurface.backgroundModal,
        ]}
      >
        {currentButtonConfig.map((button, index) => (
          <Button
            key={index}
            {...button.extraButtonProps}
            labelStyle={
              index === currentButtonConfig.length - 1
                ? button.disabled
                  ? s.buttonLabelDisabled
                  : s.buttonLabel
                : {}
            }
            style={[s.button, button.customButtonStyle, button.disabled ? s.disabled : {}]}
            onPress={async () => {
              await button.onPress?.()
              button.actionsAfterOnPress.forEach(action => mapFunctions[action]())
            }}
            disabled={button.disabled ?? false}
          >
            {button.label}
          </Button>
        ))}
      </LinearGradient>
      <View style={s.bottom} />
    </>
  )
}

export default BottomSheetButtons
