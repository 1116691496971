import { useNavigation } from "@react-navigation/native"
import type { StackNavigationProp } from "@react-navigation/stack"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ConsultationView from "components/Consultation/ConsultationView"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import { QAButtons } from "components/ExerciseContents"
import { EvaluationHeader, ExerciseHeader } from "components/Headers"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useHardwareButton from "hooks/useHardwareButton"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTrainingOrEvalModals from "hooks/useTrainingOrEvalModals"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import { useDebug } from "providers/DebugProvider"
import { useEvaluation } from "providers/EvaluationProvider"
import { ExerciseProvider, useExercise } from "providers/ExerciseProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useServer } from "providers/ServerProvider"
import type { FC } from "react"
import { useCallback, useLayoutEffect, useMemo, useRef } from "react"
import { logger } from "utils/logger"
import ScreenWrapper from "wrappers/ScreenWrapper/ScreenWrapper"

import type { ExerciseContentHandle } from "./ExerciseContent"
import ExerciseContent from "./ExerciseContent"

interface IProps {
  isPresential?: boolean
  isEvaluation?: boolean
}

export const ExerciseScreen: FC<IProps> = ({ isEvaluation = false, isPresential = false }) => {
  // Global context
  const {
    preferenceState: { seeCorrection },
  } = usePreferences()
  const t = useTypedTranslation()
  const { showSnack } = useDebug()
  const navigation = useNavigation<StackNavigationProp<IRootParamList, "Exercise">>()
  // Exercise contexts - hooks
  const { refetchUserProgression } = useScenarioAndModule()
  const { isRetryLevel, setIsRetryLevel } = useLevelAndEvaluation()
  const exerciseContext = useExercise()
  const evalContext = useEvaluation()
  const { stop: stopTimer } = evalContext
  const {
    displayNextTrainingExercise,
    closeEvaluation,
    closeTraining,
    headerRef,
    getResults,
    levelState: { nbOfAcquiredRules, initialNbOfAcquiredRulesRef },
    currentExercise: {
      exercise,
      rule,
      isIntensiveTraining,
      setIsIntensiveTraining,
      resetIntensiveTrainingExercises,
      isClueVisible,
      setIsClueVisible,
    },
    consultation,
  } = exerciseContext
  const { storeLastInteractionsBeforeAbandon } = useServer()
  const { isConsultation } = useConsultationMode()
  const isPracticeTest = evalContext.evaluationType === "practice_test"

  const { screenBackground } = useStyles(({ colors: { surface } }) => ({
    screenBackground: { backgroundColor: surface.backgroundWide },
  }))

  // States - constants - refs
  const exerciseContentRef = useRef<ExerciseContentHandle>(null)

  const { showTrainingResultModal, showConfirmationCloseModal, showScoreModal } =
    useTrainingOrEvalModals()
  const { onLayout: onInstructionViewLayout, height: instructionViewHeight } = useLayout()
  const { isSmallWidth } = useDeviceTools()

  const exitTrainingBlock = useCallback(() => {
    if (isConsultation) {
      consultation?.closeConsultationView()
    }
    // TODO: this closes the training session even if the user cancelled (which atm can't be done) => move into goBack lambda?
    if (isRetryLevel) {
      setIsRetryLevel(false)
      logger("onBackPress for retry level, don't store interactions")
      return
    }
    if (!isPresential) {
      storeLastInteractionsBeforeAbandon()
    }
    closeTraining({ isTraining: true, status: "uncompleted" })
    const { masteredRules, unmasteredRules } = getResults(true)
    showTrainingResultModal({
      isPresential,
      masteredRules,
      unmasteredRules,
      nbOfAcquiredRules: nbOfAcquiredRules - (initialNbOfAcquiredRulesRef?.current ?? 0),
    })
  }, [
    isRetryLevel,
    isPresential,
    closeTraining,
    getResults,
    showTrainingResultModal,
    nbOfAcquiredRules,
    initialNbOfAcquiredRulesRef,
    setIsRetryLevel,
    storeLastInteractionsBeforeAbandon,
    isConsultation,
    consultation,
  ])

  const displayResultModals = useCallback(async () => {
    const results = getResults(false)
    await showScoreModal({
      ...results,
      timerElapsedTime: evalContext?.timerElapsedTime,
    })
  }, [evalContext?.timerElapsedTime, getResults, showScoreModal])

  const exitEvaluation = useCallback(async () => {
    const result = await showConfirmationCloseModal()

    // pause practice test
    if (result.button === "neutral") {
      // timer stopped because we leave the page
      // practice test, need to refetch to update the practice_test status
      refetchUserProgression()
      setTimeout(() => navigation.goBack(), 100)
      return
    }

    // abandon practice test
    if (result.button === "negative" && isPracticeTest) {
      stopTimer()
      closeTraining({ isTraining: false })
      await displayResultModals()
      return
    }

    // abandon next eval or initial eval
    if (result.button === "positive" && !isPracticeTest) {
      stopTimer()
      const success = await closeEvaluation()
      if (!success) {
        // noinspection ES6MissingAwait
        showSnack(t("common.errors.server_error"))
        logger("error when calling closeLevelOrEval in onEndEvaluation")
      }
      await displayResultModals()
    }
    // Do not add start, stop etc in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeEvaluation,
    closeTraining,
    displayResultModals,
    isPracticeTest,
    navigation,
    refetchUserProgression,
    showConfirmationCloseModal,
    showSnack,
    t,
  ])

  const handleHardwareButton = useMemo(
    () =>
      !isEvaluation
        ? exitTrainingBlock
        : isPracticeTest // Consider as a pause.
        ? () => {
            refetchUserProgression()
          }
        : undefined,
    [exitTrainingBlock, isEvaluation, isPracticeTest, refetchUserProgression],
  )

  useHardwareButton({
    isExercise: true,
    handler: handleHardwareButton,
  })

  useLayoutEffect(
    () =>
      navigation.setOptions({
        headerShown: true,
        header: props =>
          isEvaluation ? (
            <EvaluationHeader
              {...{ props, evalContext }}
              onPress={exitEvaluation}
              progress={exerciseContext.levelState.progress ?? 0}
            />
          ) : (
            <ExerciseHeader
              {...{ props, isEvaluation }}
              ref={headerRef}
              context={exerciseContext}
              onPress={exitTrainingBlock}
            />
          ),
      }),
    [
      exerciseContext,
      evalContext,
      exitEvaluation,
      exitTrainingBlock,
      headerRef,
      isEvaluation,
      navigation,
    ],
  )

  /** Called by BottomSheet button or by different exercise component through ExerciseContent  */
  const onBottomSheetNextButtonPress = useCallback(
    async (hideBottomSheet?: () => void) => {
      if (isIntensiveTraining) {
        resetIntensiveTrainingExercises()
        setIsIntensiveTraining(false)
        // TODO: can create issue need to check it
        exerciseContentRef?.current?.scrollToTop()
      } else if (!isConsultation) {
        const resultFromLevel = exerciseContentRef.current?.getResult() // Level
        await displayNextTrainingExercise(resultFromLevel === "correct")
        if (isClueVisible) {
          setIsClueVisible(false)
        }
      }
      hideBottomSheet?.()
      exerciseContentRef?.current?.cleanResult()
    },
    [
      isConsultation,
      isIntensiveTraining,
      resetIntensiveTrainingExercises,
      setIsIntensiveTraining,
      displayNextTrainingExercise,
      isClueVisible,
      setIsClueVisible,
    ],
  )

  const loading = !exercise || !rule

  return (
    <ScreenWrapper style={!isSmallWidth ? screenBackground : {}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          {isConsultation ? <ConsultationView /> : null}
          <ExerciseContent
            key={exercise.id}
            ref={exerciseContentRef}
            {...{
              exercise,
              onBottomSheetNextButtonPress,
              isEvaluation,
              rule,
              instructionViewHeight,
              onInstructionViewLayout,
            }}
          />
          {seeCorrection && !isIntensiveTraining && exerciseContentRef.current ? (
            <QAButtons
              mode="2"
              dragAndDropRef={exerciseContentRef.current.handleDragAndDropRef}
              onSelectPress={
                isEvaluation
                  ? exerciseContentRef?.current?.handleEvaluationPress
                  : exerciseContentRef?.current?.handleLevelPress(instructionViewHeight)
              }
              {...{ isEvaluation }}
            />
          ) : null}
        </>
      )}
    </ScreenWrapper>
  )
}

// training
export const ExerciseWrapper: FC = () => {
  const cs = useCommonStyles()
  const { authScenarioId: scenarioId } = useAuthContext()
  const { routeIndex } = useScenarioAndModule()
  const {
    revisionLevelWithRules,
    currentStaticLevel: { currentLevel: staticLevel, currentLevelError, isCurrentLevelLoading },
  } = useLevelAndEvaluation()

  return isCurrentLevelLoading ? (
    <ActivityIndicator style={cs.paddingTop} />
  ) : currentLevelError ? (
    <ErrorPanel title={currentLevelError.message} />
  ) : (staticLevel || revisionLevelWithRules) && scenarioId && routeIndex !== undefined ? (
    <ExerciseProvider isEvaluation={false}>
      <ExerciseScreen />
    </ExerciseProvider>
  ) : null
}
