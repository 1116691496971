import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"
import { getVersion } from "@newpv/js-common"
import { apiUrl, bffUrl, datadogClientToken, ddSampleRate } from "constants/constants"

import packageJson from "../../package.json"

const { version } = getVersion(packageJson)

const start = (): void => {
  datadogLogs.init({
    clientToken: datadogClientToken,
    site: "datadoghq.eu",
    forwardErrorsToLogs: true,
    sessionSampleRate: ddSampleRate,
  })

  datadogRum.init({
    applicationId: "9939bde1-b5fa-4588-a593-1f4d000af128",
    clientToken: datadogClientToken,
    site: "datadoghq.eu",
    service: "apprentissage",
    env: __DEV__ ? "dev" : "prd",
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: ddSampleRate,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [apiUrl, bffUrl],
  })

  if (ddSampleRate > 0) {
    // eslint-disable-next-line no-console
    console.log("Datadog web started")
    datadogRum.startSessionReplayRecording()
  } else {
    // eslint-disable-next-line no-console
    console.log("Datadog web nerfed")
  }
}

export default start
