import type { NativeStackScreenProps } from "@react-navigation/native-stack"
import { H6, Subtitle1 } from "components/Texts"
import { images } from "config/images"
import { iconSize } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { IRootParamList } from "navigation/RootNavigator"
import type { FC } from "react"
import { View } from "react-native"
import { Icon } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"

export const LoggedOutScreen: FC<NativeStackScreenProps<IRootParamList, "Login">> = () => {
  const t = useTypedTranslation()

  const {
    colors: {
      onSurface: { highEmphasis },
    },
  } = useTheme()
  const s = useStyles(
    ({
      darkMode,
      dimensions: { spacing, margin },
      colors: {
        surface: { surface, surfaceSecondary },
        onPrimary,
        neutral_50,
      },
    }) => ({
      container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: darkMode ? surface : neutral_50,
      },
      background: {
        backgroundColor: darkMode ? surfaceSecondary : onPrimary.highEmphasis,
        justifyContent: "center",
        padding: spacing,
        paddingHorizontal: spacing * 2,
        minHeight: "33%",
        width: "100%" as const,
        marginVertical: "auto",
      },
      contentContainer: {
        alignSelf: "center",
        margin: "auto",
      },
      logo: { marginHorizontal: "auto", marginBottom: margin * 1.5 },
      text: {
        textAlign: "center",
        margin: margin / 2,
      },
    }),
  )

  return (
    <SafeAreaView style={s.container}>
      <View style={s.background}>
        <View style={s.contentContainer}>
          <View style={s.logo}>
            <Icon source={images.logo} size={iconSize.SMALL} color={highEmphasis} />
          </View>
          <H6 style={s.text}>{t("LoggedOut.main")}</H6>
          <Subtitle1 style={s.text}>{t("LoggedOut.subtitle")}</Subtitle1>
        </View>
      </View>
    </SafeAreaView>
  )
}
