import CalendarSVG from "svgs/Calendar"
import ChartSVG from "svgs/Chart"
import ClickOnIconSVG from "svgs/ClickOnIcon"
import ClockSVG from "svgs/Clock"
import CommonHeaderSVG from "svgs/CommonHeader"
import DragDropSVG from "svgs/DragDrop"
import Level1 from "svgs/Level1"
import Level2 from "svgs/Level2"
import Level3 from "svgs/Level3"
import Level4 from "svgs/Level4"
import Level5 from "svgs/Level5"
import Level6 from "svgs/Level6"
import LightBulbSVG from "svgs/LightBulb"
import Logo from "svgs/Logo"
import PickOutOneSVG from "svgs/PickOutOne"
import SpeechBubblesSVG from "svgs/SpeechBubbles"
import SpellingSVG from "svgs/SpellingModule"

export const images = {
  homeSVG: CommonHeaderSVG,
  spelling: SpellingSVG,
  expression: SpeechBubblesSVG,
  click_on_mistake: ClickOnIconSVG,
  click_on_word: ClickOnIconSVG,
  click_on_email: ClickOnIconSVG,
  drag_and_drop: DragDropSVG,
  pick_out_one: PickOutOneSVG,
  pick_out_nearest: PickOutOneSVG,
  email_pick_out_one: PickOutOneSVG,
  logo: Logo,
} as const

export const levelImages = [Level1, Level2, Level3, Level4, Level5, Level6]
export const welcomeIcons = [ClockSVG, LightBulbSVG, ChartSVG]

export const warningIcons = [CalendarSVG, ClockSVG, LightBulbSVG]
