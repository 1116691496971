import NetInfo from "@react-native-community/netinfo"
import { isWeb } from "constants/constants"
import { useEffect } from "react"
import { onlineManager } from "react-query"

export function useOnlineManager(): void {
  useEffect(() => {
    // React Query already supports auto refetch on reconnect in web browser
    if (!isWeb) {
      return NetInfo.addEventListener(state => {
        onlineManager.setOnline(
          state.isConnected != null && state.isConnected && Boolean(state.isInternetReachable),
        )
      })
    }
    return
  }, [])
}
