import { Body2 } from "components/Texts"
import { useStyles } from "hooks/useStyles"
import type { StyleProp, TextStyle, ViewStyle } from "react-native"
import { View } from "react-native"
import { ActivityIndicator as PaperActivityIndicator } from "react-native-paper"
import useTheme from "theme/ThemeProvider"

interface Props {
  color?: string
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  title?: string
  size?: "large" | "small"
}

export const ActivityIndicator = ({
  style,
  containerStyle,
  color,
  title,
  size,
}: Props): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  const { baseStyle, titleStyle } = useStyles(
    ({ dimensions: { spacing } }) => ({
      baseStyle: {
        paddingTop: spacing * 2,
      },
      titleStyle: {
        paddingTop: spacing * 2,
        textAlign: "center",
      } as TextStyle,
    }),
    [],
  )

  return (
    <View style={containerStyle ?? baseStyle}>
      <PaperActivityIndicator
        style={[baseStyle, style]}
        color={color ?? onSurface.disabled}
        size={size ?? "small"}
      />
      {title ? <Body2 style={titleStyle}>{title}</Body2> : null}
    </View>
  )
}
