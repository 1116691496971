import type { DragAndDropWord } from "@newpv/js-common"
import CustomRenderHTML from "components/CustomRenderHTML"
import { Body1 } from "components/Texts"
import { MAX_TAP_DISTANCE } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import { useDragAndDrop } from "providers/DadProvider"
import { useCallback, useMemo, useRef, useState } from "react"
import type { LayoutChangeEvent } from "react-native"
import { PanResponder, View } from "react-native"
import { DraxView } from "react-native-drax"
import type { MixedStyleDeclaration } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"
import hitSlop from "utils/hitSlop"

interface WordBlockProps {
  word: DragAndDropWord
  isFiller?: boolean
}

const WordBlock = ({ word, isFiller = false }: WordBlockProps): JSX.Element => {
  const { tapSelectedWord, setTapSelectedWord } = useDragAndDrop()

  const [width, setWidth] = useState<number>()

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (event.nativeEvent.layout.width && width == null) {
        setWidth(event.nativeEvent.layout.width)
      }
    },
    [width, setWidth],
  )

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        setTapSelectedWord(word)
      },
      onPanResponderMove: (evt, gestureState) => {
        if (
          Math.abs(gestureState.dx) > MAX_TAP_DISTANCE ||
          Math.abs(gestureState.dy) > MAX_TAP_DISTANCE
        ) {
          setTapSelectedWord(undefined)
        }
      },
      onPanResponderTerminationRequest: () => true,
    }),
  )
  const {
    colors: { onSurface },
  } = useTheme()

  const s = useStyles(
    ({ roundness, dimensions: { spacing, margin }, colors: { surface } }) => ({
      wordWrapper: {
        opacity: isFiller ? 0 : 1,
        marginHorizontal: margin / 4,
        marginVertical: margin / 2,
        maxWidth: "98%",
      },
      wordInBank: {
        backgroundColor: tapSelectedWord?.id === word.id ? surface.background : surface.surface,
        opacity: tapSelectedWord?.id === word.id ? 0.6 : 1,
        borderColor: surface.outline,
        borderRadius: roundness,
        borderWidth: 1,
        paddingVertical: spacing,
        minWidth: spacing * 4,
      },
      wordText: {
        alignSelf: "center",
      },
    }),
    [isFiller, tapSelectedWord],
  )

  const memoizedStyle: MixedStyleDeclaration = useMemo(
    () => ({
      alignSelf: "center",
      textAlign: "center",
      paddingTop: 1,
      color: onSurface.highEmphasis,
    }),
    [onSurface.highEmphasis],
  )

  return (
    <DraxView
      style={s.wordWrapper}
      dragPayload={undefined}
      id={`${word.id.toString()}${isFiller ? "filler" : ""}`}
      draggable={false}
      hitSlop={hitSlop(10, 0)}
      onLayout={onLayout}
      {...(!isFiller ? panResponder.current.panHandlers : {})}
    >
      <View style={[s.wordInBank, { alignSelf: "center" }]}>
        <CustomRenderHTML
          content={word.content}
          width={width ?? 0}
          typographyVariant="body1"
          // do NOT use a style from a StyleSheet here
          customStyle={memoizedStyle}
          customDefaultTextProps={{ selectable: false }}
        />
        {/* this component is necessary to set the correct width for CustomRenderHTML */}
        <Body1 selectable={false} color="transparent" style={{ height: 1 }}>
          {word.content}
        </Body1>
      </View>
    </DraxView>
  )
}

export default WordBlock
