import { CIRCLE_STEP } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import { View } from "react-native"
import Svg, { Defs, Path, Pattern, Rect } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

interface IProps {
  height?: number
  parentHeight?: number
  disabled?: boolean
  levelIndex: string
}

const Bar: FC<IProps> = ({ parentHeight, height, disabled, levelIndex }) => {
  const {
    darkMode,
    colors: { surface, primary_400 },
  } = useTheme()
  const barHeight = height ?? 30
  const width = 30
  const { styleHorizontal, styleVertical } = useStyles(
    () => ({
      styleVertical: {
        alignSelf: "center" as const,
        left: CIRCLE_STEP / 2 - width / 2,
        position: "absolute" as const,
        top: parentHeight ? parentHeight / 2 + CIRCLE_STEP / 2 : 0,
      },
      styleHorizontal: {
        transform: [{ rotate: "90deg" }],
      },
    }),
    [parentHeight],
  )

  return (
    <View {...{ style: parentHeight ? styleVertical : styleHorizontal }}>
      <Svg {...{ width }} height={barHeight} viewBox={`0 0 2 ${barHeight}`}>
        <Defs>
          <Pattern
            id={`linePattern-${levelIndex}`}
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="2"
            height="92"
            viewBox="0 0 2 92"
          >
            <Path
              fill={
                disabled ? (darkMode ? surface.overlayProgression : surface.overlay) : primary_400
              }
              d="M0 0H2V92.5H0z"
            />
          </Pattern>
        </Defs>
        <Rect fill={`url(#linePattern-${levelIndex})`} width="2" height={barHeight} />
      </Svg>
    </View>
  )
}

export default Bar
