import type {
  PickOutNearestExercise,
  PickOutOneExercise,
  PickOutOneWordBlock,
} from "@newpv/js-common"
import { isPickOutOne } from "@newpv/js-common"
import _ from "lodash"

import { getCorrectWordInExercise } from "./getCorrectWordInExercise"

export const getWordArray = (
  randomWordsFromOtherExercises: PickOutOneWordBlock[],
  exercise: PickOutOneExercise | PickOutNearestExercise,
  isCrucial = false,
): Array<PickOutOneWordBlock | undefined> => {
  // we pick 3 or 5 distractors on top of the one picked in another exercise, in case the foreign one is the same as one of the "local" distractors
  const arrayWithUniqueValues = _([
    ...randomWordsFromOtherExercises,
    ..._.sampleSize(
      exercise.words.filter(ex => !ex.isAnswer),
      isPickOutOne(exercise)
        ? exercise.distractorsCount
        : isCrucial
        ? 6 - randomWordsFromOtherExercises.length
        : 4 - randomWordsFromOtherExercises.length,
    ),
  ])
    .uniqWith((arrVal, othVal) => arrVal?.text === othVal?.text)
    .value()

  // and we keep the 3 or 5 first values of the distractor array, + the correct word
  return _.shuffle(
    [
      ...arrayWithUniqueValues
        .filter(e => e)
        .slice(0, isPickOutOne(exercise) ? exercise.distractorsCount : isCrucial ? 5 : 3),
      getCorrectWordInExercise(exercise),
    ].filter(elem => elem),
  )
}
