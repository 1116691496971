export interface BottomSheetState {
  panelOpen: boolean // is the bottom sheet deployed or not
  modalVisible: boolean
}

export type ActionName = "close" | "showModal" | "hideModal" | "openPanel" | "closePanel"

export const defaultBSValues: BottomSheetState = {
  panelOpen: false,
  modalVisible: false,
}

export function bottomSheetStateReducer(
  state: BottomSheetState,
  action: { type: ActionName; height?: any; useIntensiveTrainingLayout?: boolean },
): BottomSheetState {
  switch (action.type) {
    case "close": {
      return {
        ...state,
        panelOpen: false,
        modalVisible: false,
      }
    }
    case "openPanel": {
      return {
        ...state,
        panelOpen: true,
      }
    }
    case "closePanel": {
      return {
        ...state,
        panelOpen: false,
      }
    }
    case "showModal": {
      return {
        ...state,
        modalVisible: true,
      }
    }
    case "hideModal": {
      return {
        ...state,
        modalVisible: false,
      }
    }
    default:
      return state
  }
}
