import type { Level } from "@newpv/js-common"
import RowItem from "components/RowItem/RowItem"
import { Subtitle1 } from "components/Texts"
import { levelImages } from "config/images"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import type { LevelRowProps } from "models/ModuleInterfaces"
import React from "react"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"

interface IProps extends LevelRowProps {
  focusedLevel?: Level
}

/** We supposed that a module without revision level has free access to its levels  */
export const LevelsWithoutRevision: React.FC<IProps> = ({
  displayedModuleId,
  moduleProgression,
  focusedLevel,
  levels,
  onRowItemPress,
}) => {
  const cs = useCommonStyles()
  const {
    typography,
    colors: { primary_400 },
  } = useTheme()
  const s = useStyles(
    ({
      dimensions: { margin },
      roundness,
      colors: {
        surface: { surface, outline },
      },
    }) => ({
      rowStyle: {
        backgroundColor: surface,
        borderColor: outline,
        borderRadius: roundness * 2,
        borderWidth: 1,
        flex: 1,
        marginVertical: margin / 2,
      },
      svgStyle: {
        justifyContent: "center",
        marginRight: margin,
      },
    }),
  )

  return (
    <>
      {levels.map((moduleLevel, index) => {
        const { id, title, description } = moduleLevel
        const completionPercentage =
          moduleProgression?.levels[moduleLevel.id]?.completionPercentage ?? 0
        const isFocusedLevel = focusedLevel?.id === id
        const indexOfIcon = index % levelImages.length
        return (
          <View
            key={`${displayedModuleId ?? "disp"}=${id ?? index}`}
            style={cs.row}
            // @ts-ignore
            accessibilityRole={isWeb ? "listitem" : undefined}
            accessibilityLabel={title}
            dataSet={{ levelId: id }}
          >
            <RowItem
              key={`row-${id}`}
              separator={false}
              onPress={() => onRowItemPress(moduleLevel, false)}
              leftIcon={levelImages[indexOfIcon]}
              rowStyle={[
                s.rowStyle,
                completionPercentage !== 100 && isFocusedLevel
                  ? { borderWidth: 2, borderColor: primary_400 }
                  : undefined,
              ]}
              titleStyle={typography.h6}
              title={title}
              description={() => (
                <View>
                  <Subtitle1 numberOfLines={2}>{description}</Subtitle1>
                </View>
              )}
              rightNode={{ key: `right-${id}`, percent: completionPercentage }}
            />
          </View>
        )
      })}
    </>
  )
}
