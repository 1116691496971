import NetInfo from "@react-native-community/netinfo"
import type { NavigationState } from "@react-navigation/routers"
import { customFonts } from "config/fonts"
import { isWeb } from "constants/constants"
import { loadAsync } from "expo-font"
import * as ScreenOrientation from "expo-screen-orientation"
import * as SplashScreen from "expo-splash-screen"
import { useAppState } from "hooks/useAppState"
import { useOnlineManager } from "hooks/useOnlineManager"
import { initI18n } from "i18n/fr"
import { AuthProvider } from "providers/AuthProvider"
import PreferenceProvider from "providers/PreferenceProvider"
import RootProvider from "providers/RootProvider"
import { useCallback, useEffect, useRef, useState } from "react"
import type { AppStateStatus } from "react-native"
import { LogBox } from "react-native"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { focusManager } from "react-query"
import ScreenTracking from "utils/analytics"

import { NavigationApp } from "./NavigationApp"
import datadog from "./utils/datadog"
initI18n()

LogBox.ignoreLogs([
  "Setting a timer",
  "fontSize should be expressed",
  "setNativeProps is deprecated",
  "`new NativeEventEmitter()`",
  "Sending `onAnimatedValueUpdate`",
])

NetInfo.configure({
  reachabilityLongTimeout: 60 * 1000, // 60s
  reachabilityShortTimeout: 5 * 1000, // 5s
  reachabilityRequestTimeout: 15 * 1000, // 15s
  reachabilityShouldRun: () => true,
  useNativeReachability: false,
})

const onAppStateChange = (status: AppStateStatus): void => {
  // React Query already supports refetch on window focus in web browser by default
  if (!isWeb) {
    focusManager.setFocused(status === "active")
  }
}

// noinspection JSIgnoredPromiseFromCall
SplashScreen.preventAutoHideAsync()

ScreenOrientation.lockPlatformAsync({
  screenOrientationArrayIOS: [1],
  screenOrientationConstantAndroid: 1,
})

// Detection of Dark Mode for react navigation
const RootApp = (): JSX.Element | null => {
  const [appIsReady, setAppIsReady] = useState(false)

  useOnlineManager()

  useAppState(onAppStateChange)

  const first = useRef(true)
  const routeNameRef = useRef<string | Partial<NavigationState> | undefined>()

  const screenTracking = useCallback((state: NavigationState | undefined) => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = ScreenTracking.getActiveRouteName(state)
    if (first.current || previousRouteName !== currentRouteName) {
      first.current = false
      ScreenTracking.logScreenTracking(currentRouteName)
    }
    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName
  }, [])

  useEffect(() => {
    async function prepare(): Promise<void> {
      try {
        // Preload fonts, make any API calls you need to do here
        await loadAsync(customFonts)
        await datadog()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      } finally {
        // Tell the application to render
        setAppIsReady(true)
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }

  return (
    <SafeAreaProvider onLayout={onLayoutRootView}>
      <AuthProvider>
        <PreferenceProvider>
          <RootProvider>
            <NavigationApp onStateChange={screenTracking} />
          </RootProvider>
        </PreferenceProvider>
      </AuthProvider>
    </SafeAreaProvider>
  )
}

export default RootApp
