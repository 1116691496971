import type {
  EngineInteraction,
  ExaminationId,
  InitialEvaluation,
  LevelId,
  MilliSeconds,
  RecordInteractionResult,
  ScenarioId,
  StepId,
  UniqueCopyId,
} from "@newpv/js-common"
import {
  apiUrl,
  axios,
  getLevelInteractionsSafe,
  getPreviousPracticeTest,
  isEmpty,
  toInteraction,
} from "@newpv/js-common"
import _ from "lodash"
import type { MutableRefObject } from "react"
import { logger } from "utils/logger"

import { getExaminationInteractions, getInitialEvaluation } from "./ServerFunctions"

interface Parameters {
  loadInteractions: (interactions: EngineInteraction[]) => RecordInteractionResult | undefined
  showErrorModal: () => Promise<void>
  isFirstTrainingInteraction?: MutableRefObject<boolean>
  levelId?: LevelId
  scenarioId?: ScenarioId
  stepId?: StepId
}

/** Client start is in milliseconds, progress is the progress in the level (in %) */
interface ResumedEvalInfo {
  previousUniqueCopyId: UniqueCopyId
  clientStart?: MilliSeconds
  allLearned?: boolean
  progress: number
  nbOfAcquiredRules: number
}

export const sendExaminationTickleSafe = async (examinationId?: ExaminationId): Promise<void> => {
  if (examinationId != null) {
    try {
      await axios.post(`${apiUrl}/evaluation/examination/${examinationId}/tickle`, {})
    } catch (err) {
      logger("error sending tickle", err)
    }
  }
}

/** Get all interactions for that training level and load them in the engine
 * Return the progress (%) and the number of acquired rules
 */
export const loadAllPreviousTrainingInteractions = async ({
  loadInteractions,
  showErrorModal,
  isFirstTrainingInteraction,
  levelId,
  scenarioId,
  stepId,
}: Parameters): Promise<{
  progress: number
  nbOfAcquiredRules: number
}> => {
  if (levelId == null || scenarioId == null) {
    return { progress: 0, nbOfAcquiredRules: 0 }
  }
  try {
    if (isFirstTrainingInteraction) {
      isFirstTrainingInteraction.current = true
    }

    const previousInteractions = await getLevelInteractionsSafe(scenarioId, levelId)
    const interactions =
      stepId != null
        ? _.filter(previousInteractions, pi => pi.stepId === stepId)
        : previousInteractions
    if (isEmpty(interactions)) {
      return { progress: 0, nbOfAcquiredRules: 0 }
    }
    const recordInteractionsResult = loadInteractions(interactions)
    return {
      progress: recordInteractionsResult?.progression ?? 0,
      nbOfAcquiredRules: recordInteractionsResult?.learned ?? 0,
    }
  } catch (e) {
    logger("error getting all previous training interactions", e)
    // noinspection ES6MissingAwait
    showErrorModal()
  }
  return { progress: 0, nbOfAcquiredRules: 0 }
}

/** Get all interactions for that practice test, load them in the engine,
 * Return the previous unique copy id and the client start date in milliseconds, along with the progress (%) and the number of acquired rules */
export const loadResumedPracticeTestInteractions = async ({
  loadInteractions,
  showErrorModal,
  isFirstTrainingInteraction,
  levelId,
  scenarioId,
}: Parameters): Promise<ResumedEvalInfo | undefined> => {
  if (levelId == null || scenarioId == null) {
    logger("load practice test interactions: no levelId, or scenarioId")
    return undefined
  }

  const previousPracticeTest = await getPreviousPracticeTest(scenarioId, levelId)
  const previousPracticeTestId = previousPracticeTest?.trainingBlockId

  if (!previousPracticeTestId || previousPracticeTest?.progress !== "started") {
    return undefined
  } else {
    try {
      if (isFirstTrainingInteraction) {
        isFirstTrainingInteraction.current = true
      }
      const previousInteractions = await getLevelInteractionsSafe(scenarioId, levelId)
      const interactions = previousPracticeTestId
        ? _.filter(previousInteractions, pi => pi.trainingBlockId === previousPracticeTestId)
        : previousInteractions

      const recordInteractionsResult = !_.isEmpty(interactions)
        ? loadInteractions(interactions)
        : undefined

      return {
        previousUniqueCopyId: previousPracticeTestId,
        clientStart: previousPracticeTest?.clientStart
          ? new Date(previousPracticeTest?.clientStart).getTime()
          : undefined,
        progress: recordInteractionsResult?.progression ?? 0,
        nbOfAcquiredRules: recordInteractionsResult?.learned ?? 0,
        allLearned: recordInteractionsResult?.allLearned,
      }
    } catch (e) {
      logger({ e })
      // noinspection ES6MissingAwait
      showErrorModal()
      return
    }
  }
}

/** Get all interactions for that initial evaluation, load them in the engine,
 * Return the previous unique copy id (`initialEvaluationBlockId`) and the client start date in milliseconds, along with the progress (%) and the number of acquired rules */
export const loadResumedInitialEvaluationInteractions = async ({
  loadInteractions,
  scenarioId,
}: Pick<Parameters, "loadInteractions" | "scenarioId">): Promise<ResumedEvalInfo | undefined> => {
  if (scenarioId == null) {
    logger("load Initial interactions: no scenarioId")
    return
  }
  let existingEvaluation: InitialEvaluation | undefined
  try {
    existingEvaluation = await getInitialEvaluation(scenarioId)
  } catch (err) {
    logger("no existing initial eval, or error fetchning it", { err })
  }

  if (!existingEvaluation || existingEvaluation?.status !== "started") {
    return undefined
  } else {
    const previousUniqueCopyId = existingEvaluation.interactions?.[0]?.initialEvaluationBlockId

    const recordInteractionsResult =
      _.isArray(existingEvaluation.interactions) && !_.isEmpty(existingEvaluation.interactions)
        ? loadInteractions(existingEvaluation.interactions.map(i => toInteraction(i, false)))
        : undefined

    return {
      previousUniqueCopyId,
      clientStart: existingEvaluation?.startDate
        ? new Date(existingEvaluation.startDate).getTime()
        : undefined,
      progress: recordInteractionsResult?.progression ?? 0,
      nbOfAcquiredRules: recordInteractionsResult?.learned ?? 0,
      allLearned: recordInteractionsResult?.allLearned,
    }
  }
}

/** Get all interactions for that next evaluation examination, load them in the engine,
 * Return the previous unique copy id (`examinationId`) and the client start date in milliseconds, along with the progress (%) and the number of acquired rules */
export const loadResumedNextEvaluationInteractions = async ({
  loadInteractions,
  showErrorModal,
  examinationId,
  startDate,
}: Pick<Parameters, "loadInteractions" | "showErrorModal"> & {
  examinationId?: ExaminationId
  startDate?: MilliSeconds
}): Promise<ResumedEvalInfo | undefined> => {
  // examinationId is defined if we are resuming an examination
  if (examinationId == null) {
    return undefined
  } else {
    try {
      const previousExaminationInteractions = (await getExaminationInteractions(examinationId)).map(
        examinationInteraction => toInteraction(examinationInteraction, true),
      )

      const recordInteractionsResult =
        _.isArray(previousExaminationInteractions) && !_.isEmpty(previousExaminationInteractions)
          ? loadInteractions(previousExaminationInteractions)
          : undefined

      return {
        previousUniqueCopyId: examinationId,
        clientStart: startDate,
        progress: recordInteractionsResult?.progression ?? 0,
        nbOfAcquiredRules: recordInteractionsResult?.learned ?? 0,
      }
    } catch (err) {
      logger({ err })
      // noinspection ES6MissingAwait
      showErrorModal()
      return
    }
  }
}
