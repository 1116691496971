import type { StackHeaderProps } from "@react-navigation/stack"
import { images } from "config/images"
import { HEADER_HEIGHT } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { rootRoutes } from "navigation/Constants"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { BottomState, useModal } from "providers/ModalProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useCallback } from "react"
import type { Animated } from "react-native"
import { View } from "react-native"
import PersonSVG from "svgs/Person"
import SettingsSVG from "svgs/Settings"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"
import wait from "utils/wait"

import { Button } from "../Button"
import HeaderWrapper from "./HeaderWrapper"

interface IProps {
  isHome?: boolean
  props: StackHeaderProps
  offSetY?: Animated.Value
  isQA: boolean
}

const HomeHeader: FC<IProps> = ({ props, isHome = false, offSetY, isQA }) => {
  const t = useTypedTranslation()
  const {
    colors: { ripple, onSurface, surface },
  } = useTheme()
  const { setBottomModalState } = useModal()
  const { navigation } = props
  const { isDesktop } = useDeviceTools()
  const { resetScenarioAndModuleState } = useScenarioAndModule()
  const { resetLevelAndEvalState } = useLevelAndEvaluation()

  const cs = useCommonStyles()

  const s = useStyles(
    ({ dimensions: { margin, spacing } }) => ({
      buttonContentStyle: {
        backgroundColor: surface.surface,
        paddingVertical: spacing / 8,
      },
      buttonWithIconLabelStyle: {
        marginHorizontal: undefined,
        marginLeft: 0.75 * margin,
        marginRight: margin,
      },
      icon: {
        margin: 0,
      },
    }),
    [],
  )

  const openSettings = useCallback(() => {
    navigation.navigate(rootRoutes.SETTINGS)
  }, [navigation])

  const openModuleMenu = useCallback(() => {
    setBottomModalState(BottomState.MODULE)
  }, [setBottomModalState])

  return _.isUndefined(isDesktop) ? null : (
    <HeaderWrapper height={HEADER_HEIGHT} image={images.homeSVG} offSetY={offSetY}>
      <View
        style={[
          cs.row,
          { alignItems: "flex-start" },
          isHome ? { justifyContent: "flex-end" } : undefined,
        ]}
      >
        {!isHome && (
          <View style={{ alignItems: "flex-start" }}>
            <Button
              mode="outlined"
              rippleColor={rippleColor(ripple)}
              onPress={openModuleMenu}
              style={cs.buttonBorderRadius}
              labelStyle={s.buttonWithIconLabelStyle}
              contentStyle={s.buttonContentStyle}
            >
              {t("Level.modulesButton")}
            </Button>
            {isQA && (
              <Button
                mode="outlined"
                rippleColor={rippleColor(ripple)}
                onPress={async () => {
                  resetScenarioAndModuleState()
                  resetLevelAndEvalState()
                  await wait(200)
                  navigation.navigate(ns.HOME)
                }}
                labelStyle={{ color: onSurface.button }}
                style={[cs.buttonBorderRadius, { marginTop: 4 }]}
                contentStyle={[s.buttonContentStyle, { backgroundColor: "lightblue" }]}
              >
                QA: retour Home
              </Button>
            )}
          </View>
        )}
        {isHome ? (
          <Button
            mode="outlined"
            rippleColor={rippleColor(ripple)}
            onPress={openSettings}
            style={cs.buttonBorderRadius}
            contentStyle={s.buttonContentStyle}
            icon={() => <SettingsSVG color={onSurface.mediumEmphasis} />}
          >
            {t("Settings.title")}
          </Button>
        ) : (
          <Button
            mode="outlined"
            rippleColor={rippleColor(ripple)}
            onPress={() => navigation.navigate(rootRoutes.PROFILE)}
            style={cs.buttonBorderRadius}
            contentStyle={s.buttonContentStyle}
            labelStyle={s.buttonWithIconLabelStyle}
            icon={() => <PersonSVG color={onSurface.mediumEmphasis} />}
          >
            {t("Level.profileButton")}
          </Button>
        )}
      </View>
    </HeaderWrapper>
  )
}

export default HomeHeader
