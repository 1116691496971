import type { LevelId } from "@newpv/js-common"
import { Subtitle1 } from "components/Texts"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import { t } from "i18next"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useMemo } from "react"
import { View } from "react-native"
import { Tooltip } from "react-native-paper"
import Bar from "svgs/Bar"
import CircleStep from "svgs/CircleStep"
import useTheme from "theme/ThemeProvider"

interface IProps {
  revisionLevelId: LevelId
  revisionDescription?: string
  maxRulePerStep: number
}

export const StepIndicator: FC<IProps> = ({ revisionLevelId, revisionDescription }) => {
  const { progressionData, moduleId } = useScenarioAndModule()

  const {
    colors: { surface, onSurface },
  } = useTheme()
  const cs = useCommonStyles()

  const s = useStyles(() => ({
    step: {
      alignItems: "center",
      flexDirection: "row",
    },
  }))

  const revisionLevel = useMemo(
    () =>
      moduleId && revisionLevelId
        ? progressionData?.progressionDetail?.modules?.[moduleId]?.levels?.[revisionLevelId]
        : undefined,
    [moduleId, progressionData?.progressionDetail?.modules, revisionLevelId],
  )

  const steps = useMemo(() => revisionLevel?.revisionRuleIds, [revisionLevel?.revisionRuleIds])

  const completed = useMemo(
    () => (stepIndex: number) => {
      if (revisionLevel?.revisionStepId == null) {
        return false
      }
      if (revisionLevel?.completionPercentage >= 100) {
        return true
      }
      return revisionLevel?.revisionStepId > stepIndex
    },
    [revisionLevel?.completionPercentage, revisionLevel?.revisionStepId],
  )

  return steps ? (
    steps.length === 1 ? (
      <Subtitle1 style={{ marginTop: 4, overflow: "visible" }}>{revisionDescription}</Subtitle1>
    ) : (
      <Tooltip
        title={t(`${ns.LEVEL}.intermediateLevelProgression`)}
        theme={{ colors: { surface: onSurface.highEmphasis, tooltip: surface.background } }}
        enterTouchDelay={50}
        leaveTouchDelay={50}
      >
        <View style={cs.flexRow}>
          {steps.length > 0 &&
            steps.map((step, index) => (
              <View key={`stepIndicator_${index}`} style={s.step}>
                <CircleStep disabled={!completed(index)} />
                {index === steps.length - 1 ? null : (
                  <Bar
                    key={`${revisionLevelId}-stepIndex_${index}_bar`}
                    levelIndex={`${revisionLevelId}-stepIndex_${index}_bar`}
                    disabled={!completed(index)}
                  />
                )}
              </View>
            ))}
        </View>
      </Tooltip>
    )
  ) : null
}
