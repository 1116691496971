import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import type { ViewStyle } from "react-native"
import { View } from "react-native"

interface IProps {
  total: number
  activeColor: string
  inactiveColor: string
  currentActiveIndex: number
}

export const PaginationDots: FC<IProps> = ({
  total,
  currentActiveIndex,
  activeColor,
  inactiveColor,
}) => {
  const { dotShape, paginationContainer } = useStyles(
    ({ dimensions: { margin } }) => ({
      dotShape: {
        borderRadius: 40,
        height: 8,
        margin: margin / 4,
        width: 8,
      },
      paginationContainer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        width: 64,
        height: 10,
        // border is necessary for android
        borderWidth: 1,
        borderColor: "transparent",
        borderStyle: "solid",
      } as ViewStyle,
    }),
    [],
  )

  const dots = Array.from(new Array(total))
  return (
    <View style={paginationContainer}>
      {dots.map((_, index) => (
        <View
          key={index}
          style={[
            dotShape,
            { backgroundColor: index === currentActiveIndex ? activeColor : inactiveColor },
          ]}
        />
      ))}
    </View>
  )
}
