import { registerRootComponent } from "expo"

import App from "./App"

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App)

// @see https://github.com/expo/expo/issues/18485
// DO NOT DO THIS, IT CAUSES INFINITE RERENDERS ON WEB
/* if ('web' === Platform.OS) {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  rootTag.render(createElement(App));
} else {
  registerRootComponent(App);
} */
