import type { ApiError, HomeScenario, Scenario, ScenarioId } from "@newpv/js-common"
import { fetchAllScenarios, fetchOneScenario } from "@newpv/js-common"
import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

// If your query function depends on a variable, include it in your query key (the first parameter)
export const useFetchAllScenarios = (): UseQueryResult<HomeScenario[], ApiError> =>
  useQuery<HomeScenario[], ApiError>(["scenarios"], ({ signal }) => fetchAllScenarios(signal))

export const useFetchOneScenario = (
  scenarioId?: ScenarioId | null,
): UseQueryResult<Scenario, ApiError> =>
  useQuery<Scenario, ApiError>(
    ["scenario", scenarioId],
    ({ signal }) => fetchOneScenario(signal, scenarioId),
    { enabled: scenarioId != null },
  )
