import type { ApplicationCode } from "@newpv/js-common"
import { localInit } from "@newpv/js-common"
import Constants from "expo-constants"
import type { MotiTransitionProp } from "moti"
import type { RootRouteNames } from "navigation/Constants"
import { Platform } from "react-native"
import type { SvgProps } from "react-native-svg"
import ModuleImageChatSVG from "svgs/ModuleImageChat"
import ModuleImageEmailSVG from "svgs/ModuleImageEmail"
import ModuleImageFLESVG from "svgs/ModuleImageFLE"
import ModuleImageSpellingSVG from "svgs/ModuleImageSpelling"
import { logger } from "utils/logger"

import Axios from "../../../node_modules/axios"

const bffEnv = Constants.expoConfig?.extra?.BFF_URL
const backEnv = Constants.expoConfig?.extra?.API_URL ?? Constants.expoConfig?.extra?.BACK_URL
const contentEnv = Constants.expoConfig?.extra?.CONTENT_URL
const contentVersionEnv = Constants.expoConfig?.extra?.CONTENT_VERSION
const audiobaseEnv = Constants.expoConfig?.extra?.AUDIO_BASE_URL
const garEnv = Constants.expoConfig?.extra?.GAR_URL
const edEnv = Constants.expoConfig?.extra?.GAR_URL
const entEnv = Constants.expoConfig?.extra?.ENT_URL
const qaEnv = Constants.expoConfig?.extra?.QA_MODE
const ddSampleRateEnv = Constants.expoConfig?.extra?.DD_SAMPLE_RATE

export const bffUrl = bffEnv ?? "https://bff.woonoz-dev.fr"
export const apiUrl = backEnv ?? "https://api.woonoz-dev.fr"
export const contentVersion = contentVersionEnv ?? (__DEV__ ? "latest" : "v39")
export const contentUrl =
  (contentEnv ??
    (__DEV__ ? "https://d1dmkakbvilt86.cloudfront.net" : "https://d1j09mk4f1c8ce.cloudfront.net")) +
  `/${contentVersion}`
export const audioBaseUrl =
  audiobaseEnv ?? "https://www.projet-voltaire.fr/services-pjv/rest/wol/lc"
export const garUrl = garEnv ?? "https://idp-auth.partenaire.test-gar.education.fr/login"
export const edUrl = edEnv ?? "https://api.ecoledirecte.com/v3/cas/login"
export const entUrl = entEnv ?? "https://simulent.partenaire.test-gar.education.fr"
export const qaMode = (qaEnv ?? "false") === "true"
export const ddSampleRate = parseInt(ddSampleRateEnv ?? "0")

localInit({
  contentUrl,
  apiUrl,
  logger,
  axios: Axios.create(),
  axiosCache: Axios.create(),
  nocache: true,
})

if (__DEV__) {
  // eslint-disable-next-line no-console
  console.log("server environment", {
    bffEnv,
    backEnv,
    contentEnv,
    audiobaseEnv,
    garEnv,
    edEnv,
    entEnv,
    bffUrl,
    apiUrl,
    contentUrl,
    contentVersion,
    audioBaseUrl,
    garUrl,
    edUrl,
    entUrl,
    qaEnv,
    qaMode,
    ddSampleRateEnv,
    ddSampleRate,
  })
}

export function getPosition(h, r): number {
  return h - r / 2
}

export const isAxiosError = Axios.isAxiosError
export const isiOS = Platform.OS === "ios"
export const isAndroid = Platform.OS === "android"
export const isWeb = Platform.OS === "web"
// http://stackoverflow.com/questions/9038625/detect-if-device-is-ios
// https://www.whatismybrowser.com/guides/the-latest-user-agent/safari - see "Safari on iOS 13"
export const isIpadWeb =
  isWeb && window?.navigator.maxTouchPoints > 2 && window?.navigator.userAgent.includes("Mac")

// Animation
export const FEEDBACK_DELAY = 500
export const CLOSING_DELAY = 1500
export const transition: MotiTransitionProp = {
  type: "timing",
  duration: FEEDBACK_DELAY,
}

export const Keyboard = {
  ENTER: "Enter",
}

// Modal
export const modal = {
  HEADER: 84,
  IMAGE: 68,
  RADIUS: 48,
  ICON: 48,
  SCROLL_VIEW: 128,
  ACQUIRED_SCROLL_VIEW: 160,
  WARNING_TEXTS: ["30min", "280", "100%"],
}

export const DOT_SIZE = 16
// Images
export const IMAGE_RADIUS = 50
export const CIRCLE_STEP = 16
export const iconSize = {
  EXTRA_SMALL: 16,
  SMALL: 24,
  MEDIUM: 32,
  LARGE: 48,
}

export const scenarioImages: Record<ApplicationCode, (props?: SvgProps) => JSX.Element> = {
  expression: ModuleImageChatSVG,
  fle: ModuleImageFLESVG,
  orthographe: ModuleImageSpellingSVG,
  courriel: ModuleImageEmailSVG,
}
// Screens
export const MAX_TAB_HEIGHT = 64
export const defaultMaxTabHeight = 100
export const maxModalWidth = 480
export const buttonMaxWidth = isWeb ? 331 : 379
export const maxScreenWidth = 880

export const maxCarouselWidth = 550
export const minCarouselHeight = 230
export const edges = {
  NONE: ["left", "right"],
  TOP: ["left", "right", "top"],
  BOTTOM: ["left", "right", "bottom"],
} as const

// Components
export const HEADER_HEIGHT_SMALL = 56
export const HEADER_HEIGHT = 200
export const row = {
  MIN_HEIGHT: 64,
  MAX_HEIGHT: Platform.select({
    ios: 91,
    android: 91,
    default: 91,
  }),
  LEVEL_HEIGHT: Platform.select({
    ios: 91,
    android: 91,
    default: 91,
  }),
}
export const DATA_PICKER_MAX_WIDTH = 350
export const buttonWidth = 220
// Bottom Sheet
export const bottomSheet = {
  MAX_HEIGHT_1: 272,
  MAX_HEIGHT_2: 420,
  MAX_HEIGHT_3: 454,
  MAX_WIDTH: 880,
  BOTTOM_BUTTONS_CONTAINER_HEIGHT: 58,
  // TODO: review height value?
  // previously 120
  CLUE_CONTAINER_HEIGHT: 83,
}

// Helper in bottom sheet
export const HELP_MIN_HEIGHT =
  bottomSheet.MAX_HEIGHT_1 - modal.HEADER - bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT

// ClickOnMistake const
export const CLICK_INDICATOR_SIZE = 45
export const PROGRESS_WIDTH = 100
export const DEFAULT_PROGRESSION_DURATION = 250
export const HIGHLIGHT_OVERFLOW = 2
export const HIGHLIGHT_HEIGHT = 30

// Dictation
export const MAX_LENGTH = 28
export const INPUT_WIDTH = 12

// reference: developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#navigation_keys
export const ignoredValuesUppercase = [
  "ENTER",
  "TAB",
  "ARROWDOWN",
  "ARROWLEFT",
  "ARROWRIGHT",
  "ARROWUP",
  "END",
  "HOME",
  "PAGEDOWN",
  "PAGEUP",
  "CLEAR",
  "COPY",
  "CRSEL",
  "CUT",
  "DELETE",
  "EXSEL",
  "INSERT",
  "PASTE",
  "REDO",
  "UNDO",
]

// PickOutOne
export const PICK_OUT_ONE_PARSING_REGEX = /(_____)/g
export const PICK_OUT_ONE_PARSING = "_____"

// Drag and Drop
export const MAX_TAP_DISTANCE = 20

// Evaluation
/* in seconds */
export const maxTime = 3600
/* in seconds */
export const reducedTimeLimit = 60

export const LOCAL_STORAGE = {
  reload: "reload",
  preferences: "preferences",
  auth: "auth",
  scenarioId: "scenarioId",
} as const

// Server
export const BUFFER_LIMIT = 5
export const TIME_INTERVAL = 1000
export const TIME_INTERVAL_MAX = 30000
export const BUFFER_TIMEOUT = 60000 // in milliseconds

export const screens: Record<RootRouteNames, string> = {
  Home: "*",
  Level: "levels",
  Exercise: "exercise",
  Evaluation: "evaluation",
  PracticeTest: "practiceTest",
  Profile: "profile",
  Settings: "settings",
  Login: "login",
  TokenLogin: "tokenLogin",
  GarLogin: "GAR",
  OAuthLogin: "oauthLogin",
  Loading: "loading",
  Presential: "presential",
  LoggedOut: "loggedOut",
  ConsultationMode: "consultation",
  WebPage: "webpage",
}

export const datadogClientToken = "pub7a37a2745f2cbe627e703598dfffb32c"
