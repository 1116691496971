import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const CheckSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { primary_400 },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 22} height={props?.height ?? 22} viewBox="0 0 22 22" fill="none">
      <Path
        d="M8.3 14.753 4.547 11l-1.278 1.269L8.3 17.3 19.1 6.5l-1.269-1.269L8.3 14.753Z"
        fill={props?.color ?? primary_400}
      />
    </Svg>
  )
}

export default CheckSVG
