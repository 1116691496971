import { Button } from "components/Button"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { useExercise } from "providers/ExerciseProvider"
import type { FC, ReactElement } from "react"
import { useCallback, useMemo } from "react"
import { View } from "react-native"
import { IconButton } from "react-native-paper"
import useTheme from "theme/ThemeProvider"

const QAConsultationButtons: FC<{ arrows?: boolean }> = ({ arrows = false }): ReactElement => {
  const t = useTypedTranslation()
  const {
    colors: {
      onSurface: { button },
    },
  } = useTheme()
  const { consultation } = useExercise()
  const onPress = useCallback((next: boolean) => consultation?.searchExercise(next), [consultation])

  const s = useStyles(
    ({ dimensions: { margin } }) => ({
      button: {
        width: 150,
      },
      icon: {
        margin: 0,
      },
      view: {
        flexDirection: "row",
        gap: margin,
        justifyContent: "center",
        marginTop: margin / 2,
      },
    }),
    [],
  )

  const buttonsProps = useMemo(
    () => ({
      contentStyle: s.button,
      labelStyle: { color: button },
    }),
    [s.button, button],
  )

  return arrows ? (
    <View style={{ flexDirection: "row" }}>
      <IconButton icon="chevron-left" onPress={() => onPress(false)} style={s.icon} />
      <IconButton
        icon="chevron-right"
        mode="contained"
        onPress={() => onPress(true)}
        style={s.icon}
      />
    </View>
  ) : (
    <View style={s.view}>
      <Button {...buttonsProps} mode="contained" onPress={() => onPress(false)}>
        {t("common.button.previous")}
      </Button>
      <Button {...buttonsProps} onPress={() => onPress(true)} mode="contained">
        {t("common.button.next")}
      </Button>
    </View>
  )
}

export default QAConsultationButtons
