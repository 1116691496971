import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ReplaySVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  const color = props?.color ?? onSurface.highEmphasis
  return (
    <Svg
      width={props?.width ?? iconSize.SMALL}
      height={props?.height ?? iconSize.SMALL}
      viewBox="0 0 48 48"
      fill={color}
      {...props}
    >
      <Path
        d="M35.3 12.7C32.4 9.8 28.42 8 24 8 15.16 8 8.02 15.16 8.02 24S15.16 40 24 40c7.46 0 13.68-5.1 15.46-12H35.3c-1.64 4.66-6.08 8-11.3 8-6.62 0-12-5.38-12-12s5.38-12 12-12c3.32 0 6.28 1.38 8.44 3.56L26 22h14V8l-4.7 4.7z"
        fill={color}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default ReplaySVG
