import type {
  ClickOnMistakeExercise,
  ExerciseType,
  IntensiveTrainingExercise,
  Result,
  Rule,
} from "@newpv/js-common"
import IntensiveTrainingItem from "components/ExerciseContents/IntensiveTrainingItem"
import { Tabs } from "components/ExerciseContents/IntensiveTrainingTabs"
import { isWeb, maxScreenWidth, minCarouselHeight } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { useBottomSheet } from "providers/BottomSheetProvider"
import { useExercise } from "providers/ExerciseProvider"
import TabProvider from "providers/TabProvider"
import type { FC } from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { useWindowDimensions, View } from "react-native"
import type { ICarouselInstance } from "react-native-reanimated-carousel"
import Carousel from "react-native-reanimated-carousel"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import { Button } from "../Button"
import { PaginationDots } from "../PaginationDots/PaginationDots"

interface IProps {
  rule: Omit<Rule, "exercises">
  exercises?: IntensiveTrainingExercise[]
  onLevelPress?: (
    value: Result,
    providedHeight?: number,
    intensiveTraining?: boolean,
  ) => Promise<void> | undefined
  /** Function to call if no available exercises */
  onBottomSheetNextButtonPress: (hide?: () => void, skip?: boolean) => void
  exerciseType?: ExerciseType
}

const IntensiveTraining: FC<IProps> = ({
  rule,
  exercises,
  onLevelPress,
  onBottomSheetNextButtonPress,
  exerciseType,
}) => {
  const { isSmallWidth } = useDeviceTools()
  const {
    dimensions: { spacing: themeSpacing },
    colors: { ripple, primary_400, onSurface },
  } = useTheme()
  const t = useTypedTranslation()
  const { onLayout, width } = useLayout()
  const { width: windowWidth } = useWindowDimensions()
  // swiper.current.getCurrentIndex() doesn't give the correct state for pagination dots
  const [activeIndex, setActiveIndex] = useState(0)
  /** Animation refs */
  const swiper = useRef<ICarouselInstance>(null)

  const {
    currentExercise: { intensiveCount },
  } = useExercise()
  const { hideBottomSheet } = useBottomSheet()

  const isSurgingVideoMode = useMemo(() => {
    if (intensiveCount) {
      const intensiveCountModulo = intensiveCount % 4
      if (intensiveCountModulo === 0 || intensiveCountModulo === 2) {
        return true
      }
    }
    return false
  }, [intensiveCount])

  const s = useStyles(
    ({ dimensions: { spacing, margin }, colors, roundness }) => ({
      buttonContinue: {
        borderWidth: 0,
        backgroundColor: colors.primary_400,
      },
      buttonLabel: {
        color: onSurface.button,
      },
      buttonUnderstoodContent: {
        margin: margin * 2,
      },
      carouselContainer: {
        flex: isSurgingVideoMode ? 1 : undefined,
        marginTop: isSurgingVideoMode ? undefined : margin * 2,
      },
      container: {
        alignSelf: "center",
        maxWidth: maxScreenWidth,
        paddingHorizontal: isSmallWidth ? spacing : spacing * 1.5,
        paddingVertical: spacing,
        width: "100%" as const,
        backgroundColor: colors.surface.surface,
        borderRadius: roundness * 2,
        marginTop: isSmallWidth ? 0 : spacing * 2,
        paddingBottom: 100,
      },
      paginationContainer: {
        alignSelf: "center",
        marginVertical: margin,
        alignItems: "center",
      },
      surgingContinue: {
        flexDirection: "row",
        justifyContent: "flex-end",
      },
    }),
    [swiper, windowWidth, isSurgingVideoMode, isSmallWidth],
  )

  const hasExercises = exercises?.length === 3

  const swipeNextIntensiveExercise = useCallback(() => {
    if (!swiper.current) {
      return
    }
    if (swiper.current.getCurrentIndex() < 2) {
      setActiveIndex(old => old + 1)
      swiper.current.scrollTo({
        index: swiper.current.getCurrentIndex() + 1,
        animated: true,
      })
    }
  }, [])

  const formattedIntensiveTrainingExercises: ClickOnMistakeExercise[] = useMemo(
    () =>
      exercises?.map((exercise, index) => ({
        id: index,
        type: "click_on_mistake",
        hasMistake: !exercise.correcte,
        sentence: [{ text: exercise.sentence }],
      })) ?? [],
    [exercises],
  )

  const renderItem = useMemo(
    () =>
      ({ item, index }: { item: ClickOnMistakeExercise; index: number }) =>
        (
          <>
            <IntensiveTrainingItem
              {...{ index, item, width, onLevelPress, swipeNextIntensiveExercise }}
            />
            {swiper.current ? (
              <View style={s.paginationContainer}>
                <PaginationDots
                  total={3}
                  currentActiveIndex={activeIndex}
                  activeColor={primary_400}
                  inactiveColor={onSurface.disabled}
                />
              </View>
            ) : null}
          </>
        ),
    [
      activeIndex,
      onLevelPress,
      onSurface.disabled,
      primary_400,
      s.paginationContainer,
      swipeNextIntensiveExercise,
      width,
    ],
  )

  return (
    <View style={s.container}>
      <TabProvider
        initialTab={{
          index: isSurgingVideoMode && !!rule.resources?.videos ? "video" : "rule",
          title: "",
        }}
      >
        <Tabs {...{ rule, swiper, exercises, intensiveCount, isSurgingVideoMode }} />
      </TabProvider>
      <View style={s.carouselContainer} onLayout={onLayout}>
        {!isSurgingVideoMode && hasExercises ? (
          <Carousel
            {...{ renderItem }}
            data={formattedIntensiveTrainingExercises ?? []}
            width={
              windowWidth <= maxScreenWidth
                ? windowWidth - 2 * themeSpacing
                : maxScreenWidth - 2 * themeSpacing
            }
            ref={swiper}
            snapEnabled={false}
            loop={false}
            enabled={false}
            // ts ignore because of web style
            // @ts-ignore
            style={{
              minHeight: minCarouselHeight,
              alignSelf: "center",
              position: isWeb ? "static" : "relative",
            }}
          />
        ) : null}
        {exerciseType === "email_pick_out_one" || isSurgingVideoMode || !hasExercises ? (
          <View style={s.surgingContinue}>
            <Button
              mode="outlined"
              rippleColor={rippleColor(ripple)}
              labelStyle={s.buttonLabel}
              style={s.buttonContinue}
              onPress={() => onBottomSheetNextButtonPress(hideBottomSheet)}
            >
              {t("common.button.continue")}
            </Button>
          </View>
        ) : null}
      </View>
    </View>
  )
}

export default IntensiveTraining
