import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ArrowUpSVG = (props?: SvgProps): JSX.Element => {
  const { colors } = useTheme()
  return (
    <Svg
      {...props}
      width={props?.width ?? iconSize.SMALL}
      height={props?.height ?? iconSize.SMALL}
      fill="none"
    >
      <Path d="m7 14 5-5 5 5H7Z" fill={props?.color ?? colors.onSurface.mediumEmphasis} />
    </Svg>
  )
}

export default ArrowUpSVG
