import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const CalendarSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} fill="none" viewBox="0 0 24 24">
      <Path
        d="M9 10v2H7v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Zm2-7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1Zm0 16V8H5v11h14ZM9 14v2H7v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Z"
        fill={props?.color ?? onSurface.disabled}
      />
    </Svg>
  )
}

export default CalendarSVG
