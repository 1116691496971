/* eslint-disable no-bitwise */
import type { Level, LevelId, LevelRevision, LevelStatic, LevelType } from "@newpv/js-common"
import RowItem from "components/RowItem/RowItem"
import { StepIndicator } from "components/StepIndicator/StepIndicator"
import { Subtitle1 } from "components/Texts"
import { levelImages } from "config/images"
import { isiOS, isWeb } from "constants/constants"
import dayjs from "dayjs"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import type { LevelRowProps } from "models/ModuleInterfaces"
import { isStaticLevelLocked } from "models/RevisionLevelFunctions"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import type { BottomState } from "providers/ModalProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { Dispatch, FC, SetStateAction } from "react"
import { useCallback, useMemo } from "react"
import { View } from "react-native"
import RevisionLevelSVG from "svgs/RevisionLevelSVG"
import useTheme from "theme/ThemeProvider"

interface IProps extends LevelRowProps {
  /* levels to display in this block - static levels and corresponding revision level */
  setDisplayedLevelIdAndType: Dispatch<SetStateAction<{ id: LevelId; type?: LevelType }>>
  setBottomModalState: Dispatch<SetStateAction<BottomState>>
}

export const LevelsBlock: FC<IProps> = ({
  levels,
  displayedModuleId,
  moduleProgression,
  onRowItemPress,
}) => {
  const cs = useCommonStyles()
  const t = useTypedTranslation()
  const {
    typography,
    colors: { onSurface },
  } = useTheme()
  const {
    preferenceState: { openAllLevelsAndModules },
  } = usePreferences()
  const { groupedLevels } = useLevelAndEvaluation()
  const { moduleId, module } = useScenarioAndModule()

  const [revisionLevels, staticLevels] = _.partition(levels, lvl => lvl.type === "revision")
  const revisionLevel = revisionLevels?.[0] as LevelRevision

  const revisionLevelProgression = useMemo(
    () => moduleProgression?.levels?.[revisionLevel.id],
    [moduleProgression?.levels, revisionLevel.id],
  )

  const isRevisionLevelAvailable = useMemo(
    () =>
      _.every(
        staticLevels.map(level => level.id),
        // only display step indicators if all the previous levels are finished
        levelId => (moduleProgression?.levels?.[levelId]?.completionPercentage ?? 0) >= 100,
      ),
    [moduleProgression?.levels, staticLevels],
  )
  const isRevisionAutoValidated =
    isRevisionLevelAvailable && _.isEmpty(revisionLevelProgression?.revisionRuleIds)

  const isAfterCooldown = dayjs().isAfter(dayjs(revisionLevelProgression?.revisionOpenDate))

  const isRevisionLevelCooldownOver = isRevisionLevelAvailable && isAfterCooldown

  const s = useStyles(
    ({
      roundness,
      dimensions: { spacing },
      colors: {
        surface: { outline },
      },
    }) => ({
      blockContainer: {
        borderColor: outline,
        borderRadius: roundness * 2,
        borderWidth: 1,
        marginBottom: spacing,
      },
      rowStyle: {
        borderRadius: roundness * 2,
        flex: 1,
        height: 100,
        justifyContent: "center",
        paddingVertical: spacing,
      },
      divider: {
        backgroundColor: outline,
        height: 1,
        width: "100%" as const,
      },
    }),
    [isAfterCooldown, isiOS],
  )

  const isLocked = useCallback(
    (id: number) =>
      isStaticLevelLocked(_.flatten(groupedLevels), id, moduleProgression, module?.interlock) &&
      !openAllLevelsAndModules,
    [groupedLevels, module?.interlock, moduleProgression, openAllLevelsAndModules],
  )

  const titleStyle = useCallback(
    (id: number) => ({
      ...typography.h6,
      color: isLocked(id) ? onSurface.lowEmphasis : onSurface.highEmphasis,
    }),
    [isLocked, onSurface.highEmphasis, onSurface.lowEmphasis, typography.h6],
  )

  const renderDescription = useCallback(
    (lvl: Level) => {
      const color = isLocked(lvl.id) ? onSurface.lowEmphasis : onSurface.mediumEmphasis
      return (
        <Subtitle1 {...{ color }} numberOfLines={2}>
          {(lvl as LevelStatic).description}
        </Subtitle1>
      )
    },
    [isLocked, onSurface.lowEmphasis, onSurface.mediumEmphasis],
  )

  return (
    <View style={s.blockContainer}>
      {/** static levels */}
      {staticLevels.map((level, key) => {
        const { id } = level
        const LeftIcon = levelImages[key % levelImages.length]
        const completionPercentage = moduleProgression?.levels[id]?.completionPercentage ?? 0
        const locked = isLocked(id)

        return (
          <View
            key={`${displayedModuleId ?? "disp"}=${id ?? key}`}
            style={cs.row}
            // @ts-ignore
            accessibilityRole={isWeb ? "listitem" : undefined}
            accessibilityLabel={t("Level.levelNumber", { count: key })}
            dataSet={{ id }}
          >
            <RowItem
              key={`row-${id}`}
              separator={false}
              onPress={() => onRowItemPress(level, locked)}
              rowStyle={s.rowStyle}
              titleStyle={titleStyle(id)}
              title={(level as LevelStatic).title}
              leftIcon={() => <LeftIcon color={locked ? onSurface.disabled : undefined} />}
              description={renderDescription(level)}
              rightNode={{
                key: `right-${id}`,
                percent: completionPercentage || 0,
                locked,
                disabled: locked,
              }}
            />
          </View>
        )
      })}
      <View style={s.divider} />
      {/** revision level */}
      {revisionLevel ? (
        <RowItem
          title={revisionLevel.title}
          titleStyle={{
            ...typography.h6,
            color:
              isRevisionLevelAvailable || openAllLevelsAndModules
                ? onSurface.highEmphasis
                : onSurface.lowEmphasis,
          }}
          description={
            moduleId && isRevisionLevelAvailable && !isRevisionAutoValidated ? (
              <StepIndicator
                maxRulePerStep={3}
                revisionDescription={revisionLevel.description}
                revisionLevelId={revisionLevel.id}
              />
            ) : undefined
          }
          rowStyle={s.rowStyle}
          leftIcon={() => (
            <RevisionLevelSVG color={isRevisionLevelAvailable ? undefined : onSurface.disabled} />
          )}
          rightNode={
            isRevisionAutoValidated
              ? {
                  key: `${revisionLevel.id}`,
                  percent: 100,
                  locked: false,
                  disabled: false,
                  isRevisionLevel: true,
                  autoValidateRevisionLevel: true,
                }
              : {
                  key: `${revisionLevel.id}`,
                  percent: revisionLevelProgression?.completionPercentage || 0,
                  locked: !isRevisionLevelCooldownOver,
                  disabled: !isRevisionLevelCooldownOver,
                  isRevisionLevel: true,
                  revisionOpenDate: revisionLevelProgression?.revisionOpenDate,
                }
          }
          onPress={
            moduleId
              ? () => {
                  onRowItemPress(revisionLevel, !isRevisionLevelAvailable)
                }
              : undefined
          }
          separator={false}
        />
      ) : null}
    </View>
  )
}
