import type { EmailPickOutOneOption } from "@newpv/js-common"
import ClueView from "components/ClueView/ClueView"
import { EmailExerciseHeader } from "components/Headers/EmailExerciseHeader"
import { Body2 } from "components/Texts"
import { bottomSheet, isAndroid, isiOS, isWeb, maxScreenWidth } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import { useBottomSheet } from "providers/BottomSheetProvider"
import { useExercise } from "providers/ExerciseProvider"
import type { FC } from "react"
import { useCallback, useEffect, useMemo } from "react"
import { View } from "react-native"
import { TouchableRipple } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import type { ButtonConfigProp } from "../coreComponents/BottomSheetButtons"
import BottomSheetButtons from "../coreComponents/BottomSheetButtons"
import BottomSheetScrollView from "../coreComponents/BottomSheetScrollView"

interface Props {
  exerciseAnswerOptions: EmailPickOutOneOption[]
  headerHeight?: number
  emailPickOutOneButtonConfig: ButtonConfigProp
  displayClue?: boolean
}

const BottomSheetAnswerOptions: FC<Props> = ({
  headerHeight,
  emailPickOutOneButtonConfig,
  displayClue = false,
}) => {
  const { bsState, showBottomSheet, hideBottomSheet, getSliderMaxHeight, closeBottomSheet } =
    useBottomSheet()
  const {
    currentExercise: { isClueVisible },
    emailPickOutOneState: { setSelectedOption, selectedOption, emailPickOutOneOptions },
  } = useExercise()
  const { height, onLayout } = useLayout()
  const { isSmallWidth } = useDeviceTools()
  const sliderMaxHeight = getSliderMaxHeight(headerHeight ?? 0)
  const {
    colors: { ripple },
    fontMaker,
    typography,
  } = useTheme()
  const s = useStyles(
    ({ dimensions: { spacing }, colors: { surface, secondary, onSurface }, roundness }) => ({
      activeAnswer: {
        borderColor: secondary,
      },
      activeLabelAnswer: {
        ...typography.body1,
        ...fontMaker({ weight: "SemiBold" }),
      },
      answerContainer: {
        borderColor: surface.outline,
        borderRadius: roundness,
        borderWidth: 1,
        marginBottom: spacing / 2,
        padding: spacing * 1.5,
      },
      container: {
        margin: "auto",
        maxWidth: maxScreenWidth,
        width: "100%" as const,
      },
      contentContainer: {
        padding: spacing,
        paddingBottom: isSmallWidth ? bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT * 1.5 : undefined,
        backgroundColor: surface.backgroundModal,
      },
      contentScroll: {
        padding: spacing / 2,
        // ??
        paddingBottom: isAndroid
          ? // maybe not necessary, since the buttons are in the component itself
            bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT
          : isiOS
          ? (bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT ?? 0) / 2
          : undefined,
      },
      customButtonContainerStyle: isSmallWidth ? { justifyContent: "flex-end" } : { width: "100%" },
      disabledOptionText: {
        color: onSurface.disabled,
      },
      disabledOptionTouchable: {
        backgroundColor: surface.background,
        cursor: "default",
      },
      optionText: {
        textAlign: "center",
        color: onSurface.highEmphasis,
      },
      outerContainer: {
        alignSelf: "center",
        height:
          bsState.modalVisible && isSmallWidth
            ? sliderMaxHeight - height - bottomSheet.CLUE_CONTAINER_HEIGHT
            : undefined,
        maxWidth: maxScreenWidth,
        ...(!isSmallWidth
          ? {
              borderWidth: 1,
              borderRadius: 8,
              overflow: "hidden",
              borderColor: secondary,
            }
          : undefined),
        width: "100%" as const,
      },
      scroll: {
        backgroundColor: surface.backgroundModal,
        margin: "auto",
        maxHeight: "100%",
        maxWidth: maxScreenWidth,
        paddingBottom: isWeb ? spacing * 3 : undefined,
        width: "100%" as const,
      },
    }),
    [bsState.panelOpen, bottomSheet, isAndroid, bsState.modalVisible, isSmallWidth],
  )

  const togglePress = useCallback(
    () => (bsState.modalVisible ? hideBottomSheet() : showBottomSheet()),
    [showBottomSheet, hideBottomSheet, bsState.modalVisible],
  )

  const indexOfClue = useMemo(
    () =>
      _.sample(
        emailPickOutOneOptions
          .map((option, index) => (!option.isAnswer ? index : undefined))
          .filter(e => e),
      ),
    [emailPickOutOneOptions],
  )

  useEffect(() => {
    if (isClueVisible && selectedOption?.index === indexOfClue) {
      setSelectedOption?.(undefined)
    }
  }, [isClueVisible, indexOfClue, selectedOption?.index, setSelectedOption])

  return (
    <View style={s.outerContainer}>
      <View {...onLayout} style={s.container}>
        {/* // all the logic in this component is based on modalVisible and not panelOpen */}
        <EmailExerciseHeader isPanelOpen={bsState.modalVisible} onPress={togglePress} />
      </View>
      {bsState.modalVisible || !isSmallWidth ? (
        <>
          <BottomSheetScrollView contentContainerStyle={s.contentContainer} style={s.scroll}>
            {emailPickOutOneOptions.map((el, index) => (
              <TouchableRipple
                key={`option-${index}`}
                rippleColor={rippleColor(ripple)}
                style={[
                  s.answerContainer,
                  selectedOption?.index === index ? s.activeAnswer : null,
                  isClueVisible && index === indexOfClue ? s.disabledOptionTouchable : null,
                ]}
                onPress={() => {
                  setSelectedOption?.({ answer: el, index })
                }}
                disabled={isClueVisible && index === indexOfClue}
              >
                <Body2
                  style={[
                    s.optionText,
                    isClueVisible && index === indexOfClue ? s.disabledOptionText : null,
                    selectedOption?.index === index ? s.activeLabelAnswer : undefined,
                  ]}
                >
                  {el.text}
                </Body2>
              </TouchableRipple>
            ))}
            {displayClue ? <ClueView /> : null}
          </BottomSheetScrollView>
          <BottomSheetButtons
            {...{ hideBottomSheet, showBottomSheet, closeBottomSheet }}
            buttonConfig={emailPickOutOneButtonConfig}
            customButtonContainerStyle={s.customButtonContainerStyle}
          />
        </>
      ) : null}
    </View>
  )
}

export default BottomSheetAnswerOptions
