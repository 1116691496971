import type {
  PickOutNearestExercise,
  PickOutOneExercise,
  PickOutOneWordBlock,
  Result,
  Rule,
} from "@newpv/js-common"
import { isPickOutNearest, isPickOutOne } from "@newpv/js-common"
import { bottomSheet, maxScreenWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import BottomSheetProvider from "providers/BottomSheetProvider"
import { useExercise } from "providers/ExerciseProvider"
import { usePreferences } from "providers/PreferenceProvider"
import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"
import { useWindowDimensions, View } from "react-native"
import { getWordArray } from "utils/getWordArray"

import HelperView from "../HelperView/HelperView"
import ToggleButton from "../ToggleButton/ToggleButton"

interface IProps {
  result?: Result
  isEvaluation?: boolean
  onSelectPress?: (value: Result) => Promise<void>
  rule: Omit<Rule, "exercises">
  exercise: PickOutOneExercise | PickOutNearestExercise
}

const PickOutOne: FC<IProps> = ({ result, exercise, rule, isEvaluation, onSelectPress }) => {
  const [pressedWord, setPressedWord] = useState<string>()
  const { width: windowWidth } = useWindowDimensions()
  const contentWidth = windowWidth > maxScreenWidth ? maxScreenWidth : windowWidth
  const {
    currentExercise: {
      isCrucialQuestion,
      getRandomWordsFromOtherExercises,
      isExplanationAllowed,
      isClueVisible,
    },
  } = useExercise()
  const randomWordsFromOtherExercises = useMemo(
    () => getRandomWordsFromOtherExercises(isCrucialQuestion ? 2 : 1),
    [getRandomWordsFromOtherExercises, isCrucialQuestion],
  )
  const cs = useCommonStyles()

  const wordsToDisplay = useMemo(
    () =>
      (isPickOutOne(exercise) && exercise.distractorsCount == null) ||
      (isPickOutNearest(exercise) && !randomWordsFromOtherExercises)
        ? _.shuffle(exercise.words)
        : randomWordsFromOtherExercises
        ? // pick_out_nearest
          getWordArray(randomWordsFromOtherExercises, exercise, isCrucialQuestion)
        : // pick_out_one with new key distractorsCount
          getWordArray([], exercise, isCrucialQuestion),
    [exercise, isCrucialQuestion, randomWordsFromOtherExercises],
  )

  const twoOrThreeChoices = wordsToDisplay.length < 4

  const s = useStyles(
    ({ dimensions: { margin, spacing } }) => ({
      instructionSentenceContainer: {
        marginHorizontal: margin,
        marginTop: margin * 1.5,
        width: "100%" as const,
      },
      wordContainer: {
        alignSelf: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingTop: spacing * 1.5,
        width: contentWidth - 4 * spacing,
      },
    }),
    [contentWidth],
  )

  const onPress = useCallback(
    async (word: PickOutOneWordBlock) => {
      setPressedWord(word.text)
      const res = isPickOutNearest(exercise)
        ? word.isAnswer && !word.foreign
          ? "correct"
          : "wrong"
        : word.isAnswer
        ? "correct"
        : "wrong"
      await onSelectPress?.(res)
    },
    [exercise, onSelectPress],
  )

  const pickedWrongWordForQA = useMemo(
    () => _.sample(wordsToDisplay.filter(word => !word?.isAnswer)),
    [wordsToDisplay],
  )

  const {
    preferenceState: { seeCorrection },
  } = usePreferences()

  const indexOfDisabledCard = useMemo(
    () =>
      _.sample(
        wordsToDisplay
          .map((word, index) => (word?.foreign || !word?.isAnswer ? index : undefined))
          .filter(e => e),
      ),
    [wordsToDisplay],
  )

  return (
    <View style={{ width: "100%" }}>
      <View style={s.wordContainer}>
        {wordsToDisplay?.map((word, index) =>
          word ? (
            <ToggleButton
              key={index}
              result={result}
              pressedWord={pressedWord}
              isPickedWrongWordForQA={
                seeCorrection ? pickedWrongWordForQA?.text === word.text : undefined
              }
              {...{ word, isEvaluation, twoOrThreeChoices }}
              isCorrect={!!word.isAnswer && !word.foreign}
              onPress={() => onPress(word)}
              isPressed={word.text === pressedWord}
              isDisabled={isClueVisible && index === indexOfDisabledCard}
            />
          ) : null,
        )}
      </View>
      {isPickOutOne(exercise) && isExplanationAllowed && (rule?.title || rule?.description) ? (
        <View style={cs.viewContainerWithMarginTop}>
          {/* panAndTranslateDisabled? */}
          <BottomSheetProvider sliderMinHeight={bottomSheet.MAX_HEIGHT_1}>
            <HelperView {...{ rule }} />
          </BottomSheetProvider>
        </View>
      ) : null}
    </View>
  )
}

export default PickOutOne
