import { isWeb } from "constants/constants"
import * as Device from "expo-device"
import { useEffect, useState } from "react"

/** Logic from https://github.com/straxico/use-detect-keyboard-open
 * */
export const useKeyboard = (minHeight = 300, defaultValue = false): boolean => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue)
  useEffect(() => {
    if (!isWeb) {
      return
    }
    const listener = async (): Promise<void> => {
      const type = await Device.getDeviceTypeAsync()
      if (type !== Device.DeviceType.PHONE) {
        return
      }
      if (global.window.visualViewport) {
        setIsKeyboardOpen(
          global.window.screen.height - minHeight > global.window.visualViewport.height,
        )
      }
    }
    global.window.visualViewport?.addEventListener("resize", listener)
    return () => {
      global.window.visualViewport?.removeEventListener("resize", listener)
    }
  }, [minHeight])

  return isKeyboardOpen
}
