import type { IRootParamList } from "./RootNavigator"

export const rootRoutes = {
  HOME: "Home",
  LEVEL: "Level",
  EXERCISE: "Exercise",
  EVALUATION: "Evaluation",
  PRACTICE_TEST: "PracticeTest",
  PROFILE: "Profile",
  SETTINGS: "Settings",
  LOGIN: "Login",
  GAR_LOGIN: "GarLogin",
  TOKEN_LOGIN: "TokenLogin",
  OAUTH_LOGIN: "OAuthLogin",
  LOADING: "Loading",
  PRESENTIAL: "Presential",
  LOGGED_OUT: "LoggedOut",
  CONSULTATION: "ConsultationMode",
  WEB_PAGE: "WebPage",
} as const

export type RootRouteNames = typeof rootRoutes[keyof typeof rootRoutes]
export type RootNavigatorParams = Record<RootRouteNames, Record<string, unknown> | undefined>
import type { NavigationContainerRef } from "@react-navigation/core"
import { createRef } from "react"

// https://reactnavigation.org/docs/typescript#specifying-default-types-for-usenavigation-link-ref-etc
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends IRootParamList {}
  }
}

export const navigationRef = createRef<NavigationContainerRef<IRootParamList>>()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const navigate = (name: RootRouteNames, params: any): void | null =>
  navigationRef.current && navigationRef.current.navigate(name, params)
