import AsyncStorage from "@react-native-async-storage/async-storage"
import { LOCAL_STORAGE } from "constants/constants"

/** Called on logout to clear local storage */
export const clearLocalStorage = async (): Promise<void> => {
  try {
    await AsyncStorage.multiRemove([
      LOCAL_STORAGE.preferences,
      LOCAL_STORAGE.auth,
      LOCAL_STORAGE.scenarioId,
      LOCAL_STORAGE.reload,
    ])
  } catch (err) {
    throw new Error("Error during deletion of localStorage")
  }
}
