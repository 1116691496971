import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ClickOnIconSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: {
      secondary_400,
      surface: { surface },
    },
  } = useTheme()
  return (
    <Svg {...props} width={props?.width ?? 106} height={props?.height ?? 106} fill="none">
      <Path
        fill={surface}
        d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35Z"
      />
      <Path
        stroke={secondary_400}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M23.516 33.235a10.566 10.566 0 1 1 14.924 0"
      />
      <Path
        stroke={secondary_400}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M44.185 41.622V38.98c0-3.5-5.283-3.5-5.283 0V36.34c0-3.5-5.283-3.5-5.283 0V25.773c0-3.5-5.283-3.5-5.283 0v20.472c-2.443-2.444-4.82-5.746-7.132-3.434a2.64 2.64 0 0 0 0 3.764l7.462 7.462a2.543 2.543 0 0 0 2.113.727c.793.066 15.255 0 16.048 0a2.595 2.595 0 0 0 2.641-2.576V41.622c0-3.5-5.283-3.5-5.283 0Z"
      />
    </Svg>
  )
}

export default ClickOnIconSVG
