import type { ScenarioId } from "@newpv/js-common"
import { parseInt10 } from "@newpv/js-common"
import { LOCAL_STORAGE } from "constants/constants"
import { useCallback, useEffect } from "react"
import { logger } from "utils/logger"

interface AuthStorage {
  getAuthStorageItems: () => Promise<[string | null, number]>
  setTokenStorageItem: (t: string | null | undefined) => void
  setAuthScenarioIdStorageItem: (t: number | null | undefined) => void
}

const useAuthStorage = (): AuthStorage => {
  useEffect(() => {
    logger("web useAuthStorage")
  }, [])

  const getAuthStorageItems = (): Promise<[string | null, number]> =>
    Promise.resolve([
      // @ts-ignore
      window.sessionStorage.getItem(LOCAL_STORAGE.auth),
      // @ts-ignore
      parseInt10(window.sessionStorage.getItem(LOCAL_STORAGE.scenarioId)),
    ])

  const setTokenStorageItem = useCallback((t): void => {
    if (t === null) {
      // @ts-ignore
      window.sessionStorage.removeItem(LOCAL_STORAGE.auth)
    } else {
      // @ts-ignore
      window.sessionStorage.setItem(LOCAL_STORAGE.auth, t)
    }
  }, [])

  const setAuthScenarioIdStorageItem = useCallback((sid?: ScenarioId | null): void => {
    logger("web setting scenario Id in setScenarioId in AuthProvider, to:", sid)
    if (sid != null) {
      // @ts-ignore
      window.sessionStorage.setItem(LOCAL_STORAGE.scenarioId, sid.toString())
    }
  }, [])

  return {
    getAuthStorageItems,
    setTokenStorageItem,
    setAuthScenarioIdStorageItem,
  }
}
export default useAuthStorage
