import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { Subtitle1 } from "components/Texts"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import React, { useEffect, useState } from "react"
import type { TextStyle } from "react-native"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"

import { quotes } from "./data/quotes"

export const QuotesLoading: React.FC = () => {
  const { isSmallWidth: isMobileBrowser } = useDeviceTools()
  const [quoteIndex, setQuoteIndex] = useState<number>(0)

  const {
    colors: { primary_400 },
  } = useTheme()

  const t = useTypedTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex(Math.floor(Math.random() * quotes.length))
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const cs = useCommonStyles()
  const { quote, marginBottom } = useStyles(({ dimensions: { margin, spacing } }) => ({
    quote: {
      padding: isWeb && isMobileBrowser ? spacing : undefined,
      maxWidth: isWeb ? 450 : 300,
      textAlign: "center",
    } as TextStyle,
    marginBottom: {
      marginBottom: margin * 2,
    },
  }))

  return (
    <View>
      <ActivityIndicator size="large" color={primary_400} style={marginBottom} />
      <Subtitle1 style={quote}>{t(quotes[quoteIndex].content)}</Subtitle1>
      <Subtitle1 style={[quote, cs.marginTop]}>{quotes[quoteIndex].author}</Subtitle1>
    </View>
  )
}
