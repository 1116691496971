import type { ClickOnMistakeExercise, ClickOnWordExercise, Result, Rule } from "@newpv/js-common"
import { isClickOnWord, lett } from "@newpv/js-common"
import { Button } from "components/Button"
import { ExerciseSentence } from "components/Texts"
import {
  bottomSheet,
  buttonMaxWidth,
  CLICK_INDICATOR_SIZE,
  isWeb,
  Keyboard,
} from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useKeyboardEvent from "hooks/useKeyboardEvent"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import BottomSheetProvider from "providers/BottomSheetProvider"
import { useExercise } from "providers/ExerciseProvider"
import { useServer } from "providers/ServerProvider"
import type { FC, KeyboardEvent, RefObject } from "react"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import type { GestureResponderEvent } from "react-native"
import { TouchableWithoutFeedback, View } from "react-native"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"
import {
  getFontStyle,
  splitWordblocks,
  useGetBaseFontConfigFromFontFamily,
} from "utils/parseSentence"

import HelperView from "../HelperView/HelperView"
import ClickOnMistakeWordBlock from "./ClickOnMistakeWordBlock"

interface IProps {
  isEvaluation?: boolean
  exercise: ClickOnMistakeExercise | ClickOnWordExercise
  headerHeight: number
  onSelectPress?: (value: Result) => Promise<void>
  rule: Omit<Rule, "exercises">
  result?: Result
}

const ClickOnMistake: FC<IProps> = ({
  onSelectPress,
  exercise,
  headerHeight,
  isEvaluation,
  rule,
  result,
}) => {
  const {
    colors: { ripple },
    fontMaker,
  } = useTheme()
  const { isSendingInteraction } = useServer()
  const t = useTypedTranslation()
  const [buttonNoMistakeRef] = useState<RefObject<View> | null>()
  const [keyPressed, setKeyPressed] = useState(false)
  const [hover, setHover] = useState(false)
  const {
    currentExercise: { isClueAllowed, isExplanationAllowed },
  } = useExercise()
  const isClickOnWordExercise = isClickOnWord(exercise)

  const { x, y, onLayout } = useLayout()
  const [extraViewsCoords, setExtraViewsCoords] = useState<
    Record<number, { x: number; y: number }>
  >([])
  const [localCoordsOfClickedWord, setLocalCoordsOfClickedWord] =
    useState<{ x: number; y: number }>()
  const [extraViewTargetIndex, setExtraViewTargetIndex] = useState<number>()

  const coords = useMemo(
    () =>
      lett(localCoordsOfClickedWord, l => ({
        x: l.x + x + (extraViewTargetIndex != null ? extraViewsCoords[extraViewTargetIndex]?.x : 0),
        y: l.y + y + (extraViewTargetIndex != null ? extraViewsCoords[extraViewTargetIndex]?.y : 0),
      })),
    [extraViewTargetIndex, extraViewsCoords, localCoordsOfClickedWord, x, y],
  )

  const parsedSentence = useMemo(() => splitWordblocks(exercise.sentence), [exercise.sentence])

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      roundness,
      dimensions: { margin, spacing },
      colors: { neutral_200, primary_400, secondary_400 },
    }) => ({
      buttonContainer: {
        alignItems: "center",
        marginHorizontal: margin,
      },
      buttonStyle: {
        borderWidth: 0,
        backgroundColor: primary_400,
        borderRadius: roundness * 4,
        margin: margin / 2,
        maxWidth: buttonMaxWidth,
      },
      paddingContainer: {
        paddingHorizontal: spacing,
      },
      wordClickDisk: {
        backgroundColor:
          isEvaluation && coords
            ? neutral_200
            : hover && result
            ? result === "correct"
              ? primary_400
              : secondary_400
            : "transparent",
        borderRadius: CLICK_INDICATOR_SIZE,
        height: CLICK_INDICATOR_SIZE,
        width: CLICK_INDICATOR_SIZE,
        position: "absolute",
        top: coords ? coords.y - CLICK_INDICATOR_SIZE / 2 : undefined,
        left: coords ? coords.x - CLICK_INDICATOR_SIZE / 2 : undefined,
        zIndex: -1,
      },
    }),
    [coords, headerHeight, result, hover, isEvaluation, isClueAllowed],
  )

  const screenOnPress = useCallback(() => {
    setHover(false)
  }, [])

  const onNoMistakeButtonPress = useCallback(
    async (e: GestureResponderEvent | KeyboardEvent) => {
      if (!isClickOnWordExercise) {
        e.stopPropagation()
        const res = exercise.hasMistake ? "error/missed-mistake" : "correct"
        await onSelectPress?.(res)
      }
    },
    [exercise, isClickOnWordExercise, onSelectPress],
  )

  useEffect(() => {
    if (result === undefined) {
      setHover(false)
      setLocalCoordsOfClickedWord(undefined)
    }
  }, [result])

  const baseFontConfig = useGetBaseFontConfigFromFontFamily("exerciseSentence")
  const fontStyle = useMemo(
    () => getFontStyle(baseFontConfig, { text: " " }, fontMaker),
    [baseFontConfig, fontMaker],
  )

  const exerciseHasMistake = isClickOnWordExercise
    ? false
    : (exercise as ClickOnMistakeExercise).hasMistake

  const handleEnterKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === Keyboard.ENTER && !keyPressed) {
        setKeyPressed(true)
        await onNoMistakeButtonPress(event)
      }
    },
    [keyPressed, onNoMistakeButtonPress],
  )

  useKeyboardEvent(handleEnterKeyPress, buttonNoMistakeRef)

  return (
    <TouchableWithoutFeedback onPress={screenOnPress}>
      <>
        {exercise ? (
          // global wrapper for the whole sentence
          <View style={cs.sentenceContainer} {...{ onLayout }}>
            {/* mapping on the different blocks of the sentence, some blocks can have a "noSpaceAfter" property, and should then be in the same View as the following word (not necessarily the whole following block) */}
            {parsedSentence.map((block, i) => {
              // condition on i===0 ?...
              if (!block.noSpaceAfter && !block.afterNoSpace) {
                return (
                  <ClickOnMistakeWordBlock
                    index={i}
                    key={`${block.text}-${i}`}
                    {...{
                      block,
                      result,
                      setHover,
                      isEvaluation,
                      onSelectPress,
                      setLocalCoordsOfClickedWord,
                      setExtraViewTargetIndex,
                      parsedSentence,
                      exerciseHasMistake,
                    }}
                  />
                )
              }

              // while the following block is a afterNoSpace OR a noSpaceAfter, we keep them inside the View
              // we create a subArray from the original array, starting from the index and stopping at the first element that is not an afterNoSpace or noSpaceAfter
              if (block.noSpaceAfter && !block.afterNoSpace) {
                const noSpaceAfterAndFollowing = [
                  block,
                  ..._.takeWhile(
                    parsedSentence.slice(i + 1),
                    wordBlockWithExtraData => wordBlockWithExtraData.afterNoSpace,
                  ),
                ]
                return (
                  <Fragment key={`${block.text}-${i}`}>
                    {/* this space is usually inserted in ClickOnMistakeWordBlock, but in this case we want it outside the View */}
                    {i > 0 &&
                    !parsedSentence[i - 1].breakAfter &&
                    !parsedSentence[i - 1].endsWithSpace ? (
                      <ExerciseSentence
                        style={[
                          // @ts-ignore
                          isWeb ? { cursor: "pointer" } : {},
                          fontStyle,
                        ]}
                      >
                        {" "}
                      </ExerciseSentence>
                    ) : null}
                    <View
                      style={{
                        overflow: "visible",
                        flexDirection: "row",
                      }}
                      onLayout={e => {
                        setExtraViewsCoords(prevState => ({
                          ...prevState,
                          [i]: { x: e.nativeEvent.layout.x, y: e.nativeEvent.layout.y },
                        }))
                      }}
                    >
                      {/* only display the current block if the previous one was not a "noSpaceAfter" block */}
                      {noSpaceAfterAndFollowing.map((blockInExtraView, subArrayIndex) => (
                        <ClickOnMistakeWordBlock
                          key={subArrayIndex}
                          leadingBlockIndex={i}
                          index={i + subArrayIndex}
                          block={blockInExtraView}
                          {...{
                            result,
                            setHover,
                            isEvaluation,
                            onSelectPress,
                            setLocalCoordsOfClickedWord,
                            setExtraViewTargetIndex,
                            parsedSentence,
                            exerciseHasMistake,
                          }}
                          isInExtraView={true}
                        />
                      ))}
                    </View>
                  </Fragment>
                )
              }
              return null
            })}
          </View>
        ) : null}
        {isClickOnWordExercise ? null : (
          <View style={s.buttonContainer}>
            <Button
              focusable={true}
              ref={buttonNoMistakeRef}
              disabled={isSendingInteraction}
              labelStyle={cs.labelClickOnMistake}
              loading={isSendingInteraction}
              mode="outlined"
              onPress={onNoMistakeButtonPress}
              rippleColor={rippleColor(ripple)}
              style={s.buttonStyle}
            >
              {t("common.button.noError")}
            </Button>
          </View>
        )}
        {isExplanationAllowed && (rule?.title || rule?.description) ? (
          <View style={cs.fullWidth}>
            {/* // panAndTranslateDisabled? */}
            <BottomSheetProvider sliderMinHeight={bottomSheet.MAX_HEIGHT_1}>
              <HelperView {...{ rule }} />
            </BottomSheetProvider>
          </View>
        ) : null}
        <View style={s.wordClickDisk} />
      </>
    </TouchableWithoutFeedback>
  )
}

export default ClickOnMistake
