import type { SvgProps } from "react-native-svg"
import Svg, { Defs, Path, Pattern, Rect } from "react-native-svg"

export const waveLength = 9

const WavyLineSVG = (props?: SvgProps): JSX.Element => (
  <Svg width={props?.width ?? waveLength} height={5} style={props?.style}>
    <Defs>
      <Pattern
        id="WavyPattern"
        patternUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={waveLength}
        height="5"
        viewBox={`0 0 ${waveLength} 5`}
      >
        <Path
          d="M0 5c.908 0 1.618-.245 2.183-.67.533-.4.87-.917 1.11-1.294.271-.428.412-.66.598-.83C4.017 2.093 4.17 2 4.5 2V0c-.836 0-1.47.283-1.959.73-.43.394-.732.912-.937 1.234-.237.373-.409.607-.624.768C.797 2.87.521 3 0 3v2Zm4.5-3c.59 0 .832.281 1.394 1.077.252.357.597.853 1.081 1.237C7.5 4.73 8.156 5 9 5V3c-.374 0-.597-.105-.78-.252-.225-.178-.416-.432-.692-.825C7.03 1.22 6.24 0 4.5 0v2Z"
          fill="#FF1F1F"
        />
      </Pattern>
    </Defs>
    <Rect fill="url(#WavyPattern)" width="100%" height="5" />
  </Svg>
)

export default WavyLineSVG
