import type { StackHeaderProps } from "@react-navigation/stack"
import { Header } from "@react-navigation/stack"
import { edges } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import { SafeAreaView } from "react-native-safe-area-context"
import useTheme from "theme/ThemeProvider"

const CommonHeader = (props: StackHeaderProps): JSX.Element => {
  const { typography } = useTheme()

  const { headerTitleContainerStyle, headerStyle, headerTitleStyle } = useStyles(
    ({ colors: { surface } }) => ({
      headerStyle: {
        backgroundColor: surface.surface,
        shadowColor: "transparent",
      },
      headerTitleContainerStyle: {
        alignItems: "center" as const,
        width: "100%" as const,
      },
      headerTitleStyle: {
        ...typography.h6,
      },
    }),
    [],
  )

  return (
    <SafeAreaView edges={edges.TOP}>
      <Header
        {...props}
        options={{
          ...props.options,
          headerStyle,
          headerTitleStyle,
          headerTitleAlign: "center",
          headerTitleContainerStyle,
        }}
      />
    </SafeAreaView>
  )
}

export default CommonHeader
