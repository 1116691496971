import type { Exercise, ExerciseType, PickOutNearestExercise, RuleId } from "@newpv/js-common"
import { contentUrl, isClickOnMistake } from "@newpv/js-common"
import { audioBaseUrl } from "constants/constants"
import * as Speech from "expo-speech"
import { generateSentence } from "utils/generateSentence"

export const generateAudioExerciseUrl = (
  exerciseType?: ExerciseType,
  id?: RuleId,
  audioUrl?: string,
): string | null => {
  if (exerciseType === "pick_out_one" && audioUrl) {
    return `${contentUrl}/${audioUrl}`
  }
  if (!id) {
    return null
  }
  if (exerciseType === "pick_out_nearest") {
    return `${audioBaseUrl}/chapter/${id}/resource/${id}.mp3`
  } else {
    // dictation (click_on_mistake)
    return `${audioBaseUrl}/exercise/${id}/resource/${id}.mp3`
  }
}

export const speak = (exercise: Exercise): void => {
  Speech.speak(
    isClickOnMistake(exercise)
      ? generateSentence(!exercise.hasMistake ? exercise.sentence : exercise?.corrections?.[0])
      : (exercise as PickOutNearestExercise).instructionWord,
    {
      // this setting does not work on Chrome desktop if the computer itself is not in french
      // but the user will be warned thanks to the information banner
      language: "fr-FR",
    },
  )
}
