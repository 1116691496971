import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg"

const Level6 = (props?: SvgProps): JSX.Element => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <G clipPath="url(#clip0_4222_239993)">
      <Rect width={56} height={56} rx={8} fill={props?.color ?? "#74AF04"} />
      <Path
        d="M-28.725 21.95c6.8 4.687 15 6.928 23.1 7.438 8.1.611 16.4-.306 24.3-2.446 3.9-1.12 7.8-2.445 11.3-4.483 1.7-1.018 3.4-2.14 4.6-3.668.6-.713.9-1.528.8-2.343-.1-.815-.5-1.528-1.3-2.038-1.5-.917-3.5-1.018-5.4-.713-1.9.306-3.7 1.12-5.3 2.242-1.6 1.12-3 2.547-4 4.177-1 1.63-1.6 3.566-1.8 5.502-.2 1.936-.1 3.974.3 5.91.4 1.935 1.1 3.77 2.2 5.297 1.1 1.529 2.6 2.65 4.4 3.363 1.7.713 3.7 1.018 5.6 1.12 3.9.204 7.9-.509 11.8-1.426 3.9-1.019 7.8-2.241 11.6-3.668 7.6-2.853 15.1-6.215 22.5-9.883 3.7-1.834 7.3-3.668 10.9-5.706 3.6-1.935 7.2-3.973 10.8-6.01h.2v.203c-3.4 2.445-6.8 4.687-10.3 6.928-3.5 2.14-7.1 4.28-10.7 6.317-3.6 2.038-7.3 3.872-11 5.706-3.7 1.834-7.5 3.566-11.4 4.992-3.9 1.427-7.8 2.853-11.9 3.77-4.1.917-8.3 1.63-12.6 1.325-2.1-.102-4.3-.51-6.4-1.427-2.1-.917-3.9-2.343-5.3-4.177-1.3-1.834-2.1-4.076-2.5-6.215-.4-2.14-.5-4.381-.2-6.623.3-2.241 1-4.381 2.2-6.215 1.2-1.936 2.8-3.464 4.6-4.687 1.8-1.222 3.9-2.037 6-2.343 1.1-.204 2.2-.204 3.3-.102 1.1.102 2.2.408 3.2 1.12.5.306.9.816 1.3 1.325.3.51.5 1.223.6 1.834.1 1.325-.5 2.547-1.1 3.464-1.4 1.834-3.2 2.955-5.1 3.974-3.7 2.038-7.7 3.362-11.7 4.381-8 2.038-16.4 2.751-24.6 2.038-8.2-.713-16.4-3.057-23.2-7.947-.1 0-.1-.102 0-.204s.2-.102.2-.102z"
        fill={props?.color ?? "#F0FEB4"}
      />
      <Path
        d="M65.879 22.928c-4.606.342-9.212-.514-13.757-2.91-2.243-1.37-4.546-3.08-6.667-5.648a24.498 24.498 0 01-3.09-4.963c-.97-2.054-1.88-4.792-2.243-8.558-.364-3.765.121-7.873.97-10.782.848-2.91 1.879-4.793 2.909-6.504 2.12-3.252 4.424-4.963 6.727-6.161 2.303-1.027 4.666-1.54 6.97-1.37 2.302 0 4.605.685 6.908 1.712.06 0 .06.171.06.342s-.06.171-.06.171c-4.606.343-9.151 1.027-13.575 3.595-1.091.684-2.182 1.369-3.273 2.224a15.24 15.24 0 00-3.09 3.252c-.97 1.37-1.88 2.91-2.546 5.135-.666 2.054-1.03 4.792-.788 7.36.182 2.567.91 4.963 1.758 6.845a24.766 24.766 0 002.848 4.792c2 2.739 4.242 4.622 6.424 6.162 4.424 3.08 9.03 4.45 13.575 4.792.06 0 .061.171.061.171l-.121.343zM-7.864 49.209c1.997-3.322 5.218-5.703 9.981-6.834 2.541-.41 5.463-.593 9.166.017 1.954.352 4.052.878 6.644 1.848 2.592.97 5.852 2.518 9.884 5.233 4.031 2.716 7.98 6.263 10.52 9.11 2.54 2.846 3.938 5.007 5.161 7.034 2.241 3.96 2.816 6.83 2.867 9.296-.125 2.333-.804 4.303-2.152 5.695-1.174 1.526-3.046 2.515-5.268 3.235-.031.04-.206-.094-.38-.229-.176-.134-.145-.174-.145-.174 1.997-3.322 3.613-6.872 3.245-11.82-.144-1.26-.287-2.52-.605-3.916-.319-1.395-.843-2.884-1.748-4.601-.904-1.718-2.014-3.53-3.948-5.72-1.758-2.053-4.37-4.445-7.116-6.3a38.336 38.336 0 00-14.234-6.085c-3.816-.825-6.882-.817-9.567-.58-5.4.513-9.146 2.49-11.811 5.234-.03.04-.206-.094-.206-.094l-.288-.35z"
        fill={props?.color ?? "#F0FEB4"}
      />
    </G>
    <Rect
      x={0.5}
      y={0.5}
      width={55}
      height={55}
      rx={7.5}
      stroke={props?.color ?? "#050628"}
      strokeOpacity={0.16}
    />
    <Defs>
      <ClipPath id="clip0_4222_239993">
        <Rect width={56} height={56} rx={8} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Level6
