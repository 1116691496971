import type { WordBlock } from "@newpv/js-common"
import { isWeb } from "constants/constants"
import type { TextStyle } from "react-native"
import { decodeHtml } from "speedy-entities/lib"
import { createHtmlSaxParser } from "tag-soup"
import useTheme from "theme/ThemeProvider"
import type { FmFunc, StyleName, TypographyVariant, WeightName } from "theme/types"

interface BaseFontConfig {
  baseFamily: string | undefined
  weight: WeightName
  style: StyleName
}

// TODO: see if it would possible to add unit tests to test this function
export const splitWordblocks = (
  wordBlocks: WordBlock[],
): Array<WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }> =>
  wordBlocks
    .reduce((acc: Array<WordBlock & { endsWithSpace?: boolean }>, currentWordBlock) => {
      // if the word block is a noSpaceAfter block
      // split it in two after the LAST part of the sentence
      // keep the noSpaceAfter key only on the last part
      if (!currentWordBlock.noSpaceAfter) {
        acc.push(currentWordBlock)
        return acc
      }
      const currentBlockLastSpaceIndex =
        (currentWordBlock.text?.at(-1) === " "
          ? currentWordBlock.text?.trimEnd().lastIndexOf(" ")
          : currentWordBlock.text?.lastIndexOf(" ")) ?? -1
      if (currentBlockLastSpaceIndex === -1) {
        acc.push(currentWordBlock)
        return acc
      }

      return acc.concat([
        {
          ...currentWordBlock,
          text: currentWordBlock.text.slice(0, currentBlockLastSpaceIndex + 1),
          after: undefined,
          breakAfter: undefined,
          noSpaceAfter: undefined,
          endsWithSpace: true,
        },
        {
          ...currentWordBlock,
          text: currentWordBlock.text.slice(currentBlockLastSpaceIndex + 1),
          before: undefined,
        },
      ])
    }, [])
    .reduce(
      (
        acc: Array<WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }>,
        currentWordBlock,
        index,
        array,
      ) => {
        // every block that follows a noSpaceAfter block must be split after the FIRST part of the sentence
        // and add an extra key - afterNoSpace - to the first part
        if (index === 0 || (index > 0 && !array[index - 1].noSpaceAfter)) {
          acc.push(currentWordBlock)
          return acc
        }
        const firstSpaceIndex =
          (currentWordBlock.text?.[0] === " "
            ? currentWordBlock.text?.trimStart().indexOf(" ")
            : currentWordBlock.text?.indexOf(" ")) ?? -1

        if (firstSpaceIndex === -1) {
          acc.push({ ...currentWordBlock, afterNoSpace: true })
          return acc
        }

        return acc.concat([
          {
            ...currentWordBlock,
            text: currentWordBlock.text.slice(0, firstSpaceIndex),
            after: undefined,
            breakAfter: undefined,
            afterNoSpace: true,
            // doesn't actually end with space but the next one starts with space
            endsWithSpace: true,
          },
          {
            ...currentWordBlock,
            text: currentWordBlock.text.slice(firstSpaceIndex),
            before: undefined,
          },
        ])
      },
      [],
    )

export const useGetBaseFontConfigFromFontFamily = (
  baseTypography: TypographyVariant,
): BaseFontConfig => {
  const { typography } = useTheme()
  return {
    baseFamily: typography[baseTypography].fontFamily?.split("-")[0],
    weight: (typography[baseTypography].fontFamily?.split("-")[1].split(/(?=[A-Z])/g)[0] ??
      "Regular") as WeightName,
    style: (typography[baseTypography].fontFamily?.split("-")[1].split(/(?=[A-Z])/g)[1] ??
      "normal") as StyleName,
  }
}

export const getFontStyle = (
  baseFontConfig: BaseFontConfig,
  wordBlock: WordBlock,
  fontMaker: FmFunc,
): { fontFamily: string } => ({
  fontFamily:
    baseFontConfig.baseFamily +
    "-" +
    (fontMaker({
      weight: wordBlock.bold
        ? baseFontConfig.weight === "SemiBold"
          ? "Black"
          : "SemiBold"
        : baseFontConfig.weight,
      style: wordBlock.italic ? "italic" : "normal",
    }).fontFamily?.split("-")[1] ?? "Regular"),
})

export const cleanHtmlString = (htmlString: string): string => {
  const parsedFragments: any[] = []

  const htmlParser = createHtmlSaxParser(
    {
      startTag(token) {
        if (token.name === "sup" || token.name === "small") {
          parsedFragments.push(`<${token.name}>`)
        }

        if (
          token?.attributes?.["0"]?.name === "class" &&
          token?.attributes?.["0"]?.value === "smallcaps"
        ) {
          parsedFragments.push(`<small>`)
        }
      },
      endTag(token) {
        if (token.name === "sup" || token.name === "small") {
          parsedFragments.push(`</${token.name}>`)
        }
        if (token.name === "span") {
          parsedFragments.push(`</small>`)
        }
      },
      text(token) {
        parsedFragments.push(token.data)
      },
    },
    {
      decodeText: decodeHtml,
      decodeAttribute: decodeHtml,
    },
  )

  htmlParser.parse(htmlString)

  return parsedFragments.join("")
}

export const removeAllMarkup = (htmlString: string): string => {
  const parsedFragments: any[] = []

  const htmlParser = createHtmlSaxParser(
    {
      text(token) {
        parsedFragments.push(token.data)
      },
    },
    {
      decodeText: decodeHtml,
      decodeAttribute: decodeHtml,
    },
  )

  htmlParser.parse(htmlString)

  return parsedFragments.join("")
}

const parseSentence = (
  sentence: string,
  baseFontSize: number,
): Array<{
  content: string
  style?: Omit<TextStyle, "fontSize" | "textAlignVertical"> & {
    textAlignVertical: string | undefined
    fontSize?: string | number
  }
}> =>
  sentence
    .split(/(<sup>.*<\/sup>|<small>.*<\/small>)/g)
    .filter(e => e)
    .map(textSubString => {
      if (textSubString.match(/<sup>.*<\/sup>/)) {
        return {
          style: {
            textAlignVertical: isWeb ? "super" : undefined,
            fontSize: isWeb ? "small" : 0.5 * baseFontSize,
          },
          content: textSubString.substring(5, textSubString.length - 6),
        }
      } else if (textSubString.match(/<sup>.*/)) {
        return {
          style: {
            textAlignVertical: isWeb ? "super" : undefined,
            fontSize: isWeb ? "small" : 0.5 * baseFontSize,
          },
          content: textSubString.substring(5, textSubString.length),
        }
      } else if (textSubString.match(/.*<\/sup>/)) {
        return {
          style: {
            textAlignVertical: isWeb ? "super" : undefined,
            fontSize: isWeb ? "small" : 0.5 * baseFontSize,
          },
          content: textSubString.substring(0, textSubString.length - 6),
        }
      } else if (textSubString.match(/<small>.*<\/small>/)) {
        return {
          style: {
            textTransform: "uppercase",
            fontSize: 0.8 * baseFontSize,
            alignSelf: "flex-end",
            textAlignVertical: "bottom",
            paddingBottom: 1,
          },
          content: textSubString.substring(7, textSubString.length - 8),
        }
      } else if (textSubString.match(/<small>.*/)) {
        return {
          style: {
            textTransform: "uppercase",
            fontSize: 0.8 * baseFontSize,
            alignSelf: "flex-end",
            textAlignVertical: "bottom",
            paddingBottom: 1,
          },
          content: textSubString.substring(7, textSubString.length),
        }
      } else if (textSubString.match(/.*<\/small>/)) {
        return {
          style: {
            textTransform: "uppercase",
            fontSize: 0.8 * baseFontSize,
            alignSelf: "flex-end",
            textAlignVertical: "bottom",
            paddingBottom: 1,
          },
          content: textSubString.substring(0, textSubString.length - 8),
        }
      }
      return { content: textSubString }
    })

export default parseSentence
