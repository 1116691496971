import type { Memo, RuleId } from "@newpv/js-common"
import { iconSize, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { createMemoData } from "models/ServerFunctions"
import useAuthContext from "providers/AuthProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"
import { View } from "react-native"
import { HelperText, TextInput } from "react-native-paper"
import { useMutation } from "react-query"
import CheckSVG from "svgs/Check"
import useTheme from "theme/ThemeProvider"

import LoadingIconButton from "../../LoadingIconButton/LoadingIconButton"
import { Body2 } from "../../Texts"

const MemoContainer: FC<{ ruleId: RuleId }> = ({ ruleId }) => {
  const {
    darkMode,
    colors: {
      onSurface: themeOnSurface,
      surface: { outline },
    },
  } = useTheme()
  const { isSmallWidth } = useDeviceTools()
  const t = useTypedTranslation()
  const { authScenarioId: scenarioId } = useAuthContext()
  const { memos: allMemos, moduleId, setMemo } = useScenarioAndModule()
  const { levelId } = useLevelAndEvaluation()
  const memos = moduleId != null && levelId != null && scenarioId != null ? allMemos?.[levelId] : []
  const savedMemo = _.find(memos, ["ruleId", ruleId]) as unknown as Memo | undefined
  const mutation = useMutation(createMemoData, {
    onMutate: setMemo,
  })

  const [text, setText] = useState(savedMemo?.text ?? "")
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      colors: { primary_400, primary_800, onSurface, surface },
      dimensions: { spacing },
      roundness,
    }) => ({
      button: {
        backgroundColor: _.isEmpty(text) ? onSurface.disabled : primary_400,
        borderRadius: roundness,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        marginLeft: 0,
      },
      inputView: {
        alignItems: "center",
        borderRadius: 0,
        flexDirection: "row",
        position: "relative",
      },
      textInput: {
        backgroundColor: darkMode ? surface.surfaceSecondary : undefined,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        flex: 1,
        height: iconSize.LARGE,
        maxHeight: iconSize.LARGE,
      },
      outlineStyle: {
        borderRadius: roundness,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      memoTitle: {
        paddingTop: spacing,
      },
      memoInformation: {
        paddingTop: spacing / 2,
      },
      info: { bottom: 0, color: primary_800, left: spacing, position: "absolute" },
    }),
    [text],
  )

  const onChangeText = useCallback((txt: string) => {
    setText(txt)
  }, [])

  const onPress = useCallback(() => {
    if (levelId && moduleId) {
      mutation.mutate({ ruleId, levelId, moduleId, text })
    }
  }, [levelId, moduleId, mutation, ruleId, text])

  const icon = useMemo(
    () => () =>
      <CheckSVG color={mutation.isLoading ? themeOnSurface.transparent : themeOnSurface.button} />,
    [mutation.isLoading, themeOnSurface.button, themeOnSurface.transparent],
  )

  return (
    <>
      <View>
        <View style={[s.inputView, cs.paddingBottom]}>
          <TextInput
            returnKeyType="done"
            onSubmitEditing={onPress}
            // @ts-ignore
            autoComplete={isWeb ? "none" : "off"}
            style={s.textInput}
            outlineColor={outline}
            outlineStyle={s.outlineStyle}
            value={text}
            theme={{
              colors: {
                primary: darkMode ? themeOnSurface.disabled : themeOnSurface.highEmphasis,
              },
            }}
            mode="outlined"
            {...{ onChangeText }}
          />
          <HelperText
            style={s.info}
            type="info"
            visible={mutation.isSuccess && mutation.variables?.text === text}
          >
            {t("bottomSheet.memoSave")}
          </HelperText>
          <LoadingIconButton
            disabled={_.isEmpty(text) || mutation.isLoading}
            loading={mutation.isLoading}
            size={iconSize.MEDIUM}
            style={s.button}
            {...{ onPress, icon }}
          />
        </View>
      </View>
      <Body2 selectable={false} style={s.memoTitle}>
        {t("bottomSheet.memoTitle")} {!isSmallWidth ? `\n` : null}
        {t("bottomSheet.memoExample")}
      </Body2>

      <Body2 style={s.memoInformation} selectable={false}>
        {t("bottomSheet.memoInformation")}
      </Body2>
    </>
  )
}

export default MemoContainer
