import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const VolumeMuteSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="m21.707 20.293-2.023-2.023A9.566 9.566 0 0 0 21.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.113 8.113 0 0 1-1.672 4.913l-1.285-1.285C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V4a1 1 0 0 0-1.554-.832L7.727 6.313l-4.02-4.02-1.414 1.414 18 18 1.414-1.414zM12 5.868v4.718L9.169 7.755 12 5.868zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20v-1.879l-2-2v2.011l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9h.879L3.102 7.223A1.995 1.995 0 0 0 2 9v6c0 1.103.897 2 2 2z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}
export default VolumeMuteSVG
