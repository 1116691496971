import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"

const RefreshSVG = (props?: SvgProps): JSX.Element => (
  <Svg {...props} width={props?.width ?? 24} height={props?.height ?? 24} fill="none">
    <Path
      d="M18.15 6.7a7.958 7.958 0 0 0-5.65-2.35c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08a5.99 5.99 0 0 1-5.65 4c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78l-3.22 3.22h7v-7L18.15 6.7Z"
      fill="#161B27"
      fillOpacity={0.6}
    />
  </Svg>
)

export default RefreshSVG
