import type { Rule } from "@newpv/js-common"
import { DOT_SIZE } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useMemo } from "react"
import { View } from "react-native"
import LightBulbFullSVG from "svgs/LightBulbFull"
import RuleIcon from "svgs/RuleIcon"
import useTheme from "theme/ThemeProvider"

import CustomRenderHTML from "../../CustomRenderHTML"
import { Overline } from "../../Texts"

interface Props {
  rule: Omit<Rule, "exercises">
  isDefinition?: boolean
}

const RuleContainer: FC<Props> = ({ rule, isDefinition }) => {
  const t = useTypedTranslation()
  const { memos } = useScenarioAndModule()
  const {
    darkMode,
    colors: { onPrimary, onSurface },
    dimensions: { margin: themeMargin },
  } = useTheme()
  const { onLayout, width } = useLayout()
  const { levelId } = useLevelAndEvaluation()
  const memo = useMemo(
    () => (levelId ? _.find(memos?.[levelId], ["ruleId", rule?.id]) : undefined),
    [memos, levelId, rule.id],
  )

  const cs = useCommonStyles()
  const s = useStyles(
    ({ dimensions: { margin, spacing }, colors: { secondary_300 } }) => ({
      container: {
        marginTop: margin / 2,
        paddingBottom: rule?.additionalExplanation ? spacing * 1.5 : spacing,
        paddingTop: themeMargin / 2,
        width: "100%" as const,
      },
      ruleIcon: {
        alignItems: "center",
        backgroundColor: secondary_300,
        borderRadius: DOT_SIZE,
        height: DOT_SIZE,
        justifyContent: "center",
        width: DOT_SIZE,
      },
    }),
    [rule],
  )

  // <sup> won't work properly on mobile, see: https://stackoverflow.com/questions/68136402/balise-sup-is-displaying-like-sub-in-react-native-render-html-custom-rendere
  return rule ? (
    <View>
      {memo ? (
        <View style={cs.marginBottom}>
          <View style={cs.bottomSheetTitleContainer}>
            <View style={s.ruleIcon}>
              <LightBulbFullSVG color={darkMode ? onSurface.button : onPrimary.highEmphasis} />
            </View>
            <Overline selectable={false} style={cs.bottomSheetTitle}>
              {t("bottomSheet.memo")}
            </Overline>
          </View>
          <CustomRenderHTML
            customDefaultTextProps={{ selectable: false }}
            content={memo.text ?? ""}
            typographyVariant="body2"
            width={width}
          />
        </View>
      ) : null}
      <View style={cs.bottomSheetTitleContainer}>
        <View style={s.ruleIcon}>
          <RuleIcon />
        </View>
        <Overline selectable={false} style={cs.bottomSheetTitle}>
          {isDefinition ? t("bottomSheet.definition") : t("bottomSheet.rule")}
        </Overline>
      </View>
      <CustomRenderHTML
        customDefaultTextProps={{ selectable: false }}
        content={rule.title}
        typographyVariant="h6"
        width={width}
      />
      {rule.description ? (
        <View style={s.container} {...{ onLayout }}>
          <CustomRenderHTML
            customDefaultTextProps={{ selectable: false }}
            content={rule.description ?? ""}
            typographyVariant="body2"
            width={width}
          />
        </View>
      ) : null}
      {rule.additionalExplanation ? (
        <>
          <CustomRenderHTML
            customDefaultTextProps={{ selectable: false }}
            content={t("bottomSheet.additionalExplanation")}
            typographyVariant="subtitle1"
            width={width}
          />
          <View style={s.container}>
            <CustomRenderHTML
              customDefaultTextProps={{ selectable: false }}
              content={rule.additionalExplanation}
              typographyVariant="body2"
              width={width}
            />
          </View>
        </>
      ) : null}
    </View>
  ) : null
}

export default RuleContainer
