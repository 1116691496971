import type {
  EngineInteraction,
  EvaluationInteractionParams,
  EvaluationType,
  InitialEvaluationInteraction,
  LevelOrPracticeTestParams,
  ProgressionData,
  SendingInteractionsParams,
  TrainingInteraction,
  TrainingInteractionParams,
  UniqueCopyId,
  UpdateExaminationOrLevelRequestBody,
} from "@newpv/js-common"
import { BUFFER_LIMIT, BUFFER_TIMEOUT, isAxiosError } from "constants/constants"
import { progressionKey } from "hooks/useFetchUserProgressionDetail"
import _ from "lodash"
import {
  postInitialEvaluation,
  postLevelOrPracticeTest,
  sendClosedSessionData,
  sendInteractions,
  startExaminationForNextEvaluation,
} from "models/ServerFunctions"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC, MutableRefObject, PropsWithChildren } from "react"
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { logger } from "utils/logger"
import { clearLocalStorage } from "utils/storage"

import useAuthContext from "./AuthProvider"

export interface ContextData {
  isSendingInteraction: boolean
  serverError: boolean
  storeTrainingInteractions: (
    params: TrainingInteractionParams & {
      isFirstTrainingInteraction?: MutableRefObject<boolean>
    },
  ) => void
  storeEvaluationInteractions: (params: EvaluationInteractionParams) => Promise<void>
  storeLastInteractionsBeforeAbandon: () => void
  startNextEvaluation: () => Promise<UniqueCopyId | undefined>
  startInitialEvaluation: (timeLimit?: number) => Promise<void>
  startLevelOrPracticeTest: (uniqueCopyId: UniqueCopyId) => Promise<void>
  closeInitialOrNextEvaluation: (
    evaluationType: EvaluationType,
    interactions: EngineInteraction[],
    uniqueCopyId?: UniqueCopyId,
  ) => Promise<void>
  closeLevelOrPracticeTest: (params: LevelOrPracticeTestParams) => void
  logout: (clearToken?: boolean) => void
}

const serverContext = createContext<ContextData>({} as ContextData)

export const ServerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { resetAllPreferences } = usePreferences()
  const { scenario, moduleId, resetScenarioAndModuleState } = useScenarioAndModule()
  const { levelId, examination, resetLevelAndEvalState, isInitialEvaluationCompleted, levelType } =
    useLevelAndEvaluation()
  const interactionBuffer = useRef<Array<TrainingInteraction | InitialEvaluationInteraction>>([])

  const isEvaluation =
    examination || isInitialEvaluationCompleted === false || levelType === "practiceTest"

  const sendInteractionsMutation = useMutation(sendInteractions, {
    mutationKey: isEvaluation ? "noRetry" : "sendInteractions",
  })

  const sendClosedSession = useMutation(sendClosedSessionData)
  const queryClient = useQueryClient()

  const timeoutRef = useRef<NodeJS.Timeout>()
  const { token, setToken, triggerStatesReset, setTriggerStatesReset, currentUser } =
    useAuthContext()
  const [isSaving, setIsSaving] = useState(false)

  const logout = useCallback(
    (clearToken = true) => {
      resetScenarioAndModuleState()
      resetLevelAndEvalState()
      resetAllPreferences()
      if (clearToken) {
        // noinspection JSIgnoredPromiseFromCall
        clearLocalStorage()
        setToken(null)
      }
    },
    [resetScenarioAndModuleState, resetLevelAndEvalState, resetAllPreferences, setToken],
  )

  // clearing states in providers when logout happens because of an error or an expired token
  useEffect(() => {
    if (triggerStatesReset) {
      logout(false)
      setTriggerStatesReset(false)
    }
  }, [logout, setTriggerStatesReset, triggerStatesReset])

  const launchInteractionTimeout = useCallback(() => {
    if (interactionBuffer.current.length >= 1 && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        // Check length again to avoid sending empty array
        if (interactionBuffer.current.length >= 1) {
          sendInteractionsMutation.mutate({
            interactions: interactionBuffer.current,
            scenarioId: scenario?.id,
          })
          interactionBuffer.current = []
          timeoutRef.current = undefined
        }
      }, BUFFER_TIMEOUT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario, sendInteractionsMutation])

  /**
   * Add recorded interaction to the buffer or
   * Check and send an array of interactions
   *
   * */

  const sendingInteractionInAsyncMode = useCallback(
    async (params: SendingInteractionsParams) => {
      try {
        setIsSaving(true)
        // "Use mutateAsync instead of mutate to get a promise which will resolve on success or throw on an error"
        await sendInteractionsMutation.mutateAsync(params)
      } catch (error) {
        if (
          isAxiosError(error) &&
          (error.response?.status === 504 || error.response?.status === 503)
        ) {
          return
        }
        throw error
      } finally {
        setIsSaving(false)
      }
    },
    [sendInteractionsMutation],
  )

  const storeTrainingInteractions = useCallback(
    (
      params: TrainingInteractionParams & {
        isFirstTrainingInteraction?: MutableRefObject<boolean>
      },
    ) => {
      const {
        interaction,
        isEnd,
        trainingBlockId,
        isRuleNewlyAcquired,
        isFirstTrainingInteraction,
      } = params
      if (
        levelId == null ||
        moduleId == null ||
        interaction == null ||
        trainingBlockId == null ||
        scenario == null
      ) {
        logger(
          "Store interactions: no levelId, moduleId, scenarioId, trainingBlockId or interaction",
        )
        return
      }
      const lastInteraction: TrainingInteraction = {
        ...interaction,
        levelId,
        scenarioId: scenario.id,
        moduleId,
        trainingBlockId,
      }
      // Updating the interaction buffer if in training, and NOT:
      // end of buffer
      // OR end of level
      // OR newly acquired rule
      // OR first interaction after (re)starting the level
      if (
        interactionBuffer.current.length + 1 < BUFFER_LIMIT &&
        !isEnd &&
        !isRuleNewlyAcquired &&
        isFirstTrainingInteraction?.current === false
      ) {
        interactionBuffer.current = [...interactionBuffer.current, lastInteraction]
        /** Start timer only if at least one interaction is present inside the buffer */
        launchInteractionTimeout()
        return
      }
      // Else, mutate after reaching buffer limit, or reaching end of level, or if newly acquired rule, or if first interaction after (re)starting the level
      sendInteractionsMutation.mutate({
        interactions: [...interactionBuffer.current, lastInteraction],
      })
      if (isFirstTrainingInteraction?.current === true) {
        isFirstTrainingInteraction.current = false
      }
      interactionBuffer.current = []
      /** Avoid sending multiple arrays of one or two interactions */
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = undefined
      }
    },
    [levelId, moduleId, scenario, sendInteractionsMutation, launchInteractionTimeout],
  )

  // If this is an evaluation, we send the interactions one by one, with await and a try catch
  const storeEvaluationInteractions = useCallback(
    async (params: EvaluationInteractionParams) => {
      const { interaction, uniqueCopyId, evaluationType } = params
      if (interaction == null || uniqueCopyId == null || scenario == null) {
        logger("Store interactions: no scenarioId, uniqueCopyId or interaction")
        return
      }
      const isPracticeTest = evaluationType === "practice_test"
      if (isPracticeTest && levelId == null) {
        logger("Store interactions: no levelId for practiceTest")
        return
      }
      const isInitial = evaluationType === "initial_evaluation"

      const lastInteraction: InitialEvaluationInteraction | TrainingInteraction = {
        ...interaction,
        ...(isInitial ? { initialEvaluationBlockId: uniqueCopyId } : undefined),
        ...(isPracticeTest
          ? { levelId, scenarioId: scenario.id, moduleId, trainingBlockId: uniqueCopyId }
          : undefined),
      }
      if (isPracticeTest) {
        await sendingInteractionInAsyncMode({ interactions: [lastInteraction] })
        return
      }
      const interactionsParams = {
        lastInteraction,
        evaluationType,
        scenarioId: scenario.id,
        // for next evaluation
        examinationId: uniqueCopyId,
      }
      await sendingInteractionInAsyncMode(interactionsParams)
    },
    [levelId, moduleId, scenario, sendingInteractionInAsyncMode],
  )

  const storeLastInteractionsBeforeAbandon = useCallback(() => {
    if (!_.isEmpty(interactionBuffer.current)) {
      sendInteractionsMutation.mutate({ interactions: interactionBuffer.current })
      interactionBuffer.current = []
    }
    return
  }, [sendInteractionsMutation])

  const startNextEvaluation = useCallback(async () => {
    if (examination?.evaluationId == null || examination.sessionId == null) {
      logger("evaluationId or sessionId is undefined")
      return undefined
    }

    // New evaluation
    const { examinationId: newUniqueCopyId } = await startExaminationForNextEvaluation(
      examination?.evaluationId,
      examination?.sessionId,
      currentUser?.extraTime ?? false,
    )
    return newUniqueCopyId ?? undefined
  }, [currentUser, examination])

  const startInitialEvaluation = useCallback(
    async (timeLimit?: number): Promise<void> => {
      if (scenario == null) {
        logger("scenario is undefined")
        return
      }
      await postInitialEvaluation(scenario.id, timeLimit)
      return
    },
    [scenario],
  )

  const closeInitialOrNextEvaluation = useCallback(
    async (
      evaluationType: EvaluationType,
      interactions: EngineInteraction[],
      uniqueCopyId?: UniqueCopyId,
    ) => {
      if (scenario == null || levelId == null) {
        logger("scenario or levelId is undefined")
        return undefined
      }
      if (interactions && uniqueCopyId) {
        return new Promise<void>((resolve, reject) => {
          sendClosedSession.mutate(
            {
              updateExaminationOrLevelRequestBody: {
                evaluationType,
                scenarioId: scenario.id,
                interactions,
                levelId,
              },
              uniqueCopyId,
            },
            {
              onSuccess: result => {
                logger("result from Initial/Next closed session data", result)
                const key = progressionKey(token, scenario.id)
                const progressionData = queryClient.getQueryData<ProgressionData>(key)
                const newProgression = _.merge({}, progressionData, result.progression)
                queryClient.setQueryData(key, newProgression)
                resolve()
              },
              onError: reject,
            },
          )
        })
      }
      return undefined
    },
    [levelId, queryClient, scenario, sendClosedSession, token],
  )

  const startLevelOrPracticeTest = useCallback(
    async (uniqueCopyId: UniqueCopyId) => {
      if (moduleId == null || levelId == null || scenario?.id == null) {
        logger("startLevelOrPracticeTest: moduleId or levelId or scenarioId is undefined")
        return
      }
      await postLevelOrPracticeTest({
        uniqueCopyId,
        levelId,
        moduleId,
        scenarioId: scenario.id,
      })
    },
    [levelId, moduleId, scenario],
  )

  const closeLevelOrPracticeTest = useCallback(
    ({ grade, interactions, uniqueCopyId }: LevelOrPracticeTestParams) => {
      if (scenario == null || levelId == null || interactions == null) {
        logger("closeLevelOrPracticeTest: scenario or levelId or interactions is undefined")
        return
      }
      const updateExaminationOrLevelRequestBody = (
        _.isEmpty(grade)
          ? {
              levelId,
              interactions,
              scenarioId: scenario.id,
            }
          : {
              levelId,
              interactions,
              scenarioId: scenario.id,
              grade,
              evaluationType: "practice_test",
            }
      ) as UpdateExaminationOrLevelRequestBody
      sendClosedSession.mutate(
        {
          updateExaminationOrLevelRequestBody,
          uniqueCopyId,
        },
        {
          onSuccess: result => {
            logger("result from closed Level/PracticeTest session data", result)
            const key = progressionKey(token, scenario.id)
            const progressionData = queryClient.getQueryData<ProgressionData>(key)
            const newProgression = _.merge({}, progressionData, result.progression)
            queryClient.setQueryData(key, newProgression)
          },
        },
      )
      return
    },
    [levelId, queryClient, scenario, sendClosedSession, token],
  )

  /**
   * To open a new examination for a next evaluation session
   * or load interactions for an existing examination session
   * or to close a next evaluation
   * or to close/end an initial evaluation
   * */

  const contextValue: ContextData = {
    isSendingInteraction: isSaving,
    serverError: sendInteractionsMutation.isError,
    storeTrainingInteractions,
    storeEvaluationInteractions,
    startNextEvaluation,
    startInitialEvaluation,
    startLevelOrPracticeTest,
    closeInitialOrNextEvaluation,
    closeLevelOrPracticeTest,
    logout,
    storeLastInteractionsBeforeAbandon,
  }

  return <serverContext.Provider value={contextValue}>{children}</serverContext.Provider>
}

export const useServer = (): ContextData => {
  const context = useContext(serverContext)
  if (_.isEmpty(context)) {
    throw new Error("useServer must be used within a ServerProvider")
  }
  return context
}

export default ServerProvider
