import type { Exercise, Rule } from "@newpv/js-common"
import { isClickOnMistake, isPickOutNearest, isPickOutOne } from "@newpv/js-common"
import { Button } from "components/Button"
import CustomRenderHTML from "components/CustomRenderHTML"
import { iconSize } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type { FC, MutableRefObject } from "react"
import { useMemo } from "react"
import { View } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import PlayIconSVG from "svgs/PlayIcon"
import VolumeSVG from "svgs/Volume"
import VolumeMuteSVG from "svgs/VolumeMute"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import LoadingIconButton from "../LoadingIconButton/LoadingIconButton"

interface IProps {
  exercise: Exercise
  isCrucialQuestion?: boolean
  rule: Omit<Rule, "exercises">
  playExerciseAudio: (rule?: Omit<Rule, "exercises">, exercise?: Exercise) => void
  toggleLevelAudio: () => void
  exerciseAudio: MutableRefObject<boolean | undefined>
}

const AudioView: FC<IProps> = ({
  exercise,
  rule,
  playExerciseAudio,
  toggleLevelAudio,
  isCrucialQuestion,
  exerciseAudio,
}) => {
  const {
    colors: { ripple, onSurface: themeOnSurface },
    dimensions: { margin: themeMargin },
  } = useTheme()
  const { isSmallWidth } = useDeviceTools()

  const t = useTypedTranslation()
  const pickOutNearest = isPickOutNearest(exercise)
  const pickOutOne = isPickOutOne(exercise)
  const s = useStyles(
    ({
      dimensions: { spacing, margin },
      colors: {
        primary_400,
        onSurface,
        surface: { outline, surface },
      },
    }) => ({
      button: {
        borderColor: outline,
        color: onSurface.button,
        backgroundColor: surface,
        borderWidth: 1,
      },
      buttonVolume: {
        alignSelf: "center",
      },
      contentStyle: {
        paddingVertical: spacing / 2,
        backgroundColor: primary_400,
      },
      row: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        height: iconSize.SMALL + margin / 2,
        width: pickOutOne ? "100%" : undefined,
      },
      upperView: {
        alignContent: "center",
        flexDirection: isSmallWidth ? "column" : "row",
        flexWrap: "wrap",
        gap: spacing,
        justifyContent: "space-between",
        marginHorizontal: margin,
        marginTop: margin,
      },
      label: {
        marginVertical: 0,
        marginLeft: margin / 2,
        color: onSurface.button,
      },
    }),
    [pickOutNearest],
  )
  const { onLayout, width } = useLayout()

  const customStyle = useMemo<MixedStyleDeclaration>(
    () => ({ marginLeft: themeMargin }),
    [themeMargin],
  )

  const showAudioButton =
    exerciseAudio.current &&
    (isPickOutNearest(exercise) ||
      (isPickOutOne(exercise) && exercise.exerciseSound) ||
      (isClickOnMistake(exercise) && rule.isConvertibleToDictation && isCrucialQuestion))

  return (
    <View style={s.upperView}>
      <View style={s.row}>
        {showAudioButton ? (
          <LoadingIconButton
            onPress={() => playExerciseAudio(rule, exercise)}
            icon={PlayIconSVG}
            style={s.button}
          />
        ) : null}
        {pickOutNearest || pickOutOne ? (
          <View {...{ onLayout }} style={{ width: "100%", flex: 1 }}>
            <CustomRenderHTML
              customDefaultTextProps={{ selectable: false }}
              content={`<b>${
                pickOutNearest ? exercise.instructionWord : exercise.sentence ?? ""
              }</b>`}
              typographyVariant="h6"
              overrideColor={themeOnSurface.highEmphasis}
              // do NOT use a style from a StyleSheet here
              {...{ customStyle, width }}
            />
          </View>
        ) : null}
      </View>
      {!pickOutOne ? (
        <Button
          mode="contained-tonal"
          rippleColor={rippleColor(ripple)}
          style={s.buttonVolume}
          contentStyle={s.contentStyle}
          labelStyle={s.label}
          icon={() =>
            exerciseAudio.current ? (
              <VolumeMuteSVG color={themeOnSurface.disabled} />
            ) : (
              <VolumeSVG color={themeOnSurface.disabled} />
            )
          }
          onPress={toggleLevelAudio}
        >
          {t(`Exercise.audio_${exerciseAudio.current ? "disable" : "enable"}`)}
        </Button>
      ) : null}
    </View>
  )
}

export default AudioView
