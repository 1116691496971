import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ClockSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 20.5a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20.5Zm0-18a10 10 0 1 1 0 20c-5.53 0-10-4.5-10-10a10 10 0 0 1 10-10Zm.5 5v5.25l4.5 2.67-.75 1.23L11 13.5v-6h1.5Z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}
export default ClockSVG
