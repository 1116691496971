import { Modal } from "components/Modal"
import useCommonStyles from "hooks/useCommonStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import type { ChildrenRefAttributes, IModalInternal, ModalResponse } from "models/ModalInterfaces"
import { ButtonType, ModalType } from "models/ModalInterfaces"
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react"
import {
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import { View } from "react-native"

// eslint-disable-next-line no-shadow
export enum BottomState {
  HIDE,
  MODULE,
  LEVEL,
}

interface ContextData {
  showModal: <T>(
    modal: Omit<IModalInternal<T>, "onDismiss" | "visible" | "resolve">,
  ) => Promise<ModalResponse<T>>
  hideAllModals: () => void
  // bottom modal values
  bottomModalState: BottomState
  setBottomModalState: Dispatch<SetStateAction<BottomState>>
  showMaintenanceModal: (title: string, subtitle: string) => void
  setMaintenanceModal: Dispatch<SetStateAction<IModalInternal | undefined>>
}

const modalContext = createContext<ContextData>({} as ContextData)

export type ShowModal<T = any> = Omit<IModalInternal<T>, "onDismiss" | "visible">

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const t = useTypedTranslation()
  const cs = useCommonStyles()
  const [currentModal, setCurrentModal] = useState<IModalInternal | undefined>(undefined)
  const [maintenanceModal, setMaintenanceModal] = useState<IModalInternal | undefined>()
  const ref = useRef<ChildrenRefAttributes>()

  // "bottom sheet" modal
  const [bottomModalState, setBottomModalState] = useState(BottomState.HIDE)

  const onInternalDismiss = useCallback(() => {
    setCurrentModal(old => (old ? { ...old, visible: false } : undefined))
    setCurrentModal(undefined)
  }, [])

  const hideAllModals = useCallback(() => {
    setCurrentModal(undefined)
  }, [])

  const showModal = useCallback(
    (modal: ShowModal) =>
      new Promise<ModalResponse>(resolve => {
        const newModal: IModalInternal = {
          ...modal,
          children: modal.children != null ? cloneElement(modal.children, { ref }) : undefined,
          resolve,
          visible: true,
          positive: modal.positive
            ? {
                ...modal.positive,
                onPress: async () => {
                  const value = ref.current?.getValue?.()
                  await modal.positive?.onPress?.()
                  onInternalDismiss()
                  resolve({ button: ButtonType.POSITIVE, value })
                },
              }
            : undefined,
          neutral: modal.neutral
            ? {
                ...modal.neutral,
                onPress: () => {
                  modal.neutral?.onPress?.()
                  onInternalDismiss()
                  resolve({ button: ButtonType.NEUTRAL })
                },
              }
            : undefined,
          negative: modal.negative
            ? {
                ...modal.negative,
                onPress: () => {
                  modal.negative?.onPress?.()
                  onInternalDismiss()
                  resolve({ button: ButtonType.NEGATIVE })
                },
              }
            : undefined,
          onDismiss: () => {
            onInternalDismiss()
            if (modal.dismissPositiveAction) {
              modal.positive?.onPress?.()
              resolve({ button: ButtonType.POSITIVE })
              return
            }
            resolve({ button: undefined })
          },
        }
        setTimeout(() => setCurrentModal(newModal), 200)
      }),
    [onInternalDismiss],
  )

  const showMaintenanceModal = useCallback(
    (title: string, subtitle: string) => {
      const modal: IModalInternal = {
        visible: true,
        type: ModalType.ERROR,
        dismissable: false,
        bodyTitle: title,
        bodySubtitle: subtitle,
        headerTitle: t(`${ns.MODAL}.${ModalType.ERROR}.server.headerTitle`),
      }
      setMaintenanceModal(modal)
    },
    [t],
  )

  const renderModal = useMemo(
    () =>
      maintenanceModal ? (
        <Modal modal={maintenanceModal} />
      ) : currentModal ? (
        <Modal modal={currentModal} />
      ) : null,
    [currentModal, maintenanceModal],
  )

  return (
    <modalContext.Provider
      value={{
        showModal,
        hideAllModals,
        bottomModalState,
        setBottomModalState,
        showMaintenanceModal,
        setMaintenanceModal,
      }}
    >
      <>
        <View style={cs.fullFlex}>{children}</View>
        {renderModal}
      </>
    </modalContext.Provider>
  )
}

export const useModal = (): ContextData => {
  const context = useContext(modalContext)
  if (_.isEmpty(context)) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}

export default ModalProvider
