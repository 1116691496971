import type { ProgressItem } from "components/Progression/ProgressionItem"
import { isFunction } from "lodash"
import type { ImageSourcePropType } from "react-native"
import type { SvgProps } from "react-native-svg"

export type SVGComponent = (props?: SvgProps) => JSX.Element

export function isSVGComponent(
  item: undefined | ImageSourcePropType | SVGComponent | string | ProgressItem,
): item is SVGComponent {
  return isFunction(item as SVGComponent)
}
