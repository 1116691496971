import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const TrophySVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { icon },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 48} height={props?.height ?? 49} viewBox="0 0 49 49" fill="none">
      <Path
        d="M36 4.5c-1.8 0-4 2-4 4H16c0-2-2.2-4-4-4H4v18c0 2 2 4 4 4h4.4c.8 4 3.4 7.4 9.6 8v4.16c-6 .92-6 5.84-6 5.84h16s0-4.92-6-5.84V34.5c6.2-.6 8.8-4 9.6-8H40c2 0 4-2 4-4v-18h-8Zm-24 18H8v-14h4v14Zm20 1c0 3.86-1.16 7-8 7-6.82 0-8-3.14-8-7v-11h16v11Zm8-1h-4v-14h4v14Z"
        fill={icon}
      />
    </Svg>
  )
}

export default TrophySVG
