import type { ReactElement } from "react"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const DragDropIcon = (props?: SvgProps): ReactElement => {
  const {
    colors: {
      secondary_400,
      surface: { surface },
    },
  } = useTheme()
  return (
    <Svg {...props} width={props?.width ?? 106} height={props?.height ?? 106} fill="none">
      <Path
        fill={surface}
        d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35Z"
      />
      <Path
        stroke={secondary_400}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M25.439 29.64v-1.492m0-3.12v-3.145a2.983 2.983 0 0 1 2.983-2.983h23.423a2.983 2.983 0 0 1 2.983 2.983v15.512a2.983 2.983 0 0 1-2.983 2.984h-4.701m-1.581-.018H44.07M17.682 29.639h23.406a2.983 2.983 0 0 1 2.983 2.983v15.495a2.983 2.983 0 0 1-2.983 2.983H17.682a2.983 2.983 0 0 1-2.983-2.983V32.622a2.983 2.983 0 0 1 2.983-2.983Z"
      />
    </Svg>
  )
}

export default DragDropIcon
