import type { SvgProps } from "react-native-svg"
import { Path, Svg } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

export const ChevronDownSVG = (props: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg
      {...props}
      width={props?.width ?? 24}
      height={props?.height ?? 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        d="M17.09 8.59009L12.5 13.1701L7.91 8.59009L6.5 10.0001L12.5 16.0001L18.5 10.0001L17.09 8.59009Z"
        fill={props.color ?? onSurface.mediumEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}
