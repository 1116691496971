import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const EmptyStarSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg
      width={props?.width ?? iconSize.SMALL}
      height={props?.height ?? iconSize.SMALL}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="m22 9.74-7.19-.62L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03L12 17.77l6.18 3.73-1.63-7.03L22 9.74ZM12 15.9l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.6l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.9Z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}

export default EmptyStarSVG
