import { Button } from "components/Button"
import { Body2 } from "components/Texts"
import { isWeb } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { usePreferences } from "providers/PreferenceProvider"
import { useCallback, useEffect, useRef, useState } from "react"
import { View } from "react-native"
import InformationSvg from "svgs/Information"
import useTheme from "theme/ThemeProvider"

const InformationBanner = (): JSX.Element | null => {
  const {
    colors: { primary_50 },
  } = useTheme()
  const t = useTypedTranslation()
  const checkOnce = useRef(false)
  const { isSmallWidth } = useDeviceTools()
  const {
    dispatchPreferences,
    preferenceState: { hasReadFromStorage, displayLanguageBanner },
  } = usePreferences()
  const [localShowBanner, setLocalShowBanner] = useState(false)

  const s = useStyles(
    ({
      colors: {
        neutral_50,
        onSurface: { mediumEmphasis, highEmphasis },
        neutral_700,
      },
      dimensions: { spacing, margin },
      typography: { caption },
    }) => ({
      body: {
        flex: 1,
        paddingLeft: spacing * 1.5,
        color: highEmphasis,
      },
      button: {
        color: mediumEmphasis,
      },
      buttonContainer: {
        justifyContent: isSmallWidth ? "flex-end" : undefined,
        flexDirection: isSmallWidth ? "column" : "row",
      },
      view: {
        backgroundColor: neutral_50,
        bottom: 0,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        width: "100%" as const,
      },
      languageBanner: { padding: spacing * 1.5 },
      textContainer: {
        flex: 1,
        flexDirection: "row",
      },
      link: {
        marginRight: 0.5 * margin,
        ...caption,
        textDecorationLine: "underline",
        color: neutral_700,
      },
    }),
    [],
  )

  useEffect(() => {
    if (!hasReadFromStorage || !isWeb || checkOnce.current) {
      return
    }
    checkOnce.current = true
    const language = (global.window.navigator.language as string).slice(0, 2)
    if (language !== "fr" && (displayLanguageBanner || displayLanguageBanner == null)) {
      setLocalShowBanner(true)
      return
    }
    setLocalShowBanner(false)
  }, [hasReadFromStorage, displayLanguageBanner])

  const onPress = useCallback(
    (value: boolean) => {
      setLocalShowBanner(false)
      dispatchPreferences({ type: "set", name: "displayLanguageBanner", value })
    },
    [dispatchPreferences],
  )

  return localShowBanner ? (
    <View style={[s.view, s.languageBanner]}>
      <View style={s.textContainer}>
        <InformationSvg />
        <Body2 style={s.body}>{t("Level.banner.message")}</Body2>
      </View>
      <View style={s.buttonContainer}>
        <Button labelStyle={s.button} mode="text" onPress={() => onPress(false)} color={primary_50}>
          {t(isSmallWidth ? "Level.banner.no_smallWidth" : "Level.banner.no")}
        </Button>
        <Button mode="text" onPress={() => onPress(true)} color={primary_50}>
          {t(isSmallWidth ? "Level.banner.yes_smallWidth" : "Level.banner.yes")}
        </Button>
      </View>
    </View>
  ) : null
}

export default InformationBanner
