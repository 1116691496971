import type { Result } from "@newpv/js-common"
import type { ButtonProps } from "components/Button"
import { Button } from "components/Button"
import type { DragAndDropHandle } from "components/ExerciseContents/DragAndDrop"
import { bottomSheet, FEEDBACK_DELAY } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import type { FC, MutableRefObject } from "react"
import { useCallback, useMemo } from "react"
import { View } from "react-native"
import wait from "utils/wait"

interface IProps {
  mode: "1" | "2"
  isEvaluation?: boolean
  onSelectPress?: (value: Result) => Promise<void>
  dragAndDropRef: MutableRefObject<DragAndDropHandle | null>
}

const QAButtons: FC<IProps> = ({ mode, isEvaluation, onSelectPress, dragAndDropRef }) => {
  const { isSmallWidth } = useDeviceTools()
  const s = useStyles(
    () => ({
      container: {
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        top: mode === "1" ? 0 : undefined,
        bottom: mode === "2" ? 0 : undefined,
        alignSelf: mode === "2" ? "center" : undefined,
        right: isSmallWidth || mode === "1" ? undefined : bottomSheet.MAX_WIDTH / 2,
        width: 90,
      },
      button: {
        alignItems: "center",
        borderRadius: 0,
        justifyContent: "center",
        minWidth: 0,
      },
      contentStyle: {
        height: 40,
        width: 40,
      },
      labelStyle: {
        margin: 0,
        marginLeft: 0,
        marginRight: 0,
      },
    }),
    [mode, isSmallWidth],
  )

  const commonButtonProps = useMemo<Partial<ButtonProps>>(
    () => ({
      mode: "contained",
      contentStyle: s.contentStyle,
      labelStyle: s.labelStyle,
      color: "transparent",
    }),
    [s.contentStyle, s.labelStyle],
  )

  const onPress = useCallback(
    async (value: Result) => {
      if (dragAndDropRef.current) {
        if (!isEvaluation) {
          dragAndDropRef.current.displayCorrection()
        }
        await wait(FEEDBACK_DELAY)
      }
      await onSelectPress?.(value)
    },
    [dragAndDropRef, isEvaluation, onSelectPress],
  )

  return (
    <View style={s.container}>
      <Button
        {...commonButtonProps}
        style={[{ backgroundColor: "red" }, s.button]}
        onPress={() => onPress("wrong")}
      >
        KO
      </Button>
      <Button
        {...commonButtonProps}
        style={[{ backgroundColor: "lightgreen" }, s.button]}
        onPress={() => onPress("correct")}
      >
        OK
      </Button>
    </View>
  )
}

export default QAButtons
