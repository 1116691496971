import type { Exercise, Result, Rule } from "@newpv/js-common"
import {
  isClickOnEmail,
  isClickOnMistake,
  isDragAndDrop,
  isEmailPickOutOne,
  isPickOutNearest,
  isPickOutOne,
} from "@newpv/js-common"
import { useHeaderHeight } from "@react-navigation/elements"
import { BottomSheet } from "components/BottomSheet/coreComponents"
import type {
  BottomSheetButtonConfig,
  ButtonConfigProp,
} from "components/BottomSheet/coreComponents/BottomSheetButtons"
import BottomSheetAnswerOptions from "components/BottomSheet/customComponents/BottomSheetAnswerOptions"
import BottomSheetContent from "components/BottomSheet/customComponents/BottomSheetContent"
import { useEmailButtonConfig } from "components/BottomSheet/hooks/useEmailButtonConfig"
import ClueView from "components/ClueView/ClueView"
import {
  AudioView,
  ClickOnMistake,
  Dictation,
  DragAndDrop,
  IntensiveTraining,
  PickOutOne,
  QAButtons,
} from "components/ExerciseContents"
import type { DragAndDropHandle } from "components/ExerciseContents/DragAndDrop"
import EmailExercise from "components/ExerciseContents/EmailExercise"
import NoErrorButtonEmailClickOnMistake from "components/ExerciseContents/NoErrorButtonEmailClickOnMistake"
import InstructionView from "components/InstructionView/InstructionView"
import { images } from "config/images"
import { bottomSheet, FEEDBACK_DELAY, isWeb, maxScreenWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { MotiView, useDynamicAnimation } from "moti"
import BottomSheetProvider, { useBottomSheet } from "providers/BottomSheetProvider"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import DadProvider from "providers/DadProvider"
import { useExercise } from "providers/ExerciseProvider"
import { usePreferences } from "providers/PreferenceProvider"
import type { RefObject } from "react"
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react"
import type { LayoutChangeEvent } from "react-native"
import { ScrollView, useWindowDimensions, View } from "react-native"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

const scrollToTop = (ref: RefObject<ScrollView>): void => {
  if (ref?.current && typeof ref?.current?.scrollTo === "function") {
    ref.current.scrollTo({ x: 0, y: 0, animated: true })
  }
}

interface Props {
  isEvaluation?: boolean
  exercise: Exercise
  rule: Omit<Rule, "exercises">
  onBottomSheetNextButtonPress: (hide?: () => void, skip?: boolean) => Promise<void>
  instructionViewHeight: number
  onInstructionViewLayout: (e: any) => void
}

export interface ExerciseContentHandle {
  getResult: () => Result | undefined
  cleanResult: () => void
  scrollToTop: () => void
  handleDragAndDropRef: RefObject<DragAndDropHandle | null>
  handleEvaluationPress: (value: Result) => Promise<void>
  handleLevelPress: (providedHeight: number) => (value: Result) => Promise<void>
}

const ExerciseContent = forwardRef<ExerciseContentHandle, Props>(
  (
    {
      exercise,
      onBottomSheetNextButtonPress,
      rule,
      isEvaluation,
      instructionViewHeight,
      onInstructionViewLayout,
    },
    ref,
  ) => {
    // Dimension/Theme hooks
    const t = useTypedTranslation()
    const { width: windowWidth } = useWindowDimensions()
    const {
      screenStyle,
      dimensions,
      colors: { onSurface, primary_400, ripple },
    } = useTheme()
    const headerHeight = useHeaderHeight()
    const cardHeight = useRef(0)
    const animationState = useDynamicAnimation(() => ({}))
    const { isSmallWidth } = useDeviceTools()
    const cs = useCommonStyles()
    // Global contexts
    const {
      preferenceState: { seeCorrection },
    } = usePreferences()
    // Exercise hooks and states
    const { showBottomSheet } = useBottomSheet()
    const {
      currentExercise: {
        isClueAllowed,
        isIntensiveTraining,
        intensiveTrainingExercises,
        isCrucialQuestion,
      },
      displayNextEvaluationExercise,
      audio: { playExerciseAudio, toggleLevelAudio, exerciseAudio },
      emailPickOutOneState: { selectedOption, setSelectedOption, emailPickOutOneOptions },
    } = useExercise()
    const { isConsultation } = useConsultationMode()
    const useDictationComponent = useMemo(
      () =>
        isClickOnMistake(exercise) &&
        rule?.isConvertibleToDictation &&
        exerciseAudio.current &&
        isCrucialQuestion,
      [exerciseAudio, exercise, isCrucialQuestion, rule?.isConvertibleToDictation],
    )

    const [result, setResult] = useState<Result>()

    const isSavingInteraction = useRef(false)

    // Exercise refs
    const dragAndDropRef = useRef<DragAndDropHandle>(null)
    const scrollViewRef = useRef<ScrollView>(null)

    const { contentContainerStyle, exerciseCardContainer, horizontalPadding, audioPadding } =
      useStyles(
        ({ dimensions: { margin, spacing }, colors: { surface }, roundness }) => ({
          contentContainerStyle: {
            ...screenStyle,
            paddingBottom: isWeb && !isSmallWidth ? undefined : spacing * 5,
            width: windowWidth > maxScreenWidth ? maxScreenWidth : windowWidth - 2 * spacing,
          },
          exerciseCardContainer: {
            backgroundColor: surface.backgroundModal,
            borderRadius: roundness * 2,
            margin: margin / 2,
            marginTop: margin,
            padding: spacing,
          },
          horizontalPadding: {
            paddingHorizontal: isSmallWidth ? spacing : undefined,
            ...(result != null ? { overflow: "visible" as const } : {}),
          },
          audioPadding: {
            paddingTop: isWeb && !isSmallWidth && result != null ? spacing : undefined,
            marginBottom: isWeb && !isSmallWidth && result != null ? -spacing : undefined,
          },
        }),
        [isIntensiveTraining, windowWidth, isWeb, result, instructionViewHeight, isSmallWidth],
      )

    /** Methods passed to children (evaluation or level) */
    const onEvaluationPress = useCallback(
      async (value: Result) => {
        if (!isSavingInteraction.current) {
          isSavingInteraction.current = true
          // Call the method because the bottom sheet is not used
          await displayNextEvaluationExercise(value === "correct")
          isSavingInteraction.current = false
        }
      },
      [displayNextEvaluationExercise],
    )

    const onLevelPress = useCallback(
      async (value: Result, providedHeight?: number, intensiveTraining?: boolean) => {
        if (!isSavingInteraction.current) {
          isSavingInteraction.current = true
          setResult(value)
          // TODO: find if it's possible for the animation to take into account the scroll so we don't have a weird visual effect
          // knowing that a lot of ScrollView handlers are not supported on web - https://github.com/necolas/react-native-web/issues/2249
          scrollToTop(scrollViewRef)
          if (!intensiveTraining && !isConsultation) {
            // the animation state can use instructionViewHeight directly when called in ExerciseContent
            // but when using the ImperativeHandle reference, the value is stale, and has to be provided as a parameter directly from ExerciseScreen
            animationState.animateTo({
              // take into account padding and margin around text/instruction/card
              // On device it's not disturbing if we don't modify height because we don't have card, all content are on white background.
              height: isWeb
                ? [
                    cardHeight.current - dimensions.margin * 2,
                    {
                      type: "timing",
                      duration: FEEDBACK_DELAY,
                      value: cardHeight.current - dimensions.spacing * 2,
                    },
                  ]
                : undefined,
              translateY: [
                0,
                {
                  duration: FEEDBACK_DELAY,
                  type: "timing",
                  value: 0,
                },
              ],
            })
          }
          showBottomSheet()
          isSavingInteraction.current = false
        }
      },
      [isConsultation, showBottomSheet, animationState, dimensions],
    )
    const onSelectPress = useMemo(
      () => (isEvaluation ? onEvaluationPress : onLevelPress),
      [isEvaluation, onEvaluationPress, onLevelPress],
    )

    useImperativeHandle(
      ref,
      () => ({
        getResult: () => result,
        cleanResult: () => setResult(undefined),
        scrollToTop: () => {
          scrollToTop(scrollViewRef)
        },
        handleDragAndDropRef: dragAndDropRef,
        handleEvaluationPress: onEvaluationPress,
        handleLevelPress: (providedHeight: number) => (value: Result) =>
          onLevelPress(value, providedHeight),
      }),
      [onEvaluationPress, onLevelPress, result],
    )

    const renderExercise = useMemo(() => {
      switch (exercise.type) {
        case "click_on_word":
        case "click_on_mistake":
          return useDictationComponent && isCrucialQuestion && isClickOnMistake(exercise) ? (
            <Dictation
              {...{
                exercise,
                headerHeight,
                isEvaluation,
                rule,
                result,
                onSelectPress,
              }}
            />
          ) : (
            <ClickOnMistake
              {...{
                exercise,
                headerHeight,
                isEvaluation,
                onSelectPress,
                rule,
                result,
              }}
            />
          )
        case "drag_and_drop":
          return (
            <DadProvider {...{ exercise }}>
              <DragAndDrop
                ref={dragAndDropRef}
                {...{ onSelectPress, isEvaluation }}
                result={result}
              />
            </DadProvider>
          )
        case "pick_out_nearest":
        case "pick_out_one":
          return (
            <PickOutOne
              {...{
                rule,
                exercise,
                result,
                isEvaluation,
                onSelectPress,
              }}
            />
          )
        case "email_pick_out_one":
        case "click_on_email":
          return <EmailExercise {...{ exercise, onSelectPress, isEvaluation }} />
      }
    }, [
      exercise,
      useDictationComponent,
      isCrucialQuestion,
      headerHeight,
      isEvaluation,
      rule,
      result,
      onSelectPress,
    ])

    /** Is clue possible, based on the presence of a clue in the data */
    const isCluePossible = useMemo(() => {
      switch (exercise.type) {
        case "click_on_mistake":
          return _.some(exercise.sentence, word => word.clue)
        case "pick_out_nearest":
          // TODO: left it for now, but I think this condition is actually always true?..
          return _.some(exercise.words, word => word.isAnswer && !word.foreign)
        case "pick_out_one":
          // don't display the clue button if the exercise is a "duo de choix"
          return (
            _.some(exercise.words, word => word.isAnswer && !word.foreign) &&
            exercise.qcType !== "double_choices" &&
            exercise.words.length > 2
          )
        case "click_on_email":
          return (
            _.some(exercise.emailContent.body, emailBody => emailBody.clue) ||
            _.some(exercise.emailContent.header, emailHeaderField =>
              _.some(emailHeaderField, fieldContent => fieldContent.clue),
            )
          )
        default:
          return !!isEmailPickOutOne(exercise) || !!isDragAndDrop(exercise)
      }
    }, [exercise])

    /** Should the clue be displayed: only if allowed by the engine, and not during an evaluation or intensive training */
    const displayClue =
      isClueAllowed &&
      !isClickOnEmail(exercise) &&
      !isEmailPickOutOne(exercise) &&
      !isEvaluation &&
      !isIntensiveTraining &&
      (intensiveTrainingExercises == null || intensiveTrainingExercises?.length === 0)

    const displayClueEmailPickOutOneMobile =
      isClueAllowed &&
      !isEvaluation &&
      !isIntensiveTraining &&
      (intensiveTrainingExercises == null || intensiveTrainingExercises?.length === 0)

    const resources = useMemo(
      () =>
        isPickOutNearest(exercise)
          ? {
              ...rule.resources,
              lesson:
                exercise?.definitions && exercise?.definitions.length > 0
                  ? "placeholder"
                  : undefined,
            }
          : rule.resources,
      [exercise, rule.resources],
    )

    const instruction = useMemo(
      () =>
        exercise.instruction ??
        rule.instruction ??
        (useDictationComponent
          ? t("default_instructions.click_on_mistake_dictation")
          : t(`default_instructions.${exercise.type}`)),
      [exercise, rule, useDictationComponent, t],
    )

    const title = useMemo(
      () =>
        useDictationComponent
          ? t(`${ns.EXERCISE}.click_on_mistake_dictation`)
          : isPickOutOne(exercise)
          ? t(`${ns.EXERCISE}.pick_out_one.${exercise.qcType ?? "default"}`)
          : t(`${ns.EXERCISE}.${exercise.type}`),
      [exercise, t, useDictationComponent],
    )

    /** Retrieve card height */
    const onLayout = useCallback((event: LayoutChangeEvent) => {
      cardHeight.current = event.nativeEvent.layout.height
    }, [])

    const commonButtonProps = useMemo(
      () =>
        ({
          actionsAfterOnPress: ["togglePanel"],
          extraButtonProps: {
            mode: "text",
            labelStyle: cs.labelMediumOnSurface,
          },
        } as Omit<BottomSheetButtonConfig, "label">),
      [cs.labelMediumOnSurface],
    )

    // to show choices bottom sheet
    const isEmailPickOutOneExercise = !result && exercise.type === "email_pick_out_one"

    const buttonConfig: ButtonConfigProp = useMemo(
      () =>
        !isIntensiveTraining
          ? [
              {
                smallContent: {
                  label: "",
                  ...commonButtonProps,
                  customButtonStyle: {
                    opacity: 0,
                    pointerEvents: "none",
                  },
                  bottomSheetMaxHeight: bottomSheet.MAX_HEIGHT_1 - dimensions.spacing * 2,
                },
                ifPanelClosed: {
                  label: t(`bottomSheet.seeMore`),
                  ...commonButtonProps,
                },
                ifPanelOpen: {
                  label: t(`bottomSheet.seeLess`),
                  ...commonButtonProps,
                },
              },
              // "next" button - simply closes the bottom sheet
              {
                default: {
                  extraButtonProps: {
                    labelStyle: { color: onSurface.highEmphasis },
                    rippleColor: rippleColor(ripple),
                    contentStyle: { backgroundColor: primary_400 },
                    mode: "contained-tonal",
                  },
                  label: t("bottomSheet.next"),
                  onPress: async () => {
                    setSelectedOption(undefined)
                    await onBottomSheetNextButtonPress()
                  },
                  actionsAfterOnPress: ["closeBottomSheet", "hideBottomSheet"],
                },
              },
            ]
          : [
              {
                default: {
                  extraButtonProps: {
                    labelStyle: { color: onSurface.highEmphasis },
                    rippleColor: rippleColor(ripple),
                    contentStyle: { backgroundColor: primary_400 },
                    mode: "contained-tonal",
                  },
                  label: t("bottomSheet.next"),
                  onPress: async () => {
                    await onBottomSheetNextButtonPress()
                  },
                  actionsAfterOnPress: ["closeBottomSheet", "hideBottomSheet"],
                },
              },
            ],
      [
        commonButtonProps,
        dimensions.spacing,
        isIntensiveTraining,
        onBottomSheetNextButtonPress,
        onSurface.highEmphasis,
        primary_400,
        ripple,
        setSelectedOption,
        t,
      ],
    )

    const onValidationPress = useCallback(async () => {
      await onSelectPress?.(selectedOption?.answer.isAnswer ? "correct" : "wrong")
    }, [onSelectPress, selectedOption?.answer.isAnswer])

    const emailPickOutOneButtonConfig = useEmailButtonConfig({
      onPress: onValidationPress,
      disabled: selectedOption === undefined,
    })

    return (
      <>
        <ScrollView
          automaticallyAdjustKeyboardInsets={true}
          style={[cs.fullHeight, screenStyle, horizontalPadding]}
          {...{ contentContainerStyle }}
          ref={scrollViewRef}
        >
          {!isIntensiveTraining ? (
            <View {...{ onLayout }} style={[isSmallWidth ? undefined : exerciseCardContainer]}>
              <MotiView state={animationState}>
                <View onLayout={onInstructionViewLayout}>
                  <InstructionView
                    icon={images[exercise.type]}
                    {...{ instruction, title }}
                    isBottomSheetOpen={!_.isEmpty(result)}
                  />
                </View>
                {isPickOutNearest(exercise) ||
                isPickOutOne(exercise) ||
                (isClickOnMistake(exercise) &&
                  rule.isConvertibleToDictation &&
                  isCrucialQuestion) ? (
                  <View style={audioPadding}>
                    <AudioView
                      {...{
                        rule,
                        exercise,
                        exerciseAudio,
                        isCrucialQuestion,
                        playExerciseAudio,
                        toggleLevelAudio,
                      }}
                    />
                  </View>
                ) : null}
                {renderExercise}
                {!result && isCluePossible && displayClue ? <ClueView /> : null}
              </MotiView>
            </View>
          ) : null}
          {rule && isIntensiveTraining ? (
            <IntensiveTraining
              {...{ rule, onLevelPress, onBottomSheetNextButtonPress }}
              exerciseType={exercise.type}
              exercises={intensiveTrainingExercises}
            />
          ) : null}
          {seeCorrection && !isIntensiveTraining ? (
            <QAButtons mode="1" {...{ isEvaluation, dragAndDropRef, onSelectPress }} />
          ) : null}
        </ScrollView>
        {isSmallWidth && isClickOnEmail(exercise) && !isIntensiveTraining ? (
          <NoErrorButtonEmailClickOnMistake {...{ exercise, onSelectPress }} />
        ) : null}
        {isEvaluation ? null : (
          <BottomSheet
            isPanAndTranslateDisabled={isWeb && isSmallWidth}
            initialSliderHeight={bottomSheet.MAX_HEIGHT_2}
            {...{ buttonConfig }}
            customButtonContainerStyle={
              isIntensiveTraining ? { justifyContent: "flex-end" } : undefined
            }
          >
            <BottomSheetContent
              {...{ exercise, result, rule, resources, emailPickOutOneOptions, selectedOption }}
              isDictation={
                (useDictationComponent && isCrucialQuestion && isClickOnMistake(exercise)) ?? false
              }
              isHelp={false}
              isIntensiveTrainingProp={isIntensiveTraining}
            />
          </BottomSheet>
        )}
        {!isIntensiveTraining && isEmailPickOutOneExercise && isSmallWidth ? (
          <BottomSheetProvider>
            <BottomSheetAnswerOptions
              displayClue={displayClueEmailPickOutOneMobile}
              exerciseAnswerOptions={exercise.options}
              {...{ emailPickOutOneButtonConfig, headerHeight }}
            />
          </BottomSheetProvider>
        ) : null}
      </>
    )
  },
)

export default ExerciseContent
