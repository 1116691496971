import { useHeaderHeight } from "@react-navigation/elements"
import { bottomSheet, isWeb, transition } from "constants/constants"
import FocusTrap from "focus-trap-react"
import useHardwareButton from "hooks/useHardwareButton"
import { useKeyboard } from "hooks/useKeyboard"
import { useStyles } from "hooks/useStyles"
import { MotiView } from "moti"
import BottomSheetProvider, { useBottomSheet } from "providers/BottomSheetProvider"
import type { FC, ReactNode } from "react"
import { useCallback, useMemo } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { StyleSheet, View } from "react-native"
import { Portal } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import type { ButtonConfigProp } from "./BottomSheetButtons"
import BottomSheetButtons from "./BottomSheetButtons"
import CoreAnimatedView from "./CoreAnimatedView"

interface Props {
  children: ReactNode
  isPanAndTranslateDisabled: boolean
  buttonConfig: ButtonConfigProp
  customButtonContainerStyle?: StyleProp<ViewStyle>
  initialSliderHeight?: number
}

const BottomSheet: FC<Props> = ({
  children,
  isPanAndTranslateDisabled,
  buttonConfig,
  customButtonContainerStyle,
  initialSliderHeight,
}) => {
  const isKeyboardOpen = useKeyboard()

  const { bsState, togglePanel, closeBottomSheet, hideBottomSheet, showBottomSheet } =
    useBottomSheet()

  const headerHeight = useHeaderHeight()

  const { bottom: bottomInset } = useSafeAreaInsets()

  /** STYLES */
  const contentContainerStyle = useMemo<ViewStyle>(
    () => ({
      flex: 1,
      justifyContent: "flex-end",
      /* @ts-ignore available for rn-web lead to tslint unrecognized const*/
      overflow: isWeb ? "clip" : "visible",
    }),
    [],
  )

  const s = useStyles(
    ({ colors: { surface } }) => ({
      motiView: {
        backgroundColor: surface.backdropWeb,
        overflow: "hidden",
        position: "absolute",
      },
    }),
    [],
  )
  // panelOpen and togglePanel probably don't have the correct value here
  // bsState.panelOpen on this level of the tree is not used, the used value is in a deeper, nested tree
  const onBackPress = useCallback(() => {
    if (bsState.panelOpen) {
      togglePanel()
    }
    return bsState.panelOpen
  }, [bsState, togglePanel])

  useHardwareButton({ handler: onBackPress })

  const Wrapper = isWeb ? FocusTrap : View

  return bsState.modalVisible ? (
    <Portal>
      <MotiView
        from={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        {...{ transition }}
        style={[StyleSheet.absoluteFill, s.motiView]}
      >
        <BottomSheetProvider
          sliderMinHeight={
            initialSliderHeight != null ? initialSliderHeight : bottomSheet.MAX_HEIGHT_1
          }
          panAndTranslateDisabled={isPanAndTranslateDisabled}
        >
          <MotiView
            {...{ transition }}
            from={{ translateY: bottomSheet.MAX_HEIGHT_1 }}
            animate={{
              translateY: -bottomInset,
            }}
            style={contentContainerStyle}
          >
            {/* // animation for opening and closing the bottom sheet fully */}
            <CoreAnimatedView
              {...{
                isKeyboardOpen,
                headerHeight,
              }}
            >
              {children}
            </CoreAnimatedView>
            {!isKeyboardOpen ? (
              // @ts-expect-error
              <Wrapper>
                <BottomSheetButtons
                  {...{
                    showBottomSheet,
                    hideBottomSheet,
                    closeBottomSheet,
                    customButtonContainerStyle,
                    buttonConfig,
                  }}
                />
              </Wrapper>
            ) : null}
          </MotiView>
        </BottomSheetProvider>
      </MotiView>
    </Portal>
  ) : null
}
export default BottomSheet
