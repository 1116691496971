import { axios } from "@newpv/js-common"
import type { AxiosError, AxiosResponse } from "axios"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import useAuthContext from "providers/AuthProvider"
import { useModal } from "providers/ModalProvider"
import type { FC, PropsWithChildren } from "react"
import { useEffect } from "react"
import { logger } from "utils/logger"

const MaintenanceWrapper: FC<PropsWithChildren> = ({ children }) => {
  const t = useTypedTranslation()
  const { setToken, setTriggerStatesReset, setScenarioId, setAuthError } = useAuthContext()
  const { setMaintenanceModal, showMaintenanceModal } = useModal()
  // Api Maintenance
  useEffect(() => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status === 200) {
          setMaintenanceModal(undefined)
        }
        return Promise.resolve(response)
      },
      (error: AxiosError<any>) => {
        if (error.code === "ERR_NETWORK") {
          setAuthError({ code: error.code, message: t("common.errors.network") })
        } else if (error.response?.status === 401) {
          // eslint-disable-next-line no-console
          console.warn("AuthProvider got a 401 error")
          setToken(null)
          setScenarioId(null)
          setTriggerStatesReset(true)
        } else if (error.response?.status === 503 || error.response?.status === 504) {
          let parsedRes: { title?: string; description?: string } = {}
          try {
            if (_.isObject(error.response.data?.upstreamData)) {
              parsedRes = error.response.data?.upstreamData
            } else {
              parsedRes = error.response.data
            }
          } catch (e) {
            logger(e)
          }
          showMaintenanceModal(
            parsedRes?.title ?? t(`${ns.MODAL}.${ModalType.ERROR}.server.title`),
            parsedRes?.description ??
              t(`${ns.MODAL}.${ModalType.ERROR}.server.subtitle`, {
                status: error.response.status,
              }),
          )
        }
        return Promise.reject(error)
      },
    )
  }, [
    setAuthError,
    setMaintenanceModal,
    setScenarioId,
    setToken,
    setTriggerStatesReset,
    showMaintenanceModal,
    t,
  ])

  return <>{children}</>
}

export default MaintenanceWrapper
