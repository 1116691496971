import type { NavigationState } from "@react-navigation/native"
import _ from "lodash"
import { logger } from "utils/logger"

const getActiveRouteName = (
  state: Partial<NavigationState> | undefined,
): Partial<NavigationState> | undefined | string => {
  if (!state) {
    return undefined
  }
  const route = state?.routes?.[state?.index ?? ""]
  if (route?.state) {
    // Dive into nested navigators
    // @ts-ignore
    return getActiveRouteName(route?.state)
  }

  return route?.name
}

const logScreenTracking = _.debounce(currentScreen => {
  logger("screen", currentScreen)
}, 100)

export default { logScreenTracking, getActiveRouteName }
