import { defaultStyles, defaultWeights } from "theme/types"

export const customFonts = {
  // Montserrat
  "Montserrat-Black": require("../../../assets/fonts/Montserrat/Montserrat-Black.ttf"),
  "Montserrat-SemiBold": require("../../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf"),
  "Montserrat-Regular": require("../../../assets/fonts/Montserrat/Montserrat-Regular.ttf"),
  "Montserrat-Italic": require("../../../assets/fonts/Montserrat/Montserrat-Italic.ttf"),
  "Montserrat-MediumItalic": require("../../../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf"),
  "Montserrat-Light": require("../../../assets/fonts/Montserrat/Montserrat-Light.ttf"),
  // Roboto
  "Roboto-Medium": require("../../../assets/fonts/Roboto/Roboto-Medium.ttf"),
  "Roboto-Bold": require("../../../assets/fonts/Roboto/Roboto-Bold.ttf"),
}

export const Montserrat = {
  family: "Montserrat",
  iosFamily: "Montserrat",
  weights: defaultWeights,
  styles: defaultStyles,
}

export const Roboto = {
  family: "Roboto",
  weights: defaultWeights,
  styles: defaultStyles,
}
