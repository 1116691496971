import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const LightBulbSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M9.5 21.5c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1h-6v1Zm3-19c-3.86 0-7 3.14-7 7 0 2.38 1.19 4.47 3 5.74v2.26c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7Zm2.85 11.1-.85.6v2.3h-4v-2.3l-.85-.6A4.997 4.997 0 0 1 7.5 9.5c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1Z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}

export default LightBulbSVG
