import type { FrontExamination } from "@newpv/js-common"
import dayjs from "dayjs"
import { getEvaluation, getNextEvaluationCurrentSessions } from "models/ServerFunctions"
import useAuthContext from "providers/AuthProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import { useCallback, useEffect, useState } from "react"
import { logger } from "utils/logger"

export const useRootEvaluation = (token: string | null): { isEvalLoading: boolean } => {
  const [isEvalLoading, setIsEvalLoading] = useState<boolean>(true)
  const {
    scenario,
    scenarioError,
    isScenarioLoading,
    progressionData,
    progressionError,
    isProgressionLoading,
  } = useScenarioAndModule()
  const {
    isInitialEvaluationCompleted,
    setIsInitialEvaluationCompleted,
    setLevelId,
    setExamination,
    levelId,
    setIsResumedEval,
  } = useLevelAndEvaluation()

  const { extraTimeFactor } = useAuthContext()

  const checkInitialEvaluation = useCallback(() => {
    // initialEvaluation exists in the progression only if it's completed
    const userFirstEvaluation = progressionData?.progressionDetail.initialEvaluation
    if (scenario?.initialEvaluation && !userFirstEvaluation) {
      setIsInitialEvaluationCompleted(false)
      setLevelId(scenario.initialEvaluation.id)
      // no level type to set
      setIsEvalLoading(false)
    } else {
      setIsInitialEvaluationCompleted(true)
      setIsEvalLoading(false)
    }
  }, [
    scenario?.initialEvaluation,
    progressionData?.progressionDetail.initialEvaluation,
    setIsInitialEvaluationCompleted,
    setLevelId,
  ])

  const checkNextEvaluation = useCallback(async () => {
    // getting all the current sessions for next evaluation for current scenario
    const currentSessions = await getNextEvaluationCurrentSessions({
      scenarioId: scenario?.id,
    })

    logger("CURRENT SESSIONS", currentSessions)

    if ((currentSessions?.length ?? 0) > 0) {
      // check if we're resuming an examination or starting a new one
      // started examinations
      const sessionsWithStartedExaminations = currentSessions.filter(
        session => session.examination.status === "started",
      )
      const currentDate = dayjs()

      const sessionsWithStartedExaminationsWithExtraData = await Promise.all(
        sessionsWithStartedExaminations.map(async session => {
          const evaluationForThisSession = await getEvaluation({
            evaluationId: session.evaluationId,
          })
          return { ...session, duration: evaluationForThisSession.duration }
        }),
      )

      logger(
        "sessionsWithStartedExaminationsWithExtraData",
        sessionsWithStartedExaminationsWithExtraData,
      )

      // check if we have an ongoing examination
      const currentSessionWithOpenExamination = sessionsWithStartedExaminationsWithExtraData.find(
        session => {
          if (!session.examination.startDate) {
            return false
          }

          // duration is in seconds in the evaluation data, startDate was converted to milliseconds
          // duration comes from the evaluation and is the same for all sessions and all users - before adding extra time
          const closingDate = dayjs(
            session.examination.startDate + session.duration * 1000 * extraTimeFactor,
          ).add(1, "hour")

          logger("current date is:", dayjs(currentDate).format())
          logger("closing date is:", dayjs(closingDate).format())
          logger("currentDate.isBefore(closingDate):", currentDate.isBefore(closingDate))

          return currentDate.isBefore(closingDate)
        },
      )

      if (currentSessionWithOpenExamination) {
        const currentOngoingEvaluation = await getEvaluation({
          evaluationId: currentSessionWithOpenExamination.evaluationId,
        })

        const resumedExamination: FrontExamination = {
          // spread the examination
          ...currentSessionWithOpenExamination.examination,
          evaluationType: "next_evaluation" as const,
          evaluationId: currentOngoingEvaluation.evaluationId,
          sessionId: currentSessionWithOpenExamination.sessionId,
          contentId: currentOngoingEvaluation.contentId,
          // duration comes from the evaluation and is initially the same for all users - before we add extra time
          timeLimit: currentOngoingEvaluation.duration * extraTimeFactor,
          // careful - was wrongly session start date and not examination start date
          startDate: currentSessionWithOpenExamination.examination.startDate,
          // session due date - not examination due date
          sessionDueDate: currentSessionWithOpenExamination.dueDate,
          consumedTime: currentSessionWithOpenExamination.examination.consumedTime ?? 0,
        }

        setLevelId(currentOngoingEvaluation.contentId)

        setIsEvalLoading(false)

        logger(
          "there is a current session with open examination",
          currentSessionWithOpenExamination,
        )

        setExamination(resumedExamination)
        setIsResumedEval(true)
        return
      }

      // else, no ongoing examination
      const firstUnstartedSession = currentSessions.find(
        session => session.examination.status === "notstarted",
      )

      logger("no ongoing examination, the first unstarted session is:", firstUnstartedSession)

      // if no unstarted session
      if (firstUnstartedSession == null) {
        logger("no unstarted session found")
        return
      }

      const currentEvaluation = await getEvaluation({
        evaluationId: firstUnstartedSession.evaluationId,
      })
      const contentId = currentEvaluation.contentId
      setIsEvalLoading(false)
      setLevelId(contentId)
      // navigate to the evaluation screen
      setExamination({
        ...firstUnstartedSession.examination,
        evaluationType: "next_evaluation",
        evaluationId: currentEvaluation.evaluationId,
        sessionId: firstUnstartedSession.sessionId,
        contentId,
        // duration comes from the evaluation and is initially the same for all users
        timeLimit: currentEvaluation.duration * extraTimeFactor,
        // this value is undefined, it will be created when the user starts the evaluation
        startDate: undefined,
        // session due date - not examination due date
        sessionDueDate: firstUnstartedSession.dueDate,
      })
    }
  }, [scenario?.id, setLevelId, setExamination, extraTimeFactor, setIsResumedEval])

  useEffect(() => {
    // This effect runs on app startup and is responsible for determining if an evaluation is required
    ;(async () => {
      /* logger("useRootEvaluation", {
        isInitialEvaluationCompleted,
        checkInitialEvaluation,
        checkNextEvaluation,
        isProgressionLoading,
        isScenarioLoading,
        levelId,
        progressionError,
        scenario,
        scenarioError,
      }) */
      if (
        isScenarioLoading ||
        isProgressionLoading ||
        progressionError ||
        scenarioError ||
        token == null
      ) {
        return
      }
      if (!scenario) {
        setIsEvalLoading(false)
        return
      }
      if (isInitialEvaluationCompleted == null) {
        checkInitialEvaluation()
        return
      }
      if (levelId === undefined) {
        await checkNextEvaluation()
      }
      return
    })()
  }, [
    isInitialEvaluationCompleted,
    checkInitialEvaluation,
    checkNextEvaluation,
    isProgressionLoading,
    isScenarioLoading,
    levelId,
    progressionError,
    scenario,
    scenarioError,
    token,
  ])

  return { isEvalLoading }
}
