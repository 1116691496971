import { edges, HEADER_HEIGHT_SMALL } from "constants/constants"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import type { SVGComponent } from "models/SVGInterface"
import { isSVGComponent } from "models/SVGInterface"
import type { FC, PropsWithChildren } from "react"
import { useMemo } from "react"
import type { ImageSourcePropType, StyleProp, ViewStyle } from "react-native"
import { Animated, useWindowDimensions, View } from "react-native"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"
import useIsIphoneX from "utils/isIphoneX"

interface IProps extends PropsWithChildren {
  height?: number
  backgroundColor?: string
  image?: ImageSourcePropType | SVGComponent
  offSetY?: Animated.Value
  isEvaluation?: boolean
  style?: StyleProp<ViewStyle>
}

const HeaderWrapper: FC<IProps> = ({
  height,
  backgroundColor,
  children,
  image,
  offSetY,
  isEvaluation = false,
  style,
}) => {
  const isIphoneX = useIsIphoneX()
  const { isSmallWidth } = useDeviceTools()
  const { top } = useSafeAreaInsets()
  const { width: windowWidth } = useWindowDimensions()
  const smallWidth = !isEvaluation && isSmallWidth
  const localHeight = isSmallWidth ? HEADER_HEIGHT_SMALL : height
  const headerTranslateY = useMemo(
    () =>
      offSetY && localHeight
        ? offSetY?.interpolate({
            inputRange: [0, localHeight],
            outputRange: [0, -localHeight / 2],
            extrapolate: "clamp",
          })
        : undefined,
    [localHeight, offSetY],
  )
  const s = useStyles(
    ({ dimensions: { spacing }, colors: { primary_50 } }) => ({
      background: {
        alignItems: "center",
        backgroundColor: primary_50,
        height: "100%",
        minHeight: smallWidth ? HEADER_HEIGHT_SMALL * 2 + top : height ? height + top : undefined,
        position: "absolute",
        width: "100%" as const,
      },
      image: {
        backgroundColor: primary_50,
      },
      imageWrapper: {
        maxHeight: smallWidth ? HEADER_HEIGHT_SMALL * 2 + top : height ? height + top : undefined,
        overflow: "hidden",
        width: windowWidth,
      },
      style: {
        backgroundColor,
        maxHeight: smallWidth ? HEADER_HEIGHT_SMALL + top : height ? height + top : undefined,
        paddingBottom: smallWidth && !isIphoneX ? spacing / 4 : undefined,
        paddingHorizontal: smallWidth ? spacing / 4 : spacing,
        paddingTop: smallWidth ? spacing / 4 : spacing,
      },
    }),
    [height, top, smallWidth, isIphoneX, backgroundColor],
  )

  return (
    <>
      {image ? (
        <Animated.View
          pointerEvents="none"
          style={[
            s.background,
            headerTranslateY ? { transform: [{ translateY: headerTranslateY }] } : undefined,
          ]}
        >
          {isSVGComponent(image) ? (
            image({ width: "100%" as const, height: "100%" })
          ) : (
            <View style={s.imageWrapper}>
              <Animated.Image source={image} style={s.image} />
            </View>
          )}
        </Animated.View>
      ) : null}
      <SafeAreaView style={[s.style, style]} edges={edges.TOP}>
        {children}
      </SafeAreaView>
    </>
  )
}

export default HeaderWrapper
