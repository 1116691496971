import { Button } from "components/Button"
import { isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import type { TranslationKeys } from "hooks/useTypedTranslation"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import type { ModalButton, ModalType } from "models/ModalInterfaces"
import { ButtonType } from "models/ModalInterfaces"
import type { FC, RefObject } from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import type { GestureResponderEvent } from "react-native"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

interface IProps {
  positive?: ModalButton
  negative?: ModalButton
  neutral?: ModalButton
  type?: ModalType
}

const ModalButtons: FC<IProps> = ({ positive, negative, neutral, type }) => {
  const {
    colors: { primary_400, ripple, onSurface },
  } = useTheme()
  const t = useTypedTranslation()
  const [viewRef] = useState<RefObject<View> | null>(null)
  const [positiveLoading, setPositiveLoading] = useState(false)

  const cs = useCommonStyles()
  const { style, buttonStyle, ...s } = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        onSurface: { button, mediumEmphasis },
      },
    }) => ({
      buttonStyle: {
        minHeight: neutral ? 36 : undefined,
        marginHorizontal: spacing / 2,
        marginVertical: neutral ? spacing / 2 : undefined,
      },
      negative: {
        color: mediumEmphasis,
      },
      positive: {
        color: button,
      },
      style: {
        flexDirection: neutral ? ("column" as const) : ("row" as const),
        marginHorizontal: neutral ? spacing : spacing / 2,
        marginVertical: spacing,
      },
    }),
    [neutral],
  )

  const positivePress = useCallback(
    async (isNeutral?: boolean) => {
      setPositiveLoading(true)
      if (isNeutral) {
        await neutral?.onPress?.()
      } else {
        await positive?.onPress?.()
      }
      setPositiveLoading(false)
    },
    [neutral, positive],
  )

  // Paper v4 doesn't manage disabled color with button mode
  // Fix in v5 Need to give the disabled color for outlined mode
  const renderButton = useMemo(
    () =>
      ({ onPress, label, color, disabled }: ModalButton, buttonType: ButtonType) => {
        const isPositive = buttonType === ButtonType.POSITIVE
        const isNegative = buttonType === ButtonType.NEGATIVE
        const backgroundColor = isPositive
          ? {
              backgroundColor:
                positiveLoading || disabled ? onSurface.disabled : color ?? primary_400,
              borderWidth: 0,
            }
          : undefined

        return (
          <View key={`view-${buttonType}`} style={neutral ? undefined : cs.fullFlex}>
            <Button
              key={`button-${buttonType}`}
              ref={viewRef}
              mode={isPositive ? "contained-tonal" : "outlined"}
              rippleColor={rippleColor(ripple)}
              onPress={
                isNegative
                  ? onPress
                  : async (e: GestureResponderEvent) => {
                      e.stopPropagation()
                      await positivePress(buttonType === ButtonType.NEUTRAL)
                    }
              }
              loading={isNegative ? false : positiveLoading}
              disabled={disabled || (isNegative ? false : positiveLoading)}
              contentStyle={backgroundColor}
              style={buttonStyle}
              labelStyle={isPositive ? s.positive : s.negative}
            >
              {label ??
                t([
                  `${ns.MODAL}.${type}.${isNegative ? "negative" : "positive"}` as TranslationKeys,
                  `common.button.${isNegative ? "no" : "yes"}`,
                ])?.toUpperCase()}
            </Button>
          </View>
        )
      },
    [
      buttonStyle,
      cs.fullFlex,
      neutral,
      onSurface.disabled,
      positiveLoading,
      positivePress,
      primary_400,
      ripple,
      s.negative,
      s.positive,
      t,
      type,
      viewRef,
    ],
  )

  useEffect(() => {
    if (!isWeb || !viewRef) {
      return
    }
    // @ts-ignore
    viewRef.current.tabindex = 0
    viewRef.current?.focus()
  }, [viewRef])

  return (
    <View focusable={true} ref={viewRef} {...{ style }}>
      {negative ? renderButton(negative, ButtonType.NEGATIVE) : null}
      {neutral ? renderButton(neutral, ButtonType.NEUTRAL) : null}
      {positive ? renderButton(positive, ButtonType.POSITIVE) : null}
    </View>
  )
}

export default ModalButtons
