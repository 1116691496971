import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const DisconnectSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: {
      onSurface: { mediumEmphasis },
    },
  } = useTheme()
  return (
    <Svg {...props} width={props?.width ?? 24} height={props?.height ?? 24} fill="none">
      <Path
        d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5ZM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z"
        fill={props?.color ?? mediumEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default DisconnectSVG
