import { Audio } from "expo-av"
import { useCallback, useEffect, useState } from "react"

export const useSound = (): {
  playMP3FromUrl: (url: string | null, fallback: () => void) => Promise<void>
} => {
  const [loadedSound, setLoadedSound] = useState<Audio.Sound>()

  const playMP3FromUrl = useCallback(
    async (url: string | null, fallback: () => void): Promise<void> => {
      try {
        if (url) {
          const { sound, status } = await Audio.Sound.createAsync({ uri: url })
          setLoadedSound(sound)
          if (status.isLoaded) {
            await sound.playAsync()
            return
          }
        }
      } catch (err) {
        // Errors are intended to fall through to the fallback call.
      }
      fallback()
    },
    [],
  )

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true })
  }, [])

  useEffect(
    () =>
      loadedSound
        ? () => {
            loadedSound.unloadAsync()
          }
        : undefined,
    [loadedSound],
  )

  return { playMP3FromUrl }
}
