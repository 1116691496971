import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const PersonSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onPrimary },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path d="M12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" fill={props?.color ?? onPrimary.highEmphasis} />
      <Path
        d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm4 12.4c-.6-.7-1.4-1.4-3-1.4h-2c-1.6 0-2.4.7-3 1.4-1.2-1.1-2-2.6-2-4.4 0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4Z"
        fill={props?.color ?? onPrimary.highEmphasis}
      />
    </Svg>
  )
}

export default PersonSVG
