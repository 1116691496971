import { Body1, Subtitle2 } from "components/Texts"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { MotiView } from "moti"
import type { FC } from "react"
import { View } from "react-native"
import StarSVG from "svgs/Star"
import useTheme from "theme/ThemeProvider"

const scale = [
  1,
  { value: 1.3, delay: 200 },
  1,
  { value: 1.3, delay: 200 },
  1,
  { value: 1.3, delay: 200 },
  1,
]

const BottomAutoValidatedLevel: FC<{ isFinal: boolean }> = ({ isFinal }) => {
  const t = useTypedTranslation()
  const { fontMaker } = useTheme()
  const s = useStyles(
    ({ dimensions: { margin, spacing }, colors: { onSurface } }) => ({
      view: {
        alignItems: "center",
        padding: spacing,
        paddingBottom: spacing * 5,
      },
      title: {
        ...fontMaker({ weight: "SemiBold" }),
        marginBottom: margin,
        marginTop: margin * 1.5,
        color: onSurface.highEmphasis,
      },
      subtitle: {
        ...fontMaker({ style: "italic" }),
        textAlign: "center",
      },
    }),
    [],
  )

  return (
    <View style={s.view}>
      <MotiView animate={{ scale }}>
        <StarSVG height={77} width={77} />
      </MotiView>
      <Body1 style={s.title}>{t("bottomModal.autoValidated.title")}</Body1>
      <Subtitle2 style={s.subtitle}>
        {t(`bottomModal.autoValidated.${isFinal ? "message_final" : "message"}`)}
      </Subtitle2>
    </View>
  )
}

export default BottomAutoValidatedLevel
