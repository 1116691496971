import { axios, fetchVersion, getVersion, versions } from "@newpv/js-common"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { StackActions, useNavigation } from "@react-navigation/native"
import type { StackNavigationProp } from "@react-navigation/stack"
import LegalMention from "components/LegalMention"
import { Subtitle1 } from "components/Texts"
import Touchable from "components/Touchable/Touchable"
import { bffUrl, isWeb, LOCAL_STORAGE, modal } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useHardwareButton from "hooks/useHardwareButton"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import type { ReloadStorage } from "models/StorageInterfaces"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import type { LightMode } from "providers/PreferenceProvider"
import { LightModeValues, usePreferences } from "providers/PreferenceProvider"
import { useServer } from "providers/ServerProvider"
import type { FC } from "react"
import { useEffect } from "react"
import { ScrollView, View } from "react-native"
import { SegmentedButtons } from "react-native-paper"
import { useQuery } from "react-query"
import AudioSVG from "svgs/Audio"
import DisconnectSVG from "svgs/Disconnect"
import HintSVG from "svgs/Hint"
import MoonSVG from "svgs/Moon"
import useTheme from "theme/ThemeProvider"
import ScreenWrapper from "wrappers/ScreenWrapper/ScreenWrapper"

import packageJson from "../../../package.json"
import { DeveloperMode } from "./DeveloperMode"
import { SwitchBasedItem } from "./SwitchBasedItem"

const { version } = getVersion(packageJson)

export const SettingsScreen: FC<{ isModal?: boolean }> = ({ isModal = false }) => {
  const { isSmallWidth: isMobileBrowser } = useDeviceTools()
  const { isQA } = useAuthContext()
  const navigation = useNavigation<StackNavigationProp<IRootParamList, "Settings">>()
  const { darkMode, colors: themeColors } = useTheme()
  const t = useTypedTranslation()
  const cs = useCommonStyles()
  const { logout } = useServer()
  const { data: bffVersion } = useQuery({
    queryFn: async () =>
      (
        await axios.get<{ version: string; apiVersion: { version: string } }>(
          `${bffUrl}/healthcheck`,
        )
      ).data,
    queryKey: "version",
    refetchOnMount: false,
  })

  const { data: contentVersion } = useQuery({
    queryFn: async () => fetchVersion(undefined),
    queryKey: "contentVersion",
    refetchOnMount: false,
  })

  const s = useStyles(
    ({
      screenStyle,
      dimensions: { spacing },
      colors: {
        surface: { backgroundModal, surface, overlay },
      },
    }) => ({
      content: {
        ...screenStyle,
        backgroundColor: isModal ? backgroundModal : surface,
        paddingBottom: isWeb && !isMobileBrowser ? undefined : spacing * 2,
        paddingHorizontal: isWeb && !isMobileBrowser ? spacing * 6 : undefined,
      },
      divider: {
        backgroundColor: overlay,
        height: 1,
      },
      spaceBetween: {
        justifyContent: "space-between",
      },
      versionContainer: {
        backgroundColor: isModal ? backgroundModal : surface,
        justifyContent: "center",
        padding: spacing / 2,
        width: "100%" as const,
      },
      title: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
      },
      segmentedContainer: {
        marginHorizontal: spacing,
        marginVertical: spacing,
        padding: spacing / 2,
        borderRadius: spacing / 2,
        backgroundColor: darkMode
          ? themeColors.surface.surfaceSecondary
          : themeColors.surface.background,
        overflow: "hidden",
      },
      segmentedButtons: {
        borderColor: "transparent",
        borderWidth: 1,
        borderStartStartRadius: spacing / 2,
        borderEndStartRadius: spacing / 2,
        borderStartEndRadius: spacing / 2,
        borderEndEndRadius: spacing / 2,
      },
    }),
    [isMobileBrowser],
  )
  const { preferenceState, dispatchPreferences } = usePreferences()

  useHardwareButton({
    navigation: navigation.goBack,
  })
  useEffect(() => {
    ;(async () => {
      if (!isWeb) {
        return
      }
      const jsonStorage = await AsyncStorage.getItem(LOCAL_STORAGE.reload)
      if (!jsonStorage) {
        return
      }
      const storage = jsonStorage ? (JSON.parse(jsonStorage) as ReloadStorage) : undefined
      if (storage?.isReload) {
        navigation.dispatch(StackActions.replace(ns.HOME))
      }
    })()
  }, [navigation])

  const segmentButtonTheme = {
    colors: {
      primary: themeColors.onSurface.highEmphasis,
    },
  }
  return (
    <ScreenWrapper>
      {isModal ? <View style={{ height: modal.HEADER }} /> : null}
      <ScrollView
        style={isWeb && !isMobileBrowser ? cs.fullFlex : undefined}
        contentContainerStyle={s.content}
      >
        <View>
          <SwitchBasedItem
            description="Settings.audio"
            value={preferenceState.globalAudio}
            onValueChange={() => dispatchPreferences({ type: "switch", name: "globalAudio" })}
            icon={<AudioSVG />}
          />
          <SwitchBasedItem
            description="Settings.hints"
            value={preferenceState.hints}
            onValueChange={() => dispatchPreferences({ type: "switch", name: "hints" })}
            icon={<HintSVG />}
          />
          <View style={[cs.padding, cs.inlineRow]}>
            <View style={s.title}>
              <View style={cs.marginRight}>
                <MoonSVG />
              </View>
              <Subtitle1 color={themeColors.onSurface.highEmphasis}>
                {t("Settings.theme.title")}
              </Subtitle1>
            </View>
          </View>
          <View style={s.segmentedContainer}>
            <SegmentedButtons
              theme={segmentButtonTheme}
              value={preferenceState.theme}
              onValueChange={event =>
                dispatchPreferences({
                  type: "set",
                  name: "theme",
                  value: event as LightMode,
                })
              }
              buttons={LightModeValues.map(value => ({
                style: [
                  s.segmentedButtons,
                  {
                    backgroundColor:
                      value === preferenceState.theme ? themeColors.surface.surface : "transparent",
                  },
                ],
                value,
                label:
                  value === "system"
                    ? t("Settings.theme.type.system")
                    : value === "light"
                    ? t("Settings.theme.type.light")
                    : t("Settings.theme.type.dark"),
              }))}
            />
          </View>

          {isQA ? <DeveloperMode /> : null}
        </View>
        <View style={s.divider} />
        <Touchable onPress={() => logout()} style={cs.padding}>
          <View style={cs.flexRow}>
            <DisconnectSVG style={cs.marginRight} />
            <Subtitle1 color={themeColors.onSurface.highEmphasis}>
              {t("Settings.disconnect")}
            </Subtitle1>
          </View>
        </Touchable>
      </ScrollView>
      <View style={s.versionContainer}>
        <Subtitle1
          style={{ textAlign: "center" }}
          color={themeColors.onSurface.highEmphasis}
          selectable={true}
        >
          {t("Settings.version", {
            versionNumber: versions(
              version ?? "∅",
              bffVersion?.version ?? "∅",
              bffVersion?.apiVersion?.version ?? "∅",
              contentVersion?.version ?? "∅",
            ),
          })}
        </Subtitle1>
        <LegalMention />
      </View>
    </ScreenWrapper>
  )
}
