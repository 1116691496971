import type { TextStyle, ViewStyle } from "react-native"

export interface IColorTheme {
  primary_900: string
  primary_800: string
  primary_700: string
  primary_600: string
  primary: string
  primary_400: string
  primary_300: string
  primary_200: string
  primary_100: string
  primary_50: string
  secondary_700: string
  secondary_600: string
  secondary: string
  secondary_400: string
  secondary_350: string
  secondary_300: string
  secondary_200: string
  secondary_100: string
  secondary_50: string
  neutral_700: string
  neutral: string
  neutral_200: string
  neutral_100: string
  neutral_50: string
  black: string // secondary_100 secondary_50 secondary_600 secondary_800 secondary_900
  icon: string
  error: string
  ripple: string
  onPrimary: {
    highEmphasis: string
    mediumEmphasis: string
    disabled: string
  }
  onSurface: {
    button: string
    transparent: string
    highEmphasis: string
    mediumEmphasis: string
    lowEmphasis: string
    disabled: string
    disabledIcon: string
    disabledModal: string
    light: string
  }
  surface: {
    surface: string
    surfaceSecondary: string
    overlay: string
    overlayProgression: string
    background: string
    backdropWeb: string
    backgroundModal: string
    backgroundWide: string
    outline: string
    highlight: string
    translucent: string
    translucentIntensiveTraining: string
  }
  accents: {
    error: string
    danger: string
    success: string
    info: string
    warning: string
  }
  placeholder: {
    nav: string
    content: string
  }
  modules: {
    highEmphasis: string
  }
}

export type TypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "overline"
  | "exerciseSentence"

export type WeightName =
  | "Thin"
  | "Light"
  | "Regular"
  | "Medium"
  | "Semi-Bold"
  | "SemiBold"
  | "Bold"
  | "Black"

export const defaultWeights: WeightNames = {
  Thin: "100",
  Light: "300",
  Regular: "400",
  Medium: "500",
  "Semi-Bold": "600",
  SemiBold: "600",
  Bold: "700",
  Black: "900",
}

export const defaultStyles: StyleNames = {
  italic: "italic",
  normal: undefined,
}

export type StyleName = "normal" | "italic"

export interface IDimensionsTheme {
  width: number
  margin: number
  height: number
  spacing: number
  marginSides: number
}

export type ITypographyTheme = {
  [K in TypographyVariant]: TextStyle
}

export type IFontsTheme = {
  [key: string]: IFontTheme
} & { default: IFontTheme; title?: IFontTheme }

export type PartialPartial<T> = {
  [P in keyof T]?: Partial<T[P]>
}

export type PartialPartialPartial<T> = {
  [P in keyof T]?: PartialPartial<T[P]>
}

type WeightNames = {
  [K in WeightName]?:
    | "normal"
    | "bold"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
}

type StyleNames = {
  [K in StyleName]?: StyleName
}

export interface IFontTheme {
  weights: WeightNames
  styles?: StyleNames
  defaultWeight?: WeightName
  iosFamily?: string
  family?: string
}

export interface FmOptions {
  family?: string
  weight?: WeightName
  style?: StyleName
}

export type FmFunc = (options?: FmOptions) => TextStyle

type Mode = "adaptive" | "exact"

export interface ICSTheme {
  colors: IColorTheme
  dimensions: IDimensionsTheme
  typography: ITypographyTheme
  typoGen: (theme: ICSTheme) => ITypographyTheme
  fonts: IFontsTheme
  darkMode: boolean
  mode: Mode
  roundness: number
  screenStyle?: ViewStyle
}
