import { Caption } from "components/Texts"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import type { FC, PropsWithChildren } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"

interface IStatsBlockProps {
  title: string
  style?: StyleProp<ViewStyle>
}

export const StatsBlock: FC<PropsWithChildren<IStatsBlockProps>> = ({ title, children, style }) => {
  const {
    colors: { onSurface, surface },
  } = useTheme()
  const cs = useCommonStyles()
  const s = useStyles(({ dimensions: { margin, spacing } }) => ({
    bottomSpacing: {
      marginBottom: margin / 2,
    },
    container: {
      borderColor: surface.outline,
      borderRadius: 8,
      borderWidth: 1,
      flexDirection: "column",
      padding: spacing / 2,
    },
  }))
  return (
    <View style={[s.container, style]}>
      <View style={s.bottomSpacing}>
        <Caption color={onSurface.mediumEmphasis}>{title}</Caption>
      </View>
      <View style={cs.alignItemsCenter}>{children}</View>
    </View>
  )
}
