import { isApiError, parseInt10 } from "@newpv/js-common"
import type { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { Button } from "components/Button"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import { H4 } from "components/Texts"
import { maxScreenWidth } from "constants/constants"
import type { AuthRequestConfig } from "expo-auth-session"
import {
  exchangeCodeAsync,
  makeRedirectUri,
  Prompt,
  ResponseType,
  useAuthRequest,
} from "expo-auth-session"
import * as WebBrowser from "expo-web-browser"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import type i18n from "i18n/fr/fr"
import { rootRoutes } from "navigation/Constants"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import type { FC } from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Linking, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { postShowcaseLogin } from "screens/LoginScreen/login.request"
import { logger } from "utils/logger"

import { config } from "../../../package.json"

type State = keyof typeof i18n.OAuthLogin.state
type Error = keyof typeof i18n.OAuthLogin.error

WebBrowser.maybeCompleteAuthSession()

const authorizationBase = "https://pv-showcase2.projet-voltaire.com"
const discovery = {
  authorizationEndpoint: authorizationBase + "/oauth/authorize",
  tokenEndpoint: authorizationBase + "/oauth/token",
  revocationEndpoint: authorizationBase + "/oauth/revoke",
}

const clientId = "2"
// noinspection SpellCheckingInspection
const clientSecret = "OQIgcediQo0fK8KRhutbSGrFNHvgVTbVIKgiMQuB"

const OAuthLoginScreen: FC<NativeStackScreenProps<IRootParamList, "OAuthLogin">> = ({
  navigation,
}) => {
  const oauthConfig: AuthRequestConfig = useMemo(
    () => ({
      responseType: ResponseType.Code,
      clientId,
      clientSecret,
      usePKCE: false,
      redirectUri: makeRedirectUri({
        scheme: config.packageId,
      }),
      prompt: Prompt.Login,
    }),
    [],
  )
  const [request, _, promptAsync] = useAuthRequest(oauthConfig, discovery)
  const { isQA } = useAuthContext()

  const cs = useCommonStyles()

  const s = useStyles(({ dimensions: { spacing } }) => ({
    container: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    loginContainer: {
      justifyContent: "center",
      maxWidth: maxScreenWidth,
      padding: spacing,
      width: "100%" as const,
    },
  }))

  useEffect(() => {
    if (request != null) {
      setState(prevState => (prevState === "initial" ? "ready" : prevState))
    }
  }, [request])

  const t = useTypedTranslation()
  // const route = useRoute<RouteProp<IRootParamList, "OAuthLogin">>()
  const [state, setState] = useState<State>("initial")
  const [error, setError] = useState<Error>()

  const { setToken, setScenarioId } = useAuthContext()

  logger("OAuthLoginScreen", { request, state })

  useEffect(() => {
    // eslint-disable-next-line no-console
    Linking.getInitialURL().then(initialUrl => logger("Initial URL", initialUrl))
  }, [])

  const onSubmit = useCallback(async () => {
    logger("Start login")
    setError(undefined)

    try {
      const response = await promptAsync()
      if (response.type !== "success") {
        setError(response.type)
        return
      }

      setState("oauth")

      // eslint-disable-next-line no-console
      logger("before exchangeCodeAsync")
      const exchangeTokenResponse = await exchangeCodeAsync(
        {
          clientId,
          clientSecret,
          code: response.params.code,
          redirectUri: oauthConfig.redirectUri,
          /* extraParams: {
            code_verifier: request.codeVerifier ?? "",
          }, */
        },
        discovery,
      )

      // eslint-disable-next-line no-console
      logger("exchangeTokenResponse", exchangeTokenResponse)

      const jwt = exchangeTokenResponse?.accessToken

      if (!jwt) {
        setError("no_token")
        return
      }

      setState("gettingToken")
      logger("gettingToken")

      const scenarioId = "138"

      const scenarioIdNum = parseInt10(scenarioId)

      const loginResult = await postShowcaseLogin({ jwt, scenarioId, service: "" })

      if (isApiError(loginResult)) {
        setError(loginResult.data.code as Error)
        return
      }

      setToken(loginResult.data.jwt)
      setScenarioId(scenarioIdNum)

      setState("done")
    } catch (e) {
      logger("Caught error", e)
      setError(e.code ?? "default")
    }

    logger("Done login")
  }, [oauthConfig.redirectUri, promptAsync, setScenarioId, setToken])

  return (
    <SafeAreaView style={s.container}>
      <View style={s.loginContainer}>
        <H4 style={[cs.textCenter, cs.marginBottom]}>{t("OAuthLogin.title")}</H4>
        <Button onPress={onSubmit} disabled={state !== "ready"} style={cs.alignCenter}>
          {t("OAuthLogin.toLog")}
        </Button>
        {isQA ? (
          <Button
            disabled={state !== "initial" && state !== "ready"}
            onPress={() => navigation.navigate(rootRoutes.LOGIN)}
            style={[cs.alignCenter, cs.marginTop]}
          >
            {t("Login.title")}
          </Button>
        ) : null}

        {state !== "initial" && state !== "ready" && error == null ? (
          <ActivityIndicator title={t(`OAuthLogin.state.${state}`)} />
        ) : null}

        {error != null ? (
          <ErrorPanel
            title={t([`OAuthLogin.error.${error}`, "OAuthLogin.error.default"])}
            description={t([`OAuthLogin.state.${state}`, "OAuthLogin.state.initial"])}
            onPress={onSubmit}
            code={error}
          />
        ) : null}
      </View>
    </SafeAreaView>
  )
}

export default OAuthLoginScreen
