import { H6 } from "components/Texts"
import useCommonStyles from "hooks/useCommonStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { PREFERENCE_DEV_PROPERTIES, usePreferences } from "providers/PreferenceProvider"
import { View } from "react-native"

import { SwitchBasedItem } from "./SwitchBasedItem"

export const DeveloperMode = (): JSX.Element => {
  const t = useTypedTranslation()
  const cs = useCommonStyles()
  const { preferenceState, dispatchPreferences } = usePreferences()

  return (
    <View>
      <View style={cs.padding}>
        <H6>{t("Settings.devMode.title")}</H6>
      </View>
      {_.map(PREFERENCE_DEV_PROPERTIES, item => (
        <SwitchBasedItem
          key={item}
          description={`Settings.devMode.${item}`}
          onValueChange={() => dispatchPreferences({ type: "switch", name: item })}
          value={preferenceState[item]}
        />
      ))}
    </View>
  )
}
