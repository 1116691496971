import { iconSize } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import { t } from "i18next"
import _ from "lodash"
import type { SVGComponent } from "models/SVGInterface"
import { isSVGComponent } from "models/SVGInterface"
import type { Dispatch, FC, ReactNode, SetStateAction } from "react"
import { useMemo } from "react"
import type { ImageSourcePropType, StyleProp, TextStyle, ViewStyle } from "react-native"
import { Image, Pressable, View } from "react-native"
import { List, Tooltip } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import type { ProgressItem } from "../Progression/ProgressionItem"
import ProgressionItem, { isProgressionItem } from "../Progression/ProgressionItem"
import Separator from "../Separator/Separator"
import { Subtitle1 } from "../Texts"

interface IProps {
  title: string
  separator?: boolean
  onPress?: () => void
  description?: ReactNode | ((params) => ReactNode) | string
  leftIcon?: ImageSourcePropType | SVGComponent
  rowStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  setHeight?: Dispatch<SetStateAction<number>>
  rightNode?: string | ProgressItem | SVGComponent
  isValidationLevel?: boolean
  placeHolder?: boolean
}

// Paper List Item : cannot control icon position to align elements
const RowItem: FC<IProps> = ({
  title,
  description,
  titleStyle,
  separator = true,
  onPress,
  leftIcon,
  rightNode,
  rowStyle,
  placeHolder,
}) => {
  const {
    typography: { caption },
    colors: {
      ripple,
      onSurface: { transparent, highEmphasis },
      surface: { surface, background },
    },
  } = useTheme()
  const cs = useCommonStyles()
  const s = useStyles(
    ({ typography, dimensions: { spacing } }) => ({
      description: {
        ...typography.subtitle1,
      },
      icon: {
        justifyContent: "center",
        paddingHorizontal: spacing / 2,
      },
      item: {
        paddingVertical: 0,
      },
      placeholder: {
        width: iconSize.SMALL,
      },
      row: {
        backgroundColor: surface,
        justifyContent: "center",
        width: "100%" as const,
      },
    }),
    [titleStyle],
  )
  const { disabled, autoValidateRevisionLevel, percent } = (rightNode as ProgressItem) || {}

  const tooltipText = useMemo(() => {
    if (disabled) {
      return t(`${ns.LEVEL}.disabled`)
    } else if (autoValidateRevisionLevel) {
      return t(`${ns.LEVEL}.autoValidation`)
    } else if (percent === 100) {
      return t(`${ns.LEVEL}.levelValidated`)
    } else if (percent === 0) {
      return t(`${ns.LEVEL}.levelAvailable`)
    } else if (percent !== undefined) {
      return t(`${ns.LEVEL}.inTraining`)
    }
    return t(`${ns.LEVEL}.disabled`)
  }, [autoValidateRevisionLevel, disabled, percent])

  const left = useMemo(
    () => () =>
      (
        <View style={s.icon}>
          {isSVGComponent(leftIcon) ? (
            leftIcon()
          ) : placeHolder ? (
            <View style={s.placeholder} />
          ) : leftIcon ? (
            <Image source={leftIcon} style={{ width: 56, height: 56 }} resizeMode="cover" />
          ) : null}
        </View>
      ),
    [leftIcon, placeHolder, s.icon, s.placeholder],
  )

  const right = useMemo(
    () => () =>
      (
        <View style={cs.center}>
          <Pressable onPress={onPress} style={cs.center}>
            {_.isUndefined(rightNode) ? null : isSVGComponent(rightNode) ? (
              rightNode()
            ) : isProgressionItem(rightNode) ? (
              <Tooltip
                title={tooltipText}
                theme={{ colors: { surface: highEmphasis, tooltip: background } }}
                enterTouchDelay={50}
                leaveTouchDelay={50}
              >
                <ProgressionItem
                  borderWidth={3}
                  itemStyle={s.item}
                  labelStyle={caption}
                  circleBackgroundColor={transparent}
                  item={{ ...rightNode, key: `${title}-${rightNode}` }}
                />
              </Tooltip>
            ) : (
              <Subtitle1 style={cs.alignCenter}>{rightNode}</Subtitle1>
            )}
          </Pressable>
        </View>
      ),
    [
      background,
      caption,
      cs.alignCenter,
      cs.center,
      highEmphasis,
      onPress,
      rightNode,
      s.item,
      title,
      tooltipText,
      transparent,
    ],
  )

  return (
    <>
      <List.Item
        key={title}
        disabled={!onPress}
        titleNumberOfLines={4}
        style={[s.row, rowStyle]}
        descriptionStyle={s.description}
        titleStyle={[cs.subtitleHighEmphasis, titleStyle]}
        rippleColor={rippleColor(ripple)}
        {...{ title, description, onPress, left, right }}
      />
      {separator ? <Separator /> : null}
    </>
  )
}

export default RowItem
