import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const SpellingSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onPrimary },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 34} height={props?.height ?? 34} fill="none" viewBox="0 0 34 34">
      <Path
        d="M33.903 4c-1.846.179-14.988 1.909-15.905 14.357a78.774 78.774 0 0 0-.215 4.004l6.45-8.92c.109-.15.256-.235.41-.235.153 0 .3.084.409.235.108.15.17.354.17.566a.978.978 0 0 1-.17.567l-9.356 12.942a1.302 1.302 0 0 0-.19.39 1.611 1.611 0 0 0 0 .923c.044.147.109.28.19.391.164.225.385.35.615.35.231 0 .452-.125.616-.35l2.062-2.854a30.165 30.165 0 0 0 4.543-.367c1.763-.237 3.431-1.222 4.776-2.82H24.67l5.294-2.445a21.38 21.38 0 0 0 1.095-2.352h-2.923l3.834-2.667A53.76 53.76 0 0 0 33.903 4ZM8.888 29.714H.923c-.245 0-.48-.134-.653-.374a1.561 1.561 0 0 1-.27-.904c0-.339.097-.664.27-.904.174-.24.408-.374.653-.374h7.965c.245 0 .48.135.653.374.173.24.27.565.27.904 0 .34-.097.664-.27.904-.173.24-.408.374-.653.374ZM15.043 23.205H.923c-.245 0-.48-.135-.653-.375a1.56 1.56 0 0 1-.27-.903c0-.34.097-.664.27-.904.174-.24.408-.374.653-.374h14.12c.245 0 .48.134.653.374.173.24.27.565.27.904 0 .339-.097.664-.27.903-.173.24-.408.375-.653.375ZM15.043 16.695H.923c-.245 0-.48-.134-.653-.374a1.56 1.56 0 0 1-.27-.904c0-.339.097-.664.27-.903.174-.24.408-.375.653-.375h14.12c.245 0 .48.135.653.375.173.24.27.564.27.903 0 .34-.097.664-.27.904-.173.24-.408.374-.653.374Z"
        fill={onPrimary.highEmphasis}
      />
    </Svg>
  )
}
export default SpellingSVG
