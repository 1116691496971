import type { BaseEngine, EngineInteraction, Rule, RuleMetadata } from "@newpv/js-common"
import { makeEngine } from "@newpv/js-common"
import type { HeaderHandle } from "components/Headers"
import useExerciseStates from "hooks/useExerciseStates"
import useTrainingOrEvalModals from "hooks/useTrainingOrEvalModals"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import { getExercisesByRuleId, getExercisesWithRuleId } from "models/ModuleFunctions"
import useAuthContext from "providers/AuthProvider"
import type { ContextData } from "providers/ExerciseProvider"
import { exerciseContext } from "providers/ExerciseProvider"
import { usePreferences } from "providers/PreferenceProvider"
import type { FC, PropsWithChildren } from "react"
import { useCallback, useEffect, useMemo, useRef } from "react"

interface IProps {
  rules: Rule[]
}

const PresentialProvider: FC<PropsWithChildren<IProps>> = ({ children, rules }) => {
  const t = useTypedTranslation()

  const exercises = useMemo(() => getExercisesWithRuleId(rules), [rules])

  const {
    currentExercise: { rule, exercise, ruleMetaData, ...currentExerciseStateRest },
    levelState: { progress, nbOfAcquiredRules, setProgress, setNbOfAcquiredRules },
    audio: { toggleLevelAudio, ...audioStateRest },
    emailPickOutOneState,
    engine: { runEngine },
  } = useExerciseStates({ rules })
  const {
    preferenceState: { hints },
  } = usePreferences()
  // Global ref/states/constants for managing rules and exercises
  const engine = useRef<BaseEngine<RuleMetadata>>()
  const engineInitialization = useRef<boolean>()

  // Modals
  const headerRef = useRef<HeaderHandle>(null)
  const { showAcquiredRuleModal, showLevelCongratsModal, showErrorModal } =
    useTrainingOrEvalModals()
  const { isQA } = useAuthContext()

  const getResults = useCallback(
    (
      isUncompleted: boolean,
    ): {
      masteredRules: string[]
      unmasteredRules: string[]
      score?: number
      total?: number
      ratio?: number
    } => {
      const rulesById = _.keyBy(rules, "id")
      if (isUncompleted && engine.current) {
        return _.mapValues(engine.current.getRuleStatuses(), ruleIds =>
          ruleIds.map(id => rulesById[id]?.title),
        )
      }
      return {
        masteredRules: _.map(rules, r => r.title),
        unmasteredRules: [],
      }
    },
    [rules],
  )

  useEffect(() => {
    if (engineInitialization.current) {
      return
    }
    engineInitialization.current = true
    engine.current = makeEngine().init(getExercisesByRuleId(rules))
    runEngine(engine.current)
  }, [rules, runEngine])

  const displayNextTrainingExercise = useCallback(
    async (isAnswerCorrect?: boolean) => {
      if (!engine.current || !rule || !exercise) {
        // noinspection ES6MissingAwait
        showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.nextExercise.title`),
        })
        return
      }
      const interaction: EngineInteraction = {
        timestamp: Date.now(),
        ruleId: rule.id,
        exerciseId: exercise.id,
        correct: isAnswerCorrect ?? false,
      }
      const res = engine.current.recordInteraction(interaction)
      if (isQA) {
        // @ts-ignore
        // eslint-disable-next-line no-console
        console.log(engine.current.dump?.(true))
      }
      const isRuleNewlyAcquired = nbOfAcquiredRules !== res.learned
      setProgress(res.progression)
      if (isRuleNewlyAcquired) {
        setNbOfAcquiredRules(res.learned)
        await showAcquiredRuleModal(rule.title, () => headerRef.current?.animate())
      }
      // end of the level, or next evaluation, or practice test
      if (res.allLearned) {
        const { masteredRules } = getResults(false)
        showLevelCongratsModal({
          masteredRules,
          isPresential: true,
        })
        return
      }
      runEngine(engine.current)
    },
    [
      rule,
      exercise,
      isQA,
      nbOfAcquiredRules,
      setProgress,
      runEngine,
      showErrorModal,
      t,
      setNbOfAcquiredRules,
      showAcquiredRuleModal,
      getResults,
      showLevelCongratsModal,
    ],
  )

  const contextValue: ContextData = {
    headerRef,
    currentExercise: {
      rule,
      exercise,
      isCrucialQuestion: ruleMetaData?.critical,
      isClueAllowed: hints && ruleMetaData?.clue,
      isExplanationAllowed: false,
      ...currentExerciseStateRest,
    },
    levelState: {
      progress,
      nbOfAcquiredRules,
      totalNumberOfRulesInLevel: _.size(rules),
      totalNumberOfExercises: exercises.length,
    },
    audio: {
      toggleLevelAudio,
      ...audioStateRest,
    },
    emailPickOutOneState,
    getResults,
    displayNextTrainingExercise,
    displayNextEvaluationExercise: async () => {},
    closeTraining: _.noop,
    closeEvaluation: async () => true,
  }
  return <exerciseContext.Provider value={contextValue}>{children}</exerciseContext.Provider>
}

export default PresentialProvider
