import type { DragAndDropWord, Result } from "@newpv/js-common"
import { Button } from "components/Button"
import HelperView from "components/HelperView/HelperView"
import { bottomSheet, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import BottomSheetProvider from "providers/BottomSheetProvider"
import { useDragAndDrop, WordColumn } from "providers/DadProvider"
import { useExercise } from "providers/ExerciseProvider"
import { useServer } from "providers/ServerProvider"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import type { ViewStyle } from "react-native"
import { Animated, View } from "react-native"
import { DraxProvider } from "react-native-drax"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import LabelText from "../TextViews/LabelText"
import WordBank from "../WordViews/WordBank"
import WordsColumn from "../WordViews/WordsColumn"

interface IProps {
  isEvaluation?: boolean
  result?: Result
  onSelectPress?: (value: Result) => Promise<void>
}

export interface DragAndDropHandle {
  displayCorrection: () => void
}

export interface DragAndDropAnimation extends DragAndDropWord {
  animationX: Animated.Value
  animationY: Animated.Value
  position: number
}

const toAnimations = (
  wordsInColumn: DragAndDropWord[],
  columnToCompareTo: number,
): DragAndDropAnimation[] =>
  _(wordsInColumn)
    .map((el, index) =>
      el.correctColumn === columnToCompareTo
        ? {
            ...el,
            position: index,
            animationY: new Animated.Value(0),
            animationX: new Animated.Value(0),
          }
        : undefined,
    )
    .compact()
    .value()

const DragAndDrop = forwardRef<DragAndDropHandle, IProps>(
  ({ result, isEvaluation, onSelectPress }, ref) => {
    /** STATES */
    const [isAnswered, setIsAnswered] = useState(false)
    const [animatedItems, setAnimatedItems] = useState<DragAndDropAnimation[]>()
    const [firstColumnWidth, setFirstColumnWidth] = useState<{ width: number }>()
    const [secondColumnWidth, setSecondColumnWidth] = useState<{ width: number }>()
    const [itemsHeight, setItemsHeight] = useState<Array<{ id: string; height: number }>>([])

    /** PROVIDERS & CUSTOM HOOKS */
    const {
      colors: { ripple, primary_400, onSurface },
      dimensions: { spacing, margin },
    } = useTheme()
    const t = useTypedTranslation()
    const { isSendingInteraction } = useServer()
    const {
      wordsInBank,
      isDragging,
      getNumberOfErrors,
      wordsInFirstColumn,
      wordsInSecondColumn,
      columInstructions,
      manageWord,
    } = useDragAndDrop()

    const {
      currentExercise: { isClueVisible, rule, isExplanationAllowed, setIsClueVisible },
    } = useExercise()

    const cs = useCommonStyles()

    const buttonStyles = useMemo(() => {
      if ((!_.isEmpty(wordsInBank) && !_.isUndefined(wordsInBank)) || isSendingInteraction) {
        return {
          backgroundColor: onSurface.disabled,
          color: onSurface.highEmphasis,
        }
      }
      return {
        backgroundColor: primary_400,
        color: onSurface.button,
      }
    }, [
      isSendingInteraction,
      onSurface.button,
      onSurface.disabled,
      onSurface.highEmphasis,
      primary_400,
      wordsInBank,
    ])

    const { button, buttonContainer, container, buttonLabel } = useStyles(
      ({ dimensions: { margin: styleMargin } }) => ({
        button: {
          backgroundColor: buttonStyles.backgroundColor,
          borderWidth: 0,
        },
        buttonLabel: {
          color: buttonStyles.color,
        },
        buttonContainer: {
          flexDirection: "row",
          justifyContent: "center",
          marginTop: styleMargin,
        } as ViewStyle,
        container: {
          ...(isWeb && isDragging ? { cursor: "grabbing" } : {}),
          backgroundColor: onSurface.transparent,
        },
      }),
      [isDragging, wordsInBank, isSendingInteraction],
    )

    /** REFS */
    const pickedWord = useRef<DragAndDropWord>()
    const clueViewHasBeenClicked = useRef<boolean>()

    /** USEEFFECTs and USECALLBACKs */
    useEffect(() => {
      if (wordsInBank && !pickedWord.current) {
        pickedWord.current = _.sample(wordsInBank)
      }
    }, [wordsInBank])

    useEffect(() => {
      if (isClueVisible) {
        clueViewHasBeenClicked.current = true
      }
    }, [isClueVisible])

    useEffect(() => {
      if (isClueVisible && pickedWord.current) {
        manageWord(
          pickedWord.current,
          pickedWord.current.correctColumn === 1
            ? WordColumn.FIRST_COLUMN
            : WordColumn.SECOND_COLUMN,
        )
        setIsClueVisible(false)
      }
    }, [isClueVisible, manageWord, setIsClueVisible])

    useImperativeHandle(
      ref,
      () => ({
        // TODO: not a priority, but this might lead to a bug if we click on a QA button after already placing some words in the wrong columns?
        displayCorrection: () => {
          wordsInBank?.map(word =>
            manageWord(
              word,
              word.correctColumn === 1 ? WordColumn.FIRST_COLUMN : WordColumn.SECOND_COLUMN,
            ),
          )
        },
      }),
      [manageWord, wordsInBank],
    )

    /* ANIMATION AFTER ANSWER */
    const calculateTotalHeight = useCallback(
      (dragAndDropWords: DragAndDropWord[]) =>
        dragAndDropWords.reduce(
          (acc, value) =>
            acc +
            (itemsHeight?.find(itemWithHeight => itemWithHeight.id === value.id)?.height ?? 0),
          0,
        ),
      [itemsHeight],
    )

    const calculateYAnimationCorrectWords = useCallback(
      (correctItemAnimationArray: DragAndDropAnimation[], wordsInColumn: DragAndDropWord[]) => {
        correctItemAnimationArray.forEach(item => {
          // If position of item is 0, we don't need to perform an animation to move it
          if ((item?.position ?? 0) > 0) {
            const toValue = calculateTotalHeight(
              wordsInColumn.filter(
                (word, wordIndex) =>
                  wordIndex < item.position && word.correctColumn !== item.correctColumn,
              ),
            )

            Animated.timing(item.animationY, {
              toValue: -toValue,
              duration: 500,
              useNativeDriver: true,
            }).start()
          }
        })
      },
      [calculateTotalHeight],
    )

    const calculateYAnimationOnMisplacedWord = useCallback(
      (
        misplacedWord: DragAndDropAnimation,
        correctWordsInColumnToReach: DragAndDropWord[],
        wordsInSameColumn: DragAndDropWord[],
      ) => {
        const heightBeforeCurrentWordInColumnToReach = calculateTotalHeight([
          ...wordsInSameColumn.filter(
            (word, wordIndex) =>
              wordIndex < misplacedWord.position &&
              word.correctColumn === misplacedWord.correctColumn,
          ),
          ...correctWordsInColumnToReach,
        ])

        const initialHeightAboveWord = calculateTotalHeight(
          wordsInSameColumn.slice(0, misplacedWord.position),
        )

        // Gap between the current placement and its final placement
        return heightBeforeCurrentWordInColumnToReach - initialHeightAboveWord
      },
      [calculateTotalHeight],
    )

    // Manages only misplaced words
    const manageMisplacedWords = useCallback(
      (itemsToCorrect: DragAndDropAnimation[]) => {
        if (firstColumnWidth && secondColumnWidth && itemsHeight) {
          const firstColumnResult = [...wordsInFirstColumn].filter(el => el.correctColumn === 1)
          const secondColumnResult = [...wordsInSecondColumn].filter(el => el.correctColumn === 2)

          itemsToCorrect?.map((misplacedItem, index) => {
            let toValueY

            if (misplacedItem.correctColumn === 1) {
              toValueY = calculateYAnimationOnMisplacedWord(
                misplacedItem,
                firstColumnResult,
                wordsInSecondColumn,
              )
            } else {
              toValueY = calculateYAnimationOnMisplacedWord(
                misplacedItem,
                secondColumnResult,
                wordsInFirstColumn,
              )
            }

            setTimeout(() => {
              // Launch animation with delay between for all misplaced items
              Animated.timing(misplacedItem.animationX, {
                duration: 500,
                toValue:
                  misplacedItem.correctColumn === 1
                    ? // TODO: here, review spacing on mobile
                      // may be linked to exerciseCardContainer style in ExerciseContent
                      -firstColumnWidth.width - 2 * (spacing / 2)
                    : secondColumnWidth.width + 2 * (spacing / 2),
                useNativeDriver: true,
              }).start()
              Animated.timing(misplacedItem.animationY, {
                duration: 500,
                toValue: toValueY,
                useNativeDriver: true,
              }).start()
            }, index * 300)
          })
        }
      },
      [
        firstColumnWidth,
        secondColumnWidth,
        spacing,
        itemsHeight,
        wordsInFirstColumn,
        wordsInSecondColumn,
        calculateYAnimationOnMisplacedWord,
      ],
    )

    // Action launch on press validate button
    const validateAnswer = useCallback(async () => {
      setIsAnswered(true)
      const res = getNumberOfErrors() === 0 ? "correct" : "wrong"
      await onSelectPress?.(res)

      // TODO: refacto
      if (res === "wrong") {
        // Create array of animated item for wrong items in first column
        const mistakesInFirstColumn = toAnimations(wordsInFirstColumn, 2)

        // Create array of animated item for wrong items in second column
        const mistakesInSecondColumn = toAnimations(wordsInSecondColumn, 1)

        const correctWordsInFirstColumn = toAnimations(wordsInFirstColumn, 1)
        const correctWordsInSecondColumn = toAnimations(wordsInSecondColumn, 2)

        const itemsToCorrect = mistakesInFirstColumn.concat(mistakesInSecondColumn)

        // handle correct items first
        calculateYAnimationCorrectWords(correctWordsInFirstColumn, wordsInFirstColumn)
        calculateYAnimationCorrectWords(correctWordsInSecondColumn, wordsInSecondColumn)

        setAnimatedItems(
          _.concat(
            correctWordsInFirstColumn,
            correctWordsInSecondColumn,
            mistakesInFirstColumn,
            mistakesInSecondColumn,
          ),
        )

        // and then, mistakes
        setTimeout(() => {
          manageMisplacedWords(itemsToCorrect)
        }, 500)
      }
    }, [
      getNumberOfErrors,
      onSelectPress,
      wordsInFirstColumn,
      wordsInSecondColumn,
      calculateYAnimationCorrectWords,
      manageMisplacedWords,
    ])

    const totalColumHeight = useCallback(
      (columnNumber: 1 | 2) => {
        const placedWordsInColumn = _.concat(wordsInFirstColumn, wordsInSecondColumn).filter(
          word => word.correctColumn === columnNumber,
        )
        return (
          calculateTotalHeight(placedWordsInColumn) +
          (placedWordsInColumn.length * (margin / 2) + spacing / 4)
        )
      },
      [calculateTotalHeight, margin, spacing, wordsInFirstColumn, wordsInSecondColumn],
    )

    const minHeight = Math.max(totalColumHeight(1), totalColumHeight(2))

    return (
      <DraxProvider>
        <View style={container}>
          {!isEvaluation && result != null ? null : <WordBank />}
          <View style={[cs.row, cs.marginTop]}>
            <LabelText text={columInstructions?.[0]} />
            <LabelText text={columInstructions?.[1]} />
          </View>
          <View style={cs.row}>
            {[wordsInFirstColumn, wordsInSecondColumn].map((words, index) => (
              <WordsColumn
                key={index}
                clueWord={clueViewHasBeenClicked?.current ? pickedWord.current : undefined}
                {...{
                  words,
                  index,
                  result,
                  isAnswered,
                  isEvaluation,
                  setItemsHeight,
                  animatedItems,
                  setColumnInfo: index === 0 ? setFirstColumnWidth : setSecondColumnWidth,
                  minHeight,
                }}
              />
            ))}
          </View>
        </View>
        <View style={buttonContainer}>
          <Button
            mode="outlined"
            rippleColor={rippleColor(ripple)}
            style={button}
            labelStyle={buttonLabel}
            loading={isSendingInteraction}
            disabled={
              (!_.isEmpty(wordsInBank) && !_.isUndefined(wordsInBank)) || isSendingInteraction
            }
            onPress={validateAnswer}
          >
            {t("common.button.validate")}
          </Button>
        </View>
        {isExplanationAllowed && (rule?.title || rule?.description) && !isAnswered ? (
          <View style={cs.fullWidth}>
            <BottomSheetProvider sliderMinHeight={bottomSheet.MAX_HEIGHT_1}>
              <HelperView {...{ rule }} />
            </BottomSheetProvider>
          </View>
        ) : null}
      </DraxProvider>
    )
  },
)

export default DragAndDrop
