import { maxScreenWidth } from "constants/constants"
import * as Device from "expo-device"
import { DeviceType } from "expo-device"
import { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"

interface DeviceTools {
  isSmallWidth: boolean
  isDesktop?: boolean
}
const useDeviceTools = (): DeviceTools => {
  const { width: windowWidth } = useWindowDimensions()
  const [deviceType, setDeviceType] = useState<Device.DeviceType>()
  useEffect(() => {
    let mounted = true
    const effect = async (): Promise<void> => {
      Device.getDeviceTypeAsync().then(result => {
        if (mounted) {
          setDeviceType(result)
        }
      })
    }
    // noinspection JSIgnoredPromiseFromCall
    effect()
    return () => {
      mounted = false
    }
  }, [])

  return {
    // exception for tablet, the width is between small and enough to apply action for large screens
    isSmallWidth: windowWidth < maxScreenWidth && deviceType !== DeviceType.TABLET,
    isDesktop: deviceType === DeviceType.DESKTOP,
  }
}

export default useDeviceTools
