import type { TranslationKeysFR } from "i18n/i18next.fr.keys"
import type { TypedTFunction } from "i18next-typescript"
import { useTranslation } from "react-i18next"

export type TranslationKeys = TranslationKeysFR

export type TFunction = TypedTFunction<TranslationKeys>

export interface TFunctionProp {
  t: TFunction
}

const useTypedTranslation = (): TFunction => {
  const { t } = useTranslation()

  return t
}

export default useTypedTranslation
