import type {
  BaseEngine,
  EmailPickOutOneOption,
  Exercise,
  IntensiveTrainingExercise,
  PickOutNearestExercise,
  PickOutOneExercise,
  PickOutOneWordBlock,
  Rule,
  RuleId,
  RuleMetadata,
} from "@newpv/js-common"
import {
  isClickOnMistake,
  isEmailPickOutOne,
  isPickOutNearest,
  isPickOutOne,
} from "@newpv/js-common"
import { iconSize } from "constants/constants"
import { useSound } from "hooks/useSound"
import useTrainingOrEvalModals from "hooks/useTrainingOrEvalModals"
import useTypedTranslation from "hooks/useTypedTranslation"
import { ns } from "i18n/fr"
import _ from "lodash"
import { isEvaluationEngine } from "models/EngineHelperFunctions"
import { ModalType } from "models/ModalInterfaces"
import { getCurrentRuleAndExerciseData } from "models/ModuleFunctions"
import { useConsultationMode } from "providers/ConsultationModeProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useModal } from "providers/ModalProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { Dispatch, MutableRefObject, SetStateAction } from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { isSafari } from "react-device-detect"
import VolumeSVG from "svgs/Volume"
import useTheme from "theme/ThemeProvider"
import { generateAudioExerciseUrl, speak } from "utils/audio-exercice"

interface ExerciseStates {
  currentExercise: {
    rule?: Rule
    exercise?: Exercise
    ruleMetaData?: RuleMetadata
    setCurrentExercise: Dispatch<SetStateAction<Exercise | undefined>>
    setCurrentRule: Dispatch<SetStateAction<Rule | undefined>>
    getRandomWordsFromOtherExercises: (n: number) => PickOutOneWordBlock[] | undefined
    isClueVisible: boolean
    setIsClueVisible: Dispatch<SetStateAction<boolean>>
    isIntensiveTraining: boolean
    setIsIntensiveTraining: Dispatch<SetStateAction<boolean>>
    intensiveTrainingExercises?: IntensiveTrainingExercise[]
    resetIntensiveTrainingExercises: () => void
    intensiveCount?: number
  }
  levelState: {
    progress?: number
    nbOfAcquiredRules: number
    setProgress: Dispatch<SetStateAction<number | undefined>>
    setNbOfAcquiredRules: Dispatch<SetStateAction<number>>
  }
  audio: {
    exerciseAudio: MutableRefObject<boolean | undefined>
    playExerciseAudio: (rule?: Omit<Rule, "exercises">, exercise?: Exercise) => void
    toggleLevelAudio: (currentEngine?: BaseEngine<RuleMetadata>) => Promise<void>
  }
  emailPickOutOneState: {
    setSelectedOption: Dispatch<
      SetStateAction<
        | {
            answer: EmailPickOutOneOption
            index: number
          }
        | undefined
      >
    >
    emailPickOutOneOptions: EmailPickOutOneOption[]
    selectedOption:
      | {
          answer: EmailPickOutOneOption
          index: number
        }
      | undefined
  }
  engine: {
    runEngine: (engine?: BaseEngine<RuleMetadata>) => void
  }
}

interface Props {
  rules?: Rule[]
}

const useExerciseStates = (props: Props): ExerciseStates => {
  const {
    colors: { icon: iconColor },
  } = useTheme()
  const t = useTypedTranslation()
  const { showModal } = useModal()
  const {
    isConnected,
    preferenceState: { globalAudio },
  } = usePreferences()
  const { scenario } = useScenarioAndModule()
  const { isResumedEval, setIsResumedEval } = useLevelAndEvaluation()
  const {
    ExercisesState: [allExercises],
  } = useConsultationMode()
  // current Exercise, Rule and progress during a level

  const [currentExercise, setCurrentExercise] = useState<Exercise>()
  const [currentRule, setCurrentRule] = useState<Rule>()
  const [progress, setProgress] = useState<number>()
  const [ruleMetaData, setRuleMetaData] = useState<RuleMetadata>()
  const [nbOfAcquiredRules, setNbOfAcquiredRules] = useState(0)

  const [isClueVisible, setIsClueVisible] = useState(false)

  // Email pick out one states
  const [selectedOption, setSelectedOption] = useState<{
    answer: EmailPickOutOneOption
    index: number
  }>()

  const emailPickOutOneOptions = useMemo(() => {
    if (isEmailPickOutOne(currentExercise)) {
      const possiblesAnswers = _.partition(currentExercise.options, el => el.isAnswer)
      const sampleOfBadAnswers = _.sampleSize(possiblesAnswers[1], 3)
      return _.shuffle([...sampleOfBadAnswers, ...possiblesAnswers[0]])
    } else {
      return []
    }
  }, [currentExercise])

  // Intensive training
  const [isIntensiveTraining, setIsIntensiveTraining] = useState(false)
  const [intensiveTrainingExercises, setIntensiveTrainingExercises] =
    useState<IntensiveTrainingExercise[]>()
  const [intensiveCount, setIntensiveCount] = useState<number>()

  // Audio Options
  const { playMP3FromUrl } = useSound()
  const exerciseAudio = useRef<boolean>()
  exerciseAudio.current = exerciseAudio.current !== undefined ? exerciseAudio.current : globalAudio

  const { showCrucialModal, showErrorModal, showIntensiveTrainingModal } = useTrainingOrEvalModals()

  /** to pick a word from another exercise from the same level */
  const getRandomWordsFromOtherExercises = useCallback(
    (n: number): PickOutOneWordBlock[] | undefined => {
      if (!currentExercise || !isPickOutNearest(currentExercise) || !allExercises) {
        return undefined
      }

      // the random word needs to be picked from another rule, another exercise, with the same word type, and the word must be an answer but with no doNotPick flag
      const allPickOutNearestExercisesWithCorrectType = allExercises.filter(
        exerciseWithRuleId =>
          isPickOutNearest(exerciseWithRuleId) &&
          exerciseWithRuleId.wordType === currentExercise.wordType &&
          exerciseWithRuleId.id !== currentExercise.id &&
          exerciseWithRuleId.ruleId !== currentRule?.id &&
          _.some(exerciseWithRuleId.words, word => !!word.isAnswer && !word.doNotPick),
      ) as Array<PickOutNearestExercise & { ruleId: RuleId }> | undefined

      const randomWords: PickOutOneWordBlock[] | undefined =
        allPickOutNearestExercisesWithCorrectType
          ? _(allPickOutNearestExercisesWithCorrectType)
              .sampleSize(n + 1)
              .map(otherExerciseToPickFrom =>
                _(otherExerciseToPickFrom?.words)
                  .filter(word => !!word.isAnswer && !word.doNotPick)
                  .sample(),
              )
              .uniqWith((arrVal, othVal) => arrVal?.text === othVal?.text)
              .compact()
              .take(n)
              .value()
          : undefined

      return randomWords ? randomWords.map(word => ({ ...word, foreign: true })) : undefined
    },
    [currentExercise, allExercises, currentRule?.id],
  )

  const playExerciseAudio = useCallback(
    (rule?: Omit<Rule, "exercises">, exercise?: Exercise) => {
      if (!isConnected || !exerciseAudio.current) {
        return
      }
      const useDictationComponent = isClickOnMistake(exercise) && rule?.isConvertibleToDictation

      if (useDictationComponent || isPickOutNearest(exercise) || isPickOutOne(exercise)) {
        if (isPickOutNearest(exercise) && exercise.hasSound) {
          // noinspection JSIgnoredPromiseFromCall
          playMP3FromUrl(generateAudioExerciseUrl(exercise?.type, rule?.id), () => speak(exercise))
          return
        }
        if (isPickOutOne(exercise) && exercise.exerciseSound) {
          // noinspection JSIgnoredPromiseFromCall
          playMP3FromUrl(
            generateAudioExerciseUrl(exercise?.type, undefined, exercise.exerciseSound),
            () => speak(exercise),
          )
          return
        }
        if (useDictationComponent) {
          // noinspection JSIgnoredPromiseFromCall
          playMP3FromUrl(generateAudioExerciseUrl(exercise?.type, exercise?.id), () =>
            speak(exercise),
          )
          return
        }
        if (isPickOutNearest(exercise)) {
          // for pick out nearest, if no MP3, we still read the word using Expo speech
          speak(exercise)
        }
      }
    },
    [isConnected, playMP3FromUrl],
  )

  /** Intensive Training */
  const resetIntensiveTrainingExercises = (): void => {
    setIntensiveTrainingExercises(undefined)
  }

  const launchIntensiveTraining = useCallback(
    async (engine?: BaseEngine<RuleMetadata>, rule?: Rule, exercise?: Exercise) => {
      if (engine && rule?.id && isClickOnMistake(exercise)) {
        const availableExercises = rule?.intensiveTrainingExercises
        // If availableExercises is undefined, return empty array to avoid re-rendering the intensive modal
        setIntensiveTrainingExercises(_.sampleSize(availableExercises, 3) ?? [])
      }
    },
    [setIntensiveTrainingExercises],
  )

  /**
   ** Uses engine to draw a new rule and exercise, and set all the relevant states in ExerciseProvider
   ** Also triggers intensive training, crucial exercise state
   ** And handles audio for some exercises?…
   */
  const runEngine = useCallback(
    (currentEngine?: BaseEngine<RuleMetadata>) => {
      if (!currentEngine || !props.rules) {
        return
      }
      const ruleAndExercise = currentEngine.drawRuleAndExercise()
      if (_.isEmpty(ruleAndExercise)) {
        // noinspection JSIgnoredPromiseFromCall
        showErrorModal({
          subtitle: t(`${ns.MODAL}.${ModalType.ERROR}.engine.title`),
          // only show an ok button in training
          positive: !isEvaluationEngine(currentEngine),
          // reload the page - the result modal should be displayed automatically
          refresh: !isEvaluationEngine(currentEngine),
        })
        return
      }
      const { rule, exercise } = getCurrentRuleAndExerciseData(
        props.rules,
        ruleAndExercise.ruleId,
        ruleAndExercise.exerciseId,
      )
      const metaData = _.omit(ruleAndExercise, ["ruleId", "exerciseId"])
      setCurrentRule(rule)
      setCurrentExercise(exercise)
      setRuleMetaData(metaData)

      const availableIntensiveTrainingExercises =
        rule?.intensiveTrainingExercises && rule.intensiveTrainingExercises.length >= 3

      // https://www.notion.so/R-vision-intensive-0cad0d765b944e56b95eda19dcbc43c8
      const intensiveTraining =
        // we are in intensive training according to the metadata
        (metaData.intensive &&
          // AND we check the application name
          (scenario == null || ["Orthographe", "Courriel"].includes(scenario?.applicationName)) &&
          // AND we check the type of exercise
          exercise?.type &&
          [
            "click_on_mistake",
            "drag_and_drop",
            "pick_out_one",
            "click_on_word",
            "email_pick_out_one",
            "click_on_email",
          ].includes(exercise.type) &&
          // AND for click on mistake, we display the intensive training only if we have at least 3 available intensive training exercises
          (isClickOnMistake(exercise) ? availableIntensiveTrainingExercises : true)) ??
        false

      setIsIntensiveTraining(intensiveTraining)
      if (intensiveTraining) {
        showIntensiveTrainingModal(async () =>
          launchIntensiveTraining(currentEngine, rule, exercise),
        )
      } else {
        setIntensiveCount(undefined)
      }
      if (metaData.critical) {
        showCrucialModal(() => playExerciseAudio(rule, exercise))
        return
      }
      if (isResumedEval && isSafari) {
        setIsResumedEval(false)
        return
      }
      // auto play for audio
      if ((isPickOutNearest(exercise) || isPickOutOne(exercise)) && exerciseAudio.current) {
        if (!isConnected) {
          return
        }
        if (
          (exercise as PickOutNearestExercise).hasSound ||
          (exercise as PickOutOneExercise).exerciseSound
        ) {
          // noinspection JSIgnoredPromiseFromCall
          playMP3FromUrl(
            generateAudioExerciseUrl(exercise?.type, rule?.id, exercise?.exerciseSound),
            () => speak(exercise),
          )
          return
        } else if (isPickOutNearest(exercise)) {
          // for pick out nearest, if no MP3, we still read the word using Expo speech
          speak(exercise)
        }
      }
    },
    [
      props.rules,
      scenario,
      isResumedEval,
      showErrorModal,
      t,
      showIntensiveTrainingModal,
      launchIntensiveTraining,
      showCrucialModal,
      playExerciseAudio,
      setIsResumedEval,
      isConnected,
      playMP3FromUrl,
    ],
  )

  const toggleLevelAudio = useCallback(
    async (currentEngine?: BaseEngine<RuleMetadata>): Promise<void> => {
      await showModal({
        type: ModalType.TEXT_TO_SPEECH,
        dismissable: true,
        headerImage: () => (
          <VolumeSVG color={iconColor} height={iconSize.LARGE} width={iconSize.LARGE} />
        ),
        positive: {
          label: exerciseAudio.current
            ? t("common.button.deactivate")
            : t("common.button.activate"),
          onPress: async () => {
            exerciseAudio.current = !exerciseAudio.current
            runEngine(currentEngine)
          },
        },
        negative: {
          label: t("common.button.cancel"),
        },
      })
    },
    [iconColor, runEngine, showModal, t],
  )

  return {
    currentExercise: {
      rule: currentRule,
      exercise: currentExercise,
      ruleMetaData,
      setCurrentExercise,
      setCurrentRule,
      getRandomWordsFromOtherExercises,
      isClueVisible,
      setIsClueVisible,
      isIntensiveTraining,
      setIsIntensiveTraining,
      intensiveTrainingExercises,
      resetIntensiveTrainingExercises,
      intensiveCount,
    },
    levelState: {
      progress,
      nbOfAcquiredRules,
      setProgress,
      setNbOfAcquiredRules,
    },
    audio: {
      exerciseAudio,
      playExerciseAudio,
      toggleLevelAudio,
    },
    emailPickOutOneState: {
      setSelectedOption,
      emailPickOutOneOptions,
      selectedOption,
    },
    engine: {
      runEngine,
    },
  }
}

export default useExerciseStates
