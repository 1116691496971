import { isWeb } from "constants/constants"
import _ from "lodash"
import type { ViewStyle } from "react-native"

import type { Range } from "./Range"

// See https://github.com/material-components/material-components-web/blob/master/packages/mdc-elevation/_elevation-theme.scss
const depths = [
  [1, 1],
  [2, 2],
  [3, 4],
  [4, 5],
  [5, 8],
  [6, 10],
  [7, 10],
  [8, 10],
  [9, 12],
  [10, 14],
  [11, 15],
  [12, 17],
  [13, 19],
  [14, 21],
  [15, 22],
  [16, 24],
  [17, 26],
  [18, 28],
  [19, 29],
  [20, 31],
  [21, 33],
  [22, 35],
  [23, 36],
  [24, 38],
]

const DEFAULT_ELEVATION = 1

const interpolate = (i: number, a: number, b: number, a2: number, b2: number): number =>
  ((i - a) * (b2 - a2)) / (b - a) + a2

const parseShadow = (raw: number[]): { y: number; blur: number } => ({
  y: raw[0],
  blur: raw[1],
})

const generateShadow = (elevation: Range<1, 25> = DEFAULT_ELEVATION): ViewStyle => {
  const s = parseShadow(depths[elevation - 1])
  const y = s.y === 1 ? 1 : Math.floor(s.y * 0.5)

  return {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: y,
    },
    shadowRadius: isWeb ? s.blur : _.round(interpolate(s.blur, 1, 38, 1, 16), 2),
    shadowOpacity: _.round(interpolate(elevation - 1, 1, 24, 0.2, 0.6), 2),
    elevation,
  }
}

export default generateShadow
