import type {
  ApiError,
  EvaluationContent,
  EvaluationType,
  ExaminationId,
  ExerciseWithRuleId,
  LevelDetails,
  LevelPracticeTest,
  MilliSeconds,
  Rule,
  Seconds,
} from "@newpv/js-common"
import { maxTime, reducedTimeLimit } from "constants/constants"
import dayjs from "dayjs"
import { useFetchLevel } from "hooks/useFetchLevelInfo"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { getExercisesWithRuleId } from "models/ModuleFunctions"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { usePreferences } from "providers/PreferenceProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useStopwatch } from "react-timer-hook"
import { getMs } from "utils/timeConversion"

import useAuthContext from "./AuthProvider"

export interface ContextData {
  // Timer states and methods
  stop: () => void
  start: () => void
  pause: () => void
  /** in milliseconds - elapsed time from timer */
  timerElapsedTime: MilliSeconds
  isTimerRunning: boolean
  timeIsUp: boolean
  evalExercises: ExerciseWithRuleId[] | undefined
  evalRules: Rule[] | undefined
  evaluationType?: EvaluationType
  nbOfQuestions?: number
  /** in seconds */
  timeLimit: number
  isLoading: boolean
  error: ApiError | null
  title?: string
  updateRemainingTime: (
    /** Timestamp, in milliseconds */
    startDate: MilliSeconds,
    /** In seconds */
    evalTimeLimit: Seconds,
    /** In seconds */
    elapsedTime: Seconds,
  ) => number | undefined
  isTickleOn?: boolean
  setIsTickleOn: Dispatch<SetStateAction<boolean | undefined>>
}

const evaluationContext = createContext<ContextData>({} as ContextData)

interface IProps {
  examinationId?: ExaminationId
  evaluationType: EvaluationType
}

const EvaluationProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  evaluationType: propsEvaluationType,
}) => {
  const t = useTypedTranslation()

  const { start, reset, pause, seconds, minutes, isRunning } = useStopwatch({
    autoStart: false,
    offsetTimestamp: new Date(),
  })

  // _ OTHER CONTEXTS _______________________________________________________________________________
  const { extraTimeFactor } = useAuthContext()
  const { levelId } = useLevelAndEvaluation()
  const {
    preferenceState: { reduceTimeLimit },
  } = usePreferences()
  const { scenario, module } = useScenarioAndModule()

  // _ DATA FETCHING _______________________________________________________________________________
  const { data: evaluation, isLoading, error } = useFetchLevel<LevelDetails>(levelId)

  // _ STATES AND REFS _______________________________________________________________________________
  const [evalRules, setEvalRules] = useState<Rule[]>()
  const [evalExercises, setEvalExercises] = useState<ExerciseWithRuleId[]>()
  const [isTickleOn, setIsTickleOn] = useState<boolean>()

  // _ useEffects _______________________________________________________________________________
  useEffect(() => {
    ;(async (): Promise<void> => {
      if (evaluation) {
        setEvalExercises(getExercisesWithRuleId(evaluation.rules))
        setEvalRules(evaluation.rules)
      }
    })()
  }, [evaluation])

  // _ CONSTS (useMemo) AND METHODS (useCallback) _______________________________________________________________________________
  const evaluationType = useMemo<EvaluationType | undefined>(
    () => (levelId != null ? propsEvaluationType : undefined),
    [levelId, propsEvaluationType],
  )

  const timeLimit = useMemo(
    () =>
      // in seconds
      reduceTimeLimit
        ? reducedTimeLimit
        : evaluationType === "initial_evaluation"
        ? (scenario?.initialEvaluation?.timeLimit ?? 0) * extraTimeFactor
        : evaluationType === "practice_test"
        ? ((module?.levels.find(l => l.id === levelId) as LevelPracticeTest)?.timeLimit ?? 0) *
          extraTimeFactor
        : // next evaluation
          ((scenario?.evaluations?.find(l => l.id === levelId) as EvaluationContent)?.timeLimit ??
            0) * extraTimeFactor,
    [
      reduceTimeLimit,
      evaluationType,
      scenario?.initialEvaluation?.timeLimit,
      scenario?.evaluations,
      extraTimeFactor,
      module?.levels,
      levelId,
    ],
  )

  // extra time already taken into account here
  const timeIsUp = getMs(minutes, seconds) > (timeLimit ?? maxTime) * 1000

  const updateRemainingTime = useCallback(
    (startDate: MilliSeconds, evalTimeLimit: Seconds, elapsedTime: Seconds) => {
      // current date in milliseconds
      const currentDate = dayjs().valueOf()

      // convert to milliseconds
      const timerRemainingTime = dayjs((evalTimeLimit - elapsedTime) * 1000).valueOf()

      // remainingTime in milliseconds
      const remainingTime = startDate
        ? evaluationType !== "next_evaluation"
          ? // no time limit ("heure de grâce" + duration) for initial evaluation and practice test
            timerRemainingTime
          : Math.min(
              // normal remaining time
              timerRemainingTime,
              // duration until it times out - this can be negative, but it should not be for because we don't resume the exam then
              dayjs(startDate + evalTimeLimit * 1000)
                .add(1, "hour")
                .valueOf() - currentDate,
            )
        : undefined

      if (remainingTime) {
        reset(new Date(currentDate + (evalTimeLimit * 1000 - remainingTime)), false)
      }
      return remainingTime
    },
    [evaluationType, reset],
  )
  const stop = useCallback(() => {
    if (isRunning) {
      reset()
      pause()
    }
  }, [pause, reset, isRunning])

  return (
    <evaluationContext.Provider
      value={{
        start,
        stop,
        pause,
        isTimerRunning: isRunning,
        evalRules,
        evalExercises,
        timerElapsedTime: getMs(minutes, seconds),
        timeIsUp,
        evaluationType,
        nbOfQuestions: (evaluation?.rules.length ?? 0) * 2,
        timeLimit: timeLimit ?? maxTime,
        error,
        isLoading,
        title: evaluation?.title ?? t("Evaluation.defaultTitle"),
        updateRemainingTime,
        isTickleOn,
        setIsTickleOn,
      }}
    >
      {children}
    </evaluationContext.Provider>
  )
}

export const useEvaluation = (): ContextData => {
  const context = useContext(evaluationContext)
  if (_.isEmpty(context)) {
    // this hook can be used with an empty evaluationProvider
    // throw new Error("useEvaluation must be used within a EvaluationProvider")
  }
  return context
}

export default EvaluationProvider
