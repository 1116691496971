import type { FC } from "react"
import useTheme from "theme/ThemeProvider"

import type { IBodyProps } from "./common"
import { Text } from "./common"

export const Body2: FC<IBodyProps> = (props): JSX.Element => {
  const theme = useTheme()

  return <Text {...props} type={theme.typography.body2} theme={theme} />
}
