import type { ApiError, LevelId, Rule, RuleId } from "@newpv/js-common"
import { fetchLevel, parseInt10 } from "@newpv/js-common"
import type { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import ErrorPanel from "components/ErrorPanel/ErrorPanel"
import useCommonStyles from "hooks/useCommonStyles"
import _ from "lodash"
import type { IRootParamList } from "navigation/RootNavigator"
import PresentialProvider from "providers/PresentialProvider"
import type { FC } from "react"
import { useEffect, useRef, useState } from "react"
import { ExerciseScreen } from "screens/ExerciseScreen/ExerciseScreen"
import { logger } from "utils/logger"

type RulesByLevels = Record<LevelId, RuleId[]>

const PresentialScreen: FC<NativeStackScreenProps<IRootParamList, "Presential">> = ({ route }) => {
  const cs = useCommonStyles()
  const { rules } = route.params
  const fetchOnceRef = useRef(false)
  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState(true)
  const [presentialRules, setPresentialRules] = useState<Rule[]>()

  useEffect(() => {
    ;(async () => {
      if (rules && !fetchOnceRef.current) {
        fetchOnceRef.current = true
        try {
          const rulesByLevels: RulesByLevels = JSON.parse(decodeURI(rules))
          const ruleIds = new Set(_.values(rulesByLevels).flat())
          const levels = await Promise.all(
            Object.keys(rulesByLevels).map(levelId => fetchLevel(undefined, parseInt10(levelId))),
          )
          setPresentialRules(_.flatMap(levels, l => l.rules.filter(r => ruleIds.has(r.id))))
        } catch (e) {
          logger("Presential error", e)
          setError(e as ApiError)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [rules])

  return loading ? (
    <ActivityIndicator style={cs.paddingTop} />
  ) : error ? (
    <ErrorPanel title={error.message} />
  ) : presentialRules ? (
    <PresentialProvider rules={presentialRules}>
      <ExerciseScreen isPresential={true} />
    </PresentialProvider>
  ) : null
}

export default PresentialScreen
