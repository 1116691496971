import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg"

const Level2 = (props?: SvgProps): JSX.Element => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <G clipPath="url(#clip0_4222_239990)">
      <Rect width={56} height={56} rx={8} fill={props?.color ?? "#D5E9A4"} />
      <Path
        d="M55.555 60.06c-1.494-8.122-5.707-15.506-11.075-21.593-5.295-6.16-11.813-11.38-18.912-15.454-3.55-1.965-7.244-3.786-11.16-4.82-1.922-.482-3.917-.892-5.846-.66-.929.08-1.717.445-2.223 1.092-.505.647-.727 1.434-.522 2.36.413 1.71 1.755 3.195 3.315 4.323 1.56 1.127 3.408 1.824 5.332 2.163 1.924.338 3.923.32 5.783-.126 1.86-.446 3.652-1.39 5.163-2.618a19.587 19.587 0 003.966-4.39c1.086-1.652 1.888-3.444 2.19-5.302.304-1.859.036-3.712-.733-5.489-.698-1.706-1.896-3.337-3.167-4.752-2.614-2.902-5.947-5.226-9.353-7.336-3.478-2.037-7.1-3.93-10.796-5.608-7.391-3.357-15.072-6.283-22.898-8.922-3.913-1.32-7.756-2.568-11.742-3.673-3.914-1.177-7.9-2.281-11.887-3.386l-.142-.141.144-.145c4.134.676 8.123 1.495 12.183 2.385 3.987.962 8.046 1.994 12.032 3.099 3.987 1.105 7.9 2.424 11.813 3.744 3.913 1.32 7.825 2.781 11.591 4.53 3.767 1.75 7.533 3.499 11.08 5.75 3.548 2.25 7.022 4.716 9.847 7.972 1.413 1.557 2.68 3.401 3.516 5.534.837 2.134 1.101 4.415.794 6.702-.377 2.216-1.397 4.367-2.627 6.162-1.23 1.796-2.744 3.452-4.541 4.825-1.797 1.372-3.805 2.39-5.95 2.839-2.218.52-4.43.47-6.567.061a14.537 14.537 0 01-5.9-2.585c-.922-.634-1.7-1.412-2.405-2.262-.706-.85-1.268-1.844-1.47-3.055-.138-.57-.06-1.213.017-1.856.148-.572.51-1.218.872-1.72.866-1.008 2.155-1.448 3.227-1.673 2.287-.306 4.353.174 6.417.797 4.057 1.175 7.822 3.067 11.37 5.175 7.098 4.216 13.542 9.651 18.836 15.954 5.294 6.303 9.435 13.758 10.786 22.024.07.071-.002.143-.144.144-.143.002-.214-.069-.214-.069z"
        fill={props?.color ?? "#71AD00"}
      />
      <Path
        d="M3.127 52.837c2.506-4.003 5.903-7.364 10.408-9.846 2.362-1.124 5.015-2.115 8.195-2.495a22.266 22.266 0 015.542.103c2.108.3 4.688 1.017 7.706 2.738 3.017 1.721 5.78 4.33 7.434 6.597 1.654 2.266 2.436 4.132 3.091 5.906 1.146 3.506 1.038 6.336.549 8.89-.617 2.461-1.652 4.697-3.16 6.515-1.38 1.91-3.27 3.453-5.413 4.81-.036.051-.163-.04-.29-.133-.128-.092-.092-.142-.092-.142 2.506-4.004 4.722-8.141 5.465-13.19.145-1.272.29-2.545.308-3.91a14.787 14.787 0 00-.566-4.31c-.436-1.54-1.036-3.12-2.29-4.87-1.128-1.655-2.946-3.428-5-4.606-2.017-1.229-4.234-1.913-6.142-2.22a22.366 22.366 0 00-5.27-.213c-3.234.188-5.978 1.036-8.43 2.018-4.943 2.014-8.72 5.099-11.7 8.684-.036.05-.163-.041-.163-.041l-.182-.285zM38.031-4.84c1.686 3.171 3.147 6.394 4.767 9.486 1.54 3.157 3.16 6.249 5.005 9.289 1.778 2.96 4.1 5.607 6.21 8.16 2.254 2.566 4.748 4.935 7.386 7.32.066.079.066.079.053.224-.08.066-.08.066-.146-.014-3.407-1.211-6.522-3.201-9.227-5.665-2.625-2.529-4.522-5.794-6.208-8.966-1.685-3.171-3.067-6.46-4.37-9.814L37.808-4.79c-.066-.08.013-.145.092-.211-.079.066.066.08.132.16z"
        fill={props?.color ?? "#71AD00"}
      />
    </G>
    <Rect
      x={0.5}
      y={0.5}
      width={55}
      height={55}
      rx={7.5}
      stroke={props?.color ?? "#050628"}
      strokeOpacity={0.16}
    />
    <Defs>
      <ClipPath id="clip0_4222_239990">
        <Rect width={56} height={56} rx={8} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Level2
