import { isWeb } from "constants/constants"
import type { FC, PropsWithChildren } from "react"
import { View } from "react-native"

// had to add a ts-ignore and a condition on isWeb, as the "list" accessibility role does not exist on android/iOS (at least in RN v.0.64)

export const AccessibilityListWrapper: FC<PropsWithChildren> = ({ children }) =>
  isWeb ? (
    // @ts-ignore
    <View accessibilityRole="list">{children}</View>
  ) : (
    <>{children}</>
  )

export default AccessibilityListWrapper
