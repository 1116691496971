import { DEFAULT_PROGRESSION_DURATION } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import _ from "lodash"
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { Animated, Easing, useWindowDimensions, View } from "react-native"

interface IProps {
  height?: number
  paused: boolean
  progress?: number // Percentage
  viewWidth?: number
  trackColor?: string
  backgroundColor?: string
  progressAnimationDuration?: number // ms
  onCompletion?: () => void
  progressStyle?: StyleProp<ViewStyle>
}

export interface ProgressBarHandle {
  reset: () => void
}

const ProgressBar = forwardRef<ProgressBarHandle, IProps>(
  (
    {
      paused,
      height = 2,
      trackColor,
      progress = 0,
      progressStyle,
      backgroundColor,
      viewWidth = undefined,
      progressAnimationDuration = DEFAULT_PROGRESSION_DURATION,
    },
    ref,
  ) => {
    const { width } = useWindowDimensions()
    const progressWidth = viewWidth ?? width
    const scale = useMemo(() => new Animated.Value(0), [])
    const calculatedVal = useMemo(
      () => progressWidth * (_.round(progress, 2) / 100),
      [progress, progressWidth],
    )
    const animation = useMemo(
      () =>
        Animated.timing(scale, {
          duration: progressAnimationDuration,
          toValue: calculatedVal > progressWidth ? progressWidth : calculatedVal,
          useNativeDriver: true,
          easing: Easing.linear,
        }),
      [calculatedVal, progressAnimationDuration, progressWidth, scale],
    )

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          scale.setValue(0)
          animation.reset()
        },
      }),
      [animation, scale],
    )

    useEffect(() => {
      scale.setValue(calculatedVal)
      // The following dependencies are voluntarily filled with width only
      // eslint-disable-next-line
    }, [progressWidth])

    useEffect(() => {
      if (paused) {
        scale.stopAnimation()
      } else {
        animation.start()
      }
    }, [animation, paused, scale])

    const { container, progressBar } = useStyles(
      ({
        colors: {
          onPrimary: { highEmphasis },
          onSurface: { disabled },
        },
      }) => ({
        container: {
          backgroundColor: trackColor ?? disabled,
          height,
          overflow: "hidden" as const,
          width: "100%" as const,
        },
        progressBar: {
          backgroundColor: backgroundColor ?? highEmphasis,
          flex: 1,
          transform: [{ translateX: scale }],
          width: "100%" as const,
        },
      }),
      [height, trackColor, scale, backgroundColor],
    )

    return (
      <View style={[container, progressStyle]}>
        <Animated.View style={progressBar} />
      </View>
    )
  },
)

export default ProgressBar
