import type { ApiError, ProgressionData, ScenarioId } from "@newpv/js-common"
import { axios, throwIfApiError } from "@newpv/js-common"
import { bffUrl } from "constants/constants"
import useAuthContext from "providers/AuthProvider"
import type { UseQueryResult } from "react-query"
import { useQuery } from "react-query"

const fetchProgressionDetailData = async (
  signal: AbortSignal | undefined,
  scenarioId?: ScenarioId | null,
  auth?: string,
): Promise<ProgressionData> =>
  throwIfApiError(
    await axios.get(`${bffUrl}/me/scenarios/${scenarioId}/progressionDetail`, {
      signal,
      headers: {
        Authorization: auth ?? "",
      },
    }),
  )

export const progressionKey = (
  token: string | null,
  scenarioId: ScenarioId,
): Array<string | number> => [`authToken:${token}`, "progressionDetail", scenarioId]

// If your query function depends on a variable, include it in your query key (the first parameter)
export const useFetchUserProgressionDetail = (
  scenarioId?: ScenarioId | null,
): UseQueryResult<ProgressionData, ApiError> => {
  const { hasAuth, token } = useAuthContext()
  return useQuery<ProgressionData, ApiError>(
    progressionKey(token, scenarioId ?? 0),
    ({ signal }) => fetchProgressionDetailData(signal, scenarioId, `Bearer ${token}`),
    { enabled: scenarioId != null && hasAuth },
  )
}
