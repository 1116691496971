import { Caption } from "components/Texts"
import { iconSize } from "constants/constants"
import dayjs from "dayjs"
import { useStyles } from "hooks/useStyles"
import type { SVGComponent } from "models/SVGInterface"
import type { FC } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { StyleProp, TextStyle, ViewStyle } from "react-native"
import { View } from "react-native"
import { AnimatedCircularProgress } from "react-native-circular-progress"
import CheckLevelSVG from "svgs/CheckLevel"
import ClockSVG from "svgs/Clock"
import LockSVG from "svgs/Lock"
import StarFillSVG from "svgs/StarFill"
import useTheme from "theme/ThemeProvider"

export interface ProgressItem {
  key: string
  percent: number
  locked?: boolean // Temporary solution
  disabled?: boolean
  isRevisionLevel?: boolean
  revisionOpenDate?: number
  autoValidateRevisionLevel?: boolean
}

interface IProps {
  size?: number
  item: ProgressItem
  borderWidth?: number
  circleBackgroundColor?: string
  itemStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
}

export function isProgressionItem(
  item: undefined | ProgressItem | SVGComponent | string,
): item is ProgressItem {
  return (item as ProgressItem)?.percent !== undefined
}

const iconProps = {
  height: iconSize.MEDIUM,
  width: iconSize.MEDIUM,
}

/** https://medium.com/@0saurabhgour/react-native-percentage-based-progress-circle-no-external-library-e25b43e83888 */
const ProgressionItem: FC<IProps> = ({
  item: {
    percent,
    disabled,
    locked,
    isRevisionLevel,
    revisionOpenDate = 0,
    autoValidateRevisionLevel = false,
  },
  size = iconSize.MEDIUM,
  itemStyle,
  borderWidth = 2,
  circleBackgroundColor,
}) => {
  const { t } = useTranslation()
  const {
    colors: {
      primary_400: themePrimary,
      secondary_200: themeSecondary,
      surface,
      onSurface: { disabledIcon: themeDisabled },
    },
  } = useTheme()
  const localPercent = percent > 100 ? 100 : percent < 0 ? 0 : percent
  const isCompleted = localPercent === 100

  const needCooldown = dayjs().isBefore(dayjs(revisionOpenDate))

  const s = useStyles(
    ({ dimensions: { spacing }, colors: { onSurface } }) => ({
      clockIcon: {
        paddingBottom: spacing / 4,
      },
      cooldownText: {
        color: onSurface.disabled,
        marginTop: spacing / 4,
        overflow: "visible",
        textAlign: "center",
      },
      item: {
        alignItems: "center",
        paddingHorizontal: spacing / 2,
        paddingVertical: spacing,
        width: 62,
      },
    }),
    [circleBackgroundColor, disabled, locked, localPercent, isCompleted, borderWidth, size],
  )

  const cooldownTimer = useMemo(() => {
    if (needCooldown) {
      const endCooldownDate = dayjs(revisionOpenDate)
      const totalMinutes = endCooldownDate.diff(dayjs(), "minutes", true)

      const hours = totalMinutes / 60
      const rhours = Math.floor(hours)

      const minutes = (hours - rhours) * 60
      const rminutes = Math.floor(minutes)

      if (hours > 0) {
        return `${rhours}h ${rminutes}m`
      } else {
        return t("common.duration", { count: rminutes })
      }
    }
    return undefined
  }, [needCooldown, revisionOpenDate, t])

  const icon = useMemo(() => {
    if (locked) {
      return <LockSVG color={locked ? themeDisabled : themePrimary} />
    }
    if (isRevisionLevel && autoValidateRevisionLevel) {
      return <StarFillSVG color={themePrimary} {...iconProps} />
    }
    if (isRevisionLevel && revisionOpenDate && needCooldown) {
      return <ClockSVG color={themeDisabled} style={s.clockIcon} {...iconProps} />
    }
    return isCompleted ? (
      <CheckLevelSVG color={themePrimary} />
    ) : (
      <AnimatedCircularProgress
        backgroundColor={surface.overlayProgression}
        fill={localPercent}
        duration={2000}
        rotation={0}
        size={iconSize.MEDIUM}
        tintColor={isCompleted ? themePrimary : themeSecondary}
        tintTransparency={true}
        width={5}
      />
    )
  }, [
    autoValidateRevisionLevel,
    isCompleted,
    isRevisionLevel,
    localPercent,
    locked,
    needCooldown,
    revisionOpenDate,
    s.clockIcon,
    surface.overlayProgression,
    themeDisabled,
    themePrimary,
    themeSecondary,
  ])

  return (
    <View style={[s.item, itemStyle]}>
      {icon}
      {cooldownTimer && isRevisionLevel && !isCompleted ? (
        <Caption style={s.cooldownText}>{cooldownTimer}</Caption>
      ) : null}
    </View>
  )
}

export default ProgressionItem
