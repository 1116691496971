import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg"

const Level3 = (props?: SvgProps): JSX.Element => (
  <Svg width={56} height={56} viewBox="0 0 56 56" fill="none" {...props}>
    <G clipPath="url(#clip0_4222_239991)">
      <Rect width={56} height={56} rx={8} fill={props?.color ?? "#B4D56E"} />
      <Path
        d="M16.938 85.988c5.244-6.38 8.17-14.36 9.363-22.389 1.293-8.02 1.08-16.367-.383-24.42-.788-3.98-1.778-7.978-3.513-11.638-.871-1.78-1.844-3.569-3.266-4.894-.66-.658-1.447-1.025-2.267-.995-.82.031-1.565.37-2.14 1.123-1.04 1.418-1.311 3.402-1.167 5.32.144 1.92.804 3.782 1.785 5.471.982 1.69 2.285 3.205 3.825 4.339 1.54 1.134 3.418 1.896 5.33 2.258 1.912.363 3.95.436 5.913.2 1.963-.234 3.85-.777 5.465-1.744 1.616-.966 2.86-2.366 3.722-4.1.855-1.633 1.328-3.6 1.59-5.485.533-3.869.16-7.915-.424-11.878a117.89 117.89 0 00-2.674-11.869c-2.2-7.814-4.917-15.57-7.947-23.254-1.514-3.842-3.038-7.584-4.764-11.344-1.625-3.75-3.35-7.51-5.077-11.269l.017-.2c.101.01.101.01.203.018 2.149 3.595 4.095 7.172 6.033 10.849a371.14 371.14 0 015.39 11.195c1.726 3.76 3.241 7.601 4.756 11.443C38.223-3.433 39.628.5 40.719 4.506c1.092 4.007 2.184 8.013 2.751 12.176.567 4.163.923 8.408.255 12.667-.279 2.084-.87 4.241-1.962 6.256a13.377 13.377 0 01-4.61 4.929c-1.938 1.14-4.239 1.748-6.404 1.965-2.166.218-4.408.128-6.616-.36-2.208-.488-4.281-1.367-6.007-2.717-1.828-1.36-3.215-3.083-4.282-4.98a14.536 14.536 0 01-1.828-6.176c-.11-1.114-.017-2.21.178-3.297.194-1.088.592-2.158 1.387-3.094.347-.472.888-.828 1.43-1.183.533-.256 1.26-.395 1.878-.443 1.328.012 2.495.713 3.358 1.389 1.71 1.55 2.674 3.438 3.529 5.417 1.718 3.859 2.7 7.957 3.377 12.028 1.354 8.144 1.355 16.574-.049 24.685-1.403 8.11-4.431 16.083-9.879 22.445-.008.1-.11.091-.203-.017-.093-.108-.084-.208-.084-.208z"
        fill={props?.color ?? "#F0FEB4"}
      />
      <Path
        d="M28.303-12.893c1.696 3.062 2.541 6.514 2.275 10.424-.264 1.99-.753 4.133-1.905 6.429a19.22 19.22 0 01-2.548 3.742c-1.151 1.335-2.808 2.851-5.365 4.325-2.558 1.474-5.625 2.475-7.958 2.836-2.332.36-3.993.262-5.533.108-2.977-.405-4.918-1.436-6.494-2.633-1.456-1.253-2.566-2.715-3.17-4.357-.727-1.587-.968-3.396-.966-5.317-.019-.042.103-.097.224-.153.122-.055.14-.014.14-.014C-1.3 5.56.62 8.47 3.838 10.683c.83.53 1.66 1.059 2.611 1.532.952.474 2.005.85 3.283 1.074 1.277.223 2.657.349 4.447.085 1.667-.208 3.725-.847 5.47-1.848 1.765-.96 3.236-2.24 4.305-3.435a19.458 19.458 0 002.502-3.52C27.767 2.303 28.396.147 28.8-1.857c.79-4.05.31-7.668-.882-10.91-.019-.043.103-.098.103-.098l.28-.028zM58.039 39.91c-2.039 1.338-4.23 2.479-6.229 3.763-2.095 1.212-4.093 2.496-5.939 3.977-1.805 1.427-3.07 3.338-4.353 5.069-1.225 1.856-2.161 3.927-3.04 6.124-.04.054-.04.054-.177.037-.097-.072-.097-.072-.057-.126-.537-2.91-.237-5.537.682-7.788 1.016-2.18 3.038-3.697 5.076-5.035 2.04-1.338 4.327-2.407 6.712-3.405l7.172-2.814c.04-.054.136.018.233.09-.096-.072-.04.054-.08.108z"
        fill={props?.color ?? "#F0FEB4"}
      />
    </G>
    <Rect
      x={0.5}
      y={0.5}
      width={55}
      height={55}
      rx={7.5}
      stroke={props?.color ?? "#050628"}
      strokeOpacity={0.16}
    />
    <Defs>
      <ClipPath id="clip0_4222_239991">
        <Rect width={56} height={56} rx={8} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Level3
