import CustomRenderHTML from "components/CustomRenderHTML"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import type { SVGComponent } from "models/SVGInterface"
import { isSVGComponent } from "models/SVGInterface"
import type { FC } from "react"
import { useMemo } from "react"
import type { ImageSourcePropType } from "react-native"
import { Image, useWindowDimensions, View } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"

import { Subtitle1 } from "../Texts"

interface IProps {
  title: string
  instruction?: string
  icon: SVGComponent | ImageSourcePropType
  isBottomSheetOpen: boolean
}

const InstructionView: FC<IProps> = ({ instruction, title, icon, isBottomSheetOpen }) => {
  const {
    darkMode,
    dimensions: { spacing: themeSpacing },
    colors: { surface: themeSurface },
  } = useTheme()
  const { isSmallWidth } = useDeviceTools()
  const { onLayout, width: layoutWidth } = useLayout()
  const { width } = useWindowDimensions()

  const s = useStyles(
    ({ roundness, dimensions: { spacing }, colors: { surface, onSurface } }) => ({
      icon: {
        height: 70,
        width: 70,
      },
      instructionContainer: {
        backgroundColor:
          !isSmallWidth && darkMode ? surface.surfaceSecondary : themeSurface.background,
        flex: 1,
        gap: spacing / 2,
      },
      ruleContainer: {
        alignItems: "center",
        borderRadius: roundness,
        backgroundColor: darkMode
          ? isSmallWidth
            ? themeSurface.background
            : themeSurface.surfaceSecondary
          : themeSurface.background,
        flexDirection: "row",
        gap: spacing * 1.5,
        padding: spacing,
        marginTop: isSmallWidth ? spacing * 2 : 0,
      },
      ruleTitle: {
        color: onSurface.highEmphasis,
      },
    }),
    [instruction, isBottomSheetOpen, darkMode, isSmallWidth],
  )

  const customStyle = useMemo<MixedStyleDeclaration>(() => ({ textAlign: "left" }), [])

  return (
    <>
      <View style={s.ruleContainer} {...{ onLayout }}>
        {isSVGComponent(icon) ? icon(s.icon) : <Image source={icon} />}
        <View style={s.instructionContainer}>
          <Subtitle1 style={s.ruleTitle}>{title}</Subtitle1>
          {instruction ? (
            <CustomRenderHTML
              content={`<i>${instruction}</i>`}
              typographyVariant="body2"
              width={isSmallWidth ? layoutWidth : width - themeSpacing}
              // do NOT use a style from a StyleSheet here
              {...{ customStyle }}
            />
          ) : null}
        </View>
      </View>
    </>
  )
}

export default InstructionView
