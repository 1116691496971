import type { FC } from "react"
import useTheme from "theme/ThemeProvider"

import type { IBodyProps } from "./common"
import { Text } from "./common"

export const H4: FC<IBodyProps> = (props): JSX.Element => {
  const theme = useTheme()

  // noinspection RequiredAttributes
  return <Text {...props} type={theme.typography.h4} theme={theme} />
}
