/* eslint-disable no-irregular-whitespace */
import type { Definition } from "@newpv/js-common"
import { Body2 } from "components/Texts"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { View } from "react-native"

interface Props {
  definitions: Definition[]
}

const DefinitionList = ({ definitions }: Props): JSX.Element => {
  const t = useTypedTranslation()
  const s = useStyles(
    ({ dimensions: { margin }, fontMaker }) => ({
      example: {
        marginLeft: margin / 2,
      },
      italic: {
        fontFamily: fontMaker({ style: "italic" }).fontFamily,
      },
      listContainer: {
        marginVertical: margin / 2,
      },
      source: {
        marginTop: margin,
        textAlign: "center",
        width: "100%" as const,
      },
    }),
    [],
  )

  return (
    <>
      {definitions.map((item, index) => {
        const definitionNumber = index + 1
        return (
          <View key={index} style={s.listContainer}>
            <Body2 selectable={false}>{`${definitionNumber}. ${item.definition}`}</Body2>
            {item.example ? (
              <Body2 selectable={false} style={[s.example, s.italic]}>{`• ${item.example}`}</Body2>
            ) : null}
          </View>
        )
      })}
      <Body2 selectable={false} style={[s.source, s.italic]}>
        {t("bottomSheet.definitionSource")}
      </Body2>
    </>
  )
}

export default DefinitionList
