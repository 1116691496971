import { isWeb } from "constants/constants"
import type { FC, PropsWithChildren } from "react"
import { View } from "react-native"

// had to add a ts-ignore and a condition on isWeb, as the "listItem" accessibility role does not exist on android/iOS (at least in RN v.0.64)
// and the dataSet prop is not officially documented in RN

interface Props {
  accessibilityLabel: string
  dataSet: Record<string, string>
}

export const AccessibilityListItemWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  accessibilityLabel,
  dataSet,
}) =>
  isWeb ? (
    <View
      // @ts-ignore
      accessibilityRole={isWeb ? "listitem" : undefined}
      {...{ accessibilityLabel, dataSet }}
    >
      {children}
    </View>
  ) : (
    <View {...{ accessibilityLabel }}>{children}</View>
  )

export default AccessibilityListItemWrapper
