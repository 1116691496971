import type { ReactElement } from "react"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const PickOutOneIcon = (props?: SvgProps): ReactElement => {
  const {
    colors: {
      secondary_400,
      surface: { surface },
    },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 106} height={props?.height ?? 106} fill="none" {...props}>
      <Path
        fill={surface}
        d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35Z"
      />
      <Path
        stroke={secondary_400}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M31.354 18.9H17.68c-1.26 0-2.28 1.27-2.28 2.836v9.076c0 1.567 1.02 2.836 2.28 2.836h13.674c1.258 0 2.279-1.27 2.279-2.836v-9.076c0-1.566-1.02-2.836-2.28-2.836ZM52.321 18.9H38.647c-1.259 0-2.28 1.27-2.28 2.836v9.076c0 1.567 1.021 2.836 2.28 2.836H52.32c1.259 0 2.28-1.27 2.28-2.836v-9.076c0-1.566-1.021-2.836-2.28-2.836ZM31.354 37.052H17.68c-1.26 0-2.28 1.27-2.28 2.836v9.076c0 1.566 1.02 2.836 2.28 2.836h13.674c1.258 0 2.279-1.27 2.279-2.836v-9.076c0-1.566-1.02-2.836-2.28-2.836ZM52.321 37.052H38.647c-1.259 0-2.28 1.27-2.28 2.836v9.076c0 1.566 1.021 2.836 2.28 2.836H52.32c1.259 0 2.28-1.27 2.28-2.836v-9.076c0-1.566-1.021-2.836-2.28-2.836Z"
      />
    </Svg>
  )
}

export default PickOutOneIcon
