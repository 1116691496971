/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Theme as RNTheme } from "@react-navigation/native"
import { DarkTheme as RNDarkTheme, DefaultTheme as NavigationTheme } from "@react-navigation/native"
import _ from "lodash"
import { usePreferences } from "providers/PreferenceProvider"
import type { ReactNode } from "react"
import { createContext, useContext } from "react"
import { MD2LightTheme, Provider } from "react-native-paper"
import type { MD2Theme as PaperTheme } from "react-native-paper/lib/typescript/types"

import { colors, darkOverride } from "./colors"
import fontMaker from "./fontMaker"
import theme from "./theme"
import type {
  FmFunc,
  IColorTheme,
  ICSTheme,
  IFontTheme,
  PartialPartial,
  PartialPartialPartial,
} from "./types"
import { defaultStyles, defaultWeights } from "./types"
import typoGen from "./typography"

export type Theme = ICSTheme & { fontMaker: FmFunc }
const themeContext = createContext<Theme>({
  ...theme,
  typography: typoGen({ ...theme, fontMaker: fontMaker(theme) }),
  fontMaker: fontMaker(theme),
})

// noinspection JSUnusedGlobalSymbols
export const makeNavigationTheme = (
  darkTheme: boolean,
  parentThemeColors: IColorTheme,
): RNTheme => {
  const RNavigationTheme = darkTheme ? RNDarkTheme : NavigationTheme
  return {
    ...RNavigationTheme,
    colors: {
      ...RNavigationTheme.colors,
      primary: _.get(
        parentThemeColors,
        darkTheme ? "onPrimary.mediumEmphasis" : "primary_400",
        RNavigationTheme.colors.primary,
      ),
      card: _.get(parentThemeColors, "surface.background", RNavigationTheme.colors.background),
      background: _.get(parentThemeColors, "surface.surface", RNavigationTheme.colors.background),
      text: _.get(parentThemeColors, `onSurface.highEmphasis`, RNavigationTheme.colors.text),
    },
  }
}

const makePaperTheme = (appTheme: Theme): PaperTheme => {
  const paperTheme = MD2LightTheme
  const dark = appTheme.darkMode
  return {
    ...paperTheme,
    ...appTheme,
    dark,
    colors: {
      ...paperTheme.colors,
      primary: _.get(
        appTheme,
        `colors.${dark ? "primary_300" : "primary"}`,
        paperTheme.colors.primary,
      ),
      accent: _.get(
        appTheme,
        `colors.${dark ? "secondary_400" : "secondary"}`,
        paperTheme.colors.accent,
      ),
      background: _.get(appTheme, `colors.surface.surface`, paperTheme.colors.background),
      text: _.get(appTheme, `colors.onSurface.highEmphasis`, paperTheme.colors.text),
      notification: _.get(appTheme, "colors.primary", paperTheme.colors.notification),
    },
    fonts: {
      regular: {
        fontFamily: appTheme.fontMaker({ weight: "Regular" }).fontFamily!,
      },
      medium: {
        fontFamily: appTheme.fontMaker({ weight: "SemiBold" }).fontFamily!,
      },
      light: {
        fontFamily: appTheme.fontMaker({ weight: "Regular" }).fontFamily!,
      },
      thin: {
        fontFamily: appTheme.fontMaker({ weight: "Regular" }).fontFamily!,
      },
    },
  }
}

function getTheme<AppTheme extends Theme = Theme>(
  parentTheme?: PartialPartial<AppTheme>,
  csTheme?: PartialPartialPartial<AppTheme>,
): Omit<Theme, "typography" | "typeGen" | "fontMaker"> & ICSTheme {
  return _.merge({}, parentTheme as unknown as ICSTheme, csTheme as ICSTheme)
}

export function ThemeProvider<AppTheme extends Theme = Theme>({
  children,
  customTheme,
}: {
  children: ReactNode
  customTheme: PartialPartialPartial<AppTheme>
}): JSX.Element {
  const { darkTheme } = usePreferences()
  const parentTheme = useContext(themeContext)

  parentTheme.darkMode = darkTheme
  parentTheme.colors = _.merge({}, parentTheme.colors, darkTheme ? darkOverride : colors)

  const csTheme: Theme = getTheme(parentTheme as AppTheme, customTheme) as unknown as Theme
  _.forEach(csTheme.fonts as unknown as IFontTheme[], f => {
    if (f.weights === undefined) {
      f.weights = defaultWeights
    }
    if (f.styles === undefined) {
      f.styles = defaultStyles
    }
  })
  csTheme.fontMaker = fontMaker(csTheme)
  const defaultTypo = typoGen(csTheme)
  const localTypo = (customTheme as unknown as ICSTheme)?.typoGen?.(csTheme as unknown as ICSTheme)
  csTheme.typography = _.merge({}, defaultTypo, parentTheme.typography, localTypo)

  return (
    <themeContext.Provider value={csTheme}>
      <Provider theme={makePaperTheme(csTheme)}>{children}</Provider>
    </themeContext.Provider>
  )
}

function useTheme<AppTheme extends Theme = Theme>(): AppTheme {
  return useContext<Theme>(themeContext) as unknown as AppTheme
}

export default useTheme
