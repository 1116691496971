import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const VolumeSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path d="M5 13h2.83L10 15.17V8.83L7.83 11H5v2Z" fill="none" />
      <Path
        d="M3 9v6h4l5 5V4L7 9H3Zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83Zm4-.86v8.05c1.48-.73 2.5-2.25 2.5-4.02A4.5 4.5 0 0 0 14 7.97Zm0-4.74v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77Z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}

export default VolumeSVG
