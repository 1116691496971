import { getPathFromState } from "@react-navigation/core"
import type { LinkingOptions, NavigationContainerProps } from "@react-navigation/native"
import { NavigationContainer } from "@react-navigation/native"
import { screens } from "constants/constants"
import { navigationRef } from "navigation/Constants"
import type { IRootParamList } from "navigation/RootNavigator"
import RootNavigator from "navigation/RootNavigator"
import BottomSheetProvider from "providers/BottomSheetProvider"
import { usePreferences } from "providers/PreferenceProvider"
import useTheme, { makeNavigationTheme } from "theme/ThemeProvider"

const linking: LinkingOptions<IRootParamList> = {
  prefixes: [
    "http://localhost:19006",
    "https://domaine-bb731.firebaseapp.com",
    "https://*.web.app",
  ],
  config: { screens },
  // only == and not === to match the verification made by the package and avoid an error
  getPathFromState: (state, options) => (state == null ? "/" : getPathFromState(state, options)),
}

export const NavigationApp = (props: Omit<NavigationContainerProps, "children">): JSX.Element => {
  const { onStateChange } = props
  const { darkTheme } = usePreferences()
  const { colors } = useTheme()

  return (
    <NavigationContainer
      linking={linking}
      // @ts-ignore
      ref={navigationRef}
      onStateChange={onStateChange}
      theme={makeNavigationTheme(darkTheme, colors)}
    >
      <BottomSheetProvider>
        <RootNavigator />
      </BottomSheetProvider>
    </NavigationContainer>
  )
}
