import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const Information = (props?: SvgProps): JSX.Element => {
  const {
    colors: {
      onSurface: { mediumEmphasis },
    },
  } = useTheme()
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm1.2 18h-2.4v-7.2h2.4V18zm0-9.6h-2.4V6h2.4v2.4z"
        fill={props?.color ?? mediumEmphasis}
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default Information
