import { edges } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { FC, PropsWithChildren } from "react"
import { View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

/** Background of each safeArea screen. Must be the one to switch color with dark mode */
const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const s = useStyles(
    ({ colors: { surface } }) => ({
      container: {
        backgroundColor: surface.surface,
        flex: 1,
      },
      safeAreaView: {
        flexGrow: 1,
        maxHeight: "100%",
      },
    }),
    [],
  )
  return (
    <View style={s.container}>
      <SafeAreaView edges={edges.BOTTOM} style={s.safeAreaView}>
        {children}
      </SafeAreaView>
    </View>
  )
}

export default AppLayout
