import useCommonStyles from "hooks/useCommonStyles"
import type { FC, PropsWithChildren } from "react"
import type { ViewStyle } from "react-native"
import { StyleSheet, View } from "react-native"

interface IProps extends PropsWithChildren {
  style?: ViewStyle
}

const ScreenWrapper: FC<IProps> = ({ children, style }) => {
  const { fullHeight } = useCommonStyles()
  return <View style={[StyleSheet.absoluteFill, fullHeight, style]}>{children}</View>
}

export default ScreenWrapper
