import type { TFunction } from "i18next"
import _ from "lodash"

import BookSVG from "../../Svgs/Book"
import LightBulbSVG from "../../Svgs/LightBulb"
import NoteSVG from "../../Svgs/Note"
import PlayIconSVG from "../../Svgs/PlayIcon"
import type { SVGComponent } from "./SVGInterface"

export interface TabType {
  index: string
  title: string
  image?: SVGComponent
}

// eslint-disable-next-line no-shadow
export enum TabBottomSheetKeys {
  rule = "rule",
  memo = "memo",
  video = "video",
  note = "note",
}
export const getBottomSheetTabs = (t: TFunction, hasVideo: boolean, hasNote: boolean): TabType[] =>
  _.compact([
    { index: "rule", title: t("bottomSheet.rule"), image: props => <BookSVG {...props} /> },
    { index: "memo", title: t("bottomSheet.memo"), image: props => <LightBulbSVG {...props} /> },
    hasVideo
      ? {
          index: "video",
          title: t("bottomSheet.video"),
          image: props => <PlayIconSVG {...props} />,
        }
      : undefined,
    hasNote
      ? { index: "note", title: t("bottomSheet.note"), image: props => <NoteSVG {...props} /> }
      : undefined,
  ])
