import { Subtitle1 } from "components/Texts"
import { warningIcons, welcomeIcons } from "config/images"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import _ from "lodash"
import { ModalType } from "models/ModalInterfaces"
import type { FC } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, useWindowDimensions, View } from "react-native"
import ThumbsupSVG from "svgs/Thumbsup"
import useTheme from "theme/ThemeProvider"

import RowItem from "../RowItem/RowItem"

interface IProps {
  type: ModalType
  isWelcome?: boolean
  dueDate?: string
  numberOfQuestions?: string // specific to evaluation
  allocatedTime?: string // specific to evaluation
  question?: string
  isResumed?: boolean
  iconColor?: string
}

const ModalListContent: FC<IProps> = ({
  type,
  isWelcome,
  dueDate,
  allocatedTime,
  numberOfQuestions,
  question,
  isResumed,
  iconColor,
}) => {
  const {
    fontMaker,
    colors: {
      primary,
      onSurface: { highEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation()
  const { height } = useWindowDimensions()
  const color = iconColor ?? (isWelcome ? primary : highEmphasis)
  const s = useStyles(
    ({
      dimensions: { margin, spacing },
      colors: {
        surface: { backgroundModal },
        onSurface,
      },
    }) => ({
      svg: {
        alignSelf: "center",
        marginBottom: 16,
      },
      rowStyle: {
        backgroundColor: backgroundModal,
        maxHeight: undefined,
      },
      rowStyleWithContent: {
        backgroundColor: backgroundModal,
        paddingRight: spacing * 1.5,
      },
      scrollView: {
        maxHeight: height < 700 ? 300 : undefined,
      },
      style: {
        backgroundColor: backgroundModal,
        marginVertical: margin / 2,
        width: "100%" as const,
      },
      subtitle: {
        paddingHorizontal: spacing,
        color: onSurface.highEmphasis,
        marginTop: margin,
        ...fontMaker({ weight: "SemiBold" }),
      },
    }),
    [height],
  )

  const footer = question ?? t([`${ns.MODAL}.${type}.question`, "common.empty"])

  const renderRight = useMemo(
    () => (index: number) => {
      switch (index) {
        case 0:
          return dueDate
        case 1:
          return allocatedTime
        case 2:
          return numberOfQuestions
        default:
          return ""
      }
    },
    [dueDate, allocatedTime, numberOfQuestions],
  )
  return (
    <View style={s.style}>
      {isWelcome ? <ThumbsupSVG style={s.svg} /> : null}
      <ScrollView style={s.scrollView} scrollEnabled={true}>
        {isWelcome
          ? _.map(
              t(`${ns.MODAL}.${ModalType.WELCOME}.labels`, { returnObjects: true }) as string[],
              (title, index) => (
                <RowItem
                  key={index}
                  separator={false}
                  placeHolder={true}
                  {...{ title }}
                  rowStyle={s.rowStyle}
                  leftIcon={
                    welcomeIcons?.[index] ? () => welcomeIcons?.[index]({ color }) : undefined
                  }
                />
              ),
            )
          : _.map(
              t(`${ns.MODAL}.${type}.${isResumed ? "resumedLabels" : "labels"}`, {
                returnObjects: true,
              }) as string[],
              (title, index) =>
                // here, we do not display remaining time for next evaluation resuming
                // we still keep the code available as a request from Zoé
                index === 1 && isResumed && type === "next_evaluation" ? null : renderRight(
                    index,
                  ) ? (
                  <RowItem
                    key={index}
                    {...{ title }}
                    separator={false}
                    placeHolder={true}
                    rowStyle={s.rowStyleWithContent}
                    rightNode={renderRight(index)}
                    leftIcon={() => warningIcons?.[index]({ color })}
                  />
                ) : null,
            )}
      </ScrollView>
      {isWelcome ? null : (
        <Subtitle1 color={highEmphasis} style={s.subtitle}>
          {footer}
        </Subtitle1>
      )}
    </View>
  )
}

export default ModalListContent
