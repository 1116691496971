import type {
  BackEvaluationInteraction,
  ClosedLevelResult,
  CurrentEvaluationSession,
  CurrentEvaluationSessionQueryBackData,
  Evaluation,
  EvaluationId,
  ExaminationId,
  Ids,
  InitialEvaluation,
  Memo,
  ScenarioId,
  SendingInteractionsParams,
  SessionId,
  UniqueCopyId,
  UpdateExaminationOrLevelRequestBody,
} from "@newpv/js-common"
import {
  axios,
  throwIfApiError,
  toBackInitialEvaluationInteraction,
  toBackInteraction,
  toBackMemo,
  toBackNextEvaluationInteraction,
} from "@newpv/js-common"
import { apiUrl, bffUrl } from "constants/constants"
import _ from "lodash"
import { logger } from "utils/logger"

export const createMemoData = async (memo: Memo): Promise<Record<string, never>> =>
  throwIfApiError(await axios.post(`${apiUrl}/training/memo`, toBackMemo(memo)))

export const sendInteractions = async (
  variables: SendingInteractionsParams,
): Promise<Record<string, never>> => {
  logger(
    "SENDING INTERACTIONS for scenario",
    variables.scenarioId,
    "evaluation type:",
    variables.evaluationType,
    "interactions are:",
    variables.interactions ?? variables.lastInteraction,
  )

  return throwIfApiError(
    // initial evaluation
    variables.evaluationType === "initial_evaluation" && variables.lastInteraction
      ? await axios.post(
          `${apiUrl}/evaluation/initial/${variables.scenarioId}/interaction`,
          toBackInitialEvaluationInteraction(variables.lastInteraction),
        )
      : // next evaluation
      variables.evaluationType === "next_evaluation" && variables.lastInteraction
      ? await axios.post(
          `${apiUrl}/evaluation/examination/${variables.examinationId}/interaction`,
          {
            ...toBackNextEvaluationInteraction(variables.lastInteraction),
            scenarioId: variables.scenarioId,
          },
        )
      : // for practice test and training
        await axios.post(`${apiUrl}/training/interaction`, {
          interactions: variables.interactions?.map(toBackInteraction),
        }),
  )
}

export const startExaminationForNextEvaluation = async (
  evaluationId: EvaluationId,
  sessionId: SessionId,
  extraTime: boolean,
): Promise<{ examinationId: ExaminationId }> =>
  throwIfApiError(
    await axios.post(
      `${apiUrl}/evaluation/evaluation/${evaluationId}/session/${sessionId}/examination`,
      { extraTime },
    ),
  )

export const postInitialEvaluation = async (
  scenarioId: ScenarioId,
  timeLimit?: number,
): Promise<void> =>
  throwIfApiError(
    await axios.post(`${apiUrl}/evaluation/initial/${scenarioId}`, {
      duration: timeLimit ?? 0,
    }),
  )

export const postLevelOrPracticeTest = async ({
  uniqueCopyId,
  levelId,
  moduleId,
  scenarioId,
}: Omit<Ids, "ruleId"> & { uniqueCopyId: UniqueCopyId; scenarioId: ScenarioId }): Promise<void> =>
  throwIfApiError(
    await axios.post(`${apiUrl}/training/progress`, {
      clientStart: new Date().toISOString(),
      levelId,
      moduleId,
      progress: "started",
      trainingBlockId: uniqueCopyId,
      scenarioId,
    }),
  )

export const getInitialEvaluation = async (
  scenarioId: ScenarioId,
): Promise<InitialEvaluation | undefined> => {
  const result = await axios.get<InitialEvaluation>(`${apiUrl}/evaluation/initial/${scenarioId}`)

  if (result.status === 404) {
    return undefined
  }

  return throwIfApiError(result)
}

export const sendClosedSessionData = async (params: {
  updateExaminationOrLevelRequestBody: UpdateExaminationOrLevelRequestBody
  uniqueCopyId: UniqueCopyId
}): Promise<ClosedLevelResult> => {
  if (params.updateExaminationOrLevelRequestBody.evaluationType === "next_evaluation") {
    params.updateExaminationOrLevelRequestBody.interactions = undefined
  }

  return throwIfApiError(
    await axios.put(
      // TODO: rename this route in BFF
      // TODO: voir ticket sc-9098 pour la gestion du paramètre interactions
      `${bffUrl}/examinations/${params.uniqueCopyId}`,
      params.updateExaminationOrLevelRequestBody,
    ),
  )
}

export const getExaminationInteractions = async (
  examinationId: ExaminationId,
): Promise<BackEvaluationInteraction[]> => {
  const result: { results: BackEvaluationInteraction[] } = throwIfApiError(
    await axios.get(`${apiUrl}/evaluation/examination/${examinationId}/interaction`),
  )

  return result.results
}

export const getNextEvaluationCurrentSessions = async (params: {
  scenarioId?: ScenarioId
}): Promise<CurrentEvaluationSession[]> => {
  const result: { results: CurrentEvaluationSessionQueryBackData[] } = throwIfApiError(
    await axios.get(`${apiUrl}/evaluation/session?status=NotStarted&status=Started&opened=true`),
  )

  return _.sortBy(
    result.results
      .filter(sessionResult => sessionResult.scenarioId === params.scenarioId)
      .map(toFrontEvaluationSessionData),
    ["dueDate"],
  )
}

export const toFrontEvaluationSessionData = (
  backEvaluationSessionData: CurrentEvaluationSessionQueryBackData,
): CurrentEvaluationSession => ({
  ...backEvaluationSessionData,
  startDate: new Date(backEvaluationSessionData.startDate).getTime(), // to timestamp
  dueDate: new Date(backEvaluationSessionData.dueDate).getTime(),
  examination: {
    ...backEvaluationSessionData.examination,
    startDate: backEvaluationSessionData.examination.startDate
      ? new Date(backEvaluationSessionData.examination.startDate).getTime()
      : undefined,
    endDate: backEvaluationSessionData.examination.finishExaminationDate
      ? new Date(backEvaluationSessionData.examination.finishExaminationDate).getTime()
      : undefined,
  },
})

export const getEvaluation = async (params: { evaluationId: EvaluationId }): Promise<Evaluation> =>
  throwIfApiError(await axios.get(`${apiUrl}/evaluation/evaluation/${params.evaluationId}`))
