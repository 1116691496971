import { buttonWidth } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { useExercise } from "providers/ExerciseProvider"
import type { FC } from "react"
import { View } from "react-native"
import LightBulbSVG from "svgs/LightBulb"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import { Button } from "../Button"
import { Body2 } from "../Texts"

const ClueView: FC<{ isEmailExercise?: boolean }> = ({ isEmailExercise }) => {
  const t = useTypedTranslation()
  const { isSmallWidth } = useDeviceTools()
  const {
    currentExercise: { setIsClueVisible },
  } = useExercise()
  const {
    colors: { onSurface: themeOnSurface, ripple },
  } = useTheme()

  const cs = useCommonStyles()

  const isEmailExerciceOnWeb = isEmailExercise && !isSmallWidth

  const s = useStyles(
    ({
      dimensions: { spacing, margin },
      colors: {
        secondary_200,
        onSurface: { mediumEmphasis, button: labelButton },
      },
      roundness,
      typography: { button },
    }) => ({
      caption: {
        color: mediumEmphasis,
        marginBottom: spacing / 4,
        marginLeft: isEmailExerciceOnWeb ? spacing / 2 : undefined,
        maxWidth: "100%",
        textAlign: isEmailExerciceOnWeb ? "left" : "center",
        width: isEmailExerciceOnWeb ? buttonWidth : "100%",
      },
      container: {
        width: "100%",
      },
      contentStyle: {
        backgroundColor: secondary_200,
        paddingHorizontal: spacing / 2,
        width: buttonWidth,
      },
      labelStyle: {
        ...button,
        color: labelButton,
        marginLeft: spacing / 2,
        marginVertical: margin,
      },
      style: {
        borderRadius: roundness * 4,
        borderWidth: 0,
        marginVertical: spacing / 2,
      },
    }),
    [isEmailExerciceOnWeb],
  )

  return (
    <View style={[cs.alignItemsCenter, isEmailExerciceOnWeb ? cs.flexRow : s.container]}>
      <Button
        onPress={() => {
          setIsClueVisible(prevState => !prevState)
        }}
        mode="outlined"
        rippleColor={rippleColor(ripple)}
        style={s.style}
        labelStyle={s.labelStyle}
        contentStyle={s.contentStyle}
        icon={() => <LightBulbSVG color={themeOnSurface.button} />}
      >
        {t("Exercise.clue_label")}
      </Button>
      <Body2 style={s.caption}>{t("Exercise.clue_subtitle")}</Body2>
    </View>
  )
}

export default ClueView
