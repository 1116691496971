import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const ChevronLeftSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onPrimary },
  } = useTheme()

  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} fill="none" viewBox="0 0 24 24">
      <Path
        d="M17.51 3.87 15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13Z"
        fill={props?.color ?? onPrimary.highEmphasis}
      />
    </Svg>
  )
}

export default ChevronLeftSVG
