import type { NativeStackScreenProps } from "@react-navigation/native-stack"
import type { IRootParamList } from "navigation/RootNavigator"
import useAuthContext from "providers/AuthProvider"
import type { FC } from "react"
import { useEffect } from "react"
import { logger } from "utils/logger"

export const TokenLoginScreen: FC<NativeStackScreenProps<IRootParamList, "TokenLogin">> = ({
  route,
}) => {
  const { setToken, setScenarioId } = useAuthContext()

  useEffect(() => {
    if (route?.params?.jwt != null && route?.params?.scenarioId != null) {
      const scenarioIdNum = parseInt(route.params.scenarioId, 10)
      logger(
        "Setting jwt and scenarioId from URL params",
        route?.params?.jwt,
        route?.params?.scenarioId,
      )
      setScenarioId(scenarioIdNum)
      setToken(route.params.jwt)
    }
  }, [route.params, setScenarioId, setToken])

  return null
}
