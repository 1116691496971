import type { Rule } from "@newpv/js-common"
import { BottomSheet } from "components/BottomSheet/coreComponents"
import type {
  BottomSheetButtonConfig,
  ButtonConfigProp,
} from "components/BottomSheet/coreComponents/BottomSheetButtons"
import BottomSheetContent from "components/BottomSheet/customComponents/BottomSheetContent"
import CustomRenderHTML from "components/CustomRenderHTML"
import { bottomSheet, HELP_MIN_HEIGHT } from "constants/constants"
import { LinearGradient } from "expo-linear-gradient"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { useBottomSheet } from "providers/BottomSheetProvider"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { View } from "react-native"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import { Button } from "../Button"

const RULE_BODY_HEIGHT = 50
const BUTTON_HELPER_HEIGHT = 35

interface IProps {
  rule?: Omit<Rule, "exercises">
}

const HelperView: FC<IProps> = ({ rule }) => {
  const t = useTypedTranslation()
  const {
    colors: { onSurface: themeOnSurface, primary_400, ripple, surface: themeSurface },
    darkMode,
  } = useTheme()
  const cs = useCommonStyles()
  const { isSmallWidth } = useDeviceTools()
  const { onLayout, width } = useLayout()
  const { onLayout: onHTMLLayout, height } = useLayout()
  const { levelId } = useLevelAndEvaluation()

  const { memos } = useScenarioAndModule()
  const memo = useMemo(
    () => (levelId ? _.some(memos?.[levelId], ["ruleId", rule?.id]) : undefined),
    [memos, levelId, rule?.id],
  )

  const isRuleOverflowing = height > RULE_BODY_HEIGHT

  const { showBottomSheet } = useBottomSheet()

  const s = useStyles(
    ({ roundness, dimensions: { margin, spacing }, colors: { surface, onSurface } }) => ({
      help: {
        alignSelf: "center",
        justifyContent: "flex-end",
        width: "100%" as const,
      },
      labelStyle: {
        color: onSurface.highEmphasis,
      },
      ruleBody: {
        height: RULE_BODY_HEIGHT + BUTTON_HELPER_HEIGHT,
        overflow: "hidden",
        width: "100%" as const,
      },
      ruleContent: {
        borderColor: surface.outline,
        borderRadius: roundness,
        borderWidth: 1,
        marginHorizontal: margin,
        marginTop: margin,
        maxWidth: bottomSheet.MAX_WIDTH,
        paddingHorizontal: spacing,
      },
      ruleTitle: {
        marginVertical: margin,
      },
      ruleOverline: { marginTop: margin },
    }),
    [],
  )
  const onPress = useCallback(() => showBottomSheet(), [showBottomSheet])

  const commonButtonProps = useMemo(
    () =>
      ({
        actionsAfterOnPress: ["togglePanel"],
        extraButtonProps: {
          labelStyle: { color: themeOnSurface.highEmphasis },
          rippleColor: rippleColor(ripple),
          mode: "outlined",
        },
      } as Omit<BottomSheetButtonConfig, "label">),
    [themeOnSurface.highEmphasis, ripple],
  )

  const buttonConfig: ButtonConfigProp = useMemo(
    () => [
      // "see more" button
      {
        ifPanelClosed: {
          label: t(`bottomSheet.seeMore`),
          ...commonButtonProps,
        },
        ifPanelOpen: {
          label: t(`bottomSheet.seeLess`),
          ...commonButtonProps,
        },
        smallContent: {
          label: "",
          ...commonButtonProps,
          customButtonStyle: {
            opacity: 0,
            pointerEvents: "none",
          },
          bottomSheetMaxHeight: HELP_MIN_HEIGHT,
        },
      },
      // "next" button - simply closes the bottom sheet
      {
        default: {
          label: t("common.button.continue"),
          actionsAfterOnPress: ["closeBottomSheet", "hideBottomSheet"],
          extraButtonProps: {
            rippleColor: rippleColor(ripple),
            contentStyle: { backgroundColor: primary_400 },
            mode: "contained-tonal",
            labelStyle: { color: themeOnSurface.highEmphasis },
          },
        },
      },
    ],
    [commonButtonProps, primary_400, ripple, t, themeOnSurface.highEmphasis],
  )

  return (
    <>
      <View style={s.help}>
        <View style={s.ruleContent}>
          <View style={s.ruleOverline}>
            <CustomRenderHTML
              content={t("helperView.help")}
              width={width}
              typographyVariant="overline"
              overrideColor={themeOnSurface.highEmphasis}
            />
          </View>
          {rule?.title ? (
            <View style={s.ruleTitle}>
              <CustomRenderHTML
                content={rule?.title ?? ""}
                width={width}
                typographyVariant="subtitle1"
                overrideColor={themeOnSurface.highEmphasis}
              />
            </View>
          ) : null}
          {rule?.description ? (
            <View style={{ position: "relative" }}>
              <View style={s.ruleBody} {...{ onLayout }}>
                <View onLayout={onHTMLLayout}>
                  <CustomRenderHTML
                    content={rule.description ?? ""}
                    width={width}
                    typographyVariant="body2"
                  />
                </View>
              </View>
              <LinearGradient
                style={{ position: "absolute", bottom: 0, right: 0, width: "100%" }}
                colors={[
                  darkMode ? "transparent" : themeSurface.translucent,
                  darkMode && !isSmallWidth ? themeSurface.background : themeSurface.surface,
                ]}
              >
                {isRuleOverflowing || memo ? (
                  <Button
                    mode="text"
                    labelStyle={s.labelStyle}
                    style={cs.alignEnd}
                    {...{ onPress }}
                  >
                    {t("bottomSheet.seeMore")}
                  </Button>
                ) : null}
              </LinearGradient>
            </View>
          ) : null}
        </View>
      </View>
      {rule ? (
        <BottomSheet {...{ buttonConfig }} isPanAndTranslateDisabled={false}>
          <BottomSheetContent {...{ rule }} isDictation={false} isHelp={true} />
        </BottomSheet>
      ) : null}
    </>
  )
}

export default HelperView
