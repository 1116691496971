import { isiOS } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import type { ComponentProps, FC } from "react"
import { TouchableOpacity } from "react-native"
import { TouchableRipple } from "react-native-paper"

export type TouchableRippleProps = ComponentProps<typeof TouchableRipple>

const Touchable: FC<TouchableRippleProps> = (props): JSX.Element => {
  const TouchableElem = isiOS ? TouchableOpacity : TouchableRipple

  const s = useStyles(
    ({ roundness }) => ({
      style: {
        borderRadius: roundness,
      },
    }),
    [],
  )

  /* To apply the Android roundness: enable borderless  */
  // @ts-ignore
  return <TouchableElem {...props} style={[s.style, props.style]} />
}

export default Touchable
