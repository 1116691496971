import _ from "lodash"
import type { Dispatch, ReactNode, RefObject, SetStateAction } from "react"
import { createContext, useContext, useRef, useState } from "react"
import type { FlatList } from "react-native"

// noinspection ES6PreferShortImport
import type { TabType } from "../Models/TabInterfaces"

export interface ContextData {
  currentTab?: TabType
  tabRef?: RefObject<FlatList<TabType>>
  setCurrentTab: Dispatch<SetStateAction<TabType | undefined>>
}

interface IProps {
  children: ReactNode
  initialTab?: TabType
}

const tabContext = createContext<ContextData>({} as ContextData)

export const TabProvider = ({ children, initialTab }: IProps): JSX.Element => {
  const tabRef = useRef<FlatList<TabType>>(null)
  const [currentTab, setCurrentTab] = useState<TabType | undefined>(initialTab)
  const contextValue: ContextData = {
    tabRef,
    currentTab,
    setCurrentTab,
  }

  return <tabContext.Provider value={contextValue}>{children}</tabContext.Provider>
}

export const useTab = (): ContextData => {
  const context = useContext(tabContext)
  if (_.isEmpty(context)) {
    throw new Error("useTab must be used within a TabProvider")
  }
  return context
}

export default TabProvider
