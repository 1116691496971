import { isWeb } from "constants/constants"
import type { RefObject } from "react"
import { useEffect } from "react"
import type { View } from "react-native"

const useKeyboardEvent = (
  handleEnterKeyPress: (event: any) => Promise<void>,
  componentRef?: RefObject<View> | null,
): void => {
  useEffect(() => {
    if (!isWeb) {
      return
    }
    // warning: The ref value 'componentRef.current' will likely have changed by the time this effect cleanup function runs. If this ref points to a node rendered by React, copy 'componentRef.current' to a variable inside the effect, and use that variable in the cleanup function
    if (componentRef) {
      // @ts-ignore
      componentRef.current?.addEventListener("keydown", handleEnterKeyPress)
      return () =>
        // @ts-ignore
        componentRef.current?.removeEventListener("keydown", handleEnterKeyPress)
    }
    global.window.addEventListener("keydown", handleEnterKeyPress)
    return () => global.window.removeEventListener("keydown", handleEnterKeyPress)
  }, [componentRef, handleEnterKeyPress])
}

export default useKeyboardEvent
