import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const NoteSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  return (
    <Svg width={props?.width ?? 24} height={props?.height ?? 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19.625 5v9h-5v5h-9V5h14zm0-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10l6-6V5c0-1.1-.9-2-2-2zm-7 11h-5v-2h5v2zm5-4h-10V8h10v2z"
        fill={props?.color ?? onSurface.highEmphasis}
      />
    </Svg>
  )
}

export default NoteSVG
