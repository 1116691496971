import { Dimensions } from "react-native"

import type { IDimensionsTheme } from "./types"

const { height, width } = Dimensions.get("window")

const dimensions: IDimensionsTheme = {
  width,
  height,
  margin: 16,
  spacing: 16,
  marginSides: 16,
}

export default dimensions
