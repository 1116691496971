import type { Result, WordBlock } from "@newpv/js-common"
import CustomRenderHTML from "components/CustomRenderHTML"
import { maxScreenWidth } from "constants/constants"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import type { FC } from "react"
import { useMemo } from "react"
import { useWindowDimensions } from "react-native"
import type { MixedStyleDeclaration } from "react-native-render-html"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

import Touchable from "../Touchable/Touchable"

interface IProps {
  word: WordBlock
  result?: Result
  isPressed: boolean
  onPress: (word: WordBlock) => void
  isCorrect: boolean
  isDisabled?: boolean
  isEvaluation?: boolean
  isPickedWrongWordForQA?: boolean
  pressedWord?: string
  twoOrThreeChoices: boolean
}

const ToggleButton: FC<IProps> = ({
  word,
  result,
  onPress,
  isPressed,
  isCorrect,
  isDisabled,
  isEvaluation,
  isPickedWrongWordForQA,
  pressedWord,
  twoOrThreeChoices,
}) => {
  const { width } = useWindowDimensions()
  const containerWidth = width > maxScreenWidth ? maxScreenWidth : width
  const {
    colors: { ripple, onSurface, surface },
  } = useTheme()
  const { onLayout, width: parentWidth } = useLayout()
  const s = useStyles(
    ({ dimensions: { spacing, margin }, colors: { neutral_200, primary_300, secondary_300 } }) => ({
      wordCard: {
        backgroundColor: surface.surface,
        justifyContent: "center",
        margin: margin / 4,
        padding: spacing * 1.5,
        // -4 to account for borders
        width: twoOrThreeChoices
          ? containerWidth - spacing * 2 - margin - 2
          : (containerWidth - spacing * 4) / 2 - margin / 2 - 4,
        borderWidth: 1,
        borderColor: surface.outline,
      },
      background: {
        backgroundColor: isDisabled
          ? surface.background
          : isEvaluation
          ? isPressed
            ? neutral_200
            : surface.surface
          : isPressed && result
          ? result === "correct"
            ? primary_300
            : secondary_300
          : result && isCorrect
          ? primary_300
          : result && result !== "correct" && isPickedWrongWordForQA && pressedWord === undefined
          ? secondary_300
          : surface.surface,
      },
    }),
    [word, isPressed, result, containerWidth, isEvaluation, isDisabled, twoOrThreeChoices],
  )

  const customStyle = useMemo<MixedStyleDeclaration>(
    () => ({
      color: isDisabled
        ? onSurface.disabled
        : !result
        ? onSurface.highEmphasis
        : isPressed || isCorrect === (true || false)
        ? onSurface.button
        : onSurface.highEmphasis,
      textAlign: "center",
      textAlignVertical: "center",
    }),
    [
      isCorrect,
      isDisabled,
      isPressed,
      onSurface.button,
      onSurface.disabled,
      onSurface.highEmphasis,
      result,
    ],
  )

  return (
    <Touchable
      disabled={isDisabled}
      rippleColor={rippleColor(ripple)}
      style={[s.wordCard, s.background]}
      onPress={() => onPress(word)}
      onLayout={onLayout}
    >
      <CustomRenderHTML
        content={word.text}
        typographyVariant="body1"
        width={parentWidth}
        overrideColor={isDisabled ? onSurface.disabled : onSurface.highEmphasis}
        // do NOT use a style from a StyleSheet here
        {...{ customStyle }}
        isAlignCenter={true}
      />
    </Touchable>
  )
}

export default ToggleButton
