/* eslint-disable no-bitwise */
export const hashCode = (value: string | number | undefined): number => {
  let hash = 0
  let i
  let chr
  const s = String(value)
  if (s.length === 0) {
    return hash
  }
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
