/** This component must be used inside a BottomSheet context
 * It can be used if a ScrollView is needed inside the BottomSheet component, to have the correct style and responder
 */
import { bottomSheet } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import { useBottomSheet } from "providers/BottomSheetProvider"
import type { FC, ReactNode } from "react"
import { useMemo } from "react"
import type { StyleProp, ViewStyle } from "react-native"
import { PanResponder, ScrollView } from "react-native"

interface Props {
  children: ReactNode
  style?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>
}

const BottomSheetScrollView: FC<Props> = ({ children, style, contentContainerStyle }) => {
  // TODO: review here the panAndTranslateDisabled
  // if the value is correct
  // and if it's still useful
  const { bsState, panAndTranslateDisabled } = useBottomSheet()

  const s = useStyles(
    () => ({
      scroll: {
        maxHeight: bsState.panelOpen ? undefined : bottomSheet.MAX_HEIGHT_1,
      },
    }),
    [bsState.panelOpen],
  )

  const childPanResponder = useMemo(
    () =>
      PanResponder.create({
        onMoveShouldSetPanResponderCapture: (__, gestureState) =>
          bsState.panelOpen && gestureState.dy > 15,
      }),
    [bsState.panelOpen],
  )

  return (
    <ScrollView
      style={[s.scroll, style]}
      contentContainerStyle={contentContainerStyle}
      {...(panAndTranslateDisabled ? childPanResponder.panHandlers : null)}
    >
      {children}
    </ScrollView>
  )
}

export default BottomSheetScrollView
