import { getPosition, IMAGE_RADIUS, modal } from "constants/constants"
import { useStyles } from "hooks/useStyles"
import { ns } from "i18n/fr"
import _ from "lodash"
import type { ModalType } from "models/ModalInterfaces"
import type { SVGComponent } from "models/SVGInterface"
import { isSVGComponent } from "models/SVGInterface"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import type { ImageSourcePropType } from "react-native"
import { Image, View } from "react-native"
import useTheme from "theme/ThemeProvider"

import { H6 } from "../Texts"

interface IProps {
  type: ModalType
  headerTitle?: string
  numberOfMasteredRules?: number
  headerImage?: ImageSourcePropType | SVGComponent
}

const ModalHeader: FC<IProps> = ({ headerTitle, headerImage, type, numberOfMasteredRules }) => {
  const {
    colors: {
      onSurface,
      onPrimary: { highEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation()

  const title =
    headerTitle ??
    t([`${ns.MODAL}.${type}.headerTitle`, "common.empty"], {
      count: numberOfMasteredRules,
    })

  // results
  const s = useStyles(
    ({
      dimensions: { spacing },
      darkMode,
      colors: {
        surface: { background, surfaceSecondary, backgroundModal },
      },
    }) => ({
      header: {
        alignItems: "center",
        backgroundColor: darkMode ? surfaceSecondary : background,
        height: modal.HEADER,
        justifyContent: "center",
      },
      image: {
        alignItems: "center",
        backgroundColor: backgroundModal,
        borderRadius: IMAGE_RADIUS,
        height: modal.IMAGE,
        justifyContent: "center",
        position: "absolute",
        top: getPosition(modal.HEADER, modal.IMAGE),
        width: modal.IMAGE,
      },
      rule: {
        alignSelf: "center",
        position: "absolute",
        top: isSVGComponent(headerImage) ? getPosition(modal.ICON, modal.ICON) : undefined,
      },
      title: {
        paddingHorizontal: spacing * 1.5,
        textAlign: "center",
        textAlignVertical: "center",
      },
    }),
    [headerImage, type],
  )

  return (
    <View style={s.header}>
      {_.isEmpty(title) ? null : <H6 style={s.title}>{title}</H6>}
      {headerImage ? (
        <View style={s.image}>
          {isSVGComponent(headerImage) ? (
            headerImage()
          ) : (
            <Image source={headerImage} style={{ backgroundColor: onSurface.transparent }} />
          )}
          {numberOfMasteredRules ? (
            <H6 color={highEmphasis} style={s.rule}>
              {t(`${ns.MODAL}.results.masteredRule`, {
                nbRule: numberOfMasteredRules,
                signDisplay: "always",
              })}
            </H6>
          ) : null}
        </View>
      ) : null}
    </View>
  )
}

export default ModalHeader
