import type {
  BottomSheetButtonConfig,
  ButtonConfigProp,
} from "components/BottomSheet/coreComponents/BottomSheetButtons"
import useTypedTranslation from "hooks/useTypedTranslation"
import { useMemo } from "react"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

export const useEmailButtonConfig = (
  bottomSheetButtonConfig: Partial<BottomSheetButtonConfig>,
): ButtonConfigProp => {
  const t = useTypedTranslation()
  const {
    typography,
    colors: { ripple, primary_400, onSurface },
  } = useTheme()
  return useMemo(
    () => [
      // "validate" button - to send the answer, and close this bottom sheet
      {
        default: {
          ...bottomSheetButtonConfig,
          customButtonStyle: { flex: 1, backgroundColor: primary_400 },
          extraButtonProps: {
            labelStyle: { ...typography.button, color: onSurface.highEmphasis },
            rippleColor: rippleColor(ripple),
          },
          label: t("common.button.validate"),
        } as BottomSheetButtonConfig,
      },
    ],
    [bottomSheetButtonConfig, onSurface.highEmphasis, primary_400, ripple, t, typography.button],
  )
}
