import { iconSize } from "constants/constants"
import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const BookSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: { onSurface },
  } = useTheme()
  const color = props?.color ?? onSurface.highEmphasis
  return (
    <Svg
      width={props?.width ?? iconSize.SMALL}
      height={props?.height ?? iconSize.SMALL}
      fill={color}
      viewBox="0 0 24 24"
    >
      <Path
        d="M21.5 5.5C20.39 5.15 19.17 5 18 5c-1.95 0-4.05.4-5.5 1.5C11.05 5.4 8.95 5 7 5c-1.95 0-4.05.4-5.5 1.5v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05 1.35-.65 3.3-1.1 4.75-1.1 1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6.5c-.6-.45-1.25-.75-2-1ZM3.5 19V7.5C4.6 7.15 5.8 7 7 7c1.34 0 3.13.41 4.5.99v11.5c-1.37-.58-3.16-.99-4.5-.99-1.2 0-2.4.15-3.5.5Zm18 0c-1.1-.35-2.3-.5-3.5-.5-1.34 0-3.13.41-4.5.99V7.99C14.87 7.4 16.66 7 18 7c1.2 0 2.4.15 3.5.5V19Z"
        fill={color}
      />
      <Path
        opacity={0.3}
        d="M11.5 7.99C10.13 7.41 8.34 7 7 7c-1.2 0-2.4.15-3.5.5V19c1.1-.35 2.3-.5 3.5-.5 1.34 0 3.13.41 4.5.99V7.99Z"
        fill={color}
      />
      <Path
        d="M18 11c.88 0 1.73.09 2.5.26V9.74c-.79-.15-1.64-.24-2.5-.24-1.28 0-2.46.16-3.5.47v1.57c.99-.35 2.18-.54 3.5-.54ZM18 13.66c.88 0 1.73.09 2.5.26V12.4c-.79-.15-1.64-.24-2.5-.24-1.28 0-2.46.16-3.5.47v1.57c.99-.34 2.18-.54 3.5-.54ZM18 16.33c.88 0 1.73.09 2.5.26v-1.52c-.79-.15-1.64-.24-2.5-.24-1.28 0-2.46.16-3.5.47v1.57c.99-.35 2.18-.54 3.5-.54Z"
        fill={color}
      />
    </Svg>
  )
}

export default BookSVG
