import { Defs, LinearGradient, Stop } from "react-native-svg"
import type { IColorTheme } from "theme/types"

const AREA_GRADIENT_OPACITY = 0.4

export const GradientFill = ({ colorsProp }: { colorsProp: IColorTheme }): JSX.Element => (
  <Defs>
    <LinearGradient id="areaGradient" x1="0" y1="100" x2="1" y2="100">
      <Stop offset="0%" stopColor={colorsProp.secondary} stopOpacity={AREA_GRADIENT_OPACITY} />
      <Stop offset="100%" stopColor={colorsProp.primary_50} stopOpacity={AREA_GRADIENT_OPACITY} />
    </LinearGradient>
    <LinearGradient id="clipGradient" x1="0%" y1="35%" x2="0%" y2="10%">
      <Stop offset="0%" stopColor={colorsProp.secondary} stopOpacity={0.23} />
      <Stop offset="100%" stopColor={colorsProp.primary} stopOpacity={1} />
    </LinearGradient>
  </Defs>
)
