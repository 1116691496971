import type { StackNavigationProp } from "@react-navigation/stack"
import { H6 } from "components/Texts"
import { isWeb } from "constants/constants"
import useBackImage from "hooks/useBackImage"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import { rootRoutes } from "navigation/Constants"
import React from "react"
import { View } from "react-native"
import { IconButton } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import CloseSVG from "svgs/CloseIcon"
import SettingsSVG from "svgs/Settings"
import useTheme from "theme/ThemeProvider"
import { rippleColor } from "utils/hexToRgba"

export const ModalHeader: React.FC<{
  navigation: StackNavigationProp<any>
  routeName: "profile" | "settings"
}> = ({ navigation, routeName }) => {
  const t = useTypedTranslation()
  const {
    colors: { ripple, onSurface, surface },
  } = useTheme()
  const { top } = useSafeAreaInsets()
  const s = useStyles(({ dimensions: { spacing } }) => ({
    container: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: routeName === "profile" ? "space-between" : undefined,
      marginTop: top / 2,
      padding: isWeb ? spacing : undefined,
      zIndex: 1,
    },
  }))
  const backImage = useBackImage(onSurface.mediumEmphasis)
  return (
    <View style={s.container}>
      <IconButton
        containerColor={surface.backgroundModal}
        rippleColor={rippleColor(ripple)}
        onPress={() => navigation?.goBack()}
        icon={() =>
          routeName === "profile" ? <CloseSVG color={onSurface.mediumEmphasis} /> : backImage
        }
      />
      {routeName === "profile" ? (
        <IconButton
          containerColor={surface.backgroundModal}
          rippleColor={rippleColor(ripple)}
          onPress={() => navigation?.navigate(rootRoutes.SETTINGS)}
          icon={() => <SettingsSVG color={onSurface.mediumEmphasis} />}
        />
      ) : (
        <H6>{t("Settings.title")}</H6>
      )}
    </View>
  )
}
