import type { DependencyList } from "react"
import { useMemo } from "react"
import { StyleSheet } from "react-native"
import type { Theme } from "theme/ThemeProvider"
import useTheme from "theme/ThemeProvider"

export const useStyles = <
  AppTheme extends Theme,
  T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>,
>(
  // eslint-disable-next-line no-shadow
  create: (theme: Theme & AppTheme) => T,
  deps: DependencyList = [],
): T => {
  const theme = useTheme<Theme & AppTheme>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => StyleSheet.create(create(theme)), [...deps, theme])
}
