import type { EmailPickOutOneOption, Exercise, Resources, Result, Rule } from "@newpv/js-common"
import { TabBar } from "components/TabBar"
import { Subtitle1 } from "components/Texts"
import { bottomSheet, HELP_MIN_HEIGHT, MAX_TAB_HEIGHT, modal } from "constants/constants"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { getBottomSheetTabs } from "models/TabInterfaces"
import { View } from "moti"
import { useBottomSheet } from "providers/BottomSheetProvider"
import TabProvider from "providers/TabProvider"
import type { FC } from "react"
import { useEffect } from "react"
import useTheme from "theme/ThemeProvider"

import BottomSheetScrollView from "../coreComponents/BottomSheetScrollView"
import BottomSheetTabsContent from "./BottomSheetTabsContent"
import RuleContainer from "./RuleContainer"
import TopSection from "./TopSection"

interface Props {
  isHelp?: boolean
  resources?: Resources
  isIntensiveTrainingProp?: boolean
  exercise?: Exercise
  rule: Omit<Rule, "exercises">
  result?: Result
  // mandatory for now
  isDictation: boolean
  emailPickOutOneOptions?: EmailPickOutOneOption[]
  selectedOption?: {
    answer: EmailPickOutOneOption
    index: number
  }
}

const BottomSheetContent: FC<Props> = ({
  isHelp,
  isIntensiveTrainingProp,
  resources,
  exercise,
  rule,
  result,
  isDictation,
  emailPickOutOneOptions,
  selectedOption,
}) => {
  const { onLayout, height } = useLayout()
  const { bsState, hiddenPartHeight, childrenHeight, setChildrenHeight } = useBottomSheet()

  const isChildrenHeightTaller = childrenHeight > HELP_MIN_HEIGHT

  const t = useTypedTranslation()
  /** STYLES */
  const {
    colors: { surface },
  } = useTheme()
  const s = useStyles(
    ({ dimensions: { spacing } }) => ({
      container: {
        backgroundColor: surface.surface,
        flex: 1,
        padding: isHelp ? spacing : undefined,
      },
      helperViewContainer: {
        padding: spacing,
      },
      intensiveTrainingSubtitle: {
        padding: spacing / 2,
      },
    }),
    [bsState.panelOpen],
  )

  useEffect(() => {
    if (height) {
      setChildrenHeight(height)
    }
  }, [height, setChildrenHeight])

  /** HOOKS */
  return (
    <TabProvider initialTab={{ index: "rule", title: "" }}>
      {!isHelp && _.isUndefined(result) ? null : (
        <TopSection
          {...{ hiddenPartHeight }}
          title={isHelp ? t("bottomSheet.help") : undefined}
          isCorrect={result === "correct"}
        />
      )}
      {!isIntensiveTrainingProp ? (
        <BottomSheetScrollView
          contentContainerStyle={{
            paddingBottom:
              isHelp && isChildrenHeightTaller
                ? bottomSheet.BOTTOM_BUTTONS_CONTAINER_HEIGHT * 1.5 + modal.HEADER
                : bsState.panelOpen
                ? bottomSheet.MAX_HEIGHT_1
                : 0,
            backgroundColor: surface.backgroundModal,
          }}
        >
          {isHelp ? null : (
            <TabBar
              listStyle={{ maxHeight: MAX_TAB_HEIGHT }}
              tabs={getBottomSheetTabs(
                t,
                (resources?.videos?.length ?? 0) > 0,
                !!resources?.lesson,
              )}
            />
          )}
          <View {...{ onLayout }}>
            {isHelp ? (
              <View style={s.helperViewContainer}>
                <RuleContainer {...{ rule }} />
              </View>
            ) : (
              <BottomSheetTabsContent
                {...{
                  exercise,
                  result,
                  rule,
                  isDictation,
                  selectedOption,
                  emailPickOutOneOptions,
                }}
              />
            )}
          </View>
        </BottomSheetScrollView>
      ) : null}
      {isIntensiveTrainingProp ? (
        <Subtitle1 selectable={false} style={s.intensiveTrainingSubtitle}>
          {t("bottomSheet.intensiveTraining")}
        </Subtitle1>
      ) : null}
    </TabProvider>
  )
}

export default BottomSheetContent
