import type { SvgProps } from "react-native-svg"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

// Keep the SVG header in case we found a solution for the tablet bug
const CommonHeader = (props?: SvgProps): JSX.Element => {
  const { darkMode } = useTheme()
  return (
    <Svg
      {...props}
      width={props?.width ?? 411}
      height={props?.height ?? 200}
      viewBox="0 0 411 200"
      pointerEvents="none"
      preserveAspectRatio="xMaxYMax slice"
      fill="none"
    >
      <G clipPath="url(#a)">
        <Path fill="#F0FEB4" d="M0 0h411v200H0z" />
        <Path
          d="M19.042-53.93-132.22-210.491-390.57 39.115l151.262 156.561L19.042-53.93Z"
          fill="#fff"
        />
        <Path
          d="M225.894 160.192 401.362-9.337l-54.911-56.835-175.468 169.53 54.911 56.834Z"
          fill="#DFFB6B"
        />
        <Path
          d="M280.729 216.937 456.198 47.408 401.394-9.315 225.926 160.214l54.803 56.723Z"
          fill="#A2D416"
        />
        <Path
          d="m280.778 216.88 32.517-31.417-54.804-56.723-32.517 31.416 54.804 56.724Z"
          fill="#A2D416"
        />
        <Path d="m276.393 166.205 4.323 50.726-54.792-56.792 50.469 6.066Z" fill="#BEED29" />
        <Path
          d="m262.746 179.389-4.325-50.648 54.872 56.715-50.547-6.067ZM301.015 237.958l175.468-169.53-20.262-20.97-175.468 169.528 20.262 20.972Z"
          fill="#DFFB6B"
        />
        <Path
          d="m321.256 258.894 175.468-169.53-20.261-20.97-175.469 169.529 20.262 20.971Z"
          fill="#BEED29"
        />
        <Path
          d="M346.403-66.22 456.144 47.365c-31.394 30.33-81.332 29.471-111.663-1.922-30.255-31.315-29.394-81.33 1.922-111.663Z"
          fill="#FEAE06"
        />
        <Path
          d="M346.403-66.22 401.349-9.35c-31.393 30.331-56.868 54.791-56.868 54.791-30.255-31.314-29.394-81.33 1.922-111.662Z"
          fill="#A2D416"
        />
        <Path d="m365.446 53.172 14.091 46.55 15.685-46.037-29.776-.513Z" fill="#FEAE06" />
        <Path
          d="M170.253 102.567 18.992-53.994-137.57 97.268l151.262 156.56 156.56-151.261Z"
          fill="#A2D416"
        />
        <Path
          d="m66.776 202.64 103.518-100.015L19.141-53.824-84.378 46.191 66.776 202.64Z"
          fill="#71AD00"
        />
        <Path d="m68.896 49.386-2.095 153.236L-84.32 46.205l153.217 3.18Z" fill="#BEED29" />
        <Path d="m17.152 99.379 2.017-153.237L170.29 102.636 17.152 99.379Z" fill="#DFFB6B" />
        <Path
          d="M91.006 287.974c60.128 1.035 109.711-46.869 110.746-106.997 1.035-60.128-46.869-109.71-106.997-110.746C34.627 69.196-14.955 117.1-15.991 177.228c-1.035 60.128 46.87 109.711 106.997 110.746Z"
          fill="#BEED29"
        />
        <Path
          d="m92.88 179.102 78.327-75.675c41.762 43.226 40.576 112.162-2.65 153.924l-75.676-78.249Z"
          fill="#DFFB6B"
        />
        <Path d="m2.04 175.212-66.24-22.627L2.777 132.33l-.739 42.882Z" fill="#BEED29" />
        <Path
          d="m111.626 198.596-18.753-19.41-78.252 75.604 18.753 19.409 78.252-75.603Z"
          fill="#A2D416"
        />
        <Path
          d="m130.378 217.998-18.753-19.41-78.252 75.604 18.753 19.41 78.252-75.604Z"
          fill="#BEED29"
        />
        <Path
          d="M48.738-23.253-132.41-210.748l87.713-84.744L136.45-107.997c23.396 24.215 22.731 62.832-1.484 86.228C110.751 1.627 72.134.963 48.738-23.253ZM244.342-58.204c16.69 17.275 16.216 44.803-1.058 61.492-4.181 4.04-8.964 7.06-14.042 9.144l-7.759 22.671-5.945-19.649c-12.19.72-24.601-3.759-33.746-13.224-16.69-17.275-16.216-44.803 1.058-61.492 17.274-16.69 44.802-16.216 61.492 1.058Z"
          fill="#A2D416"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h411v200H0z" />
        </ClipPath>
      </Defs>
      {darkMode ? <Path fill="#121212" fillOpacity={0.52} d="M0 0h411v200H0z" /> : undefined}
    </Svg>
  )
}

export default CommonHeader
