import { iconSize, isAndroid } from "constants/constants"
import { useMemo } from "react"
import ArrowLeftSVG from "svgs/ArrowLeft"
import ChevronLeftSVG from "svgs/ChevronLeft"
import useTheme from "theme/ThemeProvider"

const useBackImage = (color?: string): JSX.Element => {
  const {
    colors: {
      onPrimary: { highEmphasis },
    },
  } = useTheme()
  const BackIcon = isAndroid ? ArrowLeftSVG : ChevronLeftSVG
  return useMemo(
    () => <BackIcon color={color ?? highEmphasis} height={iconSize.SMALL} width={iconSize.SMALL} />,

    [BackIcon, color, highEmphasis],
  )
}

export default useBackImage
