import { appName, version } from "../../../../package.json"
// noinspection ES6PreferShortImport -- do not change to a short import
import { ModalType } from "../../Models/ModalInterfaces"
// noinspection ES6PreferShortImport -- do not change to a short import
import { rootRoutes } from "../../Navigation/Constants"

export const ns = { ...rootRoutes, MODAL: "Modal" } as const

export default {
  common: {
    empty: "",
    button: {
      activate: "Activer",
      cancel: "Annuler",
      close: "Fermer",
      closeTab: "Retour à la liste des règles",
      continue: "Continuer",
      training: "Continuer l'entraînement",
      correct: "Correct",
      deactivate: "Désactiver",
      leave: "Quitter",
      listen: "Écouter",
      menu: "Retour au menu",
      next: "Suivant",
      no: "Non",
      noError: "Il n'y a pas de faute",
      ok: "OK",
      pause: "Faire une pause",
      previous: "Précédent",
      quit: "Quitter",
      replay: "Rejouer",
      restart: "Recommencer",
      result: "Accéder aux résultats",
      retry: "Réessayer",
      search: "Chercher",
      start: "Commencer",
      stop: "Arrêter définitivement",
      submitAnswer: "Valider la réponse",
      validate: "Valider",
      wrong: "Incorrect",
      yes: "Oui",
    },
    notImplemented: "Pas encore implémenté",
    openLinkError: "Erreur lors de l'ouverture du lien",
    title: {
      [ns.HOME]: `${appName} QA - Choix du scénario`,
      [ns.LEVEL]: `${appName} - Sélection du niveau`,
      [ns.EXERCISE]: `${appName} v${version}`,
      [ns.LOADING]: `${appName} - Chargement`,
      [ns.LOGIN]: `${appName} - Connexion`,
      [ns.GAR_LOGIN]: `${appName} - Connexion`,
      [ns.TOKEN_LOGIN]: `${appName} - Connexion`,
      [ns.OAUTH_LOGIN]: `${appName} - Connexion`,
      [ns.EVALUATION]: `${appName} Évaluation`,
      [ns.PROFILE]: `${appName} - Profil`,
      [ns.SETTINGS]: `${appName} - Paramètres`,
      [ns.PRACTICE_TEST]: `${appName} - Test blanc`,
      [ns.PRESENTIAL]: `${appName} - Mode présentiel`,
      [ns.LOGGED_OUT]: `${appName} - Déconnecté(e)`,
      [ns.CONSULTATION]: `${appName} - Consultation`,
      [ns.WEB_PAGE]: `Mentions légales`,
      default: `${appName} - Améliorer son orthographe et son expression`,
    },
    errors: {
      contact_support: "Si le problème persiste, prenez contact avec le support.",
      code_error: "(Code: {{error}})",
      network: "Erreur réseau",
      unknown: "Erreur inconnue",
      server_error:
        "Une erreur s'est produite lors de la communication avec le serveur, veuillez réessayer.",
    },
    durationFormat: "H [h] m [min]",
    durationMin: "m [min]",
    duration: "{{count}} minute",
    duration_plural: "{{count}} minutes",
  },
  default_instructions: {
    click_on_mistake:
      "Si vous voyez une faute, cliquez dessus ; sinon, cliquez sur le bouton « Il n’y a pas de faute ».",
    click_on_mistake_dictation: "Écoutez la phrase et écrivez les mots manquants dans le texte.",
    click_on_word: "",
    pick_out_one: "Cliquez sur un des mots ou groupes de mots.",
    pick_out_nearest:
      "Cliquez sur le mot ou le groupe de mots dont le sens est le plus proche dans un contexte professionnel.",
    drag_and_drop: "Classez les propositions dans la bonne colonne.",
    email_pick_out_one:
      "Cliquez sur la bonne réponse parmi les 4 propositions. Cherchez dans la zone surlignée.",
    click_on_email:
      "Ce courriel professionnel va être envoyé. Si vous voyez une anomalie, cliquez dessus ; sinon, cliquez sur « Envoyer ».",
  },
  bottomSheet: {
    congrats: "BRAVO !",
    wrongAnswer: "MAUVAISE RÉPONSE",
    help: "COUP DE POUCE",
    seeMore: "VOIR PLUS",
    seeLess: "VOIR MOINS",
    next: "SUIVANT",
    correction: "CORRECTION",
    comment: "PRÉCISIONS",
    rule: "RÈGLE",
    memo: "MÉMO",
    video: "VIDÉO",
    note: "FICHE",
    definition: "EXEMPLE",
    memoTitle: "Créez un moyen mnémotechnique personnalisé pour mémoriser la règle.",
    memoExample: "Exemple : Je ne prends pas de thé.(T)",
    memoInformation:
      "Vous retrouverez votre mémo chaque fois que vous répondrez à une question liée à cette règle.",
    memoSave: "Enregistré",
    studentMode: "Mode étudiant",
    intensiveTraining: "Trois bonnes réponses ! \nLa prochaine question portera sur cette règle.",
    definitionSource: "Source : Myriade définitions françaises ®",
    additionalExplanation: "Précision de l'expert",
  },
  bottomModal: {
    seeRules: "VOIR TOUTES LES RÈGLES",
    seeProgression: "VOIR LA PROGRESSION",
    autoValidated: {
      title: "Niveau validé d'office",
      message_final: "Aucune erreur n’a été commise dans les niveaux concernés.",
      message:
        "Aucune erreur n’a été commise dans les niveaux concernés. Le niveau suivant a été automatiquement débloqué.",
    },
  },
  helperView: { help: "COUP DE POUCE" },
  [ns.EXERCISE]: {
    click_on_mistake: "Cliquez sur la faute",
    click_on_mistake_dictation: "Écrivez le(s) mot(s) manquant(s).",
    click_on_word: "Cliquez sur le mot.",
    drag_and_drop: "Cliquer / Déposer",
    pick_out_one: {
      double_choices: "Duo de choix",
      triple_choices: "Trio de choix",
      quad_choices: "Carré de choix",
      default: "Carré de choix",
    },
    pick_out_nearest: "Vocabulaire",
    audio_enable: "ACTIVER L'AUDIO",
    audio_disable: "JE NE PEUX PAS ÉCOUTER",
    clue_label: "Révéler un indice",
    clue_subtitle: "Utiliser un indice n'affecte pas le calcul de votre progression.",
    skipExercise: "Exercice suivant",
    pick_out_one_replace_word: "Mot à remplacer",
    email_pick_out_one: "Choisissez la bonne réponse",
    click_on_email: "Cliquez sur la faute.",
    no_error_click_on_email: "ENVOYER (IL N'Y A PAS DE FAUTE)",
  },
  [ns.EVALUATION]: {
    progression: "Progression",
    time: "Temps",
    totalScoreText: "SCORE FINAL",
    total: "/{{score}}",
    defaultTitle: "Évaluation",
  },
  [ns.HOME]: {
    welcome: "Bonjour {{firstName}},",
    continue: "Continuer vos programmes",
    navigateToProfilePage: "Aller à la page de profil",
    openMenu: "Ouvrir le menu",
    pickScenario: "Choisir un scénario",
    pickStudentGroup: "Choisir un groupe d'apprenants",
  },
  [ns.LOGGED_OUT]: {
    main: "Vous avez été déconnecté(e).",
    subtitle: "Vous pouvez fermer cette page et vous reconnecter depuis votre ENT.",
  },
  [ns.LEVEL]: {
    modulesButton: "MODULES",
    profileButton: "PROFIL",
    percent: "{{progress}} %",
    totalTime: "TEMPS TOTAL {{time}}",
    duration: "{{duration_min}}–{{duration_max}} min",
    moduleErrors: {
      errorTitle: "Aucune donnée n’a pu être récupérée. Veuillez réessayer ultérieurement.",
      errorDescription: "Si le problème persiste, contactez le support.",
      emptyLevels: "Vous pouvez recharger la page.",
    },
    levelNumber: "Niveau {{count}}",
    finalRevisionTitle: "Révision finale",
    validationLevelStartOnly: "Validation {{start}}",
    validationLevel: "Validation {{start}}-{{end}}",
    disabled: "Niveau à débloquer.",
    autoValidation: "Validation automatique.",
    levelValidated: "Niveau validé.",
    levelAvailable: "Niveau disponible.",
    inTraining: "Entraînement en cours.",
    inProgress: "En cours",
    intermediateLevelProgression: "Progression des niveaux de révision.",
    previousGrade: "Dernier score : {{score}} %",
    previousGradeEmpty: "Dernier score : Vous n'avez pas encore terminé de test blanc.",
    banner: {
      yes: "OK, J'AI COMPRIS",
      no: "NE PLUS AFFICHER",
      yes_smallWidth: "OK",
      no_smallWidth: "MASQUER",
      message:
        "Votre navigateur n'est pas paramétré en français. Le mode dyslexique et l'audio de certains exercices ne seront pas disponibles.",
      legalMentions: "Mentions légales",
      gdprLabel: "RGPD",
      generalConditions: "CGU",
    },
  },
  [ns.MODAL]: {
    [ModalType.CRUCIAL]: {
      title: "Question cruciale",
      subtitle:
        "Répondez correctement pour collecter une étoile et valider la maîtrise d’une règle.",
    },
    [ModalType.RESULTS]: {
      title: "{{count}} nouvelle règle maîtrisée",
      title_plural: "{{count}} nouvelles règles maîtrisées",
      subtitle: "Continuez votre effort pour valider le niveau.",
      completed: "règles acquises",
      notCompleted: "règles en cours d'acquisition",
      masteredRule: "{{nbRule, number}}",
    },
    [ModalType.RULE_ACQUIRED]: {
      title: "Règle acquise",
      subtitle: "{{rule}}",
    },
    [ModalType.INITIAL_EVALUATION]: {
      headerTitle: "AVERTISSEMENT",
      headerScoreTitle: "FIN DE l'ÉVALUATION",
      subtitle:
        "Vous allez commencer une évaluation initiale.<br />Une évaluation ne s’effectue qu’une seule fois.",
      subtitleResume: "Votre évaluation n'a pas encore été validée.",
      question: "Souhaitez-vous lancer l'évaluation maintenant ?",
      questionResume: "Souhaitez-vous continuer l'évaluation maintenant ?",
      labels: ["Fin", "Temps limité", "Nombre de questions max"],
      resumedLabels: ["Fin", "Temps restant", "Questions restantes (max)"],
      leaveTitle: "Voulez-vous vraiment quitter l’évaluation ?",
      leaveSubtitle:
        "Cela sera considéré comme un abandon et vous n’aurez aucun point sur les questions restantes.",
      finish: "L'évaluation est terminée.",
      finishTimeUp: "Le temps imparti est écoulé.\nL'évaluation est terminée.",
    },
    [ModalType.NEXT_EVALUATION]: {
      headerTitle: "AVERTISSEMENT",
      headerScoreTitle: "FIN DE l'ÉVALUATION",
      bodyTitle: "Une évaluation est disponible.",
      subtitle:
        "Assurez-vous d'avoir bien révisé et d'être dans de bonnes conditions matérielles, vous n'avez droit qu'à une seule tentative.",
      subtitleResume: "Votre évaluation n'a pas encore été validée.",
      question: "Souhaitez-vous démarrer maintenant ?",
      questionResume: "Souhaitez-vous continuer l'évaluation maintenant ?",
      labels: ["Fin", "Temps limité", "Nombre de questions max"],
      resumedLabels: ["Fin", "Temps restant", "Questions restantes (max)"],
      leaveTitle: "Voulez-vous vraiment quitter l’évaluation ?",
      leaveSubtitle:
        "Cela sera considéré comme un abandon et vous n’aurez aucun point sur les questions restantes.",
      finish: "L'évaluation est terminée.",
      finishTimeUp: "Le temps imparti est écoulé.\nL'évaluation est terminée.",
    },
    [ModalType.PRACTICE_TEST]: {
      headerTitle: "TEST BLANC",
      headerScoreTitle: "FIN DU TEST BLANC",
      subtitle:
        "Vous allez commencer un test blanc.<br/>Vous pourrez rejouer ce test une fois terminé.",
      subtitleResume: "Vous avez déjà un test blanc en cours.<br/>Voulez-vous le continuer ?",
      question: "Souhaitez-vous commencer le test blanc maintenant ?",
      questionResume: "Souhaitez-vous continuer le test blanc maintenant ?",
      labels: ["Fin", "Temps limité", "Nombre de questions max"],
      resumedLabels: ["Fin", "Temps restant", "Questions restantes (max)"],
      leaveTitle: "Que souhaitez-vous faire ?",
      finish: "Le test blanc est terminé.",
      finishTimeUp: "Le temps imparti est écoulé.\nLe test blanc est terminé.",
    },
    [ModalType.EVALUATION]: {
      score: "{{score}}/{{total}}",
      clock: "{{spendTime}}",
      trophy: "{{percentage}}%",
      score_caption: "Règle sue",
      score_caption_plural: "Règles sues",
      clock_caption: "Temps passé",
      trophy_caption: "Taux de réussite",
    },
    [ModalType.WELCOME]: {
      headerTitle: "Bienvenue dans le Projet Voltaire",
      labels: [
        "Privilégiez des sessions de 15 minutes",
        "Fixez-vous une routine de 3 ou 4 sessions par semaine",
        "Laissez-vous guider dans le parcours et constatez rapidement votre progression",
      ],
    },
    [ModalType.CONGRATS]: {
      title: "Bravo !",
      subtitle: "Vous avez réussi ce niveau.",
    },
    [ModalType.IA]: {
      title: "Le saviez-vous ?",
      subtitle:
        "Pour un apprentissage plus rapide, l’entraînement est constamment personnalisé en fonction des réponses.",
    },
    [ModalType.TEXT_TO_SPEECH]: {
      title: "Fonctionnalités sonores",
      subtitle:
        "Sur les questions cruciales, les exercices sonores permettent d'écouter une phrase dictée et d'écrire vous-même une partie de celle-ci. Cette fonctionnalité nécessite l'activation du son (haut-parleurs, casques, écouteurs).\n\nCertaines fenêtres de révision intensive sont remplacées par des vidéos explicatives, le son est nécessaire à leur compréhension.",
    },
    [ModalType.ERROR]: {
      generic: {
        headerTitle: "Erreur",
        title: "Une erreur est survenue. Veuillez recharger la page.",
      },
      server: {
        headerTitle: "SERVEUR INDISPONIBLE",
        title: "Le serveur est temporairement indisponible. Veuillez réessayer plus tard.",
        subtitle:
          "Si le problème persiste, veuillez contacter le support avec le code d'erreur {{status}}.",
      },
      internet: {
        headerTitle: "ERREUR RÉSEAU",
        title: "Veuillez vérifier l'état de votre connexion internet et actualiser la page.",
      },
      engine: {
        title: "Une erreur est survenue lors de la sélection de la règle ou de l'exercice.",
      },
      resumeLevelOrEval: {
        title: "Une erreur est survenue lors de la récupération de la progression.",
      },
      nextExercise: {
        title: "Une erreur est survenue lors du chargement du prochain exercice.",
      },
      evaluation: {
        title: "Une erreur est survenue lors du lancement de l'évaluation.",
      },
      levelStart: {
        title: "Une erreur est survenue lors du lancement du niveau.",
      },
    },
    [ModalType.INTENSIVE]: {
      title: "Cette règle est difficile pour vous ?",
      subtitle: "Prenons le temps de revoir cette règle.",
    },
    [ModalType.LOCK]: {
      title: "Niveau verrouillé",
      subtitle: "Complétez les niveaux précédents pour accéder à ce niveau.",
    },
    [ModalType.COOLDOWN]: {
      title: "Niveau de validation verrouillé",
      subtitle: "Vous devez patienter 36 h entre la fin du dernier niveau et la validation.",
    },
    [ModalType.FINAL_COOLDOWN]: {
      title: "Validation finale verrouillée",
      subtitle: "Vous devez patienter 36 h entre la fin du dernier niveau et l'évaluation finale.",
    },
    [ModalType.RETRY]: {
      title: "Rejouer le niveau",
      subtitle: "Rejouer un niveau n’affectera pas la progression.",
    },
  },
  [ns.PROFILE]: {
    statistics: {
      totalTrainingTime: "Temps d'entraînement",
      masteredRules: "Règles maîtrisées",
    },
    dataPickerLegend: "Modules",
    graphEmptyState: "Pas encore de données à afficher",
    dataPickerEmpty: "Liste vide",
    dataPickerPlaceholder: "Sélectionner une option",
  },
  [ns.SETTINGS]: {
    title: "Paramètres",
    accessibility: "Accessibilité",
    hints: "Indices",
    audio: "Audio",
    disconnect: "Déconnexion",
    theme: {
      title: "Thème",
      type: {
        system: "Système",
        light: "Clair",
        dark: "Sombre",
      },
    },
    version: "version {{versionNumber}}",
    devMode: {
      title: "Mode développeur",
      noInitialAssessment: "Pas d'évaluation initiale",
      consultationMode: "Activer le mode consultation",
      skipExercises: "Passer les exercices",
      seeCorrection: "Voir les corrections",
      openAllLevelsAndModules: "Ouvrir tous les modules et niveaux",
      reduceTimeLimit: "Passer toutes les limites de temps à 1 min",
    },
  },
  [ns.CONSULTATION]: {
    activate: "Mode consultation activé",
    crucial: "Question cruciale",
    deactivate: "Mode consultation désactivé",
    end: "Vous êtes sur le dernier exercice.",
    error: {
      rule: "Erreur sur la liste des règles",
      key: "Identifiant ou index incorrect",
      content: "Contenu introuvable",
    },
    contentTitle: "Liste des identifiants contenant le texte recherché : ",
    content: "Contenu d'un exercice",
    exId: "Identifiant de l'exercice : ",
    exIndex: "Index de l'exercice : ",
    ruleId: "Identifiant de la règle : {{ruleId}}",
    ruleTitle: "Titre de la règle : {{ruleTitle}}",
    start: "Vous êtes sur le premier exercice.",
    mobile: {
      id: "id :",
      index: "index :",
      rule: "règle : {{ruleId}}",
      title: "titre : {{ruleTitle}}",
      content: "Recherche",
      contentTitle: "liste : ",
      contentId: "{{id}} || ",
    },
  },
  [ns.LOGIN]: {
    title: "Connexion QA",
    toLog: "Se connecter",
    labels: ["Utilisateur", "Mot de passe"],
    error: "Nom d'utilisateur ou mot de passe non valide",
  },
  [ns.GAR_LOGIN]: {
    noScenario: "Scénario non trouvé",
    redirectEnt: "Cliquez ici pour retourner sur votre ENT et pouvoir choisir",
    error: {
      UNAUTHORIZED: "Authentification incorrecte",
      default: "Erreur de connexion",
      noLocalUrl: "Aucune URL locale trouvée",
      noScenarioId: "Paramètre de scénario manquant",
      invalidScenarioId: "Paramètre de scénario incorrect",
      noTicket: "Récupération du ticket GAR",
    },
    state: {
      initial: "Connexion",
      gettingTicket: "Récupération du ticket GAR",
      gettingToken: "Récupération du jeton d'authentification",
    },
  },
  [ns.OAUTH_LOGIN]: {
    title: "Se connecter par Showcase",
    toLog: "Se connecter",
    error: {
      default: "Erreur inconnue",
      no_token: "Authentification vide",
      cancel: "Annulé",
      dismiss: "Fermé",
      error: "Erreur",
      locked: "Bloqué",
      opened: "Ouvert",
    },
    state: {
      initial: "Initialisation",
      ready: "Prêt",
      oauth: "Récupération du jeton oauth",
      gettingToken: "Récupération du jeton d'authentification",
      done: "Authentification terminée",
    },
  },
  intensiveTraining: { understood: "J'ai compris." },
  [ns.LOADING]: {
    quotes: [
      "« Le Français voue un culte lunaire aux bizarreries de la langue française. C’est comme ça. Ce qu’il aime dans la règle, c’est l’exception. »",
      "« Il y a en France deux sortes de ridicules qui tuent : n’être pas habillé à la dernière mode et faire des fautes d’orthographe. »",
    ],
  },
  email: {
    headers: {
      from: "De :",
      to: "À :",
      cc: "CC :",
      cci: "CCi :",
      object: "Objet :",
      attachment: "PJ :",
    },
    options: {
      click: "Cliquez sur la bonne réponse",
      show: "Voir les propositions",
      hide: "Voir l'exercice",
    },
  },
  links: [
    { label: "legalMentions", url: "https://www.projet-voltaire.fr/mentions-legales/" },
    {
      label: "gdprLabel",
      url: "https://www.projet-voltaire.fr/pv-wp/wp-content/uploads/2020/08/Projet-Voltaire-Politique-de-confidentialit%C3%A9-GAR.docx.pdf",
    },
    {
      label: "generalConditions",
      url: "https://www.projet-voltaire.fr/pv-wp/wp-content/uploads/2020/08/Projet-Voltaire-CGU.pdf",
    },
  ],
}
