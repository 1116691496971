import type { SvgProps } from "react-native-svg"
import Svg, { Path } from "react-native-svg"
import useTheme from "theme/ThemeProvider"

const MoonSVG = (props?: SvgProps): JSX.Element => {
  const {
    colors: {
      onSurface: { mediumEmphasis },
    },
  } = useTheme()
  return (
    <Svg {...props} width={24} height={24} fill="none">
      <Path
        d="M19 15C13.5 15 9 10.5 9 5c0-.9.1-1.8.4-2.6C5.1 3.5 2 7.4 2 12c0 5.5 4.5 10 10 10 4.6 0 8.5-3.1 9.6-7.4-.8.3-1.7.4-2.6.4Z"
        stroke={props?.color ?? mediumEmphasis}
        strokeOpacity={0.6}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default MoonSVG
