import type {
  Level,
  LevelId,
  LevelRevision,
  LevelStatic,
  LevelType,
  ModuleId,
  ProgressionData,
  ScenarioId,
} from "@newpv/js-common"
import { ActivityIndicator } from "components/ActivityIndicator/ActivityIndicator"
import { Graph } from "components/Graph"
import { ModalRulesStatusContent } from "components/Modal"
import { H5, Subtitle2 } from "components/Texts"
import { isWeb, maxScreenWidth, modal } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import useTypedTranslation from "hooks/useTypedTranslation"
import _ from "lodash"
import { getRevisionRulesTitles } from "models/RevisionLevelFunctions"
import { useLevelAndEvaluation } from "providers/LevelAndEvaluationProvider"
import { useScenarioAndModule } from "providers/ScenarioAndModuleProvider"
import type { FC } from "react"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { ScrollView, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import BottomAutoValidatedLevel from "screens/LevelsScreen/components/BottomAutoValidatedLevel"
import { StatsBlock } from "screens/ProfileScreen/StatsBlock"
import StarSVG from "svgs/Star"
import { formatTimeByDuration } from "utils/formatTime"

import { BUTTON_CONTAINER_HEIGHT } from "./BottomModal"

interface IProps {
  scenarioId: ScenarioId
  progressionData: ProgressionData
  displayedModuleId: ModuleId
  displayedLevelIdAndType: { id: LevelId; type?: LevelType }
  level?: Level
}

const BottomDisplayedLevel: FC<IProps> = ({
  scenarioId,
  progressionData,
  displayedLevelIdAndType,
  displayedModuleId,
  level,
}) => {
  const t = useTypedTranslation()
  const { bottom: bottomInset } = useSafeAreaInsets()
  const { height: screenHeight, width: windowWidth } = useWindowDimensions()
  const { onLayout: onViewLayout, height: viewHeight, width: viewWidth } = useLayout()

  const { module } = useScenarioAndModule()
  const { seeMasteredRules, setIsRuleListEmpty } = useLevelAndEvaluation()
  const { isSmallWidth } = useDeviceTools()
  const isRevision = level?.type === "revision" || level?.type === "finalRevision"

  const moduleProgression = useMemo(
    () => progressionData.progressionDetail?.modules?.[displayedModuleId],
    [displayedModuleId, progressionData.progressionDetail?.modules],
  )

  const graphData = useMemo(
    () => moduleProgression?.levels?.[displayedLevelIdAndType.id]?.graph ?? [],
    [displayedLevelIdAndType.id, moduleProgression?.levels],
  )

  const [masteredRules, setMasteredRules] = useState<string[]>([])
  const [unmasteredRules, setUnmasteredRules] = useState<string[]>([])
  const [totalTrainingTime, setTotalTrainingTime] = useState<string>()
  const isAutoValidated =
    isRevision && _.isEmpty(moduleProgression?.levels?.[level.id]?.revisionRuleIds)

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      dimensions: { margin, spacing },
      colors: {
        surface: { backgroundModal },
      },
    }) => ({
      contentContainer: {
        flexDirection: isWeb && !isSmallWidth ? "row" : "column",
        backgroundColor: backgroundModal,
        paddingBottom: bottomInset + BUTTON_CONTAINER_HEIGHT,
        minHeight: isWeb ? "100%" : undefined,
        alignItems: isWeb && !isSmallWidth ? "center" : undefined,
      },
      fullHeightWidth: {
        height: "100%",
        width: "100%" as const,
      },
      graphContainer: {
        marginTop: -margin * 2,
        flex: 1,
        paddingLeft: spacing,
        paddingRight: spacing / 2,
        paddingTop: 0,
      },
      levelTitle: {
        alignSelf: "center",
        marginBottom: 0,
        padding: spacing,
        textAlign: "center",
      },
      wrapper: {
        flex: 1,
        height: "100%",
        minHeight: modal.SCROLL_VIEW,
      },
      statsContainer: {
        alignSelf: "center",
        flexBasis: "33%",
        flexDirection: "column",
        padding: !isSmallWidth && isWeb ? undefined : spacing,
        paddingRight: isWeb && !isSmallWidth ? spacing : undefined,
      },
    }),
    [isSmallWidth, isWeb],
  )

  useEffect(() => {
    if (isRevision) {
      ;(async () => {
        setMasteredRules(
          await getRevisionRulesTitles(
            level as LevelRevision,
            module?.levels,
            _.flatten(moduleProgression?.levels?.[level.id]?.revisionRuleIds),
          ),
        )
      })()
      return
    }
    const ruleProgressionForSelectedLevel =
      _.values(moduleProgression?.levels?.[displayedLevelIdAndType.id]?.rules) ?? []
    const partitionedRuleInfos = _(ruleProgressionForSelectedLevel)
      .partition(rule => rule.completionPercentage >= 100)
      .value()
    setMasteredRules(_.compact(partitionedRuleInfos[0].map(rule => rule.title)))
    setUnmasteredRules(_.compact(partitionedRuleInfos[1].map(rule => rule.title)))
  }, [displayedLevelIdAndType.id, isRevision, level, module?.levels, moduleProgression?.levels])

  useEffect(() => {
    if (!_.isNil(masteredRules) && !_.isNil(unmasteredRules)) {
      setIsRuleListEmpty(masteredRules.length === 0 && unmasteredRules.length === 0)
    }
  }, [masteredRules, setIsRuleListEmpty, unmasteredRules])

  // Loading for DisplayedLevel
  useEffect(() => {
    setTimeout(
      () =>
        setTotalTrainingTime(
          scenarioId && displayedModuleId && displayedLevelIdAndType
            ? formatTimeByDuration(
                moduleProgression?.levels?.[displayedLevelIdAndType.id]?.trainingDuration / 1000 ||
                  0,
                t,
              )
            : formatTimeByDuration(0, t),
        ),
      500,
    )
  }, [displayedLevelIdAndType, displayedModuleId, moduleProgression?.levels, scenarioId, t])

  const renderStats = (
    <View style={isWeb && !isSmallWidth ? s.statsContainer : cs.statsContainer}>
      <View style={cs.block}>
        <StatsBlock title={t("Profile.statistics.totalTrainingTime")}>
          <H5>{totalTrainingTime}</H5>
        </StatsBlock>
      </View>
      {level?.type !== "practiceTest" ? (
        <View style={cs.block}>
          <StatsBlock title={t("Profile.statistics.masteredRules")}>
            <View style={cs.statsContainer}>
              <StarSVG />
              <H5 style={cs.paddingLeft}>{masteredRules?.length}</H5>
              <Subtitle2 style={cs.subtitleScore}>
                /{isRevision ? masteredRules.length : (level as LevelStatic)?.rulesNbr || 0}
              </Subtitle2>
            </View>
          </StatsBlock>
        </View>
      ) : null}
    </View>
  )

  const progressionPerSteps = moduleProgression?.levels?.[displayedLevelIdAndType.id]?.graph ?? []

  const Wrapper = isWeb ? View : Fragment

  const GlobalWrapper = isAutoValidated ? ScrollView : View

  const scrollViewRef = useRef<ScrollView | null>(null)

  // to flash the scroll bar on native apps
  useEffect(() => {
    setTimeout(function () {
      scrollViewRef.current?.flashScrollIndicators()
    }, 500)
  }, [])

  return (
    <GlobalWrapper style={isAutoValidated ? undefined : s.fullHeightWidth}>
      <H5 style={s.levelTitle} numberOfLines={2}>
        {level?.title}
      </H5>
      {isAutoValidated ? (
        <BottomAutoValidatedLevel isFinal={level.type === "finalRevision"} />
      ) : !(masteredRules?.length === 0 && unmasteredRules.length === 0) &&
        (seeMasteredRules || _.isEmpty(progressionPerSteps)) ? (
        <Wrapper {...(isWeb ? { style: s.wrapper } : undefined)}>
          <ModalRulesStatusContent
            isBottom={true}
            masteredRules={_.compact(masteredRules)}
            unmasteredRules={_.compact(unmasteredRules)}
          />
        </Wrapper>
      ) : !totalTrainingTime ? (
        <ActivityIndicator />
      ) : (
        <ScrollView contentContainerStyle={s.contentContainer} ref={scrollViewRef}>
          <View onLayout={isWeb ? onViewLayout : undefined} style={s.graphContainer}>
            <Graph
              data={graphData}
              withGradient={true}
              height={isWeb ? viewHeight : screenHeight / 2.5}
              width={isWeb ? viewWidth : windowWidth > maxScreenWidth ? maxScreenWidth : undefined}
            />
          </View>
          {renderStats}
        </ScrollView>
      )}
    </GlobalWrapper>
  )
}

export default BottomDisplayedLevel
