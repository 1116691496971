import { type WordBlock } from "@newpv/js-common"
import type { IBodyProps } from "components/Texts"
import { HIGHLIGHT_OVERFLOW, isAndroid, isIpadWeb, isWeb } from "constants/constants"
import useCommonStyles from "hooks/useCommonStyles"
import useDeviceTools from "hooks/useDeviceTools"
import useLayout from "hooks/useLayout"
import { useStyles } from "hooks/useStyles"
import { useWordBlockLayout } from "hooks/useWordBlockLayout"
import type { ComponentType, Dispatch, SetStateAction } from "react"
import { Fragment, useMemo } from "react"
import type { LayoutChangeEvent, TextStyle } from "react-native"
import { View } from "react-native"
import { TextInput } from "react-native-paper"
import useTheme from "theme/ThemeProvider"
import type { TypographyVariant } from "theme/types"
import parseSentence, {
  getFontStyle,
  useGetBaseFontConfigFromFontFamily,
} from "utils/parseSentence"

const CLUE_BACKGROUND_COLOR = "#AC9AF5"

interface Props {
  wordBlock: WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }
  wordBlocks: Array<WordBlock & { afterNoSpace?: boolean; endsWithSpace?: boolean }>
  index: number
  Comp: ComponentType<IBodyProps>
  baseTypography: TypographyVariant
  isCorrectAnswer?: boolean
  isIntensiveTrainingSentence?: boolean
  exerciseHasMistake: boolean
  isDictation?: boolean
  dictationAnswers?: Record<string, string> | undefined
  setDictationAnswers?: Dispatch<SetStateAction<Record<string, string> | undefined>>
  exerciseHasAnExplicitClue: boolean
  parentWidth?: number
  exerciseHasAnExplicitCorrection: boolean
}

const MIN_INPUT_WIDTH = 100

const CorrectionSentenceWordBlock = ({
  wordBlock,
  index,
  Comp,
  baseTypography,
  isIntensiveTrainingSentence,
  isCorrectAnswer,
  isDictation,
  exerciseHasMistake,
  dictationAnswers,
  setDictationAnswers,
  exerciseHasAnExplicitClue,
  exerciseHasAnExplicitCorrection,
  wordBlocks,
  parentWidth,
}: Props): JSX.Element => {
  const {
    colors: { primary_400, onSurface },
    dimensions: { margin: themeMargin, spacing: themePadding },
    typography,
    fontMaker,
  } = useTheme()
  const { splitBlockArray, getUnderlineOrHighlightLength, onLayout, itemsDataArray } =
    useWordBlockLayout(wordBlock)
  const { width: inputWidth, onLayout: onTextLayout } = useLayout()

  const { height, onLayout: onSimpleLayout } = useLayout()

  const baseFontConfig = useGetBaseFontConfigFromFontFamily(baseTypography)

  const fontStyle = {
    ...getFontStyle(baseFontConfig, wordBlock, fontMaker),
    ...(isDictation ? { marginTop: themeMargin * 1.5 } : null),
    color: onSurface.highEmphasis,
  }

  const deviceInfo = useDeviceTools()

  const cs = useCommonStyles()

  const s = useStyles(
    ({ dimensions: { spacing }, typography: { body1 } }) => ({
      commonStyle: { paddingHorizontal: spacing * 0.375 },
      highlightPosition: {
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 0,
      },
      textInput: {
        borderRadius: 0,
        fontFamily: typography[baseTypography].fontFamily,
        height: (typography[baseTypography].fontSize ?? 0) + spacing * 0.75,
        minWidth: MIN_INPUT_WIDTH,
        width: inputWidth ?? MIN_INPUT_WIDTH,
        maxWidth: parentWidth,
      },
      textInputHiddenComponent: {
        alignSelf: "flex-start",
        fontFamily: fontMaker({ family: "title", weight: "SemiBold" }).fontFamily,
        fontSize: body1.fontSize,
        height: isIpadWeb ? 1 : 0,
        overflow: "hidden",
        color: "transparent",
      },
    }),
    [inputWidth],
  )

  const highlightIntensiveTraining = isIntensiveTrainingSentence && isCorrectAnswer === false

  const highlightColors = useMemo(() => {
    if (!highlightIntensiveTraining) {
      if (
        wordBlock.correction ||
        (!isIntensiveTrainingSentence && !exerciseHasAnExplicitCorrection && wordBlock.clue)
      ) {
        return {
          backgroundColor: primary_400,
          textColor: onSurface.button,
        }
      }
      return {
        backgroundColor: "transparent",
        textColor: onSurface.highEmphasis,
      }
    }
    return {
      backgroundColor: (
        exerciseHasAnExplicitClue ? wordBlock.clue : wordBlock.mistake || wordBlock.correction
      )
        ? CLUE_BACKGROUND_COLOR
        : "transparent",
      textColor: onSurface.highEmphasis,
    }
  }, [
    exerciseHasAnExplicitClue,
    exerciseHasAnExplicitCorrection,
    highlightIntensiveTraining,
    isIntensiveTrainingSentence,
    onSurface.button,
    onSurface.highEmphasis,
    primary_400,
    wordBlock.clue,
    wordBlock.correction,
    wordBlock.mistake,
  ])

  return (
    <Fragment key={`${wordBlock.text}-${index}`}>
      {index === 0 ||
      (index > 0 &&
        (wordBlocks[index - 1].breakAfter ||
          wordBlocks[index - 1].endsWithSpace ||
          // for noSpaceAfter, we do display a space, but outside of the component
          wordBlock.noSpaceAfter ||
          wordBlock.afterNoSpace)) ? null : (
        <Comp selectable={false} style={fontStyle}>
          {" "}
        </Comp>
      )}
      {wordBlock.before ? (
        <View>
          <Comp selectable={false} style={fontStyle}>
            {wordBlock.before}
          </Comp>
        </View>
      ) : null}
      {isDictation &&
      ((!exerciseHasMistake && wordBlock.clue) || (exerciseHasMistake && wordBlock.mistake)) ? (
        <View style={fontStyle}>
          <TextInput
            // @ts-expect-error
            autoComplete={isWeb ? "none" : "off"}
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType={deviceInfo.isDesktop ? "default" : "email-address"}
            textContentType="none"
            value={dictationAnswers?.[index] ?? ""}
            onChangeText={input => {
              setDictationAnswers?.(prevState => ({ ...prevState, [index]: input }))
            }}
            underlineColor="transparent"
            activeUnderlineColor={primary_400}
            theme={{
              roundness: 0,
              fonts: {
                regular: {
                  fontFamily: fontMaker({ family: "title", weight: "SemiBold" }).fontFamily,
                },
              },
            }}
            style={[
              s.textInput,
              s.commonStyle,
              isAndroid ? { paddingTop: themePadding / 2 } : { paddingTop: themePadding / 4 },
            ]}
          />
          <Comp
            selectable={false}
            style={[s.textInputHiddenComponent, s.commonStyle]}
            onLayout={onTextLayout}
          >
            {dictationAnswers?.[index]}
          </Comp>
        </View>
      ) : (
        splitBlockArray.map((subBlock, subBlockIndex) => {
          const parsedTextWithoutMarkup = parseSentence(
            subBlock,
            typography[baseTypography].fontSize ?? 24,
          )

          return (
            <View
              key={`${subBlock}-${subBlockIndex}`}
              style={{ flexDirection: "row" }}
              onLayout={(event: LayoutChangeEvent) => {
                onLayout(event, subBlockIndex)
              }}
            >
              {itemsDataArray?.[subBlockIndex].x === 0 &&
              subBlock === " " &&
              !isDictation ? null : (
                <>
                  <View
                    style={[
                      s.highlightPosition,
                      {
                        width: getUnderlineOrHighlightLength(subBlockIndex)
                          ? getUnderlineOrHighlightLength(subBlockIndex) + HIGHLIGHT_OVERFLOW
                          : 0,
                        height,
                        backgroundColor: highlightColors.backgroundColor,
                      },
                    ]}
                  />
                  {parsedTextWithoutMarkup.map((item, itemIndex) => (
                    <View
                      key={`${item.content}-${itemIndex}`}
                      style={{ flexDirection: "row" }}
                      onLayout={onSimpleLayout}
                    >
                      <Comp
                        selectable={false}
                        style={[
                          fontStyle,
                          item.style as TextStyle,
                          {
                            zIndex: 10,
                            color: highlightColors.textColor,
                          },
                        ]}
                      >
                        {item.content}
                      </Comp>
                    </View>
                  ))}
                </>
              )}
            </View>
          )
        })
      )}
      {wordBlock.after ? (
        <View>
          <Comp selectable={false} style={fontStyle}>
            {wordBlock.after}
          </Comp>
        </View>
      ) : null}
      {wordBlock.breakAfter ? <View style={cs.fullWidth} /> : null}
    </Fragment>
  )
}

export default CorrectionSentenceWordBlock
